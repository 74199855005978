import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import DeleteDialog from './DeleteDialog';
import Divider from '../../../components/Divider';
import FlexScrollbar from '../../../components/FlexScrollbar';
import NameField from '../common/NameField';
import Spacer from '../../../components/helpers/Spacer';
import ReminderFrequencyField from '../common/ReminderFrequencyField';
import TabLayoutContainer from '../../../components/tabLayout/TabLayoutContainer';
import TabLayoutPage from '../../../components/tabLayout/TabLayoutPage';
import TabNavigation from '../../../components/tabLayout/TabNavigation';
import TabSubNavigation from '../../../components/tabLayout/TabSubNavigation';
import {
  getFriend,
  getFriendByShortId,
  getFriendStatus,
  updateFriend,
  deleteFriend,
} from '../../../reducers/friend';
import formValidator from '../common/formValidator';
import useRefHeight from '../../../utils/hooks/useRefHeight';
import NotFoundPage from '../../system/NotFoundPage';

const EditFriendPage = () => {
  const { friendId } = useParams();
  const _byFullId = useSelector((state) => getFriend(state, friendId));
  const _byShortId = useSelector((state) =>
    getFriendByShortId(state, friendId)
  );
  const friend = _byFullId || _byShortId;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { isFetching } = useSelector(getFriendStatus);
  const [navHeight, navRef] = useRefHeight();
  const [subNavHeight, subNavRef] = useRefHeight();
  const [footerHeight, footerRef] = useRefHeight();
  const dispatch = useDispatch();
  const history = useHistory();

  if (!friend) {
    return <NotFoundPage />;
  }

  const submit = ({ name, reminder_frequency }) => {
    toast.dismiss();
    return dispatch(
      updateFriend(friend.id, name, parseInt(reminder_frequency, 10))
    )
      .then(() => {
        history.push('/planner');
        toast.success('Friend succesfully updated.', {
          autoClose: 4000,
        });
      })
      .catch((err) => {
        toast.error(err.error);
      });
  };

  const handleDelete = () => {
    toast.dismiss();
    return dispatch(deleteFriend(friend.id))
      .then(() => {
        history.push('/planner');
        toast.success('Friend succesfully deleted.', {
          autoClose: 4000,
        });
      })
      .catch((err) => {
        toast.error(err.error);
      });
  };

  return (
    <React.Fragment>
      <Helmet title="Edit Friend" />
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        name={friend.name}
        entryCount={friend.entry_count}
        onAction={handleDelete}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
      <TabLayoutContainer
        navigation={<TabNavigation ref={navRef} active="planner" hasSubNav />}
      >
        <TabSubNavigation ref={subNavRef} backButtonTo="/planner">
          Edit Friend
        </TabSubNavigation>

        <Form
          initialValues={{
            name: friend.name,
            reminder_frequency: `${friend.reminder_frequency}`,
          }}
          onSubmit={submit}
          validate={formValidator}
          render={({ handleSubmit, invalid }) => (
            <TabLayoutPage as="form" onSubmit={handleSubmit}>
              <FlexScrollbar
                refHeights={[navHeight, subNavHeight, footerHeight]}
              >
                <TabLayoutPage.Body padding={15}>
                  <Spacer margin={15} />
                  <NameField />
                  <ReminderFrequencyField />
                </TabLayoutPage.Body>
              </FlexScrollbar>

              <TabLayoutPage.Footer ref={footerRef} padding={15}>
                <Divider margin={15} />
                <Button
                  type="submit"
                  variant="primary"
                  size="block"
                  disabled={isFetching || invalid}
                >
                  Save Changes
                </Button>
                <Spacer />
                <Button
                  type="button"
                  variant="danger"
                  size="block"
                  disabled={isFetching}
                  onClick={() => setIsDeleteDialogOpen(true)}
                >
                  Delete Friend
                </Button>
              </TabLayoutPage.Footer>
            </TabLayoutPage>
          )}
        />
      </TabLayoutContainer>
    </React.Fragment>
  );
};

export default EditFriendPage;
