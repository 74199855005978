import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CenterRow from '../../../components/helpers/CenterRow';
import H1 from '../../../components/H1';
import Wordmark from '../../../components/svg/branding/Wordmark';

const PaddingBox = styled.div`
  padding: 50px 0;
  margin: 0 auto;
`;

const Tagline = styled.div`
  font-size: 18px;
  text-align: center;
  width: 270px;
  margin: 15px auto 0 auto;
`;

const LogoWithTagline = () => (
  <PaddingBox>
    <CenterRow>
      <H1>
        <Link to="/" title="Tendship">
          <Wordmark width={183} height={40} />
        </Link>
      </H1>
    </CenterRow>
    <Tagline>Your relationship planner and private journal.</Tagline>
  </PaddingBox>
);

export default LogoWithTagline;
