import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import JournalIcon from '../svg/navigation/JournalIcon';
import PlannerIcon from '../svg/navigation/PlannerIcon';
import SearchIcon from '../svg/navigation/SearchIcon';
import OptionsIcon from '../svg/navigation/OptionsIcon';
import MediaWidths from './MediaWidths';
import {
  WHITE_000,
  GREY_200,
  GREY_600,
  TEAL_100,
  TEAL_700,
  TEAL_800,
} from '../../theme';

const Background = styled(MediaWidths)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${WHITE_000};

  min-height: 55px;
`;

const TabLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

const Tab = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;

  border-bottom: 3px solid
    ${({ isHighlighted }) => (isHighlighted ? TEAL_700 : GREY_200)};
  height: 55px;

  &:hover {
    background-color: ${TEAL_100};
  }
`;

const ImageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
`;

const LabelWrapper = styled.div`
  box-sizing: border-box;
  font-size: 10px;
  font-weight: 700;
  color: ${({ color }) => color};
  margin: 2px 0 4px 0;
`;

const TABS = [
  {
    id: 'journal',
    label: 'Journal',
    image: JournalIcon,
    url: '/',
  },
  {
    id: 'planner',
    label: 'Planner',
    image: PlannerIcon,
    url: '/planner',
  },
  {
    id: 'search',
    label: 'Search',
    image: SearchIcon,
    url: '/search',
  },
  {
    id: 'options',
    label: 'Options',
    image: OptionsIcon,
    url: '/options',
  },
];

const TabNavigation = React.forwardRef(({ active, hasSubNav }, ref) => (
  <Background ref={ref}>
    {TABS.map(({ id, label, image: Image, url }) => {
      const isActive = active === id;
      return (
        <TabLink key={id} to={url}>
          <Tab isHighlighted={hasSubNav || isActive}>
            <ImageWrapper>
              <Image color={isActive ? TEAL_700 : GREY_600} />
            </ImageWrapper>
            <LabelWrapper color={isActive ? TEAL_800 : GREY_600}>
              {label.toUpperCase()}
            </LabelWrapper>
          </Tab>
        </TabLink>
      );
    })}
  </Background>
));

export default TabNavigation;
