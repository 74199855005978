import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import ActiveState from './ActiveState';
import EmptyState from './EmptyState';
import EntryEditor from '../../../components/EntryEditor';
import FlexScrollbar from '../../../components/FlexScrollbar';
import SwipeNavigation from '../../../components/SwipeNavigation';
import TabLayoutContainer from '../../../components/tabLayout/TabLayoutContainer';
import TabNavigation from '../../../components/tabLayout/TabNavigation';
import TabLayoutPage from '../../../components/tabLayout/TabLayoutPage';
import { getDraftText } from '../../../reducers/editor';
import { getIsExtendable, extendTimeline } from '../../../reducers/thread';
import { getFriendList } from '../../../reducers/friend';
import { getEntryList, fetchEntriesForThreads } from '../../../reducers/entry';
import { getThreadId } from '../../../utils/getThreadId';
import useRefHeight from '../../../utils/hooks/useRefHeight';
import useCallbackState from '../../../utils/hooks/useCallbackState';

const JournalPage = () => {
  const isExtendable = useSelector(getIsExtendable);
  const draftText = useSelector(getDraftText);
  const friendList = useSelector(getFriendList);
  const entryList = useSelector(getEntryList);
  const [navHeight, navRef] = useRefHeight();
  const [footerHeight, footerRef] = useRefHeight([draftText]);
  const [scrollbar, getScrollbarRef] = useCallbackState();
  const dispatch = useDispatch();
  const threadId = getThreadId(new Date());
  const isEmptyState = !friendList.length && !entryList.length;
  const padding = isEmptyState ? 15 : 0;

  const handleOnScrollTop = async () => {
    if (!isExtendable) {
      return;
    }
    const pos = scrollbar.scrollTop;
    if (pos === 0) {
      // Move the scrollbar down by 1px to keep previous position.
      scrollbar.scrollTo(undefined, 1);
      dispatch(extendTimeline());
      await dispatch(fetchEntriesForThreads());
    }
  };

  const scrollToBottom = () => {
    scrollbar.scrollToBottom();
    // Hack: Wait for keyboard animation
    setTimeout(() => {
      scrollbar.scrollToBottom();
    }, 100);
    setTimeout(() => {
      scrollbar.scrollToBottom();
    }, 150);
  };

  return (
    <SwipeNavigation rightTo={'/planner'}>
      <Helmet title="Journal" />
      <TabLayoutContainer
        navigation={<TabNavigation ref={navRef} active="journal" />}
      >
        <TabLayoutPage>
          <TabLayoutPage.Header padding={padding} />
          <FlexScrollbar
            refHeights={[navHeight, footerHeight]}
            onScrollStop={handleOnScrollTop}
            onReady={getScrollbarRef}
            reverse
          >
            <TabLayoutPage.Body flexEnd={!isEmptyState} padding={padding}>
              {isEmptyState && <EmptyState />}
              {!isEmptyState && <ActiveState />}
            </TabLayoutPage.Body>
          </FlexScrollbar>
          <TabLayoutPage.Footer ref={footerRef}>
            {!isEmptyState && (
              <EntryEditor
                threadId={threadId}
                onFocus={scrollToBottom}
                onSubmit={scrollToBottom}
              />
            )}
          </TabLayoutPage.Footer>
        </TabLayoutPage>
      </TabLayoutContainer>
    </SwipeNavigation>
  );
};

export default JournalPage;
