import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Dialog from '../Dialog';
import EditEntryBar from './EditEntryBar';
import EntryInput from './EntryInput';
import QuillEntryEditor from './QuillEntryEditor';
import {
  getDraftText,
  getEditText,
  getDraftFriendIds,
  getEditFriendIds,
  setDraftText,
  setEditText,
} from '../../reducers/editor';
import {
  getEntryStatus,
  createEntry,
  updateEntry,
  deleteEntry,
} from '../../reducers/entry';
import { getDateFromThreadId } from '../../utils/getThreadId';
import getRelativeDay from '../../utils/getRelativeDay';

const EntryEditor = ({
  threadId,
  entryId,
  onReady = () => {},
  onDone = () => {},
  onSubmit = () => {},
  onFocus = () => {},
  onEscape = () => {},
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [activeText, setActiveText] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);
  const [editor, setEditor] = useState(null);
  const { isFetching } = useSelector(getEntryStatus);
  const draftText = useSelector(getDraftText);
  const editText = useSelector(getEditText);

  const draftFriendIds = useSelector(getDraftFriendIds);
  const editFriendIds = useSelector(getEditFriendIds);
  const dispatch = useDispatch();
  const threadDate = getDateFromThreadId(threadId);
  const weekday = getRelativeDay(threadDate);

  useEffect(
    () => {
      const text = entryId ? editText : draftText;
      setActiveText(text);
      setIsEmpty(!text.trim());
    },
    // eslint-disable-next-line
    [entryId]
  );

  const editorRef = useCallback(
    (node) => {
      setEditor(node);
      onReady(node);
    },
    // eslint-disable-next-line
    []
  );

  const handleEditorChange = useCallback(
    ({ text, friendIds }) => {
      if (entryId) {
        dispatch(setEditText(entryId, text, friendIds));
        setActiveText(text);
        setIsEmpty(!text.trim());
        return;
      }
      dispatch(setDraftText(text, friendIds));
      setActiveText(text);
      setIsEmpty(!text.trim());
    },
    // eslint-disable-next-line
    [entryId]
  );

  const handleMention = useCallback(() => {
    if (!editor) {
      return;
    }
    const caretPosition = editor.getSelection(true);
    editor.insertText(caretPosition, '@', 'user');
    editor.setSelection(caretPosition.index + 1);
  }, [editor]);

  const handleSave = async () => {
    toast.dismiss();
    try {
      if (entryId) {
        await dispatch(updateEntry(entryId, editText, editFriendIds));
        onSubmit(editText);
        dispatch(setEditText(null, '', []));
        setActiveText('');
        setIsEmpty(true);
        onDone();
        return;
      }
      await dispatch(createEntry(threadId, draftText, draftFriendIds));
      onSubmit(draftText);
      dispatch(setDraftText('', []));
      setActiveText('');
      setIsEmpty(true);
    } catch (err) {
      toast.error(err.error);
    }
  };

  const handleDelete = async () => {
    toast.dismiss();
    try {
      await dispatch(deleteEntry(entryId));
      dispatch(setEditText(null, '', []));
      onDone();
    } catch (err) {
      toast.error(err.error);
    }
  };

  return (
    <React.Fragment>
      {isDeleteDialogOpen && (
        <Dialog
          title="Delete Confirmation"
          actionButtonStyle="danger"
          actionButtonText="Delete Entry"
          onAction={() => {
            handleDelete().then(() => setIsDeleteDialogOpen(false));
          }}
          onClose={() => setIsDeleteDialogOpen(false)}
        >
          <p>Are you sure you want to delete the entry?</p>
        </Dialog>
      )}
      {entryId && (
        <EditEntryBar
          onDelete={() => {
            setIsDeleteDialogOpen(true);
          }}
          onDiscard={onDone}
          disabled={isFetching}
        />
      )}
      <EntryInput
        onMention={handleMention}
        onSave={handleSave}
        saveText={entryId ? 'Save Changes' : `Save to ${weekday}`}
        disabled={isFetching || isEmpty}
      >
        <QuillEntryEditor
          editorRef={editorRef}
          text={activeText}
          onChange={handleEditorChange}
          onFocus={onFocus}
          onEscape={onEscape}
          disabled={isFetching}
          placeholder="Mention a friend by typing @."
        />
      </EntryInput>
    </React.Fragment>
  );
};

export default React.memo(EntryEditor);
