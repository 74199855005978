import { useState, useEffect, useCallback } from 'react';

export default function useRefHeight(deps = []) {
  const [height, setHeight] = useState(0);
  const [refNode, setRefNode] = useState(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setRefNode(node);
    }
  }, []);

  useEffect(
    () => {
      if (!refNode) {
        return undefined;
      }

      // Set the initial height to prevent pop in
      setHeight(refNode.getBoundingClientRect().height);
      // We need to check again after dom finishes rendering
      const handlerId = setTimeout(() => {
        setHeight(refNode.getBoundingClientRect().height);
      }, 0);
      return () => clearTimeout(handlerId);
    },
    // eslint-disable-next-line
    [refNode, ...deps]
  );

  return [height, ref];
}
