import React from 'react';
import styled from 'styled-components';
import CenterRow from '../../../components/helpers/CenterRow';
import H1 from '../../../components/H1';
import { GREY_700 } from '../../../theme';

const PaddingBox = styled.div`
  padding: 30px 0 0 0;
  margin: 0 auto;
`;

const Title = styled(H1)`
  font-size: 20px;
  margin: 20px 0 10px 0;
`;

const Text = styled.div`
  font-size: 16px;
  width: 320px;
  margin: 0 auto;
  color: ${GREY_700};
`;

const Explainer = ({ image, title, children }) => (
  <PaddingBox>
    <CenterRow>{image}</CenterRow>
    <Title>{title}</Title>
    <Text>{children}</Text>
  </PaddingBox>
);

export default Explainer;
