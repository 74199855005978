import React from 'react';
import { TEAL_800 } from '../../../theme';

const CloseIcon = ({ width = 18, height = 18, color = TEAL_800 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6776 9.49988L17.737 3.44072C18.0874 3.09029 18.0874 2.52161 17.737 2.17062L16.329 0.762702C15.9786 0.41227 15.4099 0.41227 15.0589 0.762702L9.00008 6.82242L2.94074 0.763265C2.5903 0.412833 2.0216 0.412833 1.6706 0.763265L0.263198 2.17062C-0.0872445 2.52105 -0.0872445 3.08973 0.263198 3.44072L6.32254 9.49988L0.263198 15.559C-0.0872445 15.9095 -0.0872445 16.4781 0.263198 16.8291L1.67116 18.2371C2.0216 18.5875 2.5903 18.5875 2.9413 18.2371L9.00008 12.1773L15.0594 18.2365C15.4099 18.5869 15.9786 18.5869 16.3296 18.2365L17.7375 16.8286C18.088 16.4781 18.088 15.9095 17.7375 15.5585L11.6776 9.49988Z"
      fill={color}
    />
  </svg>
);

export default CloseIcon;
