import firstBy from 'thenby';
import { createSelector } from 'reselect';

export const getEntryStatus = (state) => state.entry.status;

export const getEntries = (state) => {
  const entries = state.entry.entities.entry;
  return entries || undefined;
};

export const getEntry = (state, id) => {
  const entries = getEntries(state);
  return entries ? entries[id] : undefined;
};

export const getEntryList = createSelector(getEntries, (entries) => {
  const entryList = Object.keys(entries).map((id) => entries[id]);
  entryList.sort(firstBy('thread_id', -1).thenBy('created_at'));
  return entryList;
});

export const getEntriesByThreadId = createSelector(getEntryList, (entries) =>
  entries.reduce((sum, entry) => {
    const { thread_id } = entry;
    if (!sum[thread_id]) {
      // eslint-disable-next-line
      sum[thread_id] = [];
    }
    // eslint-disable-next-line
    sum[thread_id].push(entry);
    return sum;
  }, {})
);
