import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import getReduxDevTools from './getReduxDevTools';
import { loadState } from './localStorage';
import localStorageMiddleware from './localStorageMiddleware';

export default function initStore() {
  const initialState = loadState();
  const enhancers = [];
  const middleware = [thunk, localStorageMiddleware()];

  // Throws warnings if we try to modify state data directly.
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    const freeze = require('redux-freeze');
    middleware.push(freeze);
  }

  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? getReduxDevTools() || compose
      : compose;

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  return store;
}
