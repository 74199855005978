import { combineReducers } from 'redux';
import auth from './reducers/auth';
import crypt from './reducers/crypt';
import editor from './reducers/editor';
import entry from './reducers/entry';
import error from './reducers/error';
import friend from './reducers/friend';
import person from './reducers/person';
import ready from './reducers/ready';
import setting from './reducers/setting';
import stale from './reducers/stale';
import thread from './reducers/thread';

export default combineReducers({
  auth,
  crypt,
  editor,
  entry,
  error,
  friend,
  person,
  ready,
  setting,
  stale,
  thread,
});
