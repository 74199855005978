import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsStale, gainPageFocus, losePageFocus } from '../reducers/stale';

const StaleManager = () => {
  const isStale = useSelector(getIsStale);
  const dispatch = useDispatch();

  useEffect(
    () => {
      function handleGainFocus() {
        dispatch(gainPageFocus());
      }
      function handleLoseFocus() {
        dispatch(losePageFocus());
      }
      window.addEventListener('focus', handleGainFocus);
      window.addEventListener('blur', handleLoseFocus);
      return () => {
        window.removeEventListener('focus', handleGainFocus);
        window.removeEventListener('blur', handleLoseFocus);
      };
    },
    // eslint-disable-next-line
    []
  );

  // A bit of a brute force way to ensure the app is always up to date.
  useEffect(() => {
    if (isStale) {
      window.location.reload();
    }
  }, [isStale]);

  return null;
};

export default StaleManager;
