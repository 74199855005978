import {
  TEAL_000,
  GREY_000,
  GREY_100,
  GREY_200,
  GREY_300,
  GREY_800,
  TEAL_700,
  TEAL_800,
  RED_000,
  RED_400,
  RED_500,
} from '../../theme';

const BUTTON_SIZES = {
  block: {
    fontSize: '18px',
    padding: '12px 20px',
    width: '100%',
  },
  lg: {
    fontSize: '18px',
    padding: '10px 20px',
    width: 'auto',
  },
  md: {
    fontSize: '14px',
    padding: '8px 20px',
    width: 'auto',
  },
  sm: {
    fontSize: '12px',
    padding: '6px 10px',
    width: 'auto',
  },
};

export const BUTTON_VARIANTS = {
  primary: {
    color: TEAL_000,
    colorActive: TEAL_000,
    background: TEAL_700,
    backgroundActive: TEAL_800,
    border: 'transparent',
    borderActive: 'transparent',
  },
  secondary: {
    color: GREY_800,
    colorActive: GREY_800,
    background: GREY_100,
    backgroundActive: GREY_200,
    border: 'transparent',
    borderActive: 'transparent',
  },
  tertiary: {
    color: GREY_800,
    colorActive: GREY_800,
    background: 'transparent',
    backgroundActive: GREY_200,
    border: 'transparent',
    borderActive: 'transparent',
  },
  danger: {
    color: RED_000,
    colorActive: RED_000,
    background: RED_400,
    backgroundActive: RED_500,
    border: 'transparent',
    borderActive: 'transparent',
  },
  disabled: {
    color: GREY_300,
    background: GREY_000,
  },
};

export const getSize = (key) => ({ size }) => BUTTON_SIZES[size][key];
export const getVariant = (key) => ({ variant }) =>
  BUTTON_VARIANTS[variant][key];
