import React from 'react';
import styled from 'styled-components';
import CenterColumn from '../helpers/CenterColumn';
import CenterFill from '../helpers/CenterFill';
import { LG_SCREEN } from '../../theme';

const PanelPadding = styled(CenterColumn)`
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  @media (min-width: ${LG_SCREEN}px) {
    width: auto;
    height: auto;
    flex-grow: 0;
  }
`;

const PanelLayoutContainer = ({ children }) => (
  <CenterFill>
    <PanelPadding>{children}</PanelPadding>
  </CenterFill>
);

export default PanelLayoutContainer;
