import { createSelector } from 'reselect';

function sortStringIds(obj) {
  const ids = Object.keys(obj).map((id) => parseInt(id, 10));
  ids.sort((a, b) => b - a);
  return ids;
}

export const getThreadStatus = (state) => state.thread.status;

export const getThreadIds = createSelector(
  (state) => state.thread.threads,
  sortStringIds
);

export const getVirtualThreadIds = createSelector(
  (state) => state.thread.virtualThreads,
  sortStringIds
);

export const getIsExtendable = createSelector(
  getThreadIds,
  (state) => state.thread.limit,
  (ids, limit) => ids.length >= limit
);

export const getNextThreadIds = createSelector(
  getThreadIds,
  (state) => state.thread.threads,
  (state) => state.thread.limit,
  (ids, threads, limit) =>
    ids.slice(0, limit).filter((id) => !threads[id].is_loaded)
);
