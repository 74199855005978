import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import CenterRow from '../../../components/helpers/CenterRow';
import H1 from '../../../components/H1';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import Spacer from '../../../components/helpers/Spacer';
import sleep from '../../../utils/sleep';
import { getIsPersonLoaded, fetchPerson } from '../../../reducers/person';
import { getIsCryptLoaded } from '../../../reducers/crypt';
import { ready } from '../../../reducers/ready';
import { fetchFriends } from '../../../reducers/friend';
import { initVirtualThreads, fetchThreads } from '../../../reducers/thread';
import { fetchEntries, fetchEntriesForThreads } from '../../../reducers/entry';
import { fetchSettings } from '../../../reducers/setting';
import { raiseError } from '../../../reducers/error';
import { TEAL_300, TEAL_900 } from '../../../theme';

const LoadingPage = () => {
  // Special: There needs to be a router entry with this param in the
  // `isLoading` Switch.
  const params = useParams();
  const isPersonLoaded = useSelector(getIsPersonLoaded);
  const isCryptLoaded = useSelector(getIsCryptLoaded);
  const dispatch = useDispatch();

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          // To prevent a really fast flash in the first load.
          await sleep(500);
          if (!isPersonLoaded) {
            await dispatch(fetchSettings());
            await dispatch(fetchPerson());
          }
          if (isCryptLoaded) {
            await dispatch(fetchFriends());
            await dispatch(initVirtualThreads());
            await dispatch(fetchThreads());
            await dispatch(fetchEntriesForThreads());
          }
          if (isCryptLoaded && params.threadId) {
            const threadId = parseInt(params.threadId, 10);
            if (threadId <= 0 || threadId > 400000) {
              throw new Error('Invalid Thread Id');
            }
            await dispatch(
              fetchEntries({
                threadIds: [threadId],
              })
            );
          }
          if (isCryptLoaded) {
            dispatch(ready());
          }
        } catch (err) {
          // eslint-disable-next-line
          console.error(err);
          dispatch(raiseError());
        }
      };
      toast.dismiss();
      fetchData();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Helmet title="Loading" />
      <PanelLayoutContainer>
        <PanelLayoutPage bgColor={TEAL_300}>
          <Spacer margin={50} />
          <PanelLayoutPage.Header />
          <PanelLayoutPage.Body>
            <CenterRow grow={1}>
              <H1 size={50} color={TEAL_900}>
                Loading...
              </H1>
            </CenterRow>
          </PanelLayoutPage.Body>
          <PanelLayoutPage.Footer />
        </PanelLayoutPage>
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default React.memo(LoadingPage);
