import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import ActiveState from './ActiveState';
import EmptyState from './EmptyState';
import FlexScrollbar from '../../../components/FlexScrollbar';
import LinkButton from '../../../components/buttons/LinkButton';
import Spacer from '../../../components/helpers/Spacer';
import SwipeNavigation from '../../../components/SwipeNavigation';
import TabLayoutContainer from '../../../components/tabLayout/TabLayoutContainer';
import TabNavigation from '../../../components/tabLayout/TabNavigation';
import TabLayoutPage from '../../../components/tabLayout/TabLayoutPage';
import { getFriendList } from '../../../reducers/friend';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const PlannerPage = () => {
  const friends = useSelector(getFriendList);
  const [navHeight, navRef] = useRefHeight();
  const [footerHeight, footerRef] = useRefHeight();

  return (
    <SwipeNavigation leftTo="/" rightTo="/search">
      <Helmet title="Planner" />
      <TabLayoutContainer
        navigation={<TabNavigation ref={navRef} active="planner" />}
      >
        <TabLayoutPage>
          <TabLayoutPage.Header />
          <FlexScrollbar refHeights={[navHeight, footerHeight]}>
            <TabLayoutPage.Body padding={15}>
              <Spacer margin={15} />
              {!friends.length && <EmptyState />}
              {!!friends.length && <ActiveState />}
            </TabLayoutPage.Body>
          </FlexScrollbar>
          <TabLayoutPage.Footer ref={footerRef} padding={15}>
            <Spacer margin={15} />
            <LinkButton
              type="button"
              variant="primary"
              size="block"
              to="/planner/add-friend"
            >
              Add Friend To Planner
            </LinkButton>
          </TabLayoutPage.Footer>
        </TabLayoutPage>
      </TabLayoutContainer>
    </SwipeNavigation>
  );
};

export default PlannerPage;
