import React from 'react';
import isToday from 'date-fns/isToday';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EntryPlaceholder from '../../../components/timeline/EntryPlaceholder';
import EntryText from '../../../components/timeline/EntryText';
import ThreadDate from '../../../components/timeline/ThreadDate';
import ThreadContainer from '../../../components/timeline/ThreadContainer';
import { getDateFromThreadId } from '../../../utils/getThreadId';
import { getEntriesByThreadId } from '../../../reducers/entry';

const Thread = ({ threadId }) => {
  const entries = useSelector(getEntriesByThreadId)[threadId] || [];
  const threadDate = getDateFromThreadId(threadId);
  const isActive = isToday(threadDate);
  const isPlaceholderVisible = isActive || !entries.length;

  return (
    <ThreadContainer as={Link} to={`/journal/thread/${threadId}`}>
      <ThreadDate threadId={threadId} />
      {entries.map(({ id, text }) => (
        <EntryText key={id} isWithinLink>
          {text}
        </EntryText>
      ))}
      {isPlaceholderVisible && (
        <EntryPlaceholder threadId={threadId} isActive={isActive} />
      )}
    </ThreadContainer>
  );
};

export default React.memo(Thread);
