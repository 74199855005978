import React, { useState, useCallback } from 'react';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { HotKeys } from 'react-hotkeys';
import EntryEditor from '../../../components/EntryEditor';
import EntryContainer from '../../../components/timeline/EntryContainer';
import EntryPlaceholder from '../../../components/timeline/EntryPlaceholder';
import EntryText from '../../../components/timeline/EntryText';
import FlexScrollbar from '../../../components/FlexScrollbar';
import Spacer from '../../../components/helpers/Spacer';
import TabLayoutContainer from '../../../components/tabLayout/TabLayoutContainer';
import TabNavigation from '../../../components/tabLayout/TabNavigation';
import TabSubNavigation from '../../../components/tabLayout/TabSubNavigation';
import TabLayoutPage from '../../../components/tabLayout/TabLayoutPage';
import ThreadDate from '../../../components/timeline/ThreadDate';
import {
  setEditText,
  getEditText,
  getDraftText,
} from '../../../reducers/editor';
import { getEntriesByThreadId } from '../../../reducers/entry';
import { getDateFromThreadId } from '../../../utils/getThreadId';
import useRefHeight from '../../../utils/hooks/useRefHeight';
import useCallbackState from '../../../utils/hooks/useCallbackState';

const ThreadPage = () => {
  const params = useParams();
  const threadId = parseInt(params.threadId, 10);
  const [entryId, setEntryId] = useState(null);
  const entries = useSelector(getEntriesByThreadId)[threadId] || [];
  const draftText = useSelector(getDraftText);
  const editText = useSelector(getEditText);
  const [editor, getEditor] = useCallbackState();
  const [navHeight, navRef] = useRefHeight();
  const [subNavHeight, subNavRef] = useRefHeight();
  const [footerHeight, footerRef] = useRefHeight([
    editText,
    draftText,
    entryId,
  ]);
  const [scrollbar, getScrollbarRef] = useCallbackState();
  const dispatch = useDispatch();
  const threadDate = getDateFromThreadId(threadId);
  const calendarDate = format(threadDate, 'MMM dd, yyyy');
  const handleBlur = useCallback(() => {
    setEntryId(null);
  }, []);
  const handleEntryClick = useCallback(
    (id, text) => {
      dispatch(setEditText(id, text));
      setEntryId(id);
      editor.setSelection(editor.getLength());
    },
    [dispatch, setEntryId, editor]
  );

  const scrollToBottom = () => {
    scrollbar.scrollToBottom();
    // Hack: Wait for keyboard animation
    setTimeout(() => {
      scrollbar.scrollToBottom();
    }, 100);
    setTimeout(() => {
      scrollbar.scrollToBottom();
    }, 150);
  };

  return (
    <HotKeys
      keyMap={{
        BLUR: ['escape'],
      }}
      handlers={{
        BLUR: handleBlur,
      }}
    >
      <Helmet title="Journal" />
      <TabLayoutContainer
        navigation={<TabNavigation ref={navRef} active="journal" hasSubNav />}
      >
        <TabSubNavigation ref={subNavRef} backButtonTo="/">
          {calendarDate}
        </TabSubNavigation>
        <TabLayoutPage>
          <TabLayoutPage.Header />
          <FlexScrollbar
            refHeights={[navHeight, subNavHeight, footerHeight]}
            onReady={getScrollbarRef}
            reverse
          >
            <TabLayoutPage.Body flexEnd>
              <ThreadDate threadId={threadId} />
              {entries.map(({ id, text }) => (
                <EntryContainer
                  key={id}
                  isActive={id === entryId}
                  onClick={() => handleEntryClick(id, text)}
                >
                  <EntryText>{text}</EntryText>
                </EntryContainer>
              ))}
              <EntryPlaceholder threadId={threadId} isActive />
              <Spacer margin={8} />
            </TabLayoutPage.Body>
          </FlexScrollbar>
          <TabLayoutPage.Footer ref={footerRef}>
            <EntryEditor
              threadId={threadId}
              entryId={entryId}
              onDone={handleBlur}
              onEscape={handleBlur}
              onReady={getEditor}
              onFocus={scrollToBottom}
              onSubmit={scrollToBottom}
            />
          </TabLayoutPage.Footer>
        </TabLayoutPage>
      </TabLayoutContainer>
    </HotKeys>
  );
};

export default ThreadPage;
