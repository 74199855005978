import React from 'react';

const PlantNoon = ({ width = 217, height = 200 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 217 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.7231 165.167C70.893 160.099 74.767 159.94 78.5807 160.603C78.5807 160.296 77.6437 157.704 78.1423 156.307C78.5752 155.112 80.4492 155.079 80.4492 154.827C80.493 151.512 80.6355 152.427 80.6684 149.112C80.7012 146.038 81.43 141.545 79.6273 139.09C77.8245 136.636 74.1478 137.178 70.9971 136.97C66.9752 136.696 62.5149 136.762 58.4875 136.827C50.2683 136.97 43.3314 136.373 35.1067 136.51C31.682 136.57 27.3697 135.879 21.819 136.099C16.2683 136.318 16.8875 139.934 16.8437 141.72C16.7998 143.507 17.3204 150.953 17.5067 153.573C17.693 156.192 18.2355 160.953 18.6355 164.641C19.3204 170.992 22.1094 176 29.3149 177.534C36.8108 179.112 45.2053 178.723 52.8382 178.531C56.5898 178.44 60.3377 178.245 64.082 177.945C67.6656 177.66 71.5999 177.501 74.7177 175.644C76.5456 174.575 77.9982 172.968 78.8766 171.041C75.3423 171.397 70.6081 168.696 70.7231 165.167Z"
      fill="#298A91"
    />
    <path
      d="M79.5946 108.055C73.9672 97.8465 61.1069 97.3479 51.1014 99.7972C45.7279 101.08 40.5755 103.155 35.8138 105.956C31.2713 108.696 26.1535 112.373 23.759 117.244C22.9622 118.751 22.5376 120.427 22.5206 122.131C21.8381 124.15 21.8613 126.341 22.5864 128.345C24.3288 132.992 29.6165 134.838 33.9836 136.055C45.1115 139.099 56.9594 138.085 67.4083 133.194C76.6795 128.838 85.4138 118.614 79.5946 108.055Z"
      fill="#B3DDD1"
    />
    <path
      d="M75.2604 134.214C75.3152 134.718 71.6878 135.644 71.0303 135.644C68.0604 135.616 67.8467 135.6 64.9426 134.718C64.8713 134.718 63.9234 136.006 62.822 136.438C59.7809 137.611 58.2083 137.112 54.8823 136.312C53.8776 135.88 52.9131 135.359 52.0001 134.756C49.1453 135.611 47.3261 136.236 44.5535 135.852C40.4494 135.26 38.2522 132.921 38.1481 132.734C34.8933 133.178 28.0001 134.926 27.9946 135.584C27.9946 137.134 37.7261 138.767 50.9261 138.91C64.1261 139.052 76.5261 136.625 76.5426 135.074C76.5426 134.707 76.3234 134.455 75.2604 134.214Z"
      fill="#231F20"
    />
    <path
      d="M111.156 155.967C106.077 154.997 95.874 154.438 93.9891 154.438C92.1041 154.438 81.0466 153.342 76.9589 153.89C72.8713 154.438 75.7699 162.066 76.7288 166.493C77.4251 170.071 78.3718 173.596 79.5617 177.041C80.5863 180.022 90.8384 179.781 95.1891 179.611C99.5397 179.441 105.43 179.266 107.551 178.104C110.46 176.51 110.986 171.364 111.934 165.397C112.997 158.762 114.022 156.515 111.156 155.967Z"
      fill="#F5B994"
    />
    <path
      d="M112.416 153.879C110.871 153.266 102.91 152.701 98.4165 152.647C93.3284 152.525 88.2389 152.824 83.2 153.54C81.9946 153.693 87.7096 155.025 88.7836 155.112C96.137 155.715 106.866 155.222 107.485 155.184C108.767 155.096 113.633 154.367 112.416 153.879Z"
      fill="#784E36"
    />
    <path
      d="M110.581 139.896C110.488 139.685 110.343 139.502 110.158 139.365C109.974 139.227 109.757 139.14 109.529 139.112C109.677 138.277 109.572 137.416 109.227 136.641C108.63 135.375 106.312 134.57 104.296 134.762C106.104 133.397 107.671 131.589 108.257 129.359C109.715 123.83 105.189 119.359 100.038 118.531C96.5424 117.983 94.9807 122.942 98.5424 123.956C101.748 124.866 101.879 127.562 99.041 129.112C97.1999 130.12 94.8602 130.279 93.2164 131.638C92.2136 132.471 91.3807 133.885 91.7095 135.32C91.6547 135.397 90.1972 135.825 89.9342 137.2C89.2712 140.608 91.2273 143.731 94.3451 144.356C95.7237 144.575 97.1232 144.632 98.515 144.526C97.6109 146.077 97.704 147.649 99.2931 148.685C100.504 149.474 102.647 148.488 103.403 146.948C107.436 146.197 112.723 144.312 110.581 139.896Z"
      fill="#EDF1F3"
    />
    <path
      d="M141.677 147.874C142.46 143.441 143.573 139.945 142.493 137.622C141.545 135.573 131.036 136.57 127.901 136.614C120.882 136.718 114.082 136.329 112.115 138.504C110.707 140.055 112.871 150.115 113.238 151.786C113.397 152.521 116.992 152.679 117.184 154.279C117.666 158.203 115.918 159.616 120.384 159.447C124.849 159.277 126.258 159.386 130.701 159.069C135.145 158.751 136.915 158.104 138.921 156.427C140.926 154.751 140.888 152.329 141.677 147.874Z"
      fill="#F5B994"
    />
    <path
      d="M136.521 136.586C136.647 136.132 128.877 135.233 124.849 135.2C120.822 135.167 115.288 135.342 111.671 136.438C110.888 136.674 115.71 137.342 116.603 137.452C120.438 137.923 124.444 137.775 130.849 137.578C131.562 137.578 136.663 137.118 136.521 136.586Z"
      fill="#784E36"
    />
    <path
      d="M135.441 122.077C135.085 121.504 134.604 121.02 134.035 120.658C133.466 120.297 132.823 120.068 132.153 119.989C132.29 119.677 132.397 119.352 132.471 119.019C132.611 118.42 132.625 117.799 132.513 117.195C132.4 116.59 132.164 116.016 131.819 115.507C132.658 115.001 133.275 114.197 133.545 113.255C133.818 112.239 133.702 111.158 133.22 110.223C132.737 109.289 131.923 108.568 130.937 108.203C129.096 107.507 128.592 107.162 126.658 106.904C125.165 106.828 123.669 106.935 122.203 107.222C123.478 107.011 124.709 106.591 125.847 105.978C127.66 105.09 127.567 101.096 125.332 100.186C121.419 98.5918 117.288 99.8411 114.521 103.704C111.753 107.567 114.608 111.644 118.055 113.742C119.266 114.482 121.49 115.304 121.792 116.899C122.027 118.142 120.038 118.241 119.189 118.384C117.501 118.663 115.836 119.079 114.515 120.236C113.794 120.867 113.291 121.711 113.078 122.646C112.865 123.581 112.954 124.56 113.331 125.441C114.23 127.534 116.367 128.658 118.526 128.997C120.17 129.255 122.91 129.069 123.847 130.767C124.581 132.077 126.942 131.567 126.8 129.962C126.736 128.91 126.317 127.911 125.611 127.129L126.805 127.222C129.364 127.403 133.485 128.51 135.282 126.044C135.689 125.469 135.921 124.788 135.949 124.084C135.978 123.38 135.801 122.683 135.441 122.077Z"
      fill="#EDF1F3"
    />
    <path
      d="M192.214 147.945C192.351 141.918 192.214 137.852 192.345 131.852C192.433 127.118 186.515 127.211 184.044 127.277C174.564 128.093 163.912 127.863 153.907 127.863C150.504 127.863 148.975 128.625 148.91 132.159C148.877 133.967 149.425 142.57 149.523 144.877C149.737 149.808 150.071 165.441 153.6 167.244C156.63 168.778 166.203 169.129 170.663 168.997C175.123 168.866 186.844 169.129 189.605 166.499C192.367 163.868 191.753 153.764 192.214 147.945Z"
      fill="#D1DCE2"
    />
    <path
      d="M156.438 86.8273C149.359 90.115 144.658 97.7862 145.907 105.66C146.198 107.398 146.753 109.081 147.551 110.652C148.811 113.923 151.065 116.716 153.995 118.641C159.94 122.663 168.756 124.762 175.803 123.222C183.079 121.633 187.786 115.036 188.488 107.879C190.082 90.9862 170.888 80.0712 156.438 86.8273Z"
      fill="#80BEAF"
    />
    <path
      d="M181.03 126.269C171.299 124.937 162.948 125.145 153.173 126.329C152.077 126.46 151.71 127.808 152.921 128.066C163.973 130.389 174.323 129.6 182.784 128.329C183.496 128.219 183.923 126.663 181.03 126.269Z"
      fill="#231F20"
    />
    <path
      d="M143.474 126.882C144.071 135.869 144.893 144.833 145.666 153.803C145.994 157.573 146.32 161.344 146.641 165.118C146.762 166.504 146.586 168.488 147.94 169.277C149.293 170.066 151.512 170.23 153.057 170.532C154.975 170.904 156.893 171.184 158.86 171.392C167.89 172.373 177.304 171.94 186.06 169.397C186.219 169.356 186.364 169.274 186.481 169.158C186.597 169.043 186.681 168.898 186.723 168.74C189.331 154.871 189.573 140.685 191.008 126.679C191.134 125.463 189.233 125.474 189.107 126.679C188.011 137.293 187.573 147.962 186.367 158.564C186.06 161.206 185.868 164.011 185.222 166.597C184.899 167.907 184.57 167.885 183.255 168.208C181.94 168.532 180.515 168.811 179.14 169.041C173.917 169.882 168.619 170.157 163.337 169.863C160.768 169.741 158.207 169.481 155.666 169.085C154.241 168.86 149.693 168.647 148.773 167.408C148.225 166.669 148.334 163.162 148.225 162.214C147.673 156.117 147.125 150.016 146.581 143.912C146.06 138.236 145.578 132.57 145.178 126.882C145.101 125.786 143.403 125.786 143.479 126.882H143.474Z"
      fill="#231F20"
    />
    <path
      d="M190.088 125.704C183.041 122.855 174.296 122.455 166.789 122.416C159.282 122.378 151.403 123.079 144.29 125.786C144.107 125.842 143.946 125.955 143.832 126.109C143.718 126.263 143.656 126.449 143.656 126.641C143.656 126.833 143.718 127.019 143.832 127.173C143.946 127.327 144.107 127.44 144.29 127.496C150.937 129.781 158.395 130.279 165.364 130.455C173.616 130.663 182.274 130.208 190.121 127.441C191.184 127.063 190.723 125.353 189.649 125.731C181.934 128.471 173.479 128.893 165.364 128.685C158.532 128.51 151.277 128.033 144.762 125.786V127.496C151.743 124.838 159.37 124.148 166.789 124.181C174.647 124.219 182.055 125.682 189.77 126.855C189.917 126.879 190.068 126.848 190.194 126.768C190.319 126.688 190.411 126.564 190.45 126.42C190.49 126.276 190.474 126.123 190.407 125.99C190.341 125.856 190.227 125.753 190.088 125.699V125.704Z"
      fill="#231F20"
    />
    <path
      d="M167.71 119.364C167.775 114.926 168.411 110.214 171.079 106.504C172.5 104.551 174.383 102.981 176.559 101.934C178.959 100.773 183.134 99.8081 184.866 102.57C188.044 107.627 176.553 108.751 173.83 109.693C171.107 110.636 168.46 112.159 166.778 114.559C166.033 115.655 167.786 116.641 168.526 115.583C172.296 110.208 179.994 111.485 184.992 108.126C188.449 105.797 187.731 101.403 184.044 99.704C179.496 97.6218 173.868 100.86 170.893 104.225C167.359 108.197 165.792 114.301 166.647 119.501C166.756 120.137 167.742 119.962 167.742 119.353L167.71 119.364Z"
      fill="#231F20"
    />
    <path
      d="M168.033 126.301C168.8 121.37 165.841 116.011 161.94 113.123C157.491 109.836 151.14 109.743 146.208 111.945C144.564 112.68 141.178 114.4 140.729 116.395C140.312 118.301 142.51 119.299 143.978 119.682C151.173 121.589 158.532 116.137 165.348 120.411C166.247 120.959 167.359 119.606 166.504 118.91C163.595 116.537 159.688 116.417 156.093 116.882C153.31 117.249 150.559 117.978 147.737 118.066C146.712 118.066 144.68 118.247 143.83 117.589C142.115 116.258 144.734 114.932 145.737 114.351C149.923 111.918 155.797 111.567 159.984 114.153C164.17 116.74 166.269 121.403 166.822 126.143C166.893 126.734 167.863 127.014 167.973 126.296L168.033 126.301Z"
      fill="#231F20"
    />
    <path
      d="M168.11 118.97C169.304 112.608 166.975 106.312 162.63 101.627C158.285 96.9425 150.088 92.674 143.688 95.8904C138.104 98.6959 138.471 106.756 145.2 107.523C149.638 108.027 153.852 106.795 158.307 107.978C163.008 109.222 166.438 112.515 166.137 117.622C166.055 118.975 168.17 118.975 168.252 117.622C168.548 112.406 165.353 108.307 160.548 106.504C157.766 105.546 154.831 105.106 151.89 105.205C148.723 105.249 142.126 106.882 141.808 102.038C141.474 96.9425 148.493 96.4603 151.912 97.3808C156.502 98.7094 160.506 101.554 163.27 105.451C166.035 109.348 167.397 114.068 167.134 118.838C167.134 119.386 168.005 119.523 168.11 118.97Z"
      fill="#231F20"
    />
    <path
      d="M166.986 107.945C167.501 103.063 167.468 98.3946 170.153 94.0713C171.386 92.0933 173.167 90.1152 175.31 89.096C177.452 88.0768 181.605 87.8576 180.888 91.8357C180.652 93.1344 179.556 94.2522 178.608 95.0905C175.753 97.622 172.099 98.7289 169.529 101.633C167.545 103.885 165.781 106.614 165.408 109.649C165.266 110.833 167.129 110.822 167.271 109.649C167.611 106.849 169.671 104.066 171.605 102.137C173.671 100.077 176.444 99.0522 178.773 97.3261C181.605 95.2275 185.107 90.2357 180.992 87.4631C176.877 84.6905 171.973 88.559 169.611 91.7809C166.203 96.4385 165.457 102.373 165.753 107.945C165.753 108.11 165.819 108.267 165.935 108.383C166.051 108.499 166.208 108.565 166.373 108.565C166.537 108.565 166.694 108.499 166.81 108.383C166.926 108.267 166.992 108.11 166.992 107.945H166.986Z"
      fill="#231F20"
    />
    <path
      d="M166.488 105.677C166.559 102.701 166.219 99.7644 166.17 96.7945C166.126 94.0548 166.142 91.3151 165.184 88.7068C163.764 84.8712 159.704 81.2274 155.321 82.4603C151.217 83.611 151.14 87.9397 153.869 90.6027C155.907 92.5808 158.296 94.011 160.121 96.2411C162.219 98.8164 163.326 101.693 164.427 104.784C164.46 104.902 164.515 105.012 164.59 105.109C164.665 105.205 164.759 105.286 164.865 105.347C164.971 105.407 165.089 105.446 165.21 105.462C165.331 105.477 165.455 105.468 165.573 105.436C165.691 105.403 165.801 105.348 165.898 105.273C165.994 105.198 166.075 105.104 166.136 104.998C166.196 104.892 166.235 104.774 166.251 104.653C166.266 104.532 166.257 104.408 166.225 104.29C165.77 101.085 164.088 97.9781 162.137 95.4356C160.988 93.9392 159.669 92.5805 158.208 91.3863C156.789 90.2301 153.392 88.4 154.022 86.0493C155.03 82.2849 160.499 85.0137 161.901 86.5973C163.726 88.6356 164.274 91.326 164.488 93.9671C164.8 97.8685 165.069 101.786 165.386 105.693C165.386 105.838 165.444 105.978 165.547 106.081C165.65 106.183 165.789 106.241 165.934 106.241C166.08 106.241 166.219 106.183 166.322 106.081C166.424 105.978 166.482 105.838 166.482 105.693L166.488 105.677Z"
      fill="#231F20"
    />
    <path
      d="M168.148 124.192C168.09 122.777 168.129 121.36 168.263 119.951C168.63 117.606 170.838 116.663 172.795 115.644C176.471 113.753 184.362 110.406 186.932 115.644C188.647 119.107 185.052 120.575 182.269 120.603C179.923 120.603 177.666 119.671 175.392 119.211C172.362 118.597 168.882 118.504 166.625 120.943C165.847 121.781 166.866 123.293 167.902 122.586C173.699 118.685 180.132 124.816 186.258 122.038C190.46 120.132 190.597 114.915 186.871 112.351C181.978 109.003 175.562 111.753 170.981 114.301C169.217 115.282 167.041 116.406 166.488 118.499C165.984 120.395 166.258 122.515 166.625 124.411C166.8 125.293 168.148 125.058 168.159 124.203L168.148 124.192Z"
      fill="#231F20"
    />
    <path
      d="M86.1151 150.51C86.7781 145.469 87.4466 140.521 87.4302 135.419C87.4302 133.989 85.2001 133.984 85.2055 135.419C85.2055 140.521 84.537 145.469 83.874 150.51C83.6877 151.934 85.9288 151.923 86.1151 150.51Z"
      fill="#231F20"
    />
    <path
      d="M17.5672 135.753C17.6274 140.658 18.3781 145.655 19.0137 150.515C19.4431 154.582 20.1758 158.612 21.2055 162.57C23.0302 168.948 27.1617 174.077 33.3206 176.773C40.6028 179.94 49.8247 180.236 57.6548 180.06C64.2302 179.907 72.2137 179.205 77.3315 174.641C78.4274 173.671 76.8165 172.082 75.7315 173.041C70.7014 177.507 62.1918 177.786 55.8082 177.808C48.7398 177.836 40.5699 177.655 34.0165 174.773C19.1233 168.219 21.6439 148.762 19.0466 135.551C18.8822 134.712 17.5617 134.915 17.5672 135.753Z"
      fill="#231F20"
    />
    <path
      d="M26.8549 132.192C25.3974 132.192 16.2467 133.359 17.5398 136.055C18.2138 137.474 22.0056 138.044 23.2713 138.4C26.1573 139.207 29.0909 139.833 32.0549 140.274C46.1296 142.289 60.4281 142.166 74.4658 139.907C78.2576 139.293 84.433 138.723 87.1672 135.567C87.2619 135.467 87.333 135.347 87.3755 135.215C87.4181 135.084 87.4309 134.945 87.413 134.808C87.3952 134.671 87.3472 134.54 87.2725 134.424C87.1977 134.308 87.0982 134.21 86.9809 134.137C84.4358 132.337 81.4527 131.256 78.3453 131.008C77.1837 130.915 77.2111 132.696 78.3453 132.811C81.1228 133.101 83.7793 134.099 86.0604 135.71L85.8795 134.28C83.3152 137.255 76.9152 137.726 73.37 138.28C65.7239 139.42 57.9986 139.944 50.2686 139.847C42.696 139.808 34.9261 139.414 27.5289 137.786C26.022 137.452 24.5206 137.069 23.0357 136.641C22.3672 136.449 19.1398 135.304 19.1069 135.501C19.0357 135.951 20.2631 135.233 20.57 135.123C21.2987 134.866 22.0384 134.652 22.7617 134.455C24.079 134.053 25.4373 133.801 26.8111 133.704C26.9984 133.688 27.1728 133.602 27.3 133.464C27.4271 133.325 27.4977 133.144 27.4977 132.956C27.4977 132.768 27.4271 132.587 27.3 132.449C27.1728 132.31 26.9984 132.224 26.8111 132.208L26.8549 132.192Z"
      fill="#231F20"
    />
    <path
      d="M47.9726 114.619C46.4055 108.455 41.7699 103.293 36.2904 100.225C36.1243 100.129 35.9359 100.078 35.7441 100.078C35.5523 100.077 35.3638 100.128 35.1974 100.223C35.031 100.318 34.8925 100.456 34.7959 100.621C34.6992 100.787 34.6478 100.975 34.6466 101.167C34.0274 110.482 35.1069 121.54 44.1261 126.816C45.4247 127.578 46.4713 125.677 45.2877 124.827C37.3534 119.14 36.3288 110.252 36.8165 101.167L35.1726 102.104C40.4313 104.908 44.5743 109.424 46.9151 114.904C46.9722 115.017 47.0665 115.107 47.1821 115.158C47.2977 115.209 47.4275 115.219 47.5494 115.185C47.6713 115.152 47.7779 115.077 47.851 114.974C47.9242 114.871 47.9594 114.745 47.9507 114.619H47.9726Z"
      fill="#231F20"
    />
    <path
      d="M53.4245 134.416C51.9286 133.134 52.5149 131.819 53.5122 130.296C54.2823 129.137 55.1203 128.024 56.0218 126.964C56.5368 126.351 57.4793 124.827 58.3231 124.882C59.0519 124.926 60.5478 126.619 61.1396 127.112C63.7697 129.304 65.6108 133.523 61.8355 135.721C59.0026 137.364 55.5505 136.016 53.167 134.219L52.1313 135.994L52.1807 136.033C53.2766 136.8 54.2848 135.019 53.2163 134.257L53.167 134.219C52.0711 133.447 51.0903 135.216 52.1313 135.994C55.3807 138.444 60.219 139.682 63.6985 136.926C67.293 134.077 66.4382 129.211 63.3642 126.312C62.1313 125.14 60.2355 123.096 58.4875 122.729C56.7396 122.362 55.556 124.345 54.5642 125.512C52.1533 128.351 48.23 132.636 51.9724 135.874C52.9916 136.729 54.4546 135.282 53.4245 134.416Z"
      fill="#231F20"
    />
    <path
      d="M65.6657 129.863C68.1808 127.869 70.3999 125.841 73.2054 124.208C75.7299 122.847 78.1494 121.299 80.4438 119.578L78.652 119.107C79.7479 123.414 79.8246 129.107 75.4356 131.71C73.1123 133.101 69.0684 134.121 66.4821 133.085C65.0136 132.499 64.378 134.871 65.8301 135.452C69.2602 136.822 74.789 135.386 77.6054 133.145C81.8629 129.759 82.5369 123.326 80.7232 118.515C80.6685 118.337 80.5694 118.176 80.4353 118.048C80.3013 117.919 80.1366 117.826 79.9568 117.778C79.777 117.731 79.5881 117.729 79.4077 117.775C79.2274 117.821 79.0616 117.911 78.926 118.038C76.2903 120.318 73.0794 121.819 70.1862 123.732C68.0657 125.134 65.5232 126.767 64.7999 129.337C64.7883 129.443 64.8075 129.549 64.8553 129.644C64.903 129.738 64.9772 129.817 65.0689 129.871C65.1605 129.924 65.2657 129.95 65.3717 129.944C65.4776 129.939 65.5797 129.903 65.6657 129.841V129.863Z"
      fill="#231F20"
    />
    <path
      d="M59.4959 119.551C60.5644 117.594 60.7507 115.781 62.4384 114.126C64.4712 112.137 67.0466 110.745 69.4302 109.222L68.1644 108.893C71.1343 113.726 69.7534 121.649 66.7562 126.197C65.9836 127.37 67.8521 128.46 68.6356 127.293C72.0329 122.126 73.5671 113.2 69.7589 107.956C69.6344 107.747 69.4326 107.594 69.197 107.532C68.9614 107.469 68.7107 107.502 68.4986 107.622C64.1425 110.619 58.5754 113.293 58.4932 119.277C58.5006 119.391 58.5436 119.5 58.6163 119.589C58.689 119.677 58.7876 119.741 58.8982 119.771C59.0089 119.8 59.1261 119.794 59.2333 119.754C59.3405 119.714 59.4324 119.641 59.4959 119.545V119.551Z"
      fill="#231F20"
    />
    <path
      d="M71.6986 116.34C73.3863 113.052 75.348 109.836 78.274 107.469L76.7343 107.063C77.8302 112.186 77.7699 116.701 74.6904 121.129C74.1151 121.962 75.3863 122.91 76.0767 122.197C79.7863 118.362 80.4932 111.392 78.5096 106.575C78.4648 106.422 78.381 106.282 78.2664 106.17C78.1519 106.058 78.0103 105.977 77.8555 105.936C77.7007 105.895 77.5379 105.894 77.3828 105.934C77.2276 105.974 77.0854 106.053 76.9699 106.164C74.2302 109.101 71.8521 111.989 70.3945 115.781C70.1041 116.542 71.3425 117.058 71.7096 116.329L71.6986 116.34Z"
      fill="#231F20"
    />
    <path
      d="M37.863 128.625C36.3898 126.547 34.233 125.053 31.7698 124.406C27.863 123.353 24.2739 124.488 20.3506 124.74C19.8027 124.778 19.1397 125.353 19.4027 125.989C22.4109 133.364 31.1287 137.671 38.2301 133.112C39.326 132.422 38.3068 130.69 37.2274 131.392C31.3096 135.227 23.8137 131.753 21.3041 125.479L20.3506 126.729C23.7534 126.532 27.0959 125.49 30.4986 126.181C33.0794 126.729 34.789 128.142 36.8931 129.605C37.4411 130 38.2794 129.205 37.863 128.636V128.625Z"
      fill="#231F20"
    />
    <path
      d="M43.6164 106.427C42.7945 102.553 44.5315 99.6055 46.6137 96.5644L45.3479 96.8931C47.0465 97.8685 48.8164 98.6849 50.2794 100.033C51.4372 101.195 52.3862 102.548 53.0849 104.033C53.5397 104.866 54.7287 104.268 54.5424 103.419C53.7698 99.3479 49.5616 97.1233 46.3233 95.2986C46.1104 95.1804 45.8603 95.1486 45.6246 95.2098C45.389 95.271 45.1859 95.4205 45.0575 95.6274C43.7698 97.5507 42.4767 99.2493 41.9835 101.567C41.6328 103.211 41.4356 105.688 42.7178 106.975C42.7976 107.085 42.9152 107.161 43.0479 107.189C43.1807 107.217 43.3191 107.195 43.4363 107.127C43.5536 107.059 43.6414 106.949 43.6828 106.82C43.7241 106.691 43.7161 106.551 43.6602 106.427H43.6164Z"
      fill="#231F20"
    />
    <path
      d="M36.5041 113.014C34.0328 109.321 29.2986 107.43 25.3972 105.655C25.2532 105.574 25.0908 105.532 24.9258 105.533C24.7608 105.534 24.5989 105.578 24.4559 105.66C24.3129 105.742 24.1937 105.86 24.1099 106.003C24.0261 106.145 23.9807 106.306 23.978 106.471C24.1205 110.647 24.0821 114.926 25.8301 118.811C27.3916 122.365 30.0902 125.298 33.5013 127.151C33.7061 127.249 33.9406 127.267 34.158 127.201C34.3754 127.135 34.5599 126.989 34.6746 126.793C34.7894 126.596 34.8259 126.364 34.777 126.142C34.7282 125.92 34.5974 125.724 34.4109 125.594C31.1859 123.701 28.6804 120.791 27.2876 117.321C25.9671 113.879 26.0164 110.104 25.8794 106.471L24.4547 107.288C28.241 108.992 32.5424 110.778 35.4465 113.836C35.9945 114.384 36.9479 113.666 36.5424 113.014H36.5041Z"
      fill="#231F20"
    />
    <path
      d="M59.4521 105.485C60.6137 101.704 62.1151 98.3617 65.8247 96.4439L64.4987 95.896C65.5946 98.4768 66.7617 101.134 66.8987 103.973C66.9973 106.093 66.5315 107.578 65.8028 109.523C65.3425 110.756 67.2603 111.227 67.748 110.071C69.8466 105.052 68.1863 100.011 66.148 95.211C66.0248 95.0074 65.8272 94.8598 65.5971 94.7995C65.3669 94.7391 65.1223 94.7708 64.9151 94.8878C61.2987 96.8713 58.1041 101.041 58.1644 105.299C58.1673 105.456 58.2262 105.606 58.3305 105.724C58.4348 105.841 58.5776 105.917 58.7331 105.938C58.8886 105.959 59.0466 105.924 59.1784 105.839C59.3103 105.754 59.4073 105.624 59.4521 105.474V105.485Z"
      fill="#231F20"
    />
    <path
      d="M48.4273 127.299C47.1287 121.584 47.5068 116.74 50.3342 111.534C52.5919 107.191 55.4287 103.175 58.7671 99.5946L57.0465 99.1453C58.151 104.183 58.6676 109.331 58.5862 114.488C58.5095 117.748 57.3424 120.559 56.1424 123.578C55.5945 124.948 57.8301 125.562 58.3342 124.186C59.589 120.953 60.7287 117.967 60.778 114.488C60.8168 109.139 60.2209 103.805 59.0027 98.5973C58.9528 98.4259 58.8595 98.2702 58.7318 98.1454C58.6041 98.0207 58.4462 97.9311 58.2737 97.8853C58.1011 97.8395 57.9196 97.8392 57.7468 97.8842C57.574 97.9293 57.4159 98.0183 57.2876 98.1425C50.0328 105.847 43.589 116.997 46.1808 127.907C46.526 129.348 48.7287 128.74 48.3725 127.299H48.4273Z"
      fill="#231F20"
    />
    <path
      d="M52.1041 133.452C50.1534 127.452 44.3616 123.562 38.4767 122.049C38.2239 121.982 37.9547 122.016 37.7271 122.146C37.4996 122.275 37.3319 122.488 37.2602 122.74C36.3123 127.26 35.0027 132.603 39.6054 135.655C42.6739 137.682 48.4822 138.11 51.6 135.978C51.8146 135.844 51.9744 135.638 52.0508 135.397C52.1273 135.156 52.1152 134.896 52.0169 134.663C51.9185 134.43 51.7403 134.239 51.5142 134.126C51.2882 134.013 51.0291 133.983 50.7835 134.044C47.7972 134.554 44.9917 135.545 41.989 134.351C37.3643 132.515 37.863 127.189 39.1726 123.266L37.9506 123.956C43.589 125.512 47.7589 129.025 50.5534 134.099C50.658 134.272 50.8217 134.402 51.0143 134.465C51.207 134.528 51.4159 134.52 51.6028 134.441C51.7896 134.363 51.942 134.219 52.0321 134.038C52.1221 133.856 52.1438 133.648 52.0931 133.452H52.1041Z"
      fill="#231F20"
    />
    <path
      d="M107.233 135.704C106.641 140.427 107.068 147.085 109.567 151.244C109.607 151.312 109.659 151.371 109.722 151.419C109.784 151.467 109.856 151.502 109.932 151.522C110.008 151.542 110.087 151.547 110.165 151.536C110.243 151.526 110.318 151.5 110.386 151.46C110.454 151.421 110.514 151.368 110.561 151.305C110.609 151.243 110.644 151.171 110.664 151.095C110.684 151.019 110.689 150.94 110.679 150.862C110.668 150.784 110.642 150.709 110.603 150.641C108.318 145.852 108.614 140.833 108.055 135.704C108.055 135.595 108.011 135.491 107.934 135.413C107.857 135.336 107.753 135.293 107.644 135.293C107.535 135.293 107.43 135.336 107.353 135.413C107.276 135.491 107.233 135.595 107.233 135.704Z"
      fill="#231F20"
    />
    <path
      d="M140.493 136.126C140.225 143.573 139.299 153.222 131.781 157.052C130.921 157.49 131.682 158.794 132.543 158.351C140.093 154.515 142.307 143.83 141.507 136.126C141.494 136.001 141.435 135.885 141.341 135.8C141.248 135.716 141.126 135.669 141 135.669C140.874 135.669 140.753 135.716 140.659 135.8C140.565 135.885 140.506 136.001 140.493 136.126Z"
      fill="#231F20"
    />
    <path
      d="M116.8 158.17C123.129 159.551 131.079 159.304 137.222 157.173C137.359 157.119 137.471 157.017 137.537 156.886C137.603 156.754 137.617 156.603 137.577 156.461C137.538 156.32 137.447 156.198 137.322 156.12C137.198 156.042 137.049 156.013 136.904 156.038C133.486 156.948 129.978 157.475 126.444 157.611C123.266 157.682 120.077 157.206 116.91 157.353C116.466 157.353 116.323 158.066 116.8 158.17Z"
      fill="#231F20"
    />
    <path
      d="M117.074 160.652C122.285 161.238 127.542 161.304 132.767 160.849C135.288 160.63 137.781 159.6 137.633 156.668C137.605 156.049 136.586 155.825 136.482 156.515C135.874 160.504 130.57 159.77 127.468 159.885C124 160.005 120.537 159.846 117.057 159.819C116.509 159.819 116.509 160.597 117.057 160.652H117.074Z"
      fill="#231F20"
    />
    <path
      d="M144.805 147.397C143.014 148.564 140.778 148.888 138.701 149.183C137.918 149.299 138.099 150.63 138.888 150.57C141.112 150.405 143.616 149.819 145.463 148.553C146.175 148.077 145.518 146.91 144.784 147.397H144.805Z"
      fill="#231F20"
    />
    <path
      d="M141.644 140.822C142.099 139.923 143.222 138.63 144.351 139.299C145.08 139.732 145.754 138.532 145.008 138.203C143.156 137.326 141.255 138.614 140.663 140.433C140.651 140.552 140.677 140.671 140.739 140.774C140.801 140.876 140.895 140.955 141.006 140.999C141.117 141.043 141.24 141.05 141.355 141.018C141.47 140.986 141.572 140.917 141.644 140.822Z"
      fill="#231F20"
    />
    <path
      d="M144.34 144.849C143.129 145.808 141.205 146.345 139.704 146.592C139.008 146.707 139.194 147.786 139.868 147.792C141.837 147.7 143.729 146.999 145.282 145.786C145.951 145.238 145.003 144.323 144.34 144.849Z"
      fill="#231F20"
    />
    <path
      d="M141.342 143.507C141.967 142.855 143.096 141.715 144.082 141.907C144.882 142.06 145.222 140.811 144.416 140.679C142.838 140.405 141.496 141.605 140.548 142.734C140.104 143.282 140.827 144.027 141.326 143.507H141.342Z"
      fill="#231F20"
    />
    <path
      d="M108.028 136.142C113.37 134.164 119.452 133.951 125.091 134.016C129.912 134.066 136.247 133.956 140.504 136.597L140.663 135.381C136.51 136.805 131.474 136.712 127.14 136.827C120.767 136.997 114.186 136.827 108 135.151C107.829 135.119 107.652 135.153 107.505 135.246C107.357 135.339 107.25 135.483 107.204 135.651C107.158 135.819 107.176 135.998 107.256 136.153C107.335 136.308 107.47 136.427 107.633 136.488C113.94 138.175 120.652 138.356 127.14 138.175C131.578 138.055 136.756 138.131 141.019 136.663C141.143 136.625 141.253 136.552 141.337 136.454C141.42 136.355 141.473 136.234 141.49 136.106C141.507 135.978 141.486 135.847 141.431 135.73C141.376 135.613 141.288 135.515 141.178 135.446C136.904 132.811 130.625 132.8 125.797 132.707C119.874 132.603 113.299 132.8 107.715 135.063C107.069 135.326 107.332 136.378 108.006 136.131L108.028 136.142Z"
      fill="#231F20"
    />
    <path
      d="M77.6494 153.057C77.222 157.945 78.6302 163.715 80.4274 168.225C81.885 171.868 83.9672 176.099 87.8028 177.693C88.5809 178.016 89.074 176.751 88.3507 176.345C80.1699 171.66 80.4329 160.888 78.4877 152.942C78.3617 152.444 77.6822 152.597 77.6439 153.057H77.6494Z"
      fill="#231F20"
    />
    <path
      d="M116.91 153.715C116.11 158.027 116.148 162.312 114.652 166.515C113.194 170.614 110.91 174.575 106.794 176.378C106.022 176.718 106.592 177.847 107.342 177.682C111.441 176.778 113.918 172.099 115.381 168.564C117.14 164.285 118.581 158.356 117.759 153.721C117.731 153.629 117.675 153.55 117.598 153.493C117.522 153.436 117.429 153.406 117.334 153.406C117.239 153.406 117.146 153.436 117.07 153.493C116.993 153.55 116.937 153.629 116.91 153.721V153.715Z"
      fill="#231F20"
    />
    <path
      d="M82.3945 175.929C91.7095 180.044 103.025 180.099 112.477 176.356C113.381 175.995 113.025 174.553 112.06 174.855C107.238 176.329 102.575 177.507 97.4849 177.48C92.3945 177.452 87.7095 176.148 82.8054 174.954C82.2575 174.822 81.8191 175.677 82.3945 175.929Z"
      fill="#231F20"
    />
    <path
      d="M82.1698 175.403C81.6219 178.082 82.778 179.523 85.3369 180.055C90.0952 181.097 94.9601 181.571 99.8301 181.469C102.746 181.358 105.65 181.025 108.515 180.471C111.496 179.923 112.701 178.663 112.997 175.54C113.079 174.707 111.682 174.515 111.523 175.343C111.233 176.888 110.86 178.137 109.282 178.674C108.331 178.945 107.357 179.128 106.373 179.222C104.263 179.534 102.115 179.808 99.9835 179.923C96.822 180.032 93.6569 179.902 90.515 179.534C88.7999 179.359 86.8657 179.315 85.2109 178.816C83.1671 178.203 83.315 177.173 82.7287 175.397C82.641 175.151 82.2301 175.101 82.1808 175.397L82.1698 175.403Z"
      fill="#231F20"
    />
    <path
      d="M78.2082 157.321C73.5671 156.055 68.8931 158.318 70.0164 163.682C70.9643 168.153 76.7725 169.901 80.8383 169.819C81.0447 169.819 81.2426 169.737 81.3885 169.591C81.5344 169.445 81.6164 169.247 81.6164 169.041C81.6164 168.835 81.5344 168.637 81.3885 168.491C81.2426 168.345 81.0447 168.263 80.8383 168.263C78.252 168.181 73.463 167.31 72.0712 164.849C71.7046 164.203 71.4733 163.489 71.3913 162.75C71.3094 162.012 71.3785 161.264 71.5945 160.553C72.6191 157.907 75.8082 158.236 78.0438 158.466C78.7068 158.532 78.8164 157.485 78.1972 157.321H78.2082Z"
      fill="#231F20"
    />
    <path
      d="M79.0026 160.455C77.6491 159.808 75.9889 159.112 74.4875 159.649C73.8332 159.883 73.292 160.356 72.9736 160.974C72.6552 161.591 72.5833 162.307 72.7724 162.975C73.5834 165.995 77.6765 167.386 80.4765 167.069C80.661 167.053 80.8328 166.968 80.958 166.832C81.0832 166.695 81.1526 166.517 81.1526 166.332C81.1526 166.146 81.0832 165.968 80.958 165.832C80.8328 165.695 80.661 165.611 80.4765 165.595C78.7669 165.507 76.4984 165.354 75.1779 164.099C74.2409 163.211 73.408 161.359 75.2053 160.871C76.2026 160.597 77.6436 161.101 78.5916 161.419C79.1395 161.617 79.5505 160.707 79.0026 160.449V160.455Z"
      fill="#231F20"
    />
    <path
      d="M117.282 153.112C111.255 149.529 103.134 149.397 96.3505 149.447C92.6967 149.451 89.0476 149.711 85.43 150.225C82.9588 150.586 80.2245 150.986 78.0437 152.29C77.913 152.372 77.8084 152.49 77.7424 152.629C77.6764 152.768 77.6517 152.923 77.6713 153.076C77.691 153.229 77.754 153.373 77.853 153.491C77.9521 153.609 78.0829 153.697 78.23 153.743C84.1697 156.027 90.8327 156.553 97.156 156.663C103.731 156.784 110.915 156.553 117.266 154.586C117.366 154.559 117.459 154.512 117.541 154.448C117.623 154.384 117.691 154.304 117.742 154.214C117.794 154.124 117.826 154.024 117.839 153.921C117.852 153.818 117.844 153.714 117.816 153.614C117.789 153.514 117.741 153.42 117.678 153.338C117.614 153.257 117.534 153.188 117.444 153.137C117.354 153.086 117.254 153.053 117.151 153.04C117.048 153.028 116.944 153.035 116.844 153.063C110.381 154.492 103.774 155.165 97.156 155.069C90.9533 154.964 84.4985 154.444 78.6519 152.236L78.8437 153.682C80.8108 152.488 83.3314 152.121 85.567 151.77C89.1376 151.241 92.741 150.962 96.3505 150.937C102.964 150.855 110.74 150.937 116.772 154.006C116.83 154.039 116.893 154.061 116.959 154.07C117.024 154.079 117.091 154.075 117.155 154.058C117.219 154.041 117.279 154.012 117.332 153.972C117.385 153.932 117.429 153.882 117.463 153.825C117.496 153.768 117.518 153.704 117.527 153.639C117.536 153.573 117.532 153.506 117.515 153.442C117.499 153.378 117.469 153.318 117.429 153.265C117.389 153.212 117.339 153.168 117.282 153.134V153.112Z"
      fill="#231F20"
    />
    <path
      d="M0.0930568 167.447C-0.0165832 170.667 0.137355 173.89 0.553331 177.085C0.651961 178 2.07662 178.033 2.01086 177.085C1.78621 173.863 1.80813 170.471 1.2547 167.288C1.13415 166.597 0.15881 166.816 0.0930568 167.447Z"
      fill="#231F20"
    />
    <path
      d="M130.137 198.959C145.03 198.307 160.055 198.077 174.784 195.556C182.046 194.282 189.193 192.418 196.153 189.984C202.641 187.742 209.633 185.052 214.553 180.077C215.337 179.288 214.115 178.066 213.337 178.855C208.11 184.131 200.378 186.849 193.468 189.09C186.987 191.235 180.355 192.895 173.627 194.055C159.277 196.422 144.663 196.663 130.164 197.31C129.107 197.359 129.101 199.008 130.164 198.953L130.137 198.959Z"
      fill="#231F20"
    />
    <path
      d="M74.9424 197.934C79.841 198.219 84.7396 198.515 89.6382 198.773C90.6903 198.827 90.6848 197.189 89.6382 197.129C84.7396 196.882 79.841 196.581 74.9424 196.334C73.9232 196.279 73.9232 197.863 74.9424 197.923V197.934Z"
      fill="#231F20"
    />
    <path
      d="M0.821919 177.638C3.87945 181.474 8.4548 183.879 12.8767 185.858C18.4935 188.276 24.2969 190.234 30.2301 191.715C40.3397 194.367 50.8329 196.471 61.2767 197.233C62.2849 197.31 62.2849 195.731 61.2767 195.66C51.189 194.964 41.063 192.97 31.2822 190.482C25.3305 189.051 19.5035 187.143 13.8575 184.778C9.4411 182.86 4.89315 180.493 1.62192 176.877C1.13425 176.329 0.40548 177.118 0.838357 177.66L0.821919 177.638Z"
      fill="#231F20"
    />
    <path
      d="M213.704 168.767C213.759 172.307 213.381 175.77 213.277 179.293C213.249 180.142 214.663 180.389 214.795 179.501C215.31 175.947 215.402 172.343 215.069 168.767C214.997 167.918 213.704 167.885 213.721 168.767H213.704Z"
      fill="#231F20"
    />
    <path
      d="M88.3289 146.252C94.7343 145.704 101.123 145.414 107.551 145.359C107.719 145.359 107.881 145.292 108 145.173C108.119 145.054 108.186 144.892 108.186 144.723C108.186 144.555 108.119 144.393 108 144.274C107.881 144.155 107.719 144.088 107.551 144.088C101.123 144.148 94.7343 144.427 88.3289 144.997C87.5289 145.068 87.5234 146.323 88.3289 146.252Z"
      fill="#231F20"
    />
    <path
      d="M15.2494 156.258C10.1645 158.033 -1.13412 161.189 0.213821 168.592C1.38642 175.003 9.92341 177.693 15.1727 179.6C35.1946 186.871 57.0631 188.915 78.1864 190.208C90.6851 190.97 103.211 191.189 115.732 191.151C127.929 191.112 140.225 191.216 152.384 190.186C162.893 189.293 173.329 187.676 183.617 185.348C191.507 183.562 199.507 181.468 206.893 178.142C209.151 177.129 211.743 175.951 213.43 174.082C215.595 171.666 215.381 167.447 214.159 164.652C212.937 161.858 210.515 160.323 208.006 158.811C203.667 156.207 198.837 154.528 193.819 153.879C192.723 153.732 192.723 155.485 193.819 155.633C198.339 156.232 202.696 157.722 206.636 160.016C208.789 161.266 211.222 162.63 212.384 164.948C215.858 171.808 210.307 174.86 205.069 177.09C189.534 183.715 171.841 186.647 155.145 188.307C144.652 189.348 134.093 189.403 123.556 189.512C112.532 189.649 101.507 189.627 90.4933 189.26C70.1207 188.581 49.3152 187.156 29.4577 182.329C21.4138 180.373 12.3618 178.214 5.41382 173.507C1.93437 171.151 -0.208096 166.795 3.4467 163.419C6.69601 160.427 11.5344 158.915 15.5946 157.479C16.3508 157.211 16.0275 156 15.2604 156.269L15.2494 156.258Z"
      fill="#231F20"
    />
    <path
      d="M1.6602 150.8C6.2465 151.348 11.1122 150.8 15.7259 150.74C15.909 150.724 16.0795 150.64 16.2037 150.505C16.3279 150.369 16.3968 150.192 16.3968 150.008C16.3968 149.824 16.3279 149.647 16.2037 149.512C16.0795 149.377 15.909 149.293 15.7259 149.277C11.0629 149.359 6.14239 149.107 1.52321 149.781C0.898552 149.874 1.13417 150.74 1.6602 150.8Z"
      fill="#231F20"
    />
    <path
      d="M193.989 150.444C200.669 151.129 207.836 150.773 214.537 150.499C214.697 150.499 214.85 150.435 214.963 150.322C215.076 150.209 215.14 150.056 215.14 149.896C215.14 149.736 215.076 149.583 214.963 149.47C214.85 149.357 214.697 149.293 214.537 149.293C207.786 149.238 200.586 148.789 193.89 149.699C193.43 149.759 193.6 150.405 193.989 150.444Z"
      fill="#231F20"
    />
    <path
      d="M203.764 18.4548C203.523 40.2575 204.257 62.1041 204.597 83.9014C204.937 105.699 205.003 127.545 205.6 149.348C205.616 149.541 205.704 149.721 205.847 149.852C205.989 149.983 206.176 150.056 206.37 150.056C206.563 150.056 206.75 149.983 206.893 149.852C207.035 149.721 207.123 149.541 207.14 149.348C207.025 127.534 206.394 105.71 206.044 83.9014C205.693 62.0932 205.72 40.2356 204.767 18.4548C204.767 18.3226 204.714 18.1957 204.621 18.1022C204.527 18.0087 204.401 17.9562 204.268 17.9562C204.136 17.9562 204.009 18.0087 203.916 18.1022C203.822 18.1957 203.77 18.3226 203.77 18.4548H203.764Z"
      fill="#231F20"
    />
    <path
      d="M6.10425 16.3342C6.1426 38.6027 6.70151 60.8821 7.20014 83.1451C7.43758 94.1041 7.67503 105.063 7.91247 116.022C8.14809 127.32 8.05493 138.668 8.63028 149.951C8.64675 150.148 8.73673 150.332 8.88237 150.466C9.02801 150.6 9.21868 150.674 9.41658 150.674C9.61447 150.674 9.80514 150.6 9.95078 150.466C10.0964 150.332 10.1864 150.148 10.2029 149.951C10.3563 138.657 9.7974 127.315 9.55082 116.022C9.31338 105.063 9.07229 94.1041 8.82754 83.1451C8.32343 60.8767 7.90151 38.5972 7.10151 16.3342C7.08869 16.2131 7.03149 16.101 6.94094 16.0195C6.8504 15.938 6.7329 15.8929 6.6111 15.8929C6.4893 15.8929 6.3718 15.938 6.28125 16.0195C6.1907 16.101 6.13351 16.2131 6.12069 16.3342H6.10425Z"
      fill="#231F20"
    />
    <path
      d="M203.014 112.817C199.617 117.112 196.027 121.227 192.493 125.419C192.214 125.748 192.68 126.23 192.975 125.896C196.647 121.814 200.099 117.523 203.441 113.162C203.627 112.915 203.206 112.586 203.014 112.827V112.817Z"
      fill="#231F20"
    />
    <path
      d="M61.0302 25.5233C47.4247 41.874 33.726 58.1809 20.3562 74.7288C20.126 75.0137 20.5206 75.4247 20.7617 75.1343C34.4932 58.8822 47.9178 42.3507 61.4082 25.9014C61.6274 25.6384 61.2548 25.2548 61.0302 25.5233Z"
      fill="#231F20"
    />
    <path
      d="M53.4247 20.1371C42.2905 32.3617 31.4138 44.9754 20.8275 57.6768C20.548 58.011 21.0138 58.4878 21.3096 58.159C32.3562 45.8521 43.2713 33.2604 53.7644 20.4768C53.9617 20.2412 53.6384 19.9288 53.4247 20.1371Z"
      fill="#231F20"
    />
    <path
      d="M214.197 3.83565C208.652 13.9781 198.028 21.8083 186.049 20.822C174.833 19.9014 164.258 11.9946 159.595 1.88497C159.288 1.22195 158.34 1.48497 158.148 2.07127C154.428 13.3699 143.469 21.3863 131.578 21.7315C118.932 22.1041 108.06 13.1014 103.178 1.92332C102.882 1.25483 101.781 1.46305 101.633 2.12606C99.3426 12.1535 88.5755 18.0822 78.8988 18.6302C67.3043 19.3425 57.4522 11.0959 52.7508 1.04113C52.6619 0.897997 52.5336 0.783535 52.3812 0.71145C52.2289 0.639365 52.059 0.612709 51.8919 0.634679C51.7248 0.656649 51.5676 0.726313 51.4391 0.835326C51.3106 0.94434 51.2162 1.08808 51.1673 1.24935C48.6522 11.3096 40.4659 18.3562 30.3892 20.3178C18.9481 22.548 8.47139 16.2247 2.41112 6.74524C2.36039 6.65889 2.29314 6.58338 2.21323 6.52302C2.13332 6.46266 2.0423 6.41863 1.94537 6.39344C1.84844 6.36825 1.7475 6.3624 1.64831 6.37623C1.54912 6.39005 1.45363 6.42328 1.36728 6.47401C1.28093 6.52474 1.20542 6.59198 1.14506 6.67189C1.08469 6.75181 1.04066 6.84282 1.01548 6.93975C0.990292 7.03668 0.984443 7.13762 0.998267 7.23681C1.01209 7.336 1.04532 7.4315 1.09605 7.51784C7.05769 17.6329 18.3508 24.137 30.1974 22.0932C41.118 20.2083 50.1317 12.5644 52.8385 1.70963L51.2549 1.91784C56.307 12.7014 67.1454 21.3699 79.5508 20.2904C89.7481 19.4083 100.849 13.1672 103.26 2.56442L101.715 2.76716C106.926 14.674 118.833 24.137 132.28 23.3589C144.674 22.6576 155.841 14.2959 159.677 2.48223L158.225 2.67401C163.156 13.2603 174.323 21.3863 186.049 22.2904C198.471 23.2548 209.578 14.9809 215.244 4.42743C215.611 3.7425 214.57 3.1288 214.197 3.81921V3.83565Z"
      fill="#231F20"
    />
  </svg>
);

export default PlantNoon;
