import { useState, useEffect } from 'react';

// Source: https://github.com/beautifulinteractions/beautiful-react-hooks
// Extracted to keep bundle size low
// Usage: useMediaQuery('max-width: 48rem');
export default function useMediaQuery(mediaQuery, fallbackValue = false) {
  const [isMatch, setMatch] = useState(
    mediaQuery ? !!window.matchMedia(`(${mediaQuery})`).matches : fallbackValue
  );

  useEffect(() => {
    if (!mediaQuery) {
      return () => null;
    }
    const mediaQueryList = window.matchMedia(`(${mediaQuery})`);
    const handler = () => setMatch(!!mediaQueryList.matches);
    mediaQueryList.addListener(handler);
    return () => {
      mediaQueryList.removeListener(handler);
    };
  }, [mediaQuery]);

  return isMatch;
}
