import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BORDER_MD } from '../../theme';
import { getVariant, getSize, BUTTON_VARIANTS } from './utils';

const LinkButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  box-sizing: border-box;
  background: ${getVariant('background')};

  color: ${getVariant('color')};
  letter-spacing: 0.5px;
  font-size: ${getSize('fontSize')};
  font-weight: 400;

  border: 0px;
  border-radius: ${BORDER_MD};
  cursor: pointer;

  padding: ${getSize('padding')};
  width: ${getSize('width')};
  margin: 0 0 2px 0;

  &:disabled {
    box-shadow: none;
    cursor: auto;
    background: ${BUTTON_VARIANTS.disabled.background};
    color: ${BUTTON_VARIANTS.disabled.color};
  }

  &:hover {
    background: ${getVariant('backgroundActive')};
    color: ${getVariant('colorActive')};
  }
`;

export default LinkButton;
