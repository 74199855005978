import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import getRelativeDay from '../../../utils/getRelativeDay';
import { getDateFromThreadId } from '../../../utils/getThreadId';
import { GREY_900, GREY_500 } from '../../../theme';

const PaddingBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  padding: 0 20px 2px 16px;
  width: 100%;
`;

const H2 = styled.h2`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
`;

const Weekday = styled.div`
  box-sizing: border-box;
  font-size: 17px;
  font-weight: 700;
  color: ${GREY_900};
  margin-right: 5px;
`;

const ExactDate = styled.div`
  box-sizing: border-box;
  font-size: 17px;
  font-weight: 400;
  color: ${GREY_500};
`;

const ThreadDate = ({ threadId }) => {
  const threadDate = getDateFromThreadId(threadId);
  const weekday = getRelativeDay(threadDate);
  const calendarDate = format(threadDate, 'MMM dd, yyyy');
  return (
    <PaddingBox>
      <H2>
        <Weekday>{weekday}</Weekday>
        <ExactDate>{calendarDate}</ExactDate>
      </H2>
    </PaddingBox>
  );
};

export default React.memo(ThreadDate);
