import React from 'react';
import LinePlantNoon from '../../../components/svg/illustrations/LinePlantNoon';
import NoticeWithText from '../../../components/NoticeWithText';

const EmptyState = () => (
  <NoticeWithText title="Relationship Planner" image={<LinePlantNoon />}>
    Press the <strong>Add Friend To Planner</strong> button to add a friend to
    your planner. Your list of friends is prviate. Tendship is not a social
    network.
  </NoticeWithText>
);

export default EmptyState;
