import PasswordValidator from 'password-validator';

const schema = new PasswordValidator();
schema
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

export default function validatePassword(value) {
  const failures = schema.validate(value, { list: true });
  const errors = [];

  if (failures.includes('min')) {
    errors.push('Must be at least 8 characters.');
  }

  if (failures.includes('max')) {
    errors.push('Must be at most 100 characters.');
  }

  if (failures.includes('uppercase')) {
    errors.push('Must have at least one uppercase character.');
  }

  if (failures.includes('lowercase')) {
    errors.push('Must have at least one lowercase character.');
  }

  if (failures.includes('number')) {
    errors.push('Must have at least one number character.');
  }

  if (failures.includes('symbols')) {
    errors.push('Must have at least one special character.');
  }

  return errors;
}
