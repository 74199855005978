import React from 'react';
import { useSelector } from 'react-redux';
import EmptySlat from './EmptySlat';
import FriendSlat from './FriendSlat';
import H2 from '../../../components/H2';
import LinePlantCoffee from '../../../components/svg/illustrations/LinePlantCoffee';
import LinePlantPhoto from '../../../components/svg/illustrations/LinePlantPhoto';
import SlatDivider from './SlatDivider';
import Spacer from '../../../components/helpers/Spacer';
import {
  getReadyFriendList,
  getWaitingFriendList,
  getSilentFriendList,
} from '../../../reducers/friend';

const ActiveState = () => {
  const readyFriends = useSelector(getReadyFriendList);
  const pendingFriends = useSelector(getWaitingFriendList);
  const silentFriends = useSelector(getSilentFriendList);
  return (
    <React.Fragment>
      <H2 italic size={18}>
        Upcoming Engagements
      </H2>
      <SlatDivider />
      {!readyFriends.length && (
        <EmptySlat image={<LinePlantCoffee width={69} height={60} />}>
          You&apos;re all caught up!
        </EmptySlat>
      )}
      {readyFriends.map(({ short_id, name, entry_count }) => (
        <FriendSlat
          key={short_id}
          shortId={short_id}
          name={name}
          entryCount={entry_count}
        />
      ))}

      <Spacer margin={30} />

      <H2 italic size={18}>
        Future Engagements
      </H2>
      <SlatDivider />
      {!pendingFriends.length && !silentFriends.length && (
        <EmptySlat image={<LinePlantPhoto width={99} height={60} />}>
          It&apos;s been a while...
        </EmptySlat>
      )}
      {pendingFriends.map(({ short_id, name, entry_count, reminder_at }) => (
        <FriendSlat
          key={short_id}
          shortId={short_id}
          name={name}
          entryCount={entry_count}
          reminderAt={reminder_at}
        />
      ))}
      {silentFriends.map(({ short_id, name, entry_count }) => (
        <FriendSlat
          key={short_id}
          shortId={short_id}
          name={name}
          entryCount={entry_count}
          reminderAt={null}
        />
      ))}
    </React.Fragment>
  );
};

export default ActiveState;
