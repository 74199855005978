import styled from 'styled-components';
import { GREY_800 } from '../../theme';

export default styled.h1`
  font-size: ${({ size = 20 }) => `${size}px`};
  text-align: center;
  font-weight: 700;
  color: ${({ color }) => color || GREY_800};
  margin: 0;
  ${({ italic }) => (italic ? 'font-style: italic;' : '')}
`;
