import firstBy from 'thenby';
import isAfter from 'date-fns/isAfter';
import { createSelector } from 'reselect';
import reduceToObj from '../../utils/reduceToObj';

export const getFriendStatus = (state) => state.friend.status;

export const getFriends = (state) => {
  const friends = state.friend.entities.friend;
  return friends || undefined;
};

export const getFriend = (state, id) => {
  const friends = getFriends(state);
  return friends ? friends[id] : undefined;
};

// `list` getters
export const getFriendList = createSelector(getFriends, (friends) => {
  if (!friends) {
    return [];
  }
  const friendList = Object.keys(friends).map((id) => friends[id]);
  friendList.sort(firstBy('reminder_at').thenBy('reminder_frequency'));
  return friendList;
});

export const getReadyFriendList = createSelector(getFriendList, (friends) => {
  const today = new Date();
  return friends.filter(
    ({ reminder_frequency, reminder_at }) =>
      reminder_frequency > 0 && isAfter(today, new Date(reminder_at))
  );
});

export const getWaitingFriendList = createSelector(getFriendList, (friends) => {
  const today = new Date();
  return friends.filter(
    ({ reminder_frequency, reminder_at }) =>
      reminder_frequency > 0 && isAfter(new Date(reminder_at), today)
  );
});

export const getSilentFriendList = createSelector(getFriendList, (friends) =>
  friends.filter(({ reminder_frequency }) => reminder_frequency === 0)
);

// `short_id` getters
export const getFriendsByShortId = createSelector(getFriendList, (friends) =>
  reduceToObj(friends, (friend) => ({
    [friend.short_id]: friend,
  }))
);

export const getFriendByShortId = (state, shortId) => {
  const friends = getFriendsByShortId(state);
  return friends ? friends[shortId] : undefined;
};
