import React from 'react';
import styled from 'styled-components';
import {
  WHITE_000,
  GREY_000,
  GREY_200,
  GREY_800,
  TEAL_800,
  BORDER_MD,
} from '../../theme';

const Background = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${GREY_000};
  border-top: 1px solid ${GREY_200};
  min-height: 60px;
`;

const Content = styled.div`
  box-sizing: border-box;

  background-color: ${WHITE_000};
  border: 1px solid ${GREY_200};
  border-radius: ${BORDER_MD};
  margin: 15px 20px 0 20px;
  padding: 10px;
  min-height: 30px;
`;

const ActionBar = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 10px 20px 10px 20px;
`;

const SubtleButton = styled.button`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: ${GREY_800};
  text-align: center;
  vertical-align: middle;

  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:disabled {
    cursor: auto;
    font-weight: 400;
    color: ${GREY_200};
  }

  &:hover:enabled {
    color: ${TEAL_800};
  }
`;

const EntryInput = React.forwardRef(
  (
    {
      children,
      saveText,
      disabled = false,
      onMention = () => null,
      onSave = () => null,
    },
    ref
  ) => (
    <Background ref={ref}>
      <Content>{children}</Content>
      <ActionBar>
        <SubtleButton onClick={onMention}>@ Mention</SubtleButton>
        <SubtleButton disabled={disabled} onClick={onSave}>
          {saveText}{' '}
        </SubtleButton>
      </ActionBar>
    </Background>
  )
);

export default EntryInput;
