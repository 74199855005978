import 'whatwg-fetch';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import Router from './Router';
import './vendor/quill-mention/quill.mention';
import './vendor/quill-mention/quill.mention.css';
import './quill.css';
import './index.css';
import initStore from './init/store';

const store = initStore();

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <Router />
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);
