export const LG_SCREEN = 420;

export const WHITE_000 = '#FFFFFF';

export const TEAL_000 = '#F8FCFB';
export const TEAL_100 = '#DBEFEB';
export const TEAL_200 = '#CAE8E1';
export const TEAL_300 = '#AFDDD3';
export const TEAL_400 = '#88CCBD';
export const TEAL_500 = '#6EC1AF';
export const TEAL_600 = '#64B0A0';
export const TEAL_700 = '#5B9E90';
export const TEAL_800 = '#467B70';
export const TEAL_900 = '#3E6D63';

// Legacy shades
export const TEAL_X200 = '#95D1C4';
export const TEAL_X300 = '#88CCBD';
export const TEAL_X400 = '#7BC6B6';

export const BLUE_000 = '#E0FCFF';
export const BLUE_100 = '#BEF8fD';
export const BLUE_200 = '#87EAF2';
export const BLUE_300 = '#54D1DB';
export const BLUE_400 = '#38BEC9';
export const BLUE_500 = '#2CB1BC';
export const BLUE_600 = '#14919B';
export const BLUE_700 = '#0E7C86';
export const BLUE_800 = '#0A6C74';
export const BLUE_900 = '#044E54';

export const GREY_000 = '#F5F7FA';
export const GREY_100 = '#E4E7EB';
export const GREY_200 = '#CBD2D9';
export const GREY_300 = '#9AA5B1';
export const GREY_400 = '#7B8794';
export const GREY_500 = '#616E7C';
export const GREY_600 = '#52606D';
export const GREY_700 = '#3E4C59';
export const GREY_800 = '#323F4B';
export const GREY_900 = '#1F2933';

export const RED_000 = '#FFEEEE';
export const RED_100 = '#FACDCD';
export const RED_200 = '#F29B9B';
export const RED_300 = '#E66A6A';
export const RED_400 = '#D64545';
export const RED_500 = '#BA2525';
export const RED_600 = '#A61B1B';
export const RED_700 = '#911111';
export const RED_800 = '#780A0A';
export const RED_900 = '#610404';

export const YELLOW_000 = '#FFFAEB';
export const YELLOW_100 = '#FCEFC7';
export const YELLOW_200 = '#F8E3A3';
export const YELLOW_300 = '#F9DA8B';
export const YELLOW_400 = '#F7D070';
export const YELLOW_500 = '#E9B949';
export const YELLOW_600 = '#C99A2E';
export const YELLOW_700 = '#A27C1A';
export const YELLOW_800 = '#7C5E10';
export const YELLOW_900 = '#513C06';

export const GREEN_000 = '#EFFCF6';
export const GREEN_100 = '#C6F7E2';
export const GREEN_200 = '#8EEDC7';
export const GREEN_300 = '#65D6AD';
export const GREEN_400 = '#3EBD93';
export const GREEN_500 = '#27AB83';
export const GREEN_600 = '#199473';
export const GREEN_700 = '#147D64';
export const GREEN_800 = '#0C6B58';
export const GREEN_900 = '#014D40';

export const BORDER_SM = '2px';
export const BORDER_MD = '4px';
export const BORDER_LG = '6px';
