import React from 'react';
import Divider from '../Divider';
import H1 from '../H1';
import Spacer from '../helpers/Spacer';

const H1Divider = ({ children }) => (
  <React.Fragment>
    <H1>{children}</H1>
    <Spacer margin={15} />
    <Divider />
    <Spacer margin={15} />
  </React.Fragment>
);

export default H1Divider;
