import { LOGOUT } from '../auth';

export const RAISE_ERROR = 'error/RAISE_ERROR';

const initialState = false;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RAISE_ERROR:
      return true;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function raiseError() {
  return { type: RAISE_ERROR };
}

export const getIsErrorRaised = (state) => state.error;
