import React from 'react';
import { GREY_500 } from '../../../theme';

const LinePlant = ({ width = 74, height = 89 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 74 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.354 41.2472C60.4158 41.7697 56.7023 42.736 56.0281 42.736C52.9776 42.736 52.7641 42.736 49.7809 41.8259C49.1024 42.5522 48.3318 43.1868 47.4888 43.7135C44.3764 44.9326 42.8877 44.3203 39.472 43.5169C38.4382 43.08 37.4455 42.5516 36.5057 41.9382C33.5899 42.8371 31.7248 43.4888 28.8821 43.0955C24.6742 42.5337 22.4045 40.1292 22.2978 39.9326C18.9663 40.4101 11.9045 42.236 11.8989 42.9158C11.8989 44.5056 21.8989 46.1236 35.4326 46.191C48.9663 46.2584 61.663 43.7023 61.6742 42.1124C61.6742 41.7191 61.4439 41.4607 60.354 41.2472Z"
      fill={GREY_500}
    />
    <path
      d="M1.34265 42.8484C1.42692 48.3371 2.21905 53.8989 2.96063 59.3371C3.58422 63.8821 4.22467 68.5787 5.87636 72.8877C8.84827 80.6517 15.7078 85.3484 23.6966 86.9326C32.5446 88.7253 41.6332 88.9989 50.573 87.7416C54.4719 87.1798 58.5842 86.2473 61.8089 83.8091C65.0337 81.3708 67.2921 77.9102 68.8033 74.309C70.7584 69.6686 71.2247 64.6967 71.9494 59.7641C72.7865 54.0281 73.5337 48.3146 73.5224 42.5057C73.5224 40.7304 70.7696 40.7304 70.7696 42.5057C70.7287 47.2501 70.3438 51.9854 69.6179 56.6742C69.0561 60.6967 68.6516 64.8147 67.6853 68.7641C66.8239 72.6455 65.0372 76.2612 62.4775 79.3034C59.7528 82.4158 56.2528 83.7978 52.2696 84.5956C44.3651 86.1517 36.1573 85.8821 28.1966 84.927C19.8258 83.927 11.7696 80.6911 8.35388 72.3989C4.49995 63.0225 5.08422 52.3877 3.17973 42.5787C2.9831 41.545 1.35389 41.7978 1.36512 42.8259L1.34265 42.8484Z"
      fill={GREY_500}
    />
    <path
      d="M11.0112 39.1966C9.48876 39.1966 0.134831 40.3932 1.46067 43.1292C2.17977 44.6461 6.31461 45.2528 7.66854 45.6236C10.6392 46.4344 13.6584 47.0555 16.7079 47.4831C31.1687 49.4949 45.85 49.3188 60.2584 46.9607C63.9326 46.3595 70.309 45.809 72.882 42.6123C72.9791 42.5094 73.052 42.3861 73.0956 42.2515C73.1392 42.1169 73.1523 41.9742 73.1341 41.8339C73.1158 41.6936 73.0666 41.5591 72.9899 41.4401C72.9133 41.3212 72.8112 41.2207 72.691 41.146C70.0737 39.3078 67.0093 38.2094 63.8202 37.9663C62.6292 37.8708 62.6573 39.6966 63.8202 39.8146C66.6679 40.1116 69.3916 41.1349 71.7303 42.7865L71.5449 41.3202C70.4213 42.691 68.3539 43.2191 66.7528 43.691C64.042 44.4526 61.2829 45.0307 58.4944 45.4213C50.8066 46.5422 43.044 47.07 35.2753 47C27.5056 47 19.5786 46.6011 11.9663 44.9719C9.26966 44.4101 5.3764 43.9269 3.06742 42.3314C2.96067 42.8539 3.06741 42.955 3.4382 42.6404C3.81273 42.4719 4.18726 42.3202 4.5618 42.1854C5.30899 41.9213 6.06741 41.7022 6.80899 41.5C8.15954 41.0881 9.55224 40.8299 10.9607 40.7303C11.1527 40.7139 11.3316 40.6259 11.462 40.484C11.5923 40.342 11.6646 40.1562 11.6646 39.9635C11.6646 39.7707 11.5923 39.585 11.462 39.443C11.3316 39.301 11.1527 39.2131 10.9607 39.1966H11.0112Z"
      fill={GREY_500}
    />
    <path
      d="M32.6685 21.1797C31.0618 14.8426 26.3089 9.56737 20.691 6.41569C20.5206 6.31735 20.3275 6.26544 20.1308 6.26514C19.9342 6.26484 19.7409 6.31617 19.5703 6.41399C19.3997 6.5118 19.2577 6.65268 19.1586 6.82255C19.0595 6.99241 19.0067 7.18532 19.0056 7.38198C18.3707 16.9325 19.4775 28.2696 28.7247 33.6797C30.0561 34.4606 31.1292 32.5112 29.9157 31.6404C21.7808 25.8089 20.7303 16.6966 21.2303 7.38198L19.5449 8.34265C24.9365 11.2171 29.1842 15.8474 31.5842 21.4662C31.6427 21.5819 31.7395 21.6738 31.858 21.7263C31.9765 21.7788 32.1096 21.7888 32.2346 21.7544C32.3596 21.72 32.4688 21.6434 32.5438 21.5376C32.6188 21.4319 32.6549 21.3034 32.646 21.1741L32.6685 21.1797Z"
      fill={GREY_500}
    />
    <path
      d="M38.2809 41.4775C36.7472 40.1629 37.3484 38.8146 38.3708 37.2528C39.1605 36.0645 40.0196 34.924 40.9439 33.8371C41.472 33.2079 42.4382 31.6461 43.3034 31.7023C44.0506 31.7472 45.5843 33.4831 46.1911 33.9888C48.8877 36.2359 50.7753 40.5618 46.9045 42.8146C44.0001 44.5 40.4607 43.118 38.0169 41.2753L36.9551 43.0955L37.0057 43.1348C38.1293 43.9213 39.163 42.0955 38.0675 41.3146L38.0169 41.2753C36.8933 40.4831 35.8877 42.2978 36.9551 43.0955C40.2866 45.6067 45.2472 46.8764 48.8147 44.0506C52.5001 41.1292 51.6236 36.1404 48.472 33.1685C47.2079 31.9663 45.2641 29.8708 43.472 29.4944C41.6798 29.118 40.4663 31.1517 39.4495 32.3483C36.9776 35.2584 32.9551 39.6517 36.7922 42.9719C37.8147 43.8483 39.3147 42.3708 38.2809 41.4775Z"
      fill={GREY_500}
    />
    <path
      d="M50.809 36.8089C53.3877 34.764 55.6629 32.6853 58.5393 31.0112C61.1276 29.6154 63.6083 28.0286 65.9607 26.264L64.1236 25.7808C65.2472 30.1966 65.3258 36.0337 60.8258 38.7022C58.4438 40.1292 54.2978 41.1741 51.6461 40.1123C50.1405 39.5112 49.4888 41.9438 50.9775 42.5393C54.4944 43.9438 60.1629 42.4719 63.0506 40.1741C67.4157 36.7022 68.1067 30.1067 66.2472 25.1741C66.1911 24.9919 66.0895 24.8269 65.952 24.6947C65.8146 24.5625 65.6457 24.4675 65.4614 24.4187C65.2771 24.3698 65.0833 24.3686 64.8984 24.4152C64.7135 24.4619 64.5436 24.5548 64.4045 24.6853C61.7023 27.0224 58.4101 28.5617 55.4438 30.5224C53.2697 31.9606 50.6629 33.6348 49.9214 36.2696C49.9094 36.3777 49.9291 36.4869 49.9781 36.5841C50.027 36.6812 50.1031 36.762 50.1971 36.8167C50.2911 36.8715 50.3989 36.8977 50.5075 36.8924C50.6162 36.887 50.7209 36.8502 50.809 36.7865V36.8089Z"
      fill={GREY_500}
    />
    <path
      d="M44.4832 26.236C45.5787 24.2304 45.7697 22.3708 47.5 20.6742C49.5843 18.6349 52.2247 17.2079 54.6686 15.6461L53.3708 15.309C56.4157 20.2641 55 28.3877 51.927 33.0506C51.1349 34.2528 53.0506 35.3708 53.8539 34.1742C57.3371 28.8764 58.9101 19.7248 55.0056 14.3484C54.8779 14.1335 54.6711 13.9773 54.4295 13.9133C54.1879 13.8492 53.9309 13.8824 53.7135 14.0057C49.2472 17.0787 43.5393 19.8203 43.4551 25.9551C43.4627 26.0723 43.5068 26.1842 43.5813 26.2751C43.6558 26.3659 43.7569 26.4311 43.8704 26.4615C43.9839 26.4919 44.104 26.4859 44.2139 26.4444C44.3239 26.403 44.418 26.3281 44.4832 26.2304V26.236Z"
      fill={GREY_500}
    />
    <path
      d="M56.9944 22.9439C58.7248 19.5731 60.736 16.2754 63.736 13.8484L62.1574 13.4326C63.2809 18.6855 63.2192 23.3147 60.0619 27.854C59.472 28.7079 60.7753 29.6798 61.4832 28.9495C65.2866 25.0169 66.0113 17.8709 63.9776 12.9327C63.9316 12.7749 63.8457 12.6318 63.7283 12.5169C63.6108 12.4021 63.4657 12.3195 63.307 12.2772C63.1483 12.2348 62.9813 12.2341 62.8222 12.2751C62.6632 12.3161 62.5174 12.3975 62.3989 12.5113C59.5899 15.5225 57.1517 18.4832 55.6574 22.3709C55.3596 23.1518 56.6293 23.6798 57.0057 22.9327L56.9944 22.9439Z"
      fill={GREY_500}
    />
    <path
      d="M22.3033 35.5393C20.7929 33.4091 18.5816 31.8779 16.0561 31.2135C12.0505 30.1348 8.37075 31.2978 4.34828 31.5562C3.78648 31.5955 3.10671 32.1854 3.37637 32.8371C6.46064 40.3989 15.3988 44.8146 22.6797 40.1404C23.8033 39.4326 22.7584 37.6573 21.6517 38.3764C15.5842 42.309 7.89884 38.7472 5.3258 32.3146L4.34828 33.5955C7.83704 33.3933 11.264 32.3258 14.7528 33.0337C17.3988 33.5955 19.1517 35.0449 21.309 36.5449C21.8708 36.9494 22.7303 36.1348 22.3033 35.5506V35.5393Z"
      fill={GREY_500}
    />
    <path
      d="M28.2023 12.7809C27.3596 8.80903 29.1405 5.78656 31.2753 2.66858L29.9776 3.00566C31.7191 4.00566 33.5337 4.84275 35.0337 6.22477C36.2208 7.41663 37.1938 8.80392 37.9102 10.3259C38.3765 11.1798 39.5955 10.5675 39.4045 9.69668C38.6124 5.52252 34.2978 3.24161 30.9776 1.37083C30.7594 1.2496 30.5029 1.217 30.2613 1.27975C30.0197 1.34251 29.8115 1.49582 29.6798 1.70791C28.3596 3.67982 27.0337 5.42139 26.5281 7.7978C26.1686 9.48319 25.9663 12.0225 27.2809 13.3427C27.3627 13.4552 27.4834 13.5334 27.6195 13.562C27.7555 13.5907 27.8974 13.5679 28.0177 13.498C28.1379 13.4281 28.2279 13.3161 28.2703 13.1836C28.3127 13.0512 28.3045 12.9077 28.2472 12.7809H28.2023Z"
      fill={GREY_500}
    />
    <path
      d="M20.9101 19.5337C18.3764 15.7472 13.5224 13.809 9.52244 11.9888C9.37484 11.9061 9.20832 11.8632 9.03915 11.8642C8.86998 11.8651 8.70396 11.91 8.55733 11.9943C8.41069 12.0787 8.28846 12.1997 8.20258 12.3454C8.1167 12.4912 8.07012 12.6567 8.06738 12.8259C8.21345 17.1068 8.17412 21.4944 9.96626 25.4775C11.5694 29.1197 14.3356 32.1269 17.8314 34.0281C18.0414 34.1294 18.2818 34.1478 18.5047 34.0798C18.7276 34.0117 18.9168 33.8622 19.0344 33.661C19.152 33.4597 19.1895 33.2215 19.1394 32.9939C19.0893 32.7663 18.9553 32.5659 18.764 32.4326C15.4574 30.4915 12.8887 27.5078 11.4606 23.9495C10.1067 20.4214 10.1573 16.5506 10.0168 12.8259L8.55614 13.6629C12.4382 15.4101 16.8483 17.2416 19.8258 20.4045C20.3876 20.9663 21.3651 20.2304 20.9494 19.5618L20.9101 19.5337Z"
      fill={GREY_500}
    />
    <path
      d="M44.4101 11.8146C45.6011 7.93822 47.1404 4.51125 50.9438 2.54496L49.5842 1.98316C50.7078 4.62923 51.9045 7.35396 52.0449 10.2641C52.146 12.4382 51.6685 13.9607 50.9213 15.9551C50.4494 17.2191 52.4157 17.7023 52.9157 16.5169C55.0674 11.3708 53.3651 6.20226 51.2752 1.28092C51.149 1.07217 50.9464 0.920811 50.7104 0.858933C50.4744 0.797054 50.2236 0.82952 50.0112 0.949457C46.3033 2.98316 43.0281 7.25844 43.0898 11.6236C43.0928 11.7845 43.1532 11.939 43.2601 12.0592C43.3671 12.1795 43.5135 12.2575 43.6729 12.2792C43.8324 12.3009 43.9943 12.2649 44.1295 12.1776C44.2647 12.0903 44.3642 11.9576 44.4101 11.8034V11.8146Z"
      fill={GREY_500}
    />
    <path
      d="M33.1348 34.1797C31.8034 28.3202 32.191 23.3539 35.0899 18.0168C37.4046 13.5638 40.3132 9.44567 43.7359 5.77522L41.9719 5.31456C43.0368 10.4641 43.4857 15.722 43.309 20.9775C43.2079 24.3483 42.2977 27.2134 41.0618 30.3876C40.5 31.7921 42.7921 32.4213 43.309 31.0112C44.6067 27.6404 45.4944 24.5786 45.5562 20.9775C45.7003 15.5189 45.1796 10.0631 44.0056 4.73029C43.9536 4.55446 43.8572 4.39498 43.7257 4.26723C43.5942 4.13947 43.432 4.04776 43.2548 4.0009C43.0775 3.95404 42.8912 3.95363 42.7137 3.99972C42.5362 4.0458 42.3736 4.13682 42.2416 4.264C34.809 12.1629 28.1966 23.5955 30.8595 34.7809C31.2135 36.2584 33.4719 35.6348 33.1067 34.1573L33.1348 34.1797Z"
      fill={GREY_500}
    />
    <path
      d="M36.9045 40.4888C34.9045 34.3371 28.9663 30.3765 22.9326 28.7978C22.6734 28.7286 22.3974 28.764 22.1641 28.8964C21.9308 29.0289 21.7589 29.2477 21.6854 29.5057C20.7135 34.1405 19.3708 39.618 24.0899 42.7473C27.236 44.8259 33.191 45.2641 36.3877 43.0787C36.6077 42.9415 36.7716 42.7304 36.8499 42.4832C36.9283 42.236 36.9159 41.969 36.8151 41.7301C36.7142 41.4912 36.5315 41.2961 36.2998 41.1798C36.068 41.0635 35.8024 41.0336 35.5506 41.0956C32.4888 41.618 29.6124 42.6349 26.5337 41.4102C21.7922 39.5282 22.3034 34.0675 23.6461 30.045L22.3933 30.7529C28.1742 32.3484 32.4495 35.9495 35.3146 41.1517C35.4219 41.3297 35.5897 41.4631 35.7872 41.5276C35.9848 41.592 36.1989 41.5832 36.3905 41.5028C36.5821 41.4223 36.7384 41.2756 36.8307 41.0894C36.923 40.9032 36.9452 40.69 36.8933 40.4888H36.9045Z"
      fill={GREY_500}
    />
  </svg>
);

export default LinePlant;
