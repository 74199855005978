import { LOGOUT } from '../auth';
import fetchJSON from '../../utils/core/fetchJSON';

export const SINGLE_REQUEST = 'setting/SINGLE_REQUEST';
export const SINGLE_SUCCESS = 'setting/SINGLE_SUCCESS';
export const SINGLE_FAILURE = 'setting/SINGLE_FAILURE';
export const UPDATE_REQUEST = 'setting/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'setting/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'setting/UPDATE_FAILURE';

const initialState = {
  status: {
    ok: false,
    isFetching: false,
    error: null,
  },
  data: {
    values: {},
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SUCCESS:
    case SINGLE_SUCCESS: {
      const { values } = action.payload.data;
      return {
        status: {
          ok: true,
          isFetching: false,
          error: null,
        },
        data: {
          values,
        },
      };
    }
    case SINGLE_REQUEST: {
      return {
        ...initialState,
        status: {
          ok: false,
          isFetching: true,
          error: null,
        },
      };
    }
    case SINGLE_FAILURE: {
      return {
        ...initialState,
        status: {
          ok: false,
          isFetching: false,
          error: action.error,
        },
      };
    }
    case UPDATE_REQUEST: {
      return {
        ...state,
        status: {
          ok: false,
          isFetching: true,
          error: null,
        },
      };
    }
    case UPDATE_FAILURE: {
      return {
        ...state,
        status: {
          ok: false,
          isFetching: false,
          error: action.error,
        },
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function fetchSettings() {
  return (dispatch) => {
    dispatch({ type: SINGLE_REQUEST });
    return dispatch(
      fetchJSON('/api/v1/person/me/setting', {
        method: 'GET',
      })
    )
      .then(({ data }) =>
        dispatch({
          type: SINGLE_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: SINGLE_FAILURE, error: err.error });
        throw err;
      });
  };
}

export function updateSettings(values) {
  return (dispatch) => {
    dispatch({ type: UPDATE_REQUEST });
    return dispatch(
      fetchJSON('/api/v1/person/me/setting', {
        method: 'PATCH',
        body: { values },
      })
    )
      .then(({ data }) =>
        dispatch({
          type: UPDATE_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: UPDATE_FAILURE, error: err.error });
        throw err;
      });
  };
}

export const getSettings = (state) => state.setting.data.values;
export const getSettingStatus = (state) => state.setting.status;
