import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${({ grow }) => (grow ? `flex-grow: ${grow};` : '')}
`;
