import { createSelector } from 'reselect';
import { LOGOUT } from '../auth';
import {
  getIdFromSecret,
  getKeyFromSecret,
  getKeyArrayFromSecret,
} from '../../utils/encryption';

export const CRYPT_SET = 'crypt/CRYPT_SET';

const initialState = {
  secret: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CRYPT_SET:
      return {
        secret: action.payload.secret,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function setCryptSecret(secret) {
  return { type: CRYPT_SET, payload: { secret } };
}

export const getIsCryptLoaded = (state) => !!state.crypt.secret;

export const getCryptId = createSelector(
  (state) => state.crypt.secret,
  (key) => getIdFromSecret(key)
);

export const getCryptKey = createSelector(
  (state) => state.crypt.secret,
  getKeyFromSecret
);

export const getCryptKeyArray = createSelector(
  (state) => state.crypt.secret,
  getKeyArrayFromSecret
);
