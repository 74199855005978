import React from 'react';
import styled from 'styled-components';
import getRelativeDay from '../../../utils/getRelativeDay';
import { getDateFromThreadId } from '../../../utils/getThreadId';
import { GREY_300, GREY_500 } from '../../../theme';

const PaddingBox = styled.div`
  padding: 5px 20px 5px 20px;
`;

const Text = styled.div`
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  color: ${GREY_500};
  border-left: 4px solid ${GREY_300};
  padding-left: 7px;
`;

const EntryPlaceHolder = ({ threadId, isActive }) => {
  const threadDate = getDateFromThreadId(threadId);
  const weekday = getRelativeDay(threadDate);
  return (
    <PaddingBox>
      <Text>
        {isActive
          ? `Write an entry for ${weekday} by typing below.`
          : `Write an entry for ${weekday}...`}
      </Text>
    </PaddingBox>
  );
};

export default EntryPlaceHolder;
