import React from 'react';
import styled from 'styled-components';
import useQueryString from '../../utils/hooks/useQueryString';
import BackButton from '../buttons/BackButton';
import { TEAL_200, BORDER_MD, LG_SCREEN } from '../../theme';

const NavigationContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${TEAL_200};
  padding: 10px 15px;
  min-height: 45px;
  border-radius: 0;

  @media (min-width: ${LG_SCREEN}px) {
    border-radius: ${BORDER_MD} ${BORDER_MD} 0 0;
  }
`;

const PanelNavigation = React.forwardRef(
  ({ backButtonTo, onBackButtonClick }, ref) => {
    const { return_url } = useQueryString();
    return (
      <NavigationContainer ref={ref}>
        <BackButton
          href={return_url}
          to={backButtonTo}
          onClick={onBackButtonClick}
        />
      </NavigationContainer>
    );
  }
);

export default PanelNavigation;
