import React, { useCallback } from 'react';
import { Swipeable } from 'react-swipeable';
import { useHistory } from 'react-router-dom';

const SwipeNavigation = ({ children, leftTo, rightTo }) => {
  const history = useHistory();
  const onSwipeLeft = useCallback(() => {
    if (leftTo) {
      history.push(leftTo);
    }
  }, [history, leftTo]);
  const onSwipeRight = useCallback(() => {
    if (rightTo) {
      history.push(rightTo);
    }
  }, [history, rightTo]);
  return (
    <Swipeable onSwipedLeft={onSwipeRight} onSwipedRight={onSwipeLeft}>
      {children}
    </Swipeable>
  );
};

export default SwipeNavigation;
