import React, { useState } from 'react';
import md5 from 'md5';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import Divider from '../../../components/Divider';
import DropArea from '../../../components/fields/DropArea';
import FlexScrollbar from '../../../components/FlexScrollbar';
import H1 from '../../../components/H1';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import Spacer from '../../../components/helpers/Spacer';
import TextField from '../../../components/fields/TextField';
import ViewKeyDialog from './ViewKeyDialog';
import createTextFile from '../../../utils/createTextFile';
import useRefHeight from '../../../utils/hooks/useRefHeight';
import { logout } from '../../../reducers/auth';
import { getPersonStatus, setCryptId } from '../../../reducers/person';
import { setCryptSecret } from '../../../reducers/crypt';
import {
  createSecret,
  isValidSecret,
  getIdFromSecret,
  getKeyFromSecret,
} from '../../../utils/encryption';

const KeyGenerationPage = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [secret] = useState(createSecret());
  const { isFetching } = useSelector(getPersonStatus);
  const [navHeight, navRef] = useRefHeight();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBack = () => {
    dispatch(logout()).then(() => history.push('/'));
  };

  const submit = ({ encryption_key }) => {
    const cryptId = getIdFromSecret(encryption_key);
    const cryptKeyMd5 = md5(getKeyFromSecret(encryption_key));
    toast.dismiss();
    dispatch(setCryptSecret(encryption_key));
    return dispatch(setCryptId(cryptId, cryptKeyMd5))
      .then(() => {
        history.push('/');
      })
      .catch((err) => {
        dispatch(setCryptSecret(null));
        toast.error(err.error);
      });
  };

  const handleDownload = () => {
    const id = getIdFromSecret(secret);
    createTextFile(`tendship-${id}.key.txt`, secret);
  };

  return (
    <React.Fragment>
      <Helmet title="Securing Your Journal" />
      <ViewKeyDialog
        isOpen={isDialogOpen}
        secret={secret}
        onClose={() => setIsDialogOpen(false)}
      />
      <PanelLayoutContainer>
        <Form
          onSubmit={submit}
          validate={(values) => {
            const errors = {};

            if (!values.encryption_key) {
              errors.encryption_key = 'Encryption key is required.';
            }

            if (
              values.encryption_key &&
              !isValidSecret(values.encryption_key)
            ) {
              errors.encryption_key = 'Encryption key must be valid.';
            }

            if (values.encryption_key !== secret) {
              errors.encryption_key =
                'Encryption key does not match the generated key.';
            }

            return errors;
          }}
          render={({ handleSubmit, invalid }) => (
            <DropArea name="encryption_key">
              <PanelLayoutPage as="form" onSubmit={handleSubmit}>
                <PanelNavigation ref={navRef} onBackButtonClick={handleBack} />
                <FlexScrollbar
                  refHeights={[navHeight]}
                  desktopHeight={PanelLayoutPage.minHeight}
                >
                  <PanelLayoutPage.Header>
                    <Spacer margin={10} />
                    <H1>Securing Your Journal</H1>
                    <Spacer margin={15} />
                    <p>
                      Your <strong>encryption key</strong> has been locally
                      generated on your device. You will need to provide this
                      key every time you go into Tendship.
                    </p>

                    <p>
                      Your journal entries will never be readable by Tendship or
                      anyone else because they are encrypted by your key on your
                      device before they are sent to Tendship for storage.
                    </p>

                    <p>
                      You will have the <strong>only</strong> copy of your key.
                      Tendship will not be able to recover your data if your key
                      is lost. Storing the key in another service (e.g. Dropbox)
                      is advised.
                    </p>
                    <Spacer margin={15} />
                  </PanelLayoutPage.Header>
                  <PanelLayoutPage.Body />

                  <PanelLayoutPage.Footer>
                    <Divider margin={15} />
                    <TextField
                      name="encryption_key"
                      type="text"
                      placeholder="XXXXXX.Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y"
                      hint={
                        <React.Fragment>
                          Press the <strong>Download Encryption Key</strong>{' '}
                          button to save the encryption key to your device. Copy
                          the 40 letter value inside the file in this field or
                          drag the file into it.
                        </React.Fragment>
                      }
                    >
                      <strong>Encryption Key</strong>
                    </TextField>
                    <Spacer margin={15} />
                    <Button
                      type="submit"
                      disabled={isFetching || invalid}
                      variant="primary"
                      size="block"
                    >
                      Confirm Encryption Key
                    </Button>
                    <Spacer />
                    <Button
                      type="button"
                      disabled={isFetching}
                      variant="secondary"
                      size="block"
                      onClick={handleDownload}
                    >
                      Download Encryption Key
                    </Button>
                    <Spacer />
                    <Button
                      type="button"
                      disabled={isFetching}
                      variant="tertiary"
                      size="block"
                      onClick={() => setIsDialogOpen(true)}
                    >
                      View Encryption Key
                    </Button>
                  </PanelLayoutPage.Footer>
                </FlexScrollbar>
              </PanelLayoutPage>
            </DropArea>
          )}
        />
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default KeyGenerationPage;
