import addSeconds from 'date-fns/addSeconds';
import fetchJSON from '../../utils/core/fetchJSON';
import { raiseError } from '../error';

export const NOT_AUTHENTICATED = 'auth/NOT_AUTHENTICATED';
export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';
export const LOGIN_RESTORE = 'auth/LOGIN_RESTORE';
export const REFRESH_REQUEST = 'auth/REFRESH_REQUEST';
export const REFRESH_SUCCESS = 'auth/REFRESH_SUCCESS';
export const REFRESH_FAILURE = 'auth/REFRESH_FAILURE';
export const LOGOUT = 'auth/LOGOUT';

const initialState = {
  status: {
    ok: false,
    isFetching: false,
    error: null,
  },
  isAuthenticated: false,
  isVerified: false,
  expiresAt: null,
  data: {
    access_token: null,
    refresh_token: null,
    token_type: null,
    expires_in: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...initialState,
        status: {
          ok: false,
          isFetching: true,
          error: null,
        },
      };
    case LOGIN_RESTORE:
    case LOGIN_SUCCESS:
    case REFRESH_SUCCESS: {
      const { access_token, expires_in } = action.payload.data;
      // `expires_in` is in number of seconds. We subtract 60 seconds to have
      // a buffer so we don't make a request when it's not expired.
      const expiresAt = addSeconds(new Date(), expires_in - 60);

      let isVerified;
      try {
        const parsed = JSON.parse(atob(access_token.split('.')[1]));
        isVerified = parsed.is_verified;
      } catch (e) {
        isVerified = false;
      }

      return {
        ...state,
        status: {
          ok: true,
          isFetching: false,
          error: null,
        },
        isAuthenticated: true,
        isVerified,
        expiresAt,
        data: action.payload.data,
      };
    }
    case REFRESH_FAILURE:
    case LOGIN_FAILURE:
      return {
        ...state,
        status: {
          ok: false,
          isFetching: false,
          error: action.error,
        },
        isAuthenticated: false,
        data: {},
      };
    case REFRESH_REQUEST:
      return {
        ...state,
        status: {
          ok: false,
          isFetching: true,
          error: null,
        },
      };
    case LOGOUT:
    case NOT_AUTHENTICATED:
      return initialState;
    default:
      return state;
  }
}

export function register(name, email, password, password_confirm, beta_code) {
  return (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });

    return dispatch(
      fetchJSON('/api/v1/auth/register', {
        method: 'POST',
        body: { name, email, password, password_confirm, beta_code },
      })
    )
      .then(({ data }) =>
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: LOGIN_FAILURE, error: err.error });
        throw err;
      });
  };
}

export function login(email, password) {
  return (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });

    return dispatch(
      fetchJSON('/api/v1/auth/login', {
        method: 'POST',
        body: { email, password },
      })
    )
      .then(({ data }) =>
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: LOGIN_FAILURE, error: err.error });
        throw err;
      });
  };
}

export function refreshToken() {
  return (dispatch, getState) => {
    const state = getState();
    const expiresAt = state.auth.expiresAt;
    const token = state.auth.data.refresh_token;
    const isVerified = getIsVerified(state);

    // Do not refresh if the token is still valid
    if (!token || (isVerified && new Date() < new Date(expiresAt))) {
      return Promise.resolve();
    }

    dispatch({ type: REFRESH_REQUEST });

    return fetch('/api/v1/auth/token', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh_token: token }),
    })
      .then((res) => res.json())
      .then(({ ok, status, data }) => {
        if (status === 500) {
          // If we fail to renew the token due to the server being down
          // we don't want to automatically logout the user.
          dispatch(raiseError());
          return;
        }
        if (!ok) {
          dispatch({ type: REFRESH_FAILURE, error: 'NOT_FOUND' });
          return;
        }
        dispatch({
          type: REFRESH_SUCCESS,
          payload: { data },
        });
      })
      .catch((err) => {
        // If we fail to renew the token due to the server being down
        // we don't want to automatically logout the user.
        dispatch(raiseError());
        throw err;
      });
  };
}

export function logout() {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.auth.data.refresh_token;

    return (
      dispatch(
        fetchJSON('/api/v1/auth/logout', {
          method: 'POST',
          body: { refresh_token: token },
        })
      )
        // Silent fetch. We don't care if the logout fails.
        .then(() => dispatch({ type: LOGOUT }))
        .catch(() => dispatch({ type: LOGOUT }))
    );
  };
}

export function notAuthenticated() {
  return { type: NOT_AUTHENTICATED };
}

export const getAuthStatus = (state) => state.auth.status;

export const getIsAuthenticated = (state) => state.auth.isAuthenticated;

export const getIsVerified = (state) => state.auth.isVerified;

export const getTokenHeader = (state) => {
  const accessToken = state.auth.data.access_token;
  const tokenType = state.auth.data.token_type;
  if (!accessToken || !tokenType) {
    return undefined;
  }
  return `${tokenType} ${accessToken}`;
};
