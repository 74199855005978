import React from 'react';
import { TEAL_300 } from '../../../theme';

const BackIcon = ({ width = 8, height = 14, color = TEAL_300 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.93957 0.943376V13.0566C7.93957 13.8954 6.92546 14.3155 6.33237 13.7224L0.275736 7.66573C-0.091912 7.29808 -0.091912 6.70194 0.275736 6.33429L6.33237 0.277656C6.92546 -0.315477 7.93957 0.104612 7.93957 0.943376Z"
      fill={color}
    />
  </svg>
);

export default BackIcon;
