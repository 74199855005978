import React from 'react';
import LinePlantMorning from '../../../components/svg/illustrations/LinePlantMorning';
import NoticeWithText from '../../../components/NoticeWithText';

const EmptyState = () => (
  <NoticeWithText title="Journal" image={<LinePlantMorning />}>
    Welcome to your private journal. Before you can write your first journal
    entry, you need to go to your <strong>Planner</strong> and add at least one
    friend. Once you do, you&apos;ll be able to mention them in your journal.
  </NoticeWithText>
);

export default EmptyState;
