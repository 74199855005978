import React from 'react';

const LinePlantMorning = ({ width = 214, height = 200 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 214 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M90.354 152.247C90.4158 152.77 86.7023 153.736 86.0281 153.736C82.9776 153.736 82.7641 153.736 79.7809 152.826C79.1024 153.552 78.3318 154.187 77.4888 154.714C74.3764 155.933 72.8877 155.32 69.472 154.517C68.4382 154.08 67.4455 153.552 66.5057 152.938C63.5899 153.837 61.7248 154.489 58.8821 154.096C54.6742 153.534 52.4045 151.129 52.2978 150.933C48.9663 151.41 41.9045 153.236 41.8989 153.916C41.8989 155.506 51.8989 157.124 65.4326 157.191C78.9663 157.258 91.663 154.702 91.6742 153.112C91.6742 152.719 91.4439 152.461 90.354 152.247Z"
      fill="#52606D"
    />
    <path
      d="M31.3427 153.848C31.4269 159.337 32.2191 164.899 32.9606 170.337C33.5842 174.882 34.2247 179.579 35.8764 183.888C38.8483 191.652 45.7078 196.348 53.6966 197.933C62.5446 199.725 71.6332 199.999 80.573 198.742C84.4719 198.18 88.5842 197.247 91.8089 194.809C95.0337 192.371 97.2921 188.91 98.8033 185.309C100.758 180.669 101.225 175.697 101.949 170.764C102.786 165.028 103.534 159.315 103.522 153.506C103.522 151.73 100.77 151.73 100.77 153.506C100.729 158.25 100.344 162.985 99.6179 167.674C99.0561 171.697 98.6516 175.815 97.6853 179.764C96.8239 183.645 95.0372 187.261 92.4775 190.303C89.7528 193.416 86.2528 194.798 82.2696 195.596C74.3651 197.152 66.1573 196.882 58.1966 195.927C49.8258 194.927 41.7696 191.691 38.3539 183.399C34.5 174.023 35.0842 163.388 33.1797 153.579C32.9831 152.545 31.3539 152.798 31.3651 153.826L31.3427 153.848Z"
      fill="#52606D"
    />
    <path
      d="M41.0112 150.197C39.4888 150.197 30.1348 151.393 31.4607 154.129C32.1798 155.646 36.3146 156.253 37.6685 156.624C40.6392 157.434 43.6584 158.055 46.7079 158.483C61.1687 160.495 75.85 160.319 90.2584 157.961C93.9326 157.36 100.309 156.809 102.882 153.612C102.979 153.509 103.052 153.386 103.096 153.251C103.139 153.117 103.152 152.974 103.134 152.834C103.116 152.694 103.067 152.559 102.99 152.44C102.913 152.321 102.811 152.221 102.691 152.146C100.074 150.308 97.0093 149.209 93.8202 148.966C92.6292 148.871 92.6573 150.697 93.8202 150.815C96.6679 151.112 99.3916 152.135 101.73 153.786L101.545 152.32C100.421 153.691 98.3539 154.219 96.7528 154.691C94.042 155.453 91.2829 156.031 88.4944 156.421C80.8066 157.542 73.044 158.07 65.2753 158C57.5056 158 49.5786 157.601 41.9663 155.972C39.2697 155.41 35.3764 154.927 33.0674 153.331C32.9607 153.854 33.0674 153.955 33.4382 153.64C33.8127 153.472 34.1873 153.32 34.5618 153.185C35.309 152.921 36.0674 152.702 36.809 152.5C38.1595 152.088 39.5522 151.83 40.9607 151.73C41.1527 151.714 41.3316 151.626 41.462 151.484C41.5923 151.342 41.6646 151.156 41.6646 150.963C41.6646 150.771 41.5923 150.585 41.462 150.443C41.3316 150.301 41.1527 150.213 40.9607 150.197H41.0112Z"
      fill="#52606D"
    />
    <path
      d="M62.6685 132.18C61.0618 125.843 56.3089 120.567 50.691 117.416C50.5206 117.317 50.3275 117.265 50.1308 117.265C49.9342 117.265 49.7409 117.316 49.5703 117.414C49.3997 117.512 49.2577 117.653 49.1586 117.823C49.0595 117.992 49.0067 118.185 49.0056 118.382C48.3707 127.933 49.4775 139.27 58.7247 144.68C60.0561 145.461 61.1292 143.511 59.9157 142.64C51.7808 136.809 50.7303 127.697 51.2303 118.382L49.5449 119.343C54.9365 122.217 59.1842 126.847 61.5842 132.466C61.6427 132.582 61.7395 132.674 61.858 132.726C61.9765 132.779 62.1096 132.789 62.2346 132.754C62.3596 132.72 62.4688 132.643 62.5438 132.538C62.6188 132.432 62.6549 132.303 62.646 132.174L62.6685 132.18Z"
      fill="#52606D"
    />
    <path
      d="M68.2809 152.478C66.7472 151.163 67.3484 149.815 68.3708 148.253C69.1605 147.065 70.0196 145.924 70.9439 144.837C71.472 144.208 72.4382 142.646 73.3034 142.702C74.0506 142.747 75.5843 144.483 76.1911 144.989C78.8877 147.236 80.7753 151.562 76.9045 153.815C74.0001 155.5 70.4607 154.118 68.0169 152.275L66.9551 154.096L67.0057 154.135C68.1293 154.921 69.163 153.096 68.0675 152.315L68.0169 152.275C66.8933 151.483 65.8877 153.298 66.9551 154.096C70.2866 156.607 75.2472 157.876 78.8147 155.051C82.5001 152.129 81.6236 147.14 78.472 144.169C77.2079 142.966 75.2641 140.871 73.472 140.494C71.6798 140.118 70.4663 142.152 69.4495 143.348C66.9776 146.258 62.9551 150.652 66.7922 153.972C67.8147 154.848 69.3147 153.371 68.2809 152.478Z"
      fill="#52606D"
    />
    <path
      d="M80.809 147.809C83.3877 145.764 85.6629 143.685 88.5393 142.011C91.1276 140.615 93.6083 139.029 95.9607 137.264L94.1236 136.781C95.2472 141.197 95.3258 147.034 90.8258 149.702C88.4438 151.129 84.2978 152.174 81.6461 151.112C80.1405 150.511 79.4888 152.944 80.9775 153.539C84.4944 154.944 90.1629 153.472 93.0506 151.174C97.4157 147.702 98.1067 141.107 96.2472 136.174C96.1911 135.992 96.0895 135.827 95.952 135.695C95.8146 135.563 95.6457 135.468 95.4614 135.419C95.2771 135.37 95.0833 135.369 94.8984 135.415C94.7135 135.462 94.5436 135.555 94.4045 135.685C91.7023 138.022 88.4101 139.562 85.4438 141.522C83.2697 142.961 80.6629 144.635 79.9214 147.27C79.9094 147.378 79.9291 147.487 79.9781 147.584C80.027 147.681 80.1031 147.762 80.1971 147.817C80.2911 147.871 80.3989 147.898 80.5075 147.892C80.6162 147.887 80.7209 147.85 80.809 147.786V147.809Z"
      fill="#52606D"
    />
    <path
      d="M74.4832 137.236C75.5787 135.23 75.7697 133.371 77.5 131.674C79.5843 129.635 82.2247 128.208 84.6686 126.646L83.3708 126.309C86.4157 131.264 85 139.388 81.927 144.051C81.1349 145.253 83.0506 146.371 83.8539 145.174C87.3371 139.876 88.9101 130.725 85.0056 125.348C84.8779 125.134 84.6711 124.977 84.4295 124.913C84.1879 124.849 83.9309 124.882 83.7135 125.006C79.2472 128.079 73.5393 130.82 73.4551 136.955C73.4627 137.072 73.5068 137.184 73.5813 137.275C73.6558 137.366 73.7569 137.431 73.8704 137.461C73.9839 137.492 74.104 137.486 74.2139 137.444C74.3239 137.403 74.418 137.328 74.4832 137.23V137.236Z"
      fill="#52606D"
    />
    <path
      d="M86.9944 133.944C88.7248 130.573 90.736 127.275 93.736 124.848L92.1574 124.433C93.2809 129.685 93.2192 134.315 90.0619 138.854C89.472 139.708 90.7753 140.68 91.4832 139.95C95.2866 136.017 96.0113 128.871 93.9776 123.933C93.9316 123.775 93.8457 123.632 93.7283 123.517C93.6108 123.402 93.4657 123.32 93.307 123.277C93.1483 123.235 92.9813 123.234 92.8222 123.275C92.6632 123.316 92.5174 123.397 92.3989 123.511C89.5899 126.523 87.1517 129.483 85.6574 133.371C85.3596 134.152 86.6293 134.68 87.0057 133.933L86.9944 133.944Z"
      fill="#52606D"
    />
    <path
      d="M52.3033 146.539C50.7929 144.409 48.5816 142.878 46.0561 142.213C42.0505 141.135 38.3707 142.298 34.3483 142.556C33.7865 142.596 33.1067 143.185 33.3764 143.837C36.4606 151.399 45.3988 155.815 52.6797 151.14C53.8033 150.433 52.7584 148.657 51.6517 149.376C45.5842 153.309 37.8988 149.747 35.3258 143.315L34.3483 144.596C37.837 144.393 41.264 143.326 44.7528 144.034C47.3988 144.596 49.1517 146.045 51.309 147.545C51.8708 147.949 52.7303 147.135 52.3033 146.551V146.539Z"
      fill="#52606D"
    />
    <path
      d="M58.2023 123.781C57.3596 119.809 59.1405 116.787 61.2753 113.669L59.9776 114.006C61.7191 115.006 63.5337 115.843 65.0337 117.225C66.2208 118.417 67.1938 119.804 67.9102 121.326C68.3765 122.18 69.5955 121.567 69.4045 120.697C68.6124 116.523 64.2978 114.242 60.9776 112.371C60.7594 112.25 60.5029 112.217 60.2613 112.28C60.0197 112.343 59.8115 112.496 59.6798 112.708C58.3596 114.68 57.0337 116.421 56.5281 118.798C56.1686 120.483 55.9663 123.023 57.2809 124.343C57.3627 124.455 57.4834 124.533 57.6195 124.562C57.7555 124.591 57.8974 124.568 58.0177 124.498C58.1379 124.428 58.2279 124.316 58.2703 124.184C58.3127 124.051 58.3045 123.908 58.2472 123.781H58.2023Z"
      fill="#52606D"
    />
    <path
      d="M50.9101 130.534C48.3764 126.747 43.5224 124.809 39.5224 122.989C39.3748 122.906 39.2083 122.863 39.0391 122.864C38.87 122.865 38.704 122.91 38.5573 122.994C38.4107 123.079 38.2885 123.2 38.2026 123.345C38.1167 123.491 38.0701 123.657 38.0674 123.826C38.2135 128.107 38.1741 132.494 39.9663 136.478C41.5694 140.12 44.3356 143.127 47.8314 145.028C48.0414 145.129 48.2818 145.148 48.5047 145.08C48.7276 145.012 48.9168 144.862 49.0344 144.661C49.152 144.46 49.1895 144.222 49.1394 143.994C49.0893 143.766 48.9553 143.566 48.764 143.433C45.4574 141.492 42.8887 138.508 41.4606 134.949C40.1067 131.421 40.1573 127.551 40.0168 123.826L38.5561 124.663C42.4382 126.41 46.8483 128.242 49.8258 131.405C50.3876 131.966 51.3651 131.23 50.9494 130.562L50.9101 130.534Z"
      fill="#52606D"
    />
    <path
      d="M74.4101 122.815C75.6011 118.938 77.1404 115.511 80.9438 113.545L79.5842 112.983C80.7078 115.629 81.9045 118.354 82.0449 121.264C82.146 123.438 81.6685 124.961 80.9213 126.955C80.4494 128.219 82.4157 128.702 82.9157 127.517C85.0674 122.371 83.3651 117.202 81.2752 112.281C81.149 112.072 80.9464 111.921 80.7104 111.859C80.4744 111.797 80.2236 111.83 80.0112 111.949C76.3033 113.983 73.0281 118.258 73.0898 122.624C73.0928 122.784 73.1532 122.939 73.2601 123.059C73.3671 123.179 73.5135 123.257 73.6729 123.279C73.8324 123.301 73.9943 123.265 74.1295 123.178C74.2647 123.09 74.3642 122.958 74.4101 122.803V122.815Z"
      fill="#52606D"
    />
    <path
      d="M63.1348 145.18C61.8034 139.32 62.191 134.354 65.0899 129.017C67.4046 124.564 70.3132 120.446 73.7359 116.775L71.9719 116.315C73.0368 121.464 73.4857 126.722 73.309 131.977C73.2079 135.348 72.2977 138.213 71.0618 141.388C70.5 142.792 72.7921 143.421 73.309 142.011C74.6067 138.64 75.4944 135.579 75.5562 131.977C75.7003 126.519 75.1796 121.063 74.0056 115.73C73.9536 115.554 73.8572 115.395 73.7257 115.267C73.5942 115.139 73.432 115.048 73.2548 115.001C73.0775 114.954 72.8912 114.954 72.7137 115C72.5362 115.046 72.3736 115.137 72.2416 115.264C64.809 123.163 58.1966 134.595 60.8595 145.781C61.2135 147.258 63.4719 146.635 63.1067 145.157L63.1348 145.18Z"
      fill="#52606D"
    />
    <path
      d="M66.9045 151.489C64.9045 145.337 58.9663 141.376 52.9326 139.798C52.6734 139.729 52.3974 139.764 52.1641 139.896C51.9308 140.029 51.7589 140.248 51.6854 140.506C50.7135 145.141 49.3708 150.618 54.0899 153.747C57.236 155.826 63.191 156.264 66.3877 154.079C66.6077 153.942 66.7716 153.73 66.8499 153.483C66.9283 153.236 66.9159 152.969 66.8151 152.73C66.7142 152.491 66.5315 152.296 66.2998 152.18C66.068 152.063 65.8024 152.034 65.5506 152.096C62.4888 152.618 59.6124 153.635 56.5337 152.41C51.7922 150.528 52.3034 145.067 53.6461 141.045L52.3933 141.753C58.1742 143.348 62.4495 146.949 65.3146 152.152C65.4219 152.33 65.5897 152.463 65.7872 152.528C65.9848 152.592 66.1989 152.583 66.3905 152.503C66.5821 152.422 66.7384 152.276 66.8307 152.089C66.923 151.903 66.9452 151.69 66.8933 151.489H66.9045Z"
      fill="#52606D"
    />
    <path
      d="M203.629 156.742C206.466 156.815 209.326 156.82 212.157 157.051C213.438 157.152 213.455 155.096 212.157 155.039C209.315 154.91 206.472 154.865 203.629 154.803C202.382 154.775 202.382 156.708 203.629 156.742Z"
      fill="#52606D"
    />
    <path
      d="M104.494 156.382C114.916 156.135 125.343 156.618 135.764 156.433C136.983 156.433 136.983 154.511 135.764 154.539C125.343 154.792 114.904 154.303 104.494 154.921C103.562 154.972 103.545 156.405 104.494 156.382Z"
      fill="#52606D"
    />
    <path
      d="M30.4045 154.551C27.5281 154.551 24.6461 154.697 21.7753 154.854L22.6011 155.68C21.6573 117.618 19.2303 79.5562 17.5449 41.5168C17.0449 30.1798 16.7472 18.8146 16.0674 7.48875C15.9944 6.27527 14.1348 6.25279 14.1629 7.48875C14.6011 26.9775 15.8483 46.4831 16.7865 65.955C17.6741 84.5206 18.5562 103.088 19.4326 121.657C19.9494 132.994 20.309 144.348 20.9663 155.68C20.9678 155.898 21.0552 156.108 21.2098 156.262C21.3644 156.417 21.5736 156.504 21.7921 156.506C24.7247 156.247 27.6685 156.146 30.5843 155.742C31.2697 155.646 31.0899 154.551 30.4213 154.551H30.4045Z"
      fill="#52606D"
    />
    <path
      d="M170.691 6.17981C170.843 26.9663 171.219 47.7079 171.517 68.4719C171.815 89.236 171.472 110.045 172.348 130.77C172.348 131.032 172.453 131.283 172.638 131.469C172.823 131.654 173.075 131.758 173.337 131.758C173.599 131.758 173.851 131.654 174.036 131.469C174.222 131.283 174.326 131.032 174.326 130.77C174.685 110.039 173.708 89.1967 173.332 68.4719C172.955 47.7472 172.646 26.9663 172.197 6.17981C172.197 5.98015 172.117 5.78867 171.976 5.64749C171.835 5.50631 171.644 5.427 171.444 5.427C171.244 5.427 171.053 5.50631 170.912 5.64749C170.77 5.78867 170.691 5.98015 170.691 6.17981Z"
      fill="#52606D"
    />
    <path
      d="M179.118 0.943792C178.315 18.073 178.966 35.4045 179.236 52.5505C179.494 69.0299 179.987 85.5093 180.714 101.989C181.144 112.079 181.637 122.163 182.191 132.242C182.258 133.461 184.169 133.472 184.101 132.242C182.258 98.6011 181.056 64.9382 180.702 31.2472C180.596 21.1685 180.865 11.0224 180.433 0.966264C180.433 0.791937 180.363 0.624748 180.24 0.50148C180.117 0.378212 179.95 0.30896 179.775 0.30896C179.601 0.30896 179.434 0.378212 179.311 0.50148C179.187 0.624748 179.118 0.791937 179.118 0.966264V0.943792Z"
      fill="#52606D"
    />
    <path
      d="M0.8315 166.382C5.62925 166.584 10.4495 166.073 15.2416 165.916C20.0337 165.758 24.8989 166.095 29.6686 165.753C29.8592 165.736 30.0367 165.649 30.166 165.508C30.2954 165.367 30.3672 165.183 30.3672 164.992C30.3672 164.8 30.2954 164.616 30.166 164.475C30.0367 164.334 29.8592 164.247 29.6686 164.23C24.809 163.668 19.6798 164.006 14.7978 164.23C10.1068 164.449 5.34274 164.663 0.724759 165.545C0.235995 165.635 0.376443 166.348 0.8315 166.371V166.382Z"
      fill="#52606D"
    />
    <path
      d="M202.107 166.596C205.315 166.556 208.652 166.826 211.843 166.489C212.078 166.489 212.304 166.395 212.47 166.229C212.637 166.062 212.73 165.837 212.73 165.601C212.73 165.366 212.637 165.14 212.47 164.973C212.304 164.807 212.078 164.714 211.843 164.714C208.663 164.376 205.298 164.669 202.107 164.747C200.921 164.775 200.916 166.607 202.107 166.596Z"
      fill="#52606D"
    />
    <path
      d="M103.652 167.899C108.674 168.202 113.82 167.713 118.82 167.551C123.82 167.388 128.933 167.225 133.989 167.124C135.062 167.124 135.067 165.438 133.989 165.438C128.933 165.562 123.876 165.753 118.82 165.944C113.764 166.135 108.629 166.045 103.652 166.652C103.499 166.666 103.357 166.737 103.253 166.851C103.15 166.965 103.093 167.113 103.093 167.267C103.093 167.421 103.15 167.569 103.253 167.683C103.357 167.796 103.499 167.867 103.652 167.882V167.899Z"
      fill="#52606D"
    />
    <path
      d="M159.843 155.781C160.028 153.112 159.843 150.449 160.124 147.781C160.471 144.842 161.12 141.946 162.062 139.14L161.011 139.281C162.652 141.449 162.073 144.758 161.792 147.303C161.405 150.089 160.865 152.852 160.174 155.579C160.155 155.713 160.186 155.85 160.261 155.964C160.336 156.078 160.449 156.16 160.58 156.197C160.711 156.233 160.851 156.221 160.974 156.162C161.097 156.103 161.194 156.001 161.247 155.876C162.191 152.778 162.797 149.588 163.056 146.36C163.275 143.792 163.646 140.876 162 138.702C161.948 138.599 161.864 138.514 161.762 138.459C161.659 138.405 161.542 138.384 161.427 138.398C161.312 138.413 161.204 138.463 161.118 138.542C161.033 138.62 160.974 138.724 160.949 138.837C159.236 144.039 157.904 150.433 159.185 155.871C159.21 155.946 159.26 156.01 159.327 156.052C159.394 156.094 159.473 156.111 159.552 156.1C159.63 156.09 159.702 156.052 159.755 155.993C159.808 155.935 159.839 155.86 159.843 155.781Z"
      fill="#52606D"
    />
    <path
      d="M159.303 154.174C158.101 149.247 155.831 144.685 153.124 140.416C153.058 140.318 152.964 140.243 152.854 140.202C152.744 140.16 152.624 140.154 152.511 140.185C152.397 140.215 152.296 140.28 152.222 140.371C152.147 140.462 152.103 140.574 152.095 140.691C151.607 146.067 154.607 150.562 157.865 154.539C158.253 155.011 159.084 154.382 158.719 153.876C155.803 149.86 152.742 145.972 153.208 140.691L152.18 140.972C154.871 145.202 156.82 149.697 158.64 154.36C158.665 154.447 158.724 154.522 158.803 154.567C158.883 154.612 158.977 154.623 159.065 154.598C159.152 154.574 159.227 154.515 159.272 154.436C159.317 154.356 159.328 154.262 159.303 154.174Z"
      fill="#52606D"
    />
    <path
      d="M161.275 155.18C162.801 150.404 164.961 145.854 167.697 141.652L166.742 141.393C166.831 146.424 165.049 151.309 161.742 155.101C161.309 155.624 162.028 156.376 162.5 155.86C166.23 151.73 167.742 146.871 167.764 141.393C167.763 141.282 167.726 141.174 167.658 141.086C167.59 140.998 167.495 140.934 167.387 140.905C167.28 140.876 167.166 140.882 167.062 140.924C166.959 140.965 166.872 141.039 166.815 141.135C164.034 145.41 161.483 149.921 160.747 155.034C160.736 155.101 160.749 155.17 160.786 155.228C160.822 155.286 160.879 155.328 160.945 155.346C161.011 155.364 161.081 155.357 161.142 155.327C161.203 155.296 161.251 155.244 161.275 155.18Z"
      fill="#52606D"
    />
    <path
      d="M159.214 154.989C157.888 153.674 156.506 152.41 155.18 151.084C153.854 149.758 152.073 148.32 151.135 146.59C151.094 146.522 151.035 146.468 150.964 146.433C150.893 146.399 150.814 146.386 150.736 146.396C150.657 146.406 150.584 146.439 150.524 146.49C150.464 146.542 150.421 146.61 150.399 146.685C149.618 151.18 152.949 156.028 157.702 156.236C157.811 156.236 157.916 156.197 157.998 156.126C158.08 156.055 158.134 155.957 158.149 155.849C158.164 155.741 158.14 155.632 158.08 155.541C158.021 155.45 157.93 155.383 157.826 155.354C153.601 154.404 150.848 151.275 151.208 146.82L150.517 147.107C151.876 148.669 153.023 150.326 154.517 151.781C155.873 153.121 157.31 154.376 158.82 155.539C159.135 155.775 159.5 155.27 159.23 155.006L159.214 154.989Z"
      fill="#52606D"
    />
    <path
      d="M156.612 147.039C155.949 144.27 156.18 141.258 156.612 138.472H155.697C157.103 141.804 158.204 145.256 158.989 148.787C159.09 149.275 159.916 149.174 159.871 148.669C159.556 145.006 158.022 141.449 156.573 138.107C156.371 137.635 155.713 137.921 155.691 138.343C155.517 141.298 155.236 144.32 155.978 147.219C156.008 147.298 156.067 147.362 156.143 147.4C156.218 147.438 156.305 147.447 156.387 147.424C156.468 147.402 156.539 147.35 156.585 147.279C156.63 147.208 156.648 147.123 156.635 147.039H156.612Z"
      fill="#52606D"
    />
    <path
      d="M153.404 158.017C153.123 157.062 152.894 156.092 152.719 155.112L151.983 155.848C157.014 157.138 162.333 156.67 167.062 154.522L166.101 153.961C166.146 155.449 166.146 156.944 166.101 158.455C166.073 159.618 166.028 160.781 165.916 161.944C165.747 163.629 165.949 163.747 164.23 164.191C163.584 164.371 163.669 165.5 164.399 165.41C166.927 165.09 167.011 163.854 167.208 161.59C167.402 159.052 167.447 156.504 167.343 153.961C167.342 153.848 167.312 153.738 167.256 153.641C167.2 153.543 167.12 153.462 167.023 153.406C166.926 153.349 166.816 153.318 166.703 153.317C166.591 153.316 166.48 153.344 166.382 153.399C161.947 155.374 156.982 155.823 152.264 154.674C152.162 154.649 152.056 154.65 151.955 154.678C151.854 154.706 151.762 154.76 151.688 154.834C151.613 154.908 151.56 155 151.532 155.101C151.504 155.202 151.503 155.308 151.528 155.41C151.702 156.391 151.977 157.351 152.348 158.275C152.595 158.888 153.556 158.669 153.371 157.994L153.404 158.017Z"
      fill="#52606D"
    />
    <path
      d="M154.545 160.82C153.984 159.877 153.099 159.168 152.056 158.826C151.582 158.693 151.076 158.733 150.628 158.939C150.18 159.145 149.821 159.503 149.612 159.949C149.462 160.406 149.453 160.897 149.588 161.358C149.722 161.82 149.993 162.23 150.365 162.534C151.326 163.388 152.938 163.483 154.14 163.208C154.77 163.067 154.601 162.146 153.994 162.124C153.23 162.09 152.483 162.253 151.747 162.022C151.118 161.82 150.455 161.197 150.584 160.472C150.714 159.747 151.652 159.629 152.242 159.882C152.888 160.19 153.446 160.655 153.865 161.236C154.169 161.64 154.86 161.27 154.562 160.831L154.545 160.82Z"
      fill="#52606D"
    />
    <path
      d="M154.848 161.152C154.781 160.608 154.792 160.057 154.882 159.517C155.028 158.955 156.5 157.534 156.421 159.27C156.388 160 155.354 160.865 155.056 161.517C154.837 161.994 155.466 162.64 155.854 162.129C156.646 161.124 158.365 161.646 157.961 163.051C157.556 164.455 156.163 163.972 155.461 163.18C154.944 162.618 154 163.348 154.399 164C155.663 166.096 159.084 165.472 159.219 162.904C159.241 162.409 159.101 161.919 158.82 161.51C158.539 161.101 158.133 160.795 157.662 160.638C157.191 160.481 156.682 160.482 156.212 160.641C155.742 160.799 155.336 161.107 155.056 161.517L155.933 162.028C156.405 160.949 157.618 160.292 157.719 159.022C157.751 158.612 157.634 158.204 157.389 157.873C157.145 157.542 156.789 157.31 156.388 157.219C154.011 156.697 153.663 159.663 153.972 161.247C154.004 161.347 154.071 161.433 154.16 161.489C154.249 161.545 154.355 161.568 154.459 161.554C154.564 161.54 154.66 161.49 154.731 161.412C154.802 161.334 154.843 161.234 154.848 161.129V161.152Z"
      fill="#52606D"
    />
    <path
      d="M150.298 162.584C147.517 162.534 145.388 164.865 144.921 167.489C144.36 170.719 146.045 173.719 148.522 175.663C149.084 176.107 149.916 175.337 149.337 174.848C145.36 171.421 144.376 165.157 150.405 163.343C150.493 163.316 150.569 163.258 150.618 163.18C150.668 163.102 150.688 163.009 150.675 162.918C150.663 162.826 150.617 162.743 150.548 162.681C150.479 162.62 150.39 162.586 150.298 162.584Z"
      fill="#52606D"
    />
    <path
      d="M159.067 163.219C164.202 165.236 162.904 171.747 160.567 175.416C160.174 176.034 161.129 176.803 161.601 176.214C162.573 175.003 163.282 173.602 163.681 172.102C164.081 170.601 164.162 169.034 163.921 167.5C163.522 165 162.095 162.135 159.202 162.23C159.082 162.234 158.968 162.279 158.878 162.359C158.789 162.439 158.731 162.549 158.715 162.667C158.699 162.786 158.725 162.907 158.79 163.008C158.854 163.109 158.953 163.184 159.067 163.219Z"
      fill="#52606D"
    />
    <path
      d="M150.775 164.045C149.747 165.636 149.281 167.526 149.452 169.413C149.623 171.3 150.421 173.075 151.719 174.455C152.107 174.882 152.691 174.264 152.343 173.831C150.039 170.955 149.876 167.719 151.449 164.438C151.475 164.394 151.492 164.345 151.499 164.294C151.506 164.243 151.503 164.192 151.49 164.142C151.477 164.093 151.454 164.046 151.423 164.005C151.392 163.965 151.353 163.93 151.309 163.904C151.265 163.879 151.216 163.862 151.165 163.855C151.114 163.848 151.063 163.851 151.013 163.864C150.964 163.877 150.917 163.9 150.876 163.931C150.835 163.962 150.801 164.001 150.775 164.045Z"
      fill="#52606D"
    />
    <path
      d="M154.163 165.933C154.539 168.921 154.893 171.916 155.365 174.893C155.478 175.573 156.489 175.281 156.399 174.607C155.955 171.674 155.388 168.758 154.848 165.837C154.775 165.461 154.112 165.539 154.163 165.933Z"
      fill="#52606D"
    />
    <path
      d="M157.747 165.837C159.23 169.152 159.225 172.051 157.5 175.281C157.464 175.4 157.469 175.528 157.514 175.645C157.559 175.761 157.641 175.86 157.748 175.924C157.855 175.988 157.98 176.015 158.104 176.001C158.228 175.986 158.344 175.93 158.433 175.843C160.579 172.944 160.713 168.309 158.55 165.382C158.485 165.281 158.383 165.209 158.267 165.182C158.15 165.154 158.026 165.172 157.923 165.233C157.819 165.294 157.743 165.393 157.71 165.508C157.678 165.624 157.691 165.748 157.747 165.854V165.837Z"
      fill="#52606D"
    />
    <path
      d="M147.663 164.927C147.078 164.378 146.401 163.937 145.663 163.624C145.53 163.592 145.391 163.603 145.265 163.657C145.14 163.711 145.035 163.803 144.966 163.921C144.897 164.039 144.868 164.176 144.884 164.312C144.899 164.447 144.957 164.574 145.051 164.674C145.684 165.169 146.399 165.55 147.163 165.798C147.272 165.823 147.386 165.812 147.489 165.768C147.591 165.723 147.677 165.647 147.733 165.55C147.789 165.453 147.812 165.341 147.8 165.23C147.787 165.119 147.739 165.014 147.663 164.933V164.927Z"
      fill="#52606D"
    />
    <path
      d="M146.528 169.59C145.628 169.871 144.861 170.47 144.371 171.275C144.291 171.394 144.259 171.538 144.281 171.679C144.304 171.821 144.379 171.948 144.492 172.035C144.606 172.123 144.748 172.164 144.89 172.15C145.033 172.136 145.164 172.068 145.258 171.961C145.747 171.407 146.304 170.918 146.916 170.506C147.399 170.236 147.073 169.382 146.528 169.59Z"
      fill="#52606D"
    />
    <path
      d="M161.916 169.433C162.393 169.28 162.862 169.102 163.32 168.899C163.81 168.726 164.279 168.5 164.719 168.225C164.837 168.148 164.924 168.032 164.966 167.897C165.007 167.762 164.999 167.617 164.944 167.487C164.89 167.357 164.791 167.251 164.665 167.187C164.54 167.122 164.396 167.104 164.258 167.135C163.777 167.271 163.311 167.459 162.871 167.697C162.401 167.912 161.943 168.154 161.5 168.421C161.408 168.499 161.344 168.603 161.316 168.719C161.288 168.836 161.298 168.958 161.344 169.069C161.391 169.179 161.471 169.272 161.574 169.333C161.677 169.395 161.796 169.422 161.916 169.41V169.433Z"
      fill="#52606D"
    />
    <path
      d="M162.73 184.983C162.848 182.034 162.815 179.084 162.792 176.129C162.79 176.045 162.768 175.963 162.73 175.889C162.691 175.814 162.637 175.749 162.57 175.699C162.503 175.648 162.426 175.613 162.344 175.596C162.262 175.579 162.177 175.581 162.095 175.601C157.4 176.697 152.5 176.544 147.882 175.157C147.79 175.133 147.693 175.131 147.6 175.15C147.506 175.169 147.418 175.209 147.343 175.267C147.267 175.325 147.205 175.399 147.162 175.484C147.12 175.569 147.097 175.663 147.095 175.758C147.429 178.86 147.764 181.959 148.101 185.056C148.106 185.197 148.155 185.332 148.241 185.444C148.326 185.556 148.444 185.638 148.579 185.68C152.921 187.185 158.129 188.219 162.36 185.809C162.486 185.711 162.573 185.57 162.604 185.413C162.634 185.255 162.606 185.092 162.524 184.954C162.443 184.816 162.314 184.713 162.161 184.663C162.009 184.614 161.844 184.622 161.697 184.685C158.618 186.343 155.051 186.09 151.747 185.292C151.135 185.14 149.5 184.921 149.371 184.798C149.242 184.674 149.225 183.522 149.202 183.287C148.918 180.781 148.629 178.275 148.337 175.77L147.551 176.371C152.38 177.796 157.504 177.901 162.388 176.674L161.691 176.14C161.77 179.096 161.82 182.045 161.921 184.994C161.921 185.102 161.964 185.205 162.04 185.28C162.116 185.356 162.219 185.399 162.326 185.399C162.433 185.399 162.536 185.356 162.612 185.28C162.688 185.205 162.73 185.102 162.73 184.994V184.983Z"
      fill="#52606D"
    />
    <path
      d="M177.337 163.056C181.028 163.837 183.921 160.994 186.461 158.781C187.635 157.758 189.893 156.618 188.815 159.449C188.371 160.601 187.18 161.545 186.146 162.129C183.382 163.708 180.764 163.281 177.944 162.27C177.343 162.056 177.079 162.916 177.534 163.242C179.826 164.871 182.916 164.713 185.433 163.702C187.438 162.893 190.539 160.68 190.337 158.14C190.202 156.455 188.449 155.955 187.112 156.691C185.775 157.427 184.691 158.989 183.421 159.955C181.539 161.393 179.646 161.921 177.337 162.258C176.927 162.315 176.955 162.977 177.337 163.056Z"
      fill="#52606D"
    />
    <path
      d="M177.225 161.798C175.904 160.781 174.753 159.916 173.854 158.455C173.32 157.579 172.933 156.556 172.101 155.899C171.785 155.662 171.423 155.493 171.038 155.403C170.653 155.314 170.254 155.306 169.865 155.38C169.477 155.454 169.109 155.609 168.784 155.833C168.458 156.058 168.184 156.348 167.977 156.685C167.022 158.275 167.775 160.157 169.146 161.18C171.23 162.764 174.421 163.326 177.011 163.214C177.16 163.214 177.303 163.154 177.408 163.049C177.514 162.944 177.573 162.801 177.573 162.652C177.573 162.503 177.514 162.36 177.408 162.254C177.303 162.149 177.16 162.09 177.011 162.09C174.871 161.978 172.635 161.753 170.702 160.736C169.837 160.281 168.989 159.612 168.82 158.612C168.629 157.433 169.534 156.214 170.831 156.545C172.129 156.876 172.753 158.978 173.545 160.011C174.394 161.191 175.564 162.102 176.916 162.635C177.478 162.832 177.663 162.073 177.281 161.775L177.225 161.798Z"
      fill="#52606D"
    />
    <path
      d="M177.775 162.258C177.466 160.933 176.972 159.646 177.073 158.258C177.174 156.871 178.101 154.809 179.82 154.983C182.191 155.225 180.028 157.758 179.433 158.607C178.646 159.73 177.815 160.854 177.096 162.006C176.685 162.674 177.657 163.185 178.118 162.601C179.011 161.433 179.803 160.174 180.618 158.961C181.18 158.141 181.972 157.242 182.152 156.242C182.393 154.854 181.343 153.893 180.028 153.854C175.421 153.702 175.73 159.775 177.039 162.567C177.236 163 177.871 162.685 177.775 162.258Z"
      fill="#52606D"
    />
    <path
      d="M176.865 156.966C175.955 155.056 175.91 153.174 175.253 151.236C174.961 150.388 174.601 149.618 173.708 149.287C172.129 148.691 170.815 149.916 170.427 151.36C169.815 153.652 171.191 155.629 172.5 157.382C173.685 158.966 174.994 161.124 176.691 162.185C177.067 162.421 177.629 161.921 177.331 161.539C175.68 159.376 173.663 157.427 172.275 155.056C171.68 154.034 171.213 152.871 171.545 151.685C172.073 149.764 173.697 150.298 174.219 151.832C174.893 153.792 174.865 155.652 176.028 157.45C176.095 157.553 176.198 157.626 176.317 157.655C176.437 157.684 176.562 157.666 176.669 157.604C176.775 157.543 176.853 157.443 176.888 157.325C176.922 157.208 176.91 157.081 176.854 156.972L176.865 156.966Z"
      fill="#52606D"
    />
    <path
      d="M181.888 156.888C182.445 156.228 182.867 155.464 183.129 154.64C183.315 153.895 183.577 153.17 183.91 152.477C184.702 151.14 186.427 151.354 186.629 153.23C186.904 155.73 184.011 159.23 182.309 160.747C181.899 161.107 182.421 161.719 182.871 161.477C184.747 160.477 185.775 158.18 186.657 156.343C187.32 154.949 188.146 152.938 187.101 151.517C186.376 150.534 185.202 150.079 184.152 150.742C182.365 151.865 182.286 154.781 181.309 156.461C181.107 156.809 181.612 157.236 181.871 156.904L181.888 156.888Z"
      fill="#52606D"
    />
    <path
      d="M177.938 154.573C177.385 153.582 177.068 152.476 177.011 151.343C177.011 150.219 177.517 148.253 179 148.298C180.197 148.337 180.433 150.545 180.511 151.444C180.577 152.484 180.496 153.528 180.27 154.545C180.242 154.674 180.265 154.808 180.333 154.921C180.402 155.033 180.511 155.115 180.637 155.15C180.764 155.185 180.9 155.171 181.016 155.109C181.133 155.048 181.221 154.945 181.264 154.82C181.826 152.803 182.051 146.64 178.539 147.275C175.028 147.91 175.764 152.921 177.124 155.045C177.191 155.144 177.293 155.213 177.409 155.24C177.525 155.267 177.647 155.248 177.751 155.188C177.854 155.128 177.931 155.032 177.965 154.918C178 154.803 177.991 154.68 177.938 154.573Z"
      fill="#52606D"
    />
    <path
      d="M184.388 164.478C185.518 167.872 185.976 171.453 185.736 175.023C185.708 175.584 186.657 175.747 186.764 175.163C187.45 171.414 186.912 167.544 185.23 164.124C185.174 164.027 185.085 163.953 184.978 163.917C184.872 163.882 184.757 163.886 184.653 163.929C184.55 163.973 184.466 164.052 184.417 164.153C184.368 164.254 184.358 164.37 184.388 164.478Z"
      fill="#52606D"
    />
    <path
      d="M184.624 163.82C181.9 163.916 179.175 163.701 176.5 163.18C174.629 162.831 172.174 162.517 170.618 161.332C170.533 161.282 170.436 161.256 170.337 161.256C170.238 161.256 170.142 161.282 170.056 161.332C167.876 162.596 166.652 165.697 165.697 167.888C164.803 169.938 164.303 171.258 166.107 172.978C171.163 177.86 180.404 179.056 186.483 175.657C187.146 175.287 186.562 174.343 185.921 174.657C183.579 175.745 181.027 176.309 178.444 176.309C175.861 176.309 173.309 175.745 170.966 174.657C168.994 173.764 165.725 172.174 166.219 169.691C166.506 168.258 167.511 166.652 168.219 165.382C168.781 164.41 169.584 162.618 170.573 162.573C171.562 162.528 173.208 163.523 174.197 163.747C177.567 164.528 181.202 165.084 184.64 164.685C185.202 164.618 185.23 163.758 184.64 163.787L184.624 163.82Z"
      fill="#52606D"
    />
    <path
      d="M192.095 197.753C197.573 177.624 199.792 156.539 203.09 135.955C203.144 135.75 203.115 135.531 203.01 135.346C202.905 135.162 202.732 135.025 202.528 134.966C182.916 131.809 163.202 128.433 143.399 126.669C143.221 126.668 143.048 126.725 142.907 126.833C142.765 126.941 142.664 127.092 142.618 127.264C139.03 147.8 135.442 168.337 131.854 188.876C131.798 189.084 131.826 189.304 131.931 189.491C132.036 189.678 132.21 189.816 132.416 189.876L183.91 197.287C186.27 197.629 189.09 197.966 191.213 198.275C192.163 198.41 192.573 196.961 191.618 196.82C174.455 194.354 157.298 191.826 140.135 189.36L132.831 188.309L133.393 189.309C136.966 168.77 140.551 148.23 144.146 127.691L143.365 128.287C163.028 130.034 182.595 133.388 202.062 136.523L201.5 135.528C198.185 156.219 195.174 176.876 190.736 197.365C190.71 197.455 190.703 197.548 190.713 197.64C190.724 197.732 190.753 197.822 190.798 197.903C190.843 197.984 190.904 198.055 190.976 198.113C191.049 198.171 191.133 198.213 191.222 198.239C191.311 198.264 191.405 198.272 191.497 198.261C191.589 198.251 191.678 198.222 191.759 198.177C191.84 198.132 191.912 198.071 191.97 197.998C192.027 197.926 192.07 197.842 192.095 197.753Z"
      fill="#52606D"
    />
    <path
      d="M148.758 135.567C164.114 137.399 179.391 139.807 194.59 142.792L193.933 141.635L185.477 184.629L184.27 190.719L185.449 190.051C170.067 187.916 154.758 185.275 139.382 183.152L140.067 184.348L148.494 141.309C148.871 139.365 149.354 136.629 149.657 135.073C149.876 133.949 148.118 133.438 147.893 134.59C145.084 148.933 142.23 163.27 139.421 177.618L138.202 183.831C138.136 184.081 138.17 184.346 138.297 184.571C138.424 184.796 138.634 184.962 138.882 185.034C154.264 187.163 169.579 189.775 184.949 191.899C185.194 191.962 185.454 191.927 185.674 191.802C185.894 191.678 186.057 191.473 186.129 191.23L194.556 148.225L195.747 142.135C195.81 141.894 195.776 141.639 195.653 141.423C195.531 141.207 195.328 141.047 195.09 140.977C179.764 137.977 164.303 135.584 148.758 134.101C147.815 134.011 147.837 135.455 148.758 135.567Z"
      fill="#52606D"
    />
    <path
      d="M197.225 165.202C196.612 170.781 197.326 176.736 197.461 182.343C197.534 185.629 197.668 188.916 197.792 192.202L199.197 191.135C197.249 190.731 195.273 190.483 193.286 190.393C193.038 190.404 192.802 190.501 192.619 190.669C192.436 190.836 192.317 191.063 192.284 191.309C192.251 191.555 192.306 191.805 192.439 192.014C192.572 192.224 192.775 192.38 193.011 192.455C194.809 192.916 196.77 192.983 198.629 193.275C198.794 193.318 198.966 193.322 199.133 193.289C199.3 193.255 199.457 193.184 199.592 193.08C199.728 192.977 199.838 192.844 199.914 192.692C199.99 192.54 200.031 192.372 200.034 192.202C199.904 188.798 199.792 185.393 199.629 181.989C199.359 176.416 199.629 170.511 198.708 165.006C198.556 164.112 197.331 164.444 197.247 165.202H197.225Z"
      fill="#52606D"
    />
  </svg>
);

export default LinePlantMorning;
