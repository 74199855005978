import React from 'react';
import Spacer from '../../helpers/Spacer';
import Label from './Label';
import Hint from './Hint';
import Errors from './Errors';

const FieldTrim = ({ children, name, label, hint, meta }) => (
  <Spacer margin={10}>
    {label && <Label htmlFor={name}>{label}</Label>}
    {children}
    <Hint>{hint}</Hint>
    {meta.touched && meta.error && <Errors errors={meta.error} />}
  </Spacer>
);

export default FieldTrim;
