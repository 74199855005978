import isAfter from 'date-fns/isAfter';
import addHours from 'date-fns/addHours';
import { LOGOUT } from '../auth';

export const GAIN_PAGE_FOCUS = 'stale/GAIN_PAGE_FOCUS';
export const LOSE_PAGE_FOCUS = 'stale/LOSE_PAGE_FOCUS';

// In Hours
const MAX_IDLE = 3;

const initialState = {
  lastPageFocus: null,
  isStale: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GAIN_PAGE_FOCUS: {
      const { lastPageFocus } = state;

      if (!lastPageFocus) {
        return state;
      }
      const maxIdleDate = addHours(new Date(lastPageFocus), MAX_IDLE);
      return {
        ...state,
        isStale: isAfter(new Date(), maxIdleDate),
      };
    }
    case LOSE_PAGE_FOCUS:
      return {
        ...state,
        lastPageFocus: new Date().toString(),
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function gainPageFocus() {
  return { type: GAIN_PAGE_FOCUS };
}

export function losePageFocus() {
  return { type: LOSE_PAGE_FOCUS };
}

export const getIsStale = (state) => state.stale.isStale;
