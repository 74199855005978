import React from 'react';
import styled from 'styled-components';
import CenterRow from '../../../components/helpers/CenterRow';
import LinePlant from '../../../components/svg/illustrations/LinePlant';
import { GREY_500 } from '../../../theme';

const PaddingBox = styled.div`
  padding: 20px 0 20px 0;
  margin: 0 auto;
`;

const Text = styled.div`
  font-size: 16px;
  text-align: center;
  margin: 0 auto;
  color: ${GREY_500};
  font-style: italic;
  margin-top: 7px;
`;

const EndMarker = ({ children }) => (
  <PaddingBox>
    <CenterRow>
      <LinePlant width={48} height={59} />
    </CenterRow>
    <Text>{children}</Text>
  </PaddingBox>
);

export default EndMarker;
