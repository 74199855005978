import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import Divider from '../../../components/Divider';
import FlexScrollbar from '../../../components/FlexScrollbar';
import NameField from '../common/NameField';
import Spacer from '../../../components/helpers/Spacer';
import ReminderFrequencyField from '../common/ReminderFrequencyField';
import TabLayoutContainer from '../../../components/tabLayout/TabLayoutContainer';
import TabLayoutPage from '../../../components/tabLayout/TabLayoutPage';
import TabNavigation from '../../../components/tabLayout/TabNavigation';
import TabSubNavigation from '../../../components/tabLayout/TabSubNavigation';
import { getFriendStatus, createFriend } from '../../../reducers/friend';
import formValidator from '../common/formValidator';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const AddFriendPage = () => {
  const { isFetching } = useSelector(getFriendStatus);
  const [navHeight, navRef] = useRefHeight();
  const [subNavHeight, subNavRef] = useRefHeight();
  const [footerHeight, footerRef] = useRefHeight();
  const dispatch = useDispatch();
  const history = useHistory();

  const submit = ({ name, reminder_frequency }) => {
    toast.dismiss();
    return dispatch(createFriend(name, parseInt(reminder_frequency, 10)))
      .then(() => {
        history.push('/planner');
        toast.success('Friend succesfully added to your planner.', {
          autoClose: 4000,
        });
      })
      .catch((err) => {
        toast.error(err.error);
      });
  };

  return (
    <React.Fragment>
      <Helmet title="Add Friend" />
      <TabLayoutContainer
        navigation={<TabNavigation ref={navRef} active="planner" hasSubNav />}
      >
        <TabSubNavigation ref={subNavRef} backButtonTo="/planner">
          Add Friend
        </TabSubNavigation>
        <Form
          initialValues={{
            reminder_frequency: '7',
          }}
          onSubmit={submit}
          validate={formValidator}
          render={({ handleSubmit, invalid }) => (
            <TabLayoutPage as="form" onSubmit={handleSubmit}>
              <FlexScrollbar
                refHeights={[navHeight, subNavHeight, footerHeight]}
              >
                <TabLayoutPage.Body padding={15}>
                  <Spacer margin={15} />
                  <NameField />
                  <ReminderFrequencyField />
                </TabLayoutPage.Body>
              </FlexScrollbar>

              <TabLayoutPage.Footer ref={footerRef} padding={15}>
                <Divider margin={15} />
                <Button
                  type="submit"
                  variant="primary"
                  size="block"
                  disabled={isFetching || invalid}
                >
                  Add Friend to Planner
                </Button>
              </TabLayoutPage.Footer>
            </TabLayoutPage>
          )}
        />
      </TabLayoutContainer>
    </React.Fragment>
  );
};

export default AddFriendPage;
