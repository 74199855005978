import React from 'react';
import { TEAL_700 } from '../../../theme';

const Pip = ({ width = 33, height = 12, color = TEAL_700 }) => (
  <svg
    width="34"
    height="12"
    viewBox="0 0 34 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={width} height={height} rx="5" fill={color} />
  </svg>
);

export default Pip;
