import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import FlexScrollbar from '../../../components/FlexScrollbar';
import Divider from '../../../components/Divider';
import Spacer from '../../../components/helpers/Spacer';
import TextField from '../../../components/fields/TextField';
import TabLayoutContainer from '../../../components/tabLayout/TabLayoutContainer';
import TabLayoutPage from '../../../components/tabLayout/TabLayoutPage';
import TabNavigation from '../../../components/tabLayout/TabNavigation';
import TabSubNavigation from '../../../components/tabLayout/TabSubNavigation';
import fetchJSON from '../../../utils/core/fetchJSON';
import validatePassword from '../../../utils/validatePassword';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const ChangePasswordPage = () => {
  const [isFetching, setFetchState] = useState(false);
  const [navHeight, navRef] = useRefHeight();
  const [subNavHeight, subNavRef] = useRefHeight();
  const dispatch = useDispatch();
  const history = useHistory();

  const submit = ({ current_password, new_password, new_password_confirm }) => {
    toast.dismiss();
    setFetchState(true);
    return dispatch(
      fetchJSON('/api/v1/person/me/password', {
        method: 'PATCH',
        body: {
          current_password,
          new_password,
          new_password_confirm,
        },
      })
    )
      .then(() => {
        history.push('/options');
        toast.success('Password successfully updated.', { autoClose: 4000 });
      })
      .catch((err) => {
        toast.error(err.error);
        setFetchState(false);
      });
  };

  return (
    <React.Fragment>
      <Helmet title="Change Password" />
      <TabLayoutContainer
        navigation={<TabNavigation ref={navRef} active="options" hasSubNav />}
      >
        <TabSubNavigation ref={subNavRef} backButtonTo="/options">
          Change Password
        </TabSubNavigation>
        <Form
          onSubmit={submit}
          validate={(values) => {
            const errors = {};

            if (!values.new_password) {
              errors.new_password = ['New password is required.'];
            }
            const passwordErrors = validatePassword(values.new_password);
            if (passwordErrors.length) {
              errors.password = passwordErrors;
            }

            if (!values.new_password_confirm) {
              errors.new_password_confirm = [
                'Confirming the new password is required.',
              ];
            } else if (values.new_password !== values.new_password_confirm) {
              errors.new_password_confirm = 'Must match the above password.';
            }

            if (!values.current_password) {
              errors.current_password = 'Current password is required.';
            }

            return errors;
          }}
          render={({ handleSubmit, invalid }) => (
            <TabLayoutPage as="form" onSubmit={handleSubmit}>
              <FlexScrollbar refHeights={[navHeight, subNavHeight]}>
                <TabLayoutPage.Header padding={15} />
                <TabLayoutPage.Body padding={15}>
                  <TextField
                    name="new_password"
                    type="password"
                    placeholder="********"
                    autoComplete="new-password"
                    hint={
                      'The password must be at least 8 characters long with at least one capital, number and special character.'
                    }
                  >
                    <strong>New Password</strong>
                  </TextField>

                  <TextField
                    name="new_password_confirm"
                    type="password"
                    placeholder="********"
                    autoComplete="new-password"
                  >
                    <strong>Confirm New Password</strong>
                  </TextField>
                </TabLayoutPage.Body>
                <TabLayoutPage.Footer padding={15}>
                  <Divider />
                  <TextField
                    name="current_password"
                    type="password"
                    placeholder="********"
                    hint="Your current password is required to make this change."
                  >
                    <strong>Current Password</strong>
                  </TextField>
                  <Spacer />
                  <Button
                    type="submit"
                    variant="primary"
                    size="block"
                    disabled={isFetching || invalid}
                  >
                    Save Changes
                  </Button>
                </TabLayoutPage.Footer>
              </FlexScrollbar>
            </TabLayoutPage>
          )}
        />
      </TabLayoutContainer>
    </React.Fragment>
  );
};

export default ChangePasswordPage;
