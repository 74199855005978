import React from 'react';
import TextField from '../../../components/fields/TextField';

const NameField = () => (
  <TextField
    name="name"
    type="text"
    placeholder="Friendly Plant"
    autoComplete="new-password"
    hint="The name must be less than 50 characters with no special characters."
  >
    <strong>Name</strong>
  </TextField>
);

export default NameField;
