import React from 'react';
import styled from 'styled-components';
import isstring from 'lodash.isstring';
import { RED_700 } from '../../../theme';

const ErrorWrapper = styled.div`
  font-size: 12px;
  line-height: 12px;
  color ${RED_700};
`;

const Errors = ({ errors }) => (
  <React.Fragment>
    {(isstring(errors) ? [errors] : errors).map((error) => (
      <ErrorWrapper key={error}>• {error}</ErrorWrapper>
    ))}
  </React.Fragment>
);

export default Errors;
