import { LOGOUT } from '../auth';

export const SET_DRAFT_TEXT = 'draft/SET_DRAFT_TEXT';
export const SET_EDIT_TEXT = 'draft/SET_EDIT_TEXT';

const initialState = {
  editEntryId: null,
  editText: '',
  editFriendIds: [],
  draftText: '',
  draftFriendIds: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DRAFT_TEXT:
      return {
        ...state,
        draftText: action.payload.text,
        draftFriendIds: action.payload.friendIds,
      };
    case SET_EDIT_TEXT:
      return {
        ...state,
        editEntryId: action.payload.id,
        editText: action.payload.text,
        editFriendIds: action.payload.friendIds,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function setEditText(id, text, friendIds = []) {
  return { type: SET_EDIT_TEXT, payload: { id, text, friendIds } };
}

export function setDraftText(text, friendIds = []) {
  return { type: SET_DRAFT_TEXT, payload: { text, friendIds } };
}

export const getEditId = (state) => state.editor.editId;
export const getEditText = (state) => state.editor.editText;
export const getDraftText = (state) => state.editor.draftText;
export const getEditFriendIds = (state) => state.editor.editFriendIds;
export const getDraftFriendIds = (state) => state.editor.draftFriendIds;
