import React from 'react';
import OptionGroupField from '../../../components/fields/OptionGroupField';

const ReminderFrequencyField = () => (
  <OptionGroupField
    name="reminder_frequency"
    description="You will receive a reminder if you don’t mention them in your journal after the specified amount of time. You can mention them in a journal entry by typing @ followed by their name."
    options={[
      {
        value: '7',
        label: 'Once A Week',
      },
      {
        value: '14',
        label: 'Every 2 Weeks',
      },
      {
        value: '21',
        label: 'Every 3 Weeks',
      },
      {
        value: '28',
        label: 'Every 4 Weeks',
      },
      {
        value: '60',
        label: 'Every 2 Months',
      },
      {
        value: '90',
        label: 'Every 3 Months',
      },
      {
        value: '120',
        label: 'Every 4 Months',
      },
      {
        value: '0',
        label: 'No Reminders',
      },
    ]}
  >
    <strong>How often do you want to keep in touch?</strong>
  </OptionGroupField>
);

export default ReminderFrequencyField;
