import React from 'react';
import Dialog from '../../../components/Dialog';
import Spacer from '../../../components/helpers/Spacer';

const DeleteDialog = ({ isOpen, name, entryCount, onAction, onClose }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Dialog
      title={`Delete ${name}`}
      actionButtonStyle="danger"
      actionButtonText="Delete Friend"
      onAction={onAction}
      onClose={onClose}
    >
      <span>
        Once a friend is deleted, they will be no longer tracked in your journal
        entries. Adding back them later will not restore any of their previous
        mentions or their total mention count.
      </span>
      <Spacer />
      <span>
        {' '}
        Are you sure you want to delete <strong>{name}</strong> who has{' '}
        <strong>{entryCount}</strong>{' '}
        {entryCount === 1 ? 'mention' : 'mentions'}?
      </span>
    </Dialog>
  );
};

export default DeleteDialog;
