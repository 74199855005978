import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import FlexScrollbar from '../../../components/FlexScrollbar';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import H1Divider from '../../../components/H1Divider';
import Spacer from '../../../components/helpers/Spacer';
import TextField from '../../../components/fields/TextField';
import validatePassword from '../../../utils/validatePassword';
import useRefHeight from '../../../utils/hooks/useRefHeight';
import { register, getAuthStatus } from '../../../reducers/auth';

const RegistrationPage = () => {
  const { isFetching } = useSelector(getAuthStatus);
  const [navHeight, navRef] = useRefHeight();
  const dispatch = useDispatch();
  const history = useHistory();

  const submit = ({ name, email, password, password_confirm, beta_code }) => {
    toast.dismiss();
    return dispatch(
      register(name, email, password, password_confirm, beta_code)
    )
      .then(() => history.push('/'))
      .catch((err) => {
        toast.error(err.error);
      });
  };

  return (
    <React.Fragment>
      <Helmet title="Register" />
      <PanelLayoutContainer>
        <Form
          onSubmit={submit}
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = 'Name is required.';
            }

            if (!values.email) {
              errors.email = 'Email is required.';
            }

            if (!values.password) {
              errors.password = ['Password is required.'];
            }
            const passwordErrors = validatePassword(values.password);
            if (passwordErrors.length) {
              errors.password = passwordErrors;
            }

            if (!values.password_confirm) {
              errors.password_confirm = [
                'Confirming the password is required.',
              ];
            } else if (values.password !== values.password_confirm) {
              errors.password_confirm = 'Must match the above password.';
            }

            return errors;
          }}
          render={({ handleSubmit, pristine, invalid }) => (
            <PanelLayoutPage as="form" onSubmit={handleSubmit}>
              <PanelNavigation
                ref={navRef}
                backButtonTo={'/overview/be-yourself'}
              />
              <FlexScrollbar
                refHeights={[navHeight]}
                desktopHeight={PanelLayoutPage.minHeight}
              >
                <PanelLayoutPage.Header>
                  <Spacer margin={10} />
                  <H1Divider>Account Registration</H1Divider>
                </PanelLayoutPage.Header>
                <PanelLayoutPage.Body>
                  <TextField
                    name="name"
                    type="text"
                    placeholder="Friendly Plant"
                    autoComplete="new-password"
                  >
                    <strong>Name</strong>
                  </TextField>

                  <TextField
                    name="email"
                    type="email"
                    placeholder="friendlyplant@tendship.com"
                    autoComplete="new-password"
                    hint={
                      'You will be sent a verification email to this address.'
                    }
                  >
                    <strong>Email</strong>
                  </TextField>

                  <TextField
                    name="password"
                    type="password"
                    placeholder="********"
                    autoComplete="new-password"
                    hint={
                      'The password must be at least 8 characters long with at least one capital, number and special character.'
                    }
                  >
                    <strong>Password</strong>
                  </TextField>

                  <TextField
                    name="password_confirm"
                    type="password"
                    placeholder="********"
                    autoComplete="new-password"
                  >
                    <strong>Confirm Password</strong>
                  </TextField>
                </PanelLayoutPage.Body>

                <PanelLayoutPage.Footer>
                  <Button
                    type="submit"
                    disabled={pristine || invalid || isFetching}
                    variant="primary"
                    size="block"
                  >
                    Create Account
                  </Button>
                </PanelLayoutPage.Footer>
              </FlexScrollbar>
            </PanelLayoutPage>
          )}
        />
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default RegistrationPage;
