import React from 'react';
import { useSelector } from 'react-redux';
import EndMarker from './EndMarker';
import Spacer from '../../../components/helpers/Spacer';
import Thread from './Thread';
import ThreadGap from '../../../components/timeline/ThreadGap';
import { getIsExtendable, getTimeline } from '../../../reducers/thread';

const ActiveState = () => {
  const isExtendable = useSelector(getIsExtendable);
  const timeline = useSelector(getTimeline);

  return (
    <React.Fragment>
      {!isExtendable && <EndMarker>The Beginning</EndMarker>}
      <Spacer margin={20} />
      {timeline.map(({ type, id, size }) => {
        if (type === 'gap') {
          return <ThreadGap key={id} threadId={id} size={size} />;
        }
        return <Thread key={id} threadId={id} />;
      })}
      <Spacer margin={10} />
    </React.Fragment>
  );
};

export default ActiveState;
