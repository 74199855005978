import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import Divider from '../../../components/Divider';
import FlexScrollbar from '../../../components/FlexScrollbar';
import H2 from '../../../components/H2';
import LinkButton from '../../../components/buttons/LinkButton';
import Spacer from '../../../components/helpers/Spacer';
import SwipeNavigation from '../../../components/SwipeNavigation';
import TabLayoutContainer from '../../../components/tabLayout/TabLayoutContainer';
import TabNavigation from '../../../components/tabLayout/TabNavigation';
import TabLayoutPage from '../../../components/tabLayout/TabLayoutPage';
import { logout } from '../../../reducers/auth';
import EmailFrequencySetting from './EmailFrequencySetting';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const OptionsPage = () => {
  const dispatch = useDispatch();
  const [navHeight, navRef] = useRefHeight();
  const history = useHistory();

  const handleBack = () => {
    dispatch(logout()).then(() => history.push('/'));
  };

  return (
    <SwipeNavigation leftTo="/search">
      <Helmet title="Options" />
      <TabLayoutContainer
        navigation={<TabNavigation ref={navRef} active="options" />}
      >
        <TabLayoutPage>
          <FlexScrollbar refHeights={[navHeight]}>
            <TabLayoutPage.Header padding={15} />
            <TabLayoutPage.Body padding={15}>
              <H2>Reminder Options</H2>
              <Divider margin={10} />
              <EmailFrequencySetting />
              <Spacer margin={15} />

              <H2>Account Options</H2>
              <Divider margin={10} />
              <LinkButton
                type="button"
                variant="secondary"
                size="block"
                to="/options/change-details"
              >
                Change Name or Email
              </LinkButton>
              <Spacer margin={15} />
              <LinkButton
                type="button"
                variant="secondary"
                size="block"
                to="/options/change-password"
              >
                Change Password
              </LinkButton>
            </TabLayoutPage.Body>
            <TabLayoutPage.Footer padding={15}>
              <Button
                type="button"
                variant="danger"
                size="block"
                onClick={handleBack}
              >
                Logout
              </Button>
            </TabLayoutPage.Footer>
          </FlexScrollbar>
        </TabLayoutPage>
      </TabLayoutContainer>
    </SwipeNavigation>
  );
};

export default OptionsPage;
