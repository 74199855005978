import styled from 'styled-components';
import { WHITE_000, BORDER_MD, LG_SCREEN } from '../../theme';

const PANEL_HEIGHT = 630;

const PanelLayoutPage = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: ${({ bgColor }) => bgColor || WHITE_000};

  /* Desktop */
  @media (min-width: ${LG_SCREEN}px) {
    width: 400px;
    height: auto;
    min-height: ${PANEL_HEIGHT}px;
    flex-grow: auto;
    border-radius: ${BORDER_MD};
  }
`;

PanelLayoutPage.minHeight = PANEL_HEIGHT;

PanelLayoutPage.Header = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px 15px 0px 15px;
`;

PanelLayoutPage.Body = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%
  width: 100%;
  padding: 0px 15px;
`;

PanelLayoutPage.Footer = styled.div`
  box-sizing: border-box;
  align-self: flex-end;
  width: 100%;
  padding: 0px 15px 15px 15px;
`;

export default PanelLayoutPage;
