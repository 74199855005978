import React from 'react';
import { GREY_200 } from '../../../theme';

const CaretDownIcon = ({ width = 10, height = 6, color = GREY_200 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.605575 -7.62939e-06H8.39442C8.93326 -7.62939e-06 9.20267 0.637407 8.82125 1.01096L4.92834 4.82656C4.69222 5.0578 4.30778 5.0578 4.07166 4.82656L0.178747 1.01096C-0.202673 0.637407 0.0667427 -7.62939e-06 0.605575 -7.62939e-06Z"
      fill={color}
    />
  </svg>
);

export default CaretDownIcon;
