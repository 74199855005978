import React from 'react';
import styled from 'styled-components';
import Dialog from '../../../components/Dialog';
import Spacer from '../../../components/helpers/Spacer';
import { GREY_000, GREY_900, RED_900, BORDER_MD } from '../../../theme';

const SecretText = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: ${GREY_900};
  border-radius: ${BORDER_MD};
  background-color: ${GREY_000};
  text-align: center;

  margin: 4px 0;
  padding: 6px 0;
  font-style: italic;
`;

const WarningText = styled.div`
  color: ${RED_900};
`;

const ViewKeyDialog = ({ isOpen, secret, onClose }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Dialog
      title="View Encryption Key"
      cancelButtonText="Close"
      onClose={onClose}
    >
      <div>
        You will have the <strong>only</strong> copy of your key. Tendship will
        not be able to recover your data if your key is lost. Storing the key in
        another service (e.g. Dropbox) is advised.
      </div>
      <Spacer />

      <div>
        <strong>Encryption Key:</strong>
        <SecretText>{secret}</SecretText>
      </div>
      <Spacer />

      <WarningText>
        You will not get another chance to see your encryption key after it has
        been confirmed.
      </WarningText>
    </Dialog>
  );
};

export default ViewKeyDialog;
