import React from 'react';
import { TEAL_900 } from '../../../theme';

const JournalIcon = ({ width = 31, height = 24, color = TEAL_900 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.6177 0.00290924C25.7254 0.169516 19.9767 0.775941 16.4279 2.98093C16.183 3.13307 16.0442 3.40361 16.0442 3.68646V23.1794C16.0442 23.7981 16.7108 24.1892 17.2729 23.9021C20.9241 22.0367 26.2046 21.5278 28.8156 21.3885C29.707 21.3408 30.4 20.6155 30.4 19.746V1.64754C30.4005 0.698799 29.5898 -0.0528048 28.6177 0.00290924ZM13.9721 2.98093C10.4238 0.775941 4.67514 0.170051 1.78286 0.00290924C0.810681 -0.0528048 0 0.698799 0 1.64754V19.7466C0 20.6166 0.692984 21.3419 1.58442 21.3891C4.19643 21.5283 9.47958 22.0378 13.1308 23.9042C13.6913 24.1908 14.3558 23.8003 14.3558 23.1832V3.67682C14.3558 3.39343 14.2175 3.13361 13.9721 2.98093Z"
      fill={color}
    />
  </svg>
);

export default JournalIcon;
