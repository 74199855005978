import React from 'react';
import { GREY_200, GREY_900 } from '../../../theme';

const CautionIcon = ({
  width = 577,
  height = 512,
  primaryColor = GREY_200,
  secondaryColor = GREY_900,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 577 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M569.52 440L329.58 24C311.14 -8 264.89 -8 246.42 24L6.47997 440C-11.94 471.94 11.12 512 48.05 512H527.94C564.81 512 588 472 569.52 440ZM288 448C281.671 448 275.484 446.123 270.222 442.607C264.959 439.091 260.858 434.093 258.436 428.246C256.014 422.399 255.38 415.965 256.615 409.757C257.85 403.55 260.897 397.848 265.373 393.373C269.848 388.897 275.55 385.85 281.757 384.615C287.964 383.38 294.399 384.014 300.246 386.436C306.093 388.858 311.091 392.959 314.607 398.222C318.123 403.484 320 409.671 320 416C320 424.487 316.629 432.626 310.627 438.627C304.626 444.629 296.487 448 288 448ZM326.24 209.59L313.44 337.59C313.046 341.538 311.198 345.199 308.256 347.862C305.314 350.525 301.488 352 297.52 352H278.52C274.552 352 270.726 350.525 267.784 347.862C264.842 345.199 262.994 341.538 262.6 337.59L249.8 209.59C249.578 207.369 249.823 205.127 250.52 203.006C251.217 200.886 252.349 198.935 253.845 197.278C255.34 195.622 257.166 194.296 259.204 193.387C261.242 192.478 263.448 192.006 265.68 192H310.32C312.555 192 314.765 192.469 316.809 193.375C318.852 194.282 320.682 195.606 322.182 197.263C323.682 198.921 324.818 200.874 325.517 202.997C326.216 205.12 326.462 207.366 326.24 209.59V209.59Z"
      fill={primaryColor}
    />
    <path
      d="M310.32 192H265.68C263.445 192 261.234 192.469 259.191 193.375C257.148 194.282 255.318 195.606 253.818 197.263C252.318 198.921 251.182 200.874 250.483 202.997C249.784 205.12 249.538 207.366 249.76 209.59L262.56 337.59C262.954 341.538 264.802 345.199 267.744 347.862C270.686 350.525 274.512 352 278.48 352H297.48C301.448 352 305.274 350.525 308.216 347.862C311.158 345.199 313.006 341.538 313.4 337.59L326.2 209.59C326.422 207.369 326.177 205.127 325.48 203.006C324.783 200.886 323.651 198.935 322.155 197.278C320.66 195.622 318.834 194.296 316.796 193.387C314.758 192.478 312.552 192.006 310.32 192V192ZM288 384C281.671 384 275.484 385.877 270.222 389.393C264.959 392.909 260.858 397.907 258.436 403.754C256.014 409.601 255.38 416.035 256.615 422.243C257.85 428.45 260.897 434.152 265.373 438.627C269.848 443.103 275.55 446.15 281.757 447.385C287.964 448.62 294.399 447.986 300.246 445.564C306.093 443.142 311.091 439.041 314.607 433.778C318.123 428.516 320 422.329 320 416C320 407.513 316.629 399.374 310.627 393.373C304.626 387.371 296.487 384 288 384Z"
      fill={secondaryColor}
    />
  </svg>
);

export default CautionIcon;
