import { useState, useEffect } from 'react';

// Only one hook of this type can be active at any time.
export default function useBodyStyle(key, value) {
  const [oldValue, setOldValue] = useState(null);
  useEffect(() => {
    setOldValue(document.body.style[key]);
    document.body.style[key] = value;
    return () => {
      document.body.style[key] = oldValue;
    };
  }, [key, value, oldValue]);
}
