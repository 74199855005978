import React from 'react';

const LinePlantPhoto = ({ width = 146, height = 90 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 146 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.354 41.2472C60.4158 41.7696 56.7023 42.7359 56.0281 42.7359C52.9776 42.7359 52.7641 42.7359 49.7809 41.8258C49.1024 42.5522 48.3318 43.1868 47.4888 43.7135C44.3764 44.9326 42.8877 44.3202 39.472 43.5168C38.4382 43.0799 37.4455 42.5516 36.5057 41.9382C33.5899 42.8371 31.7248 43.4887 28.8821 43.0955C24.6742 42.5337 22.4045 40.1292 22.2978 39.9326C18.9663 40.4101 11.9045 42.2359 11.8989 42.9157C11.8989 44.5056 21.8989 46.1236 35.4326 46.191C48.9663 46.2584 61.663 43.7022 61.6742 42.1123C61.6742 41.7191 61.4439 41.4606 60.354 41.2472Z"
      fill="#616E7C"
    />
    <path
      d="M1.34265 42.8483C1.42692 48.3371 2.21905 53.8989 2.96063 59.3371C3.58422 63.882 4.22467 68.5786 5.87636 72.8876C8.84827 80.6517 15.7078 85.3483 23.6966 86.9326C32.5446 88.7252 41.6332 88.9988 50.573 87.7416C54.4719 87.1798 58.5842 86.2472 61.8089 83.809C65.0337 81.3708 67.2921 77.9101 68.8033 74.309C70.7584 69.6685 71.2247 64.6966 71.9494 59.764C72.7865 54.0281 73.5337 48.3146 73.5224 42.5056C73.5224 40.7303 70.7696 40.7303 70.7696 42.5056C70.7287 47.2501 70.3438 51.9853 69.6179 56.6741C69.0561 60.6966 68.6516 64.8146 67.6853 68.764C66.8239 72.6454 65.0372 76.2611 62.4775 79.3034C59.7528 82.4157 56.2528 83.7978 52.2696 84.5955C44.3651 86.1517 36.1573 85.882 28.1966 84.927C19.8258 83.927 11.7696 80.691 8.35388 72.3989C4.49995 63.0225 5.08422 52.3876 3.17973 42.5786C2.9831 41.5449 1.35389 41.7977 1.36512 42.8258L1.34265 42.8483Z"
      fill="#616E7C"
    />
    <path
      d="M11.0112 39.1966C9.48876 39.1966 0.134831 40.3932 1.46067 43.1292C2.17977 44.6461 6.31461 45.2528 7.66854 45.6236C10.6392 46.4344 13.6584 47.0555 16.7079 47.4831C31.1687 49.4949 45.85 49.3188 60.2584 46.9607C63.9326 46.3595 70.309 45.809 72.882 42.6123C72.9791 42.5094 73.052 42.3861 73.0956 42.2515C73.1392 42.1169 73.1523 41.9742 73.1341 41.8339C73.1158 41.6936 73.0666 41.5591 72.9899 41.4401C72.9133 41.3212 72.8112 41.2207 72.691 41.146C70.0737 39.3078 67.0093 38.2094 63.8202 37.9663C62.6292 37.8708 62.6573 39.6966 63.8202 39.8146C66.6679 40.1116 69.3916 41.1349 71.7303 42.7865L71.5449 41.3202C70.4213 42.691 68.3539 43.2191 66.7528 43.691C64.042 44.4526 61.2829 45.0307 58.4944 45.4213C50.8066 46.5422 43.044 47.07 35.2753 47C27.5056 47 19.5786 46.6011 11.9663 44.9719C9.26966 44.4101 5.3764 43.9269 3.06742 42.3314C2.96067 42.8539 3.06741 42.955 3.4382 42.6404C3.81273 42.4719 4.18726 42.3202 4.5618 42.1854C5.30899 41.9213 6.06741 41.7022 6.80899 41.5C8.15954 41.0881 9.55224 40.8299 10.9607 40.7303C11.1527 40.7139 11.3316 40.6259 11.462 40.484C11.5923 40.342 11.6646 40.1562 11.6646 39.9635C11.6646 39.7707 11.5923 39.585 11.462 39.443C11.3316 39.301 11.1527 39.2131 10.9607 39.1966H11.0112Z"
      fill="#616E7C"
    />
    <path
      d="M32.6685 21.1797C31.0618 14.8426 26.3089 9.56737 20.691 6.41569C20.5206 6.31735 20.3275 6.26544 20.1308 6.26514C19.9342 6.26484 19.7409 6.31617 19.5703 6.41399C19.3997 6.5118 19.2577 6.65268 19.1586 6.82255C19.0595 6.99241 19.0067 7.18532 19.0056 7.38198C18.3707 16.9325 19.4775 28.2696 28.7247 33.6797C30.0561 34.4606 31.1292 32.5112 29.9157 31.6404C21.7808 25.8089 20.7303 16.6966 21.2303 7.38198L19.5449 8.34265C24.9365 11.2171 29.1842 15.8474 31.5842 21.4662C31.6427 21.5819 31.7395 21.6738 31.858 21.7263C31.9765 21.7788 32.1096 21.7888 32.2346 21.7544C32.3596 21.72 32.4688 21.6434 32.5438 21.5376C32.6188 21.4319 32.6549 21.3034 32.646 21.1741L32.6685 21.1797Z"
      fill="#616E7C"
    />
    <path
      d="M38.2809 41.4775C36.7472 40.1629 37.3484 38.8146 38.3708 37.2528C39.1605 36.0645 40.0196 34.924 40.9439 33.8371C41.472 33.2079 42.4382 31.6461 43.3034 31.7023C44.0506 31.7472 45.5843 33.4831 46.1911 33.9888C48.8877 36.2359 50.7753 40.5618 46.9045 42.8146C44.0001 44.5 40.4607 43.118 38.0169 41.2753L36.9551 43.0955L37.0057 43.1348C38.1293 43.9213 39.163 42.0955 38.0675 41.3146L38.0169 41.2753C36.8933 40.4831 35.8877 42.2978 36.9551 43.0955C40.2866 45.6067 45.2472 46.8764 48.8147 44.0506C52.5001 41.1292 51.6236 36.1404 48.472 33.1685C47.2079 31.9663 45.2641 29.8708 43.472 29.4944C41.6798 29.118 40.4663 31.1517 39.4495 32.3483C36.9776 35.2584 32.9551 39.6517 36.7922 42.9719C37.8147 43.8483 39.3147 42.3708 38.2809 41.4775Z"
      fill="#616E7C"
    />
    <path
      d="M50.809 36.809C53.3877 34.7641 55.6629 32.6854 58.5393 31.0112C61.1276 29.6154 63.6083 28.0286 65.9607 26.264L64.1236 25.7809C65.2472 30.1966 65.3258 36.0337 60.8258 38.7023C58.4438 40.1292 54.2978 41.1742 51.6461 40.1124C50.1405 39.5112 49.4888 41.9438 50.9775 42.5393C54.4944 43.9438 60.1629 42.4719 63.0506 40.1742C67.4157 36.7022 68.1067 30.1068 66.2472 25.1742C66.1911 24.9919 66.0895 24.8269 65.952 24.6948C65.8146 24.5626 65.6457 24.4676 65.4614 24.4187C65.2771 24.3698 65.0833 24.3687 64.8984 24.4153C64.7135 24.462 64.5436 24.5549 64.4045 24.6854C61.7023 27.0225 58.4101 28.5618 55.4438 30.5225C53.2697 31.9607 50.6629 33.6348 49.9214 36.2697C49.9094 36.3778 49.9291 36.487 49.9781 36.5841C50.027 36.6812 50.1031 36.7621 50.1971 36.8168C50.2911 36.8715 50.3989 36.8978 50.5075 36.8924C50.6162 36.887 50.7209 36.8503 50.809 36.7865V36.809Z"
      fill="#616E7C"
    />
    <path
      d="M44.4832 26.2359C45.5787 24.2303 45.7697 22.3708 47.5 20.6741C49.5843 18.6348 52.2247 17.2078 54.6686 15.6461L53.3708 15.309C56.4157 20.264 55 28.3876 51.927 33.0505C51.1349 34.2528 53.0506 35.3708 53.8539 34.1741C57.3371 28.8764 58.9101 19.7247 55.0056 14.3483C54.8779 14.1335 54.6711 13.9773 54.4295 13.9132C54.1879 13.8491 53.9309 13.8823 53.7135 14.0056C49.2472 17.0786 43.5393 19.8202 43.4551 25.955C43.4627 26.0723 43.5068 26.1842 43.5813 26.275C43.6558 26.3658 43.7569 26.431 43.8704 26.4614C43.9839 26.4918 44.104 26.4858 44.2139 26.4444C44.3239 26.4029 44.418 26.3281 44.4832 26.2303V26.2359Z"
      fill="#616E7C"
    />
    <path
      d="M56.9944 22.9438C58.7247 19.573 60.736 16.2753 63.736 13.8483L62.1573 13.4326C63.2809 18.6854 63.2191 23.3146 60.0618 27.8539C59.4719 28.7079 60.7753 29.6798 61.4831 28.9494C65.2865 25.0169 66.0112 17.8708 63.9775 12.9326C63.9315 12.7749 63.8457 12.6317 63.7282 12.5169C63.6107 12.4021 63.4656 12.3195 63.3069 12.2771C63.1482 12.2348 62.9812 12.234 62.8222 12.2751C62.6631 12.3161 62.5173 12.3974 62.3989 12.5112C59.5899 15.5225 57.1517 18.4831 55.6573 22.3708C55.3595 23.1517 56.6292 23.6798 57.0056 22.9326L56.9944 22.9438Z"
      fill="#616E7C"
    />
    <path
      d="M22.3033 35.5393C20.7929 33.4091 18.5816 31.8779 16.0561 31.2135C12.0505 30.1348 8.37075 31.2978 4.34828 31.5562C3.78648 31.5955 3.10671 32.1854 3.37637 32.8371C6.46064 40.3989 15.3988 44.8146 22.6797 40.1404C23.8033 39.4326 22.7584 37.6573 21.6517 38.3764C15.5842 42.309 7.89884 38.7472 5.3258 32.3146L4.34828 33.5955C7.83704 33.3933 11.264 32.3258 14.7528 33.0337C17.3988 33.5955 19.1517 35.0449 21.309 36.5449C21.8708 36.9494 22.7303 36.1348 22.3033 35.5506V35.5393Z"
      fill="#616E7C"
    />
    <path
      d="M28.2023 12.7809C27.3596 8.80897 29.1405 5.7865 31.2753 2.66852L29.9776 3.0056C31.7191 4.0056 33.5337 4.84269 35.0337 6.22471C36.2208 7.41657 37.1938 8.80386 37.9102 10.3258C38.3765 11.1798 39.5955 10.5674 39.4045 9.69662C38.6124 5.52246 34.2978 3.24155 30.9776 1.37076C30.7594 1.24954 30.5029 1.21694 30.2613 1.27969C30.0197 1.34245 29.8115 1.49576 29.6798 1.70785C28.3596 3.67976 27.0337 5.42133 26.5281 7.79774C26.1686 9.48313 25.9663 12.0225 27.2809 13.3427C27.3627 13.4552 27.4834 13.5333 27.6195 13.562C27.7555 13.5907 27.8974 13.5678 28.0177 13.4979C28.1379 13.428 28.2279 13.316 28.2703 13.1836C28.3127 13.0511 28.3045 12.9076 28.2472 12.7809H28.2023Z"
      fill="#616E7C"
    />
    <path
      d="M20.9101 19.5337C18.3764 15.7472 13.5224 13.809 9.52244 11.9888C9.37484 11.9061 9.20832 11.8632 9.03915 11.8642C8.86998 11.8651 8.70396 11.91 8.55733 11.9943C8.41069 12.0787 8.28846 12.1997 8.20258 12.3454C8.1167 12.4912 8.07012 12.6567 8.06738 12.8259C8.21345 17.1068 8.17412 21.4944 9.96626 25.4775C11.5694 29.1197 14.3356 32.1269 17.8314 34.0281C18.0414 34.1294 18.2818 34.1478 18.5047 34.0798C18.7276 34.0117 18.9168 33.8622 19.0344 33.661C19.152 33.4597 19.1895 33.2215 19.1394 32.9939C19.0893 32.7663 18.9553 32.5659 18.764 32.4326C15.4574 30.4915 12.8887 27.5078 11.4606 23.9495C10.1067 20.4214 10.1573 16.5506 10.0168 12.8259L8.55614 13.6629C12.4382 15.4101 16.8483 17.2416 19.8258 20.4045C20.3876 20.9663 21.3651 20.2304 20.9494 19.5618L20.9101 19.5337Z"
      fill="#616E7C"
    />
    <path
      d="M44.4101 11.8146C45.6011 7.93822 47.1404 4.51125 50.9438 2.54496L49.5842 1.98316C50.7078 4.62923 51.9045 7.35396 52.0449 10.2641C52.146 12.4382 51.6685 13.9607 50.9213 15.9551C50.4494 17.2191 52.4157 17.7023 52.9157 16.5169C55.0674 11.3708 53.3651 6.20226 51.2752 1.28092C51.149 1.07217 50.9464 0.920811 50.7104 0.858933C50.4744 0.797054 50.2236 0.82952 50.0112 0.949457C46.3033 2.98316 43.0281 7.25844 43.0898 11.6236C43.0928 11.7845 43.1532 11.939 43.2601 12.0592C43.3671 12.1795 43.5135 12.2575 43.6729 12.2792C43.8324 12.3009 43.9943 12.2649 44.1295 12.1776C44.2647 12.0903 44.3642 11.9576 44.4101 11.8034V11.8146Z"
      fill="#616E7C"
    />
    <path
      d="M33.1348 34.1798C31.8034 28.3203 32.191 23.3539 35.0899 18.0169C37.4046 13.5639 40.3132 9.44573 43.7359 5.77528L41.9719 5.31462C43.0368 10.4642 43.4857 15.722 43.309 20.9776C43.2079 24.3483 42.2977 27.2135 41.0618 30.3877C40.5 31.7922 42.7921 32.4214 43.309 31.0113C44.6067 27.6405 45.4944 24.5787 45.5562 20.9776C45.7003 15.519 45.1796 10.0631 44.0056 4.73035C43.9536 4.55452 43.8572 4.39504 43.7257 4.26729C43.5942 4.13953 43.432 4.04782 43.2548 4.00096C43.0775 3.95411 42.8912 3.9537 42.7137 3.99978C42.5362 4.04586 42.3736 4.13688 42.2416 4.26406C34.809 12.1629 28.1966 23.5955 30.8595 34.7809C31.2135 36.2584 33.4719 35.6349 33.1067 34.1573L33.1348 34.1798Z"
      fill="#616E7C"
    />
    <path
      d="M36.9045 40.4888C34.9045 34.3371 28.9663 30.3764 22.9326 28.7977C22.6734 28.7285 22.3974 28.764 22.1641 28.8964C21.9308 29.0288 21.7589 29.2476 21.6854 29.5056C20.7135 34.1404 19.3708 39.618 24.0899 42.7472C27.236 44.8259 33.191 45.2641 36.3877 43.0787C36.6077 42.9415 36.7716 42.7303 36.8499 42.4831C36.9283 42.2359 36.9159 41.9689 36.8151 41.73C36.7142 41.4911 36.5315 41.296 36.2998 41.1797C36.068 41.0634 35.8024 41.0336 35.5506 41.0955C32.4888 41.618 29.6124 42.6348 26.5337 41.4101C21.7922 39.5281 22.3034 34.0674 23.6461 30.0449L22.3933 30.7528C28.1742 32.3483 32.4495 35.9494 35.3146 41.1517C35.4219 41.3296 35.5897 41.4631 35.7872 41.5275C35.9848 41.592 36.1989 41.5832 36.3905 41.5027C36.5821 41.4222 36.7384 41.2755 36.8307 41.0893C36.923 40.9032 36.9452 40.69 36.8933 40.4888H36.9045Z"
      fill="#616E7C"
    />
    <path
      d="M101.017 45.1124C101.202 42.4438 101.017 39.7809 101.298 37.1124C101.645 34.1732 102.294 31.2777 103.236 28.4719L102.185 28.6124C103.826 30.7809 103.247 34.0899 102.966 36.6348C102.579 39.4209 102.039 42.1835 101.348 44.9101C101.33 45.0449 101.361 45.1818 101.435 45.2955C101.51 45.4092 101.624 45.4919 101.755 45.5282C101.886 45.5646 102.025 45.5522 102.148 45.4933C102.271 45.4344 102.368 45.3329 102.421 45.2079C103.365 42.11 103.972 38.9192 104.23 35.691C104.449 33.1236 104.82 30.2079 103.174 28.0337C103.122 27.9301 103.039 27.8452 102.936 27.7908C102.833 27.7364 102.716 27.7151 102.601 27.7299C102.486 27.7446 102.378 27.7948 102.293 27.8733C102.207 27.9519 102.148 28.055 102.124 28.1685C100.41 33.3708 99.0786 39.7641 100.36 45.2023C100.384 45.2774 100.434 45.3416 100.501 45.3835C100.568 45.4255 100.647 45.4426 100.726 45.4319C100.804 45.4212 100.876 45.3834 100.929 45.325C100.982 45.2666 101.013 45.1913 101.017 45.1124V45.1124Z"
      fill="#616E7C"
    />
    <path
      d="M100.478 43.5056C99.2753 38.5787 97.0056 34.0169 94.2978 29.7472C94.2326 29.6495 94.1385 29.5746 94.0285 29.5331C93.9186 29.4917 93.7985 29.4857 93.685 29.5161C93.5715 29.5465 93.4704 29.6117 93.3959 29.7025C93.3214 29.7934 93.2773 29.9053 93.2697 30.0225C92.7809 35.3989 95.7809 39.8933 99.0393 43.8708C99.427 44.3427 100.258 43.7135 99.8933 43.2079C96.9775 39.191 93.9157 35.3034 94.382 30.0225L93.354 30.3034C96.045 34.5337 97.9944 39.0281 99.8146 43.691C99.8392 43.7789 99.8977 43.8535 99.9772 43.8983C100.057 43.943 100.151 43.9544 100.239 43.9298C100.327 43.9052 100.401 43.8467 100.446 43.7672C100.491 43.6876 100.502 43.5935 100.478 43.5056V43.5056Z"
      fill="#616E7C"
    />
    <path
      d="M102.45 44.5112C103.975 39.735 106.135 35.185 108.871 30.9831L107.916 30.7247C108.005 35.7556 106.223 40.6404 102.916 44.4326C102.483 44.955 103.202 45.7078 103.674 45.191C107.405 41.0618 108.916 36.2022 108.938 30.7247C108.937 30.6133 108.9 30.5054 108.832 30.4172C108.764 30.3291 108.669 30.2655 108.561 30.2363C108.454 30.207 108.34 30.2137 108.236 30.2552C108.133 30.2967 108.046 30.3708 107.989 30.4663C105.208 34.7416 102.657 39.2528 101.921 44.3651C101.91 44.4325 101.924 44.5018 101.96 44.5595C101.997 44.6172 102.053 44.6593 102.119 44.6775C102.185 44.6958 102.255 44.6888 102.316 44.6581C102.378 44.6273 102.425 44.575 102.45 44.5112V44.5112Z"
      fill="#616E7C"
    />
    <path
      d="M100.388 44.3202C99.0619 43.0056 97.6799 41.7416 96.354 40.4157C95.0282 39.0899 93.2473 37.6517 92.3091 35.9214C92.2686 35.8537 92.2093 35.7993 92.1383 35.7649C92.0674 35.7304 91.988 35.7175 91.9098 35.7277C91.8316 35.7378 91.758 35.7706 91.6982 35.822C91.6384 35.8733 91.5949 35.9411 91.5731 36.0169C90.7922 40.5113 94.1237 45.3596 98.8765 45.5674C98.9852 45.5678 99.0903 45.5289 99.1725 45.4577C99.2546 45.3865 99.3082 45.288 99.3233 45.1804C99.3383 45.0727 99.3139 44.9633 99.2545 44.8723C99.1951 44.7813 99.1047 44.7149 99.0001 44.6854C94.7754 43.736 92.0225 40.6068 92.3821 36.1517L91.6911 36.4382C93.0506 38 94.1967 39.6573 95.6911 41.1124C97.0468 42.4526 98.484 43.7077 99.9945 44.8708C100.309 45.1068 100.674 44.6011 100.405 44.3371L100.388 44.3202Z"
      fill="#616E7C"
    />
    <path
      d="M97.7865 36.3708C97.1236 33.6012 97.3539 30.5899 97.7865 27.8034H96.8708C98.2768 31.1354 99.3786 34.5876 100.163 38.118C100.264 38.6068 101.09 38.5056 101.045 38C100.73 34.3371 99.1966 30.7809 97.7472 27.4382C97.545 26.9663 96.8877 27.2528 96.8652 27.6742C96.691 30.6292 96.4101 33.6517 97.1517 36.5506C97.1826 36.6293 97.2415 36.6937 97.3171 36.7316C97.3926 36.7695 97.4795 36.7781 97.5611 36.7558C97.6426 36.7335 97.713 36.6819 97.7588 36.6108C97.8045 36.5397 97.8224 36.4543 97.809 36.3708H97.7865Z"
      fill="#616E7C"
    />
    <path
      d="M94.5787 47.3484C94.2973 46.3934 94.0685 45.4238 93.8933 44.4439L93.1573 45.1798C98.1878 46.469 103.508 46.0012 108.236 43.854L107.275 43.2922C107.32 44.7809 107.32 46.2753 107.275 47.7866C107.247 48.9495 107.202 50.1124 107.09 51.2753C106.921 52.9607 107.124 53.0787 105.404 53.5225C104.758 53.7023 104.843 54.8315 105.573 54.7416C108.101 54.4214 108.185 53.1854 108.382 50.9214C108.576 48.3831 108.621 45.8357 108.517 43.2922C108.516 43.1798 108.487 43.0695 108.431 42.9721C108.374 42.8748 108.294 42.7937 108.197 42.737C108.1 42.6803 107.99 42.6498 107.877 42.6487C107.765 42.6475 107.654 42.6757 107.556 42.7304C103.121 44.7056 98.1557 45.1542 93.4382 44.0057C93.3365 43.9802 93.23 43.9815 93.129 44.0095C93.028 44.0374 92.9359 44.0911 92.8618 44.1652C92.7877 44.2393 92.734 44.3314 92.7061 44.4324C92.6781 44.5334 92.6768 44.6399 92.7023 44.7416C92.8766 45.7223 93.1515 46.6824 93.5225 47.6068C93.7697 48.2192 94.7303 48.0001 94.545 47.3259L94.5787 47.3484Z"
      fill="#616E7C"
    />
    <path
      d="M95.7192 50.1517C95.1577 49.2081 94.2737 48.4997 93.2304 48.1573C92.756 48.0246 92.2499 48.0648 91.8023 48.2706C91.3547 48.4764 90.9947 48.8344 90.7866 49.2809C90.6361 49.7373 90.6274 50.2285 90.7618 50.6899C90.8962 51.1513 91.1673 51.5611 91.5394 51.8652C92.5001 52.7191 94.1124 52.8146 95.3147 52.5394C95.9439 52.3989 95.7753 51.4775 95.1686 51.4551C94.4046 51.4214 93.6574 51.5843 92.9214 51.3539C92.2922 51.1517 91.6293 50.5281 91.7585 49.8034C91.8877 49.0787 92.8259 48.9607 93.4158 49.2135C94.0622 49.5212 94.6206 49.9868 95.0394 50.5674C95.3428 50.9719 96.0338 50.6011 95.736 50.1629L95.7192 50.1517Z"
      fill="#616E7C"
    />
    <path
      d="M96.0226 50.4832C95.955 49.9395 95.9663 49.3888 96.0563 48.8483C96.2023 48.2865 97.6742 46.8652 97.5956 48.6012C97.5619 49.3315 96.5282 50.1967 96.2304 50.8483C96.0113 51.3259 96.6405 51.9719 97.0282 51.4607C97.8203 50.4551 99.5394 50.9776 99.1349 52.3821C98.7304 53.7865 97.3372 53.3034 96.6349 52.5113C96.1181 51.9495 95.1742 52.6798 95.5731 53.3315C96.8371 55.427 100.258 54.8034 100.393 52.236C100.416 51.7402 100.276 51.2508 99.9946 50.8418C99.7135 50.4328 99.3068 50.1266 98.836 49.9697C98.3652 49.8128 97.8561 49.8137 97.3859 49.9722C96.9157 50.1308 96.51 50.4384 96.2304 50.8483L97.1068 51.3596C97.5787 50.2809 98.7922 49.6236 98.8933 48.354C98.925 47.9436 98.8079 47.5355 98.5634 47.2043C98.3189 46.8732 97.9634 46.6411 97.5619 46.5506C95.1855 46.0281 94.8372 48.9944 95.1462 50.5787C95.1787 50.6787 95.2452 50.7643 95.3343 50.8203C95.4234 50.8763 95.5293 50.8993 95.6336 50.8853C95.7378 50.8712 95.8339 50.821 95.905 50.7434C95.9761 50.6659 96.0177 50.5658 96.0226 50.4607V50.4832Z"
      fill="#616E7C"
    />
    <path
      d="M91.472 51.9158C88.6911 51.8652 86.5618 54.1967 86.0955 56.8203C85.5338 60.0506 87.2192 63.0506 89.6967 64.9944C90.2585 65.4383 91.09 64.6686 90.5113 64.1798C86.5338 60.7529 85.5506 54.4888 91.5787 52.6742C91.6671 52.6473 91.743 52.5897 91.7926 52.5118C91.8423 52.4339 91.8625 52.3408 91.8497 52.2494C91.8368 52.1579 91.7917 52.074 91.7224 52.0128C91.6532 51.9517 91.5643 51.9173 91.472 51.9158V51.9158Z"
      fill="#616E7C"
    />
    <path
      d="M100.242 52.5505C105.376 54.5674 104.079 61.0786 101.742 64.7472C101.348 65.3651 102.303 66.1348 102.775 65.5449C103.747 64.3341 104.456 62.9336 104.855 61.4331C105.255 59.9327 105.337 58.3653 105.095 56.8314C104.697 54.3314 103.27 51.4663 100.376 51.5618C100.257 51.565 100.142 51.6108 100.052 51.6908C99.9632 51.7709 99.9052 51.8801 99.889 51.9989C99.8728 52.1177 99.8994 52.2384 99.964 52.3395C100.029 52.4405 100.127 52.5153 100.242 52.5505V52.5505Z"
      fill="#616E7C"
    />
    <path
      d="M91.9495 53.3764C90.9209 54.9675 90.455 56.8573 90.6261 58.7442C90.7971 60.6311 91.5954 62.4063 92.8933 63.7865C93.281 64.2135 93.8652 63.5955 93.5169 63.1629C91.2136 60.2865 91.0506 57.0506 92.6236 53.7697C92.6495 53.7254 92.6663 53.6765 92.6732 53.6257C92.6802 53.5749 92.677 53.5233 92.664 53.4737C92.6509 53.4242 92.6282 53.3777 92.5972 53.3369C92.5662 53.2961 92.5275 53.2618 92.4832 53.236C92.4389 53.2101 92.39 53.1933 92.3392 53.1864C92.2885 53.1795 92.2368 53.1826 92.1873 53.1956C92.1377 53.2087 92.0912 53.2314 92.0504 53.2624C92.0096 53.2934 91.9753 53.3321 91.9495 53.3764V53.3764Z"
      fill="#616E7C"
    />
    <path
      d="M95.3371 55.2641C95.7135 58.2528 96.0674 61.2472 96.5393 64.2247C96.6517 64.9045 97.6629 64.6124 97.5731 63.9382C97.1292 61.0056 96.5618 58.0899 96.0225 55.1686C95.9495 54.7922 95.2865 54.8708 95.3371 55.2641V55.2641Z"
      fill="#616E7C"
    />
    <path
      d="M98.9213 55.1685C100.404 58.4831 100.399 61.382 98.6741 64.6123C98.6381 64.7319 98.643 64.86 98.6879 64.9764C98.7328 65.0929 98.8152 65.1911 98.9222 65.2555C99.0291 65.3199 99.1544 65.3469 99.2784 65.3322C99.4023 65.3174 99.5179 65.2618 99.6067 65.1741C101.753 62.2753 101.888 57.6404 99.7247 54.7135C99.6594 54.6126 99.5577 54.5407 99.4407 54.513C99.3237 54.4853 99.2006 54.5038 99.097 54.5647C98.9933 54.6255 98.9172 54.7241 98.8845 54.8397C98.8517 54.9554 98.8649 55.0792 98.9213 55.1854V55.1685Z"
      fill="#616E7C"
    />
    <path
      d="M88.8371 54.2585C88.2522 53.7099 87.5751 53.2687 86.8371 52.9551C86.7044 52.9232 86.5649 52.9349 86.4394 52.9885C86.3139 53.0421 86.2089 53.1347 86.1402 53.2526C86.0715 53.3706 86.0426 53.5075 86.0578 53.6432C86.073 53.7788 86.1316 53.9059 86.2247 54.0057C86.8578 54.5008 87.5727 54.881 88.3371 55.1293C88.4461 55.1545 88.5603 55.144 88.663 55.0995C88.7656 55.0549 88.8512 54.9785 88.9072 54.8817C88.9632 54.7848 88.9866 54.6725 88.9739 54.5613C88.9613 54.4502 88.9134 54.346 88.8371 54.2641V54.2585Z"
      fill="#616E7C"
    />
    <path
      d="M87.7024 58.9214C86.8022 59.2021 86.0352 59.8013 85.5451 60.6068C85.4648 60.7251 85.4327 60.8696 85.4553 61.0108C85.4779 61.152 85.5535 61.2793 85.6667 61.3667C85.7799 61.4541 85.9221 61.4951 86.0645 61.4812C86.2068 61.4673 86.3385 61.3997 86.4327 61.2921C86.9212 60.7388 87.4781 60.2499 88.09 59.8371C88.5731 59.5674 88.2473 58.7135 87.7024 58.9214V58.9214Z"
      fill="#616E7C"
    />
    <path
      d="M103.09 58.7641C103.567 58.6112 104.036 58.4331 104.494 58.2304C104.984 58.0573 105.453 57.8312 105.893 57.5562C106.011 57.4792 106.099 57.3632 106.14 57.2283C106.181 57.0935 106.173 56.9485 106.119 56.8187C106.064 56.6889 105.965 56.5824 105.84 56.518C105.714 56.4536 105.57 56.4353 105.433 56.4663C104.951 56.6023 104.486 56.7907 104.045 57.0281C103.575 57.244 103.117 57.4859 102.674 57.7529C102.583 57.8301 102.518 57.9345 102.49 58.0509C102.462 58.1674 102.472 58.2897 102.518 58.4001C102.565 58.5105 102.645 58.6032 102.748 58.6646C102.851 58.7261 102.971 58.753 103.09 58.7416V58.7641Z"
      fill="#616E7C"
    />
    <path
      d="M103.905 74.3146C104.022 71.3652 103.989 68.4158 103.966 65.4607C103.964 65.3769 103.942 65.2948 103.904 65.2203C103.866 65.1458 103.811 65.0809 103.744 65.0302C103.677 64.9796 103.6 64.9445 103.518 64.9277C103.436 64.9108 103.351 64.9125 103.27 64.9326C98.5744 66.0287 93.6739 65.8756 89.0562 64.4888C88.9639 64.465 88.8673 64.4624 88.7739 64.4813C88.6804 64.5003 88.5925 64.5402 88.5167 64.5981C88.441 64.656 88.3794 64.7304 88.3366 64.8156C88.2938 64.9008 88.2709 64.9946 88.2697 65.0899C88.603 68.191 88.9382 71.2903 89.2753 74.3877C89.2806 74.5282 89.3293 74.6637 89.4149 74.7754C89.5004 74.8871 89.6185 74.9695 89.7528 75.0113C94.0955 76.5169 99.3034 77.5506 103.534 75.1405C103.661 75.0426 103.748 74.9017 103.778 74.7444C103.808 74.587 103.78 74.4239 103.699 74.2858C103.617 74.1478 103.488 74.0443 103.336 73.9947C103.183 73.9452 103.018 73.9531 102.871 74.0169C99.7921 75.6742 96.2247 75.4214 92.9213 74.6236C92.309 74.4719 90.6742 74.2528 90.5449 74.1292C90.4157 74.0056 90.3989 72.854 90.3764 72.618C90.0918 70.1124 89.8034 67.6068 89.5112 65.1012L88.7247 65.7023C93.5545 67.1272 98.6778 67.232 103.562 66.0057L102.865 65.4719C102.944 68.427 102.994 71.3764 103.096 74.3259C103.096 74.4331 103.138 74.536 103.214 74.6119C103.29 74.6877 103.393 74.7304 103.5 74.7304C103.607 74.7304 103.71 74.6877 103.786 74.6119C103.862 74.536 103.905 74.4331 103.905 74.3259V74.3146Z"
      fill="#616E7C"
    />
    <path
      d="M118.511 52.3876C122.202 53.1685 125.096 50.3258 127.635 48.1123C128.809 47.0899 131.067 45.9494 129.989 48.7809C129.545 49.9326 128.354 50.8764 127.32 51.4607C124.556 53.0393 121.938 52.6123 119.118 51.6011C118.517 51.3876 118.253 52.2472 118.708 52.573C121 54.2022 124.09 54.0449 126.607 53.0337C128.612 52.2247 131.714 50.0112 131.511 47.4719C131.376 45.7865 129.624 45.2865 128.287 46.0225C126.949 46.7584 125.865 48.3202 124.596 49.2865C122.713 50.7247 120.82 51.2528 118.511 51.5899C118.101 51.6461 118.129 52.309 118.511 52.3876V52.3876Z"
      fill="#616E7C"
    />
    <path
      d="M118.399 51.1292C117.079 50.1124 115.927 49.2472 115.028 47.7865C114.494 46.9101 114.107 45.8877 113.275 45.2304C112.959 44.993 112.597 44.8242 112.212 44.7349C111.827 44.6456 111.428 44.6377 111.04 44.7117C110.651 44.7858 110.283 44.9401 109.958 45.1649C109.633 45.3896 109.358 45.6798 109.152 46.0169C108.197 47.6068 108.949 49.4888 110.32 50.5113C112.404 52.0955 115.596 52.6573 118.185 52.545C118.334 52.545 118.477 52.4858 118.583 52.3804C118.688 52.2751 118.747 52.1322 118.747 51.9832C118.747 51.8342 118.688 51.6913 118.583 51.5859C118.477 51.4806 118.334 51.4214 118.185 51.4214C116.045 51.309 113.809 51.0843 111.876 50.0674C111.011 49.6124 110.163 48.9438 109.994 47.9438C109.803 46.7641 110.708 45.545 112.006 45.8764C113.303 46.2079 113.927 48.309 114.719 49.3427C115.568 50.5224 116.738 51.4331 118.09 51.9663C118.652 52.1629 118.837 51.4045 118.455 51.1068L118.399 51.1292Z"
      fill="#616E7C"
    />
    <path
      d="M118.949 51.5899C118.64 50.2641 118.146 48.9775 118.247 47.5899C118.348 46.2023 119.275 44.1405 120.994 44.3146C123.365 44.5562 121.202 47.0899 120.607 47.9382C119.82 49.0618 118.989 50.1854 118.27 51.3371C117.86 52.0056 118.831 52.5169 119.292 51.9326C120.185 50.764 120.978 49.5056 121.792 48.2922C122.354 47.4719 123.146 46.573 123.326 45.573C123.567 44.1854 122.517 43.2247 121.202 43.1854C116.596 43.0337 116.905 49.1068 118.214 51.8989C118.41 52.3315 119.045 52.0169 118.949 51.5899Z"
      fill="#616E7C"
    />
    <path
      d="M118.039 46.2978C117.129 44.3877 117.084 42.5057 116.427 40.5674C116.135 39.7191 115.775 38.9495 114.882 38.618C113.303 38.0225 111.989 39.2472 111.601 40.691C110.989 42.9832 112.365 44.9607 113.674 46.7135C114.86 48.2978 116.169 50.4551 117.865 51.5169C118.242 51.7529 118.803 51.2529 118.506 50.8708C116.854 48.7079 114.837 46.7585 113.449 44.3877C112.854 43.3652 112.388 42.2023 112.719 41.0169C113.247 39.0955 114.871 39.6292 115.393 41.163C116.067 43.1236 116.039 44.9832 117.202 46.7809C117.269 46.8841 117.372 46.9577 117.492 46.9866C117.611 47.0154 117.737 46.9972 117.843 46.9358C117.949 46.8744 118.028 46.7744 118.062 46.6567C118.097 46.5389 118.084 46.4124 118.028 46.3034L118.039 46.2978Z"
      fill="#616E7C"
    />
    <path
      d="M123.062 46.2191C123.62 45.5591 124.042 44.7954 124.303 43.9719C124.489 43.2262 124.751 42.5015 125.084 41.809C125.876 40.4719 127.601 40.6854 127.803 42.5618C128.079 45.0618 125.185 48.5618 123.483 50.0786C123.073 50.4382 123.596 51.0505 124.045 50.809C125.921 49.809 126.949 47.5112 127.831 45.6741C128.494 44.2809 129.32 42.2697 128.275 40.8483C127.551 39.8652 126.376 39.4101 125.326 40.073C123.539 41.1966 123.461 44.1123 122.483 45.7921C122.281 46.1404 122.787 46.5674 123.045 46.2359L123.062 46.2191Z"
      fill="#616E7C"
    />
    <path
      d="M119.112 43.9046C118.559 42.9133 118.242 41.8079 118.185 40.6742C118.185 39.5506 118.691 37.5843 120.174 37.6293C121.371 37.6686 121.607 39.8765 121.685 40.7754C121.751 41.8153 121.67 42.8593 121.444 43.8765C121.417 44.0052 121.439 44.1396 121.508 44.2521C121.576 44.3645 121.685 44.4467 121.812 44.4819C121.938 44.517 122.074 44.5024 122.19 44.441C122.307 44.3797 122.395 44.2762 122.438 44.1518C123 42.1349 123.225 35.972 119.714 36.6068C116.202 37.2416 116.938 42.2529 118.298 44.3765C118.365 44.4752 118.467 44.5449 118.583 44.5715C118.699 44.5981 118.822 44.5796 118.925 44.5198C119.028 44.46 119.105 44.3632 119.14 44.2491C119.174 44.1349 119.165 44.0118 119.112 43.9046V43.9046Z"
      fill="#616E7C"
    />
    <path
      d="M125.562 53.809C126.692 57.203 127.15 60.7848 126.91 64.354C126.882 64.9158 127.831 65.0787 127.938 64.4944C128.624 60.7454 128.086 56.8753 126.405 53.4551C126.348 53.358 126.259 53.2847 126.153 53.249C126.046 53.2132 125.931 53.2174 125.827 53.2609C125.724 53.3043 125.64 53.3839 125.591 53.4848C125.542 53.5857 125.532 53.701 125.562 53.809V53.809Z"
      fill="#616E7C"
    />
    <path
      d="M125.798 53.1517C123.074 53.2478 120.349 53.0329 117.674 52.5112C115.803 52.1629 113.348 51.8483 111.792 50.6629C111.707 50.6136 111.61 50.5876 111.511 50.5876C111.413 50.5876 111.316 50.6136 111.23 50.6629C109.051 51.927 107.826 55.0281 106.871 57.2191C105.978 59.2697 105.478 60.5899 107.281 62.309C112.337 67.191 121.579 68.3877 127.657 64.9888C128.32 64.618 127.736 63.6742 127.096 63.9888C124.753 65.0768 122.201 65.6404 119.618 65.6404C117.035 65.6404 114.483 65.0768 112.14 63.9888C110.169 63.0955 106.899 61.5056 107.393 59.0225C107.68 57.5899 108.685 55.9832 109.393 54.7135C109.955 53.7416 110.758 51.9494 111.747 51.9045C112.736 51.8596 114.382 52.8539 115.371 53.0787C118.742 53.8596 122.376 54.4157 125.815 54.0169C126.376 53.9495 126.404 53.0899 125.815 53.118L125.798 53.1517Z"
      fill="#616E7C"
    />
    <path
      d="M133.27 87.0843C138.747 66.9551 140.966 45.8708 144.264 25.2865C144.318 25.081 144.289 24.8626 144.184 24.6779C144.079 24.4932 143.906 24.3568 143.702 24.2978C124.09 21.1405 104.376 17.7641 84.573 16C84.3953 15.9989 84.2223 16.0568 84.081 16.1646C83.9397 16.2723 83.8381 16.4239 83.7921 16.5955C80.2041 37.1311 76.6161 57.6686 73.0281 78.2079C72.9725 78.4149 73.0001 78.6355 73.1051 78.8224C73.2101 79.0093 73.3841 79.1477 73.5899 79.2079L125.084 86.618C127.444 86.9607 130.264 87.2978 132.388 87.6068C133.337 87.7416 133.747 86.2922 132.792 86.1517C115.629 83.6854 98.4719 81.1573 81.309 78.691L74.0056 77.6405L74.5674 78.6405C78.1404 58.1012 81.7247 37.5618 85.3202 17.0225L84.5393 17.618C104.202 19.3652 123.77 22.7191 143.236 25.854L142.674 24.8596C139.36 45.5506 136.348 66.2079 131.91 86.6967C131.885 86.7859 131.877 86.8794 131.888 86.9716C131.898 87.0638 131.927 87.153 131.972 87.2341C132.017 87.3152 132.078 87.3867 132.151 87.4443C132.223 87.502 132.307 87.5448 132.396 87.5703C132.485 87.5957 132.579 87.6033 132.671 87.5927C132.763 87.582 132.852 87.5533 132.933 87.5082C133.015 87.4631 133.086 87.4024 133.144 87.3297C133.201 87.257 133.244 87.1736 133.27 87.0843V87.0843Z"
      fill="#616E7C"
    />
    <path
      d="M89.9326 24.8989C105.288 26.7304 120.566 29.1386 135.764 32.1236L135.107 30.9663L126.652 73.9607L125.444 80.0506L126.624 79.3821C111.242 77.2472 95.9326 74.6068 80.5562 72.4832L81.2415 73.6798L89.6685 30.6405C90.0449 28.6967 90.5281 25.9607 90.8314 24.4045C91.0505 23.2809 89.2921 22.7697 89.0674 23.9214C86.2584 38.2641 83.4045 52.6012 80.5955 66.9495L79.3764 73.1629C79.3104 73.4124 79.3446 73.6778 79.4716 73.9024C79.5986 74.1271 79.8084 74.2931 80.0561 74.3652C95.4382 76.4944 110.753 79.1068 126.124 81.2304C126.369 81.2931 126.628 81.2586 126.848 81.1339C127.068 81.0093 127.231 80.8042 127.303 80.5618L135.73 37.5562L136.921 31.4663C136.984 31.2258 136.95 30.9704 136.827 30.7543C136.705 30.5382 136.503 30.3785 136.264 30.309C120.938 27.309 105.478 24.9158 89.9326 23.4326C88.9887 23.3427 89.0112 24.7866 89.9326 24.8989Z"
      fill="#616E7C"
    />
    <path
      d="M138.399 54.5337C137.786 60.1124 138.5 66.0674 138.635 71.6742C138.708 74.9607 138.843 78.2472 138.966 81.5337L140.371 80.4663C138.424 80.0626 136.447 79.8146 134.461 79.7247C134.213 79.7352 133.976 79.8327 133.793 80.0002C133.61 80.1676 133.491 80.3942 133.458 80.6402C133.426 80.8862 133.48 81.136 133.613 81.3456C133.746 81.5552 133.949 81.7114 134.185 81.7865C135.983 82.2472 137.944 82.3146 139.803 82.6068C139.968 82.6493 140.141 82.6539 140.307 82.6201C140.474 82.5863 140.631 82.5151 140.766 82.4117C140.902 82.3084 141.012 82.1756 141.088 82.0235C141.165 81.8714 141.206 81.7039 141.208 81.5337C141.079 78.1292 140.966 74.7247 140.803 71.3203C140.534 65.7472 140.803 59.8427 139.882 54.3371C139.73 53.4438 138.506 53.7753 138.421 54.5337H138.399Z"
      fill="#616E7C"
    />
  </svg>
);

export default LinePlantPhoto;
