import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export default function useQueryString() {
  const location = useLocation();
  try {
    return queryString.parse(location.search);
  } catch {
    return {};
  }
}
