import React from 'react';
import styled from 'styled-components';
import { GREY_700, GREY_900 } from '../../../theme';
import TextBlocks from './TextBlocks';

const PaddingBox = styled.div`
  padding: 4px 20px 4px 20px;
`;

const Text = styled.div`
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  color: ${GREY_900};
  border-left: 4px solid ${GREY_700};
  padding-left: 6px;
  white-space: pre-wrap;
`;

const EntryText = ({ children, isWithinLink = false }) => (
  <PaddingBox>
    <Text>
      <TextBlocks text={children} isWithinLink={isWithinLink} />
    </Text>
  </PaddingBox>
);

export default EntryText;
