import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import FlexScrollbar from '../../../components/FlexScrollbar';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import H1Divider from '../../../components/H1Divider';
import Spacer from '../../../components/helpers/Spacer';
import TextField from '../../../components/fields/TextField';
import fetchJSON from '../../../utils/core/fetchJSON';
import useRefHeight from '../../../utils/hooks/useRefHeight';
import {
  getIsAuthenticated,
  logout,
  refreshToken,
} from '../../../reducers/auth';

const EmailVerificationPage = () => {
  const [isFetchingConfirm, setConfirmState] = useState(false);
  const [isFetchingResend, setResendState] = useState(false);
  const [navHeight, navRef] = useRefHeight();
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { token } = useParams();

  const handleBack = () => {
    dispatch(logout()).then(() => history.push('/'));
  };

  const submit = ({ confirmation_code }) => {
    toast.dismiss();
    setConfirmState(true);
    return dispatch(
      fetchJSON('/api/v1/person/verify/confirm', {
        method: 'POST',
        body: {
          token: confirmation_code,
        },
      })
    )
      .then(() => {
        if (isAuthenticated) {
          return dispatch(refreshToken()).then(() => {
            history.push('/');
          });
        }
        history.push('/');
        setConfirmState(false);
        return null;
      })
      .catch((err) => {
        toast.error(err.error);
        setConfirmState(false);
      });
  };

  const handleResend = () => {
    toast.dismiss();
    setResendState(true);
    return dispatch(
      fetchJSON('/api/v1/person/me/verify/send', {
        method: 'POST',
      })
    )
      .then(() => {
        toast.success('A confirmation code has been sent to your email.', {
          autoClose: 4000,
        });
        setResendState(false);
      })
      .catch((err) => {
        toast.error(err.error);
        setResendState(false);
      });
  };

  return (
    <React.Fragment>
      <Helmet title="Email Verification" />
      <PanelLayoutContainer>
        <Form
          initialValues={{
            confirmation_code: token,
          }}
          onSubmit={submit}
          validate={(values) => {
            const errors = {};

            if (!values.confirmation_code) {
              errors.confirmation_code = 'Confirmation code is required.';
            }

            return errors;
          }}
          render={({ handleSubmit, invalid }) => (
            <PanelLayoutPage as="form" onSubmit={handleSubmit}>
              <PanelNavigation ref={navRef} onBackButtonClick={handleBack} />
              <FlexScrollbar
                refHeights={[navHeight]}
                desktopHeight={PanelLayoutPage.minHeight}
              >
                <PanelLayoutPage.Header>
                  <Spacer margin={10} />
                  <H1Divider>Email Verification</H1Divider>
                </PanelLayoutPage.Header>
                <PanelLayoutPage.Body>
                  <TextField
                    name="confirmation_code"
                    type="text"
                    hint={`
                    If you haven’t received an confirm code with a few minutes, please check your spam folder.
                    ${
                      isAuthenticated
                        ? 'If you haven\'t recieved an email, press the "Resend Confirmation Code" button below.'
                        : 'If you haven\'t recieved an email, please login and press the "Resend Confirmation Code" button.'
                    }`}
                  >
                    <strong>Confirmation Code</strong>
                  </TextField>
                </PanelLayoutPage.Body>

                <PanelLayoutPage.Footer>
                  <Spacer margin={25} />
                  <Button
                    type="submit"
                    disabled={isFetchingConfirm || invalid}
                    variant="primary"
                    size="block"
                  >
                    Verify Email Address
                  </Button>
                  <Spacer />
                  <Button
                    type="button"
                    disabled={!isAuthenticated || isFetchingResend}
                    variant="secondary"
                    size="block"
                    onClick={handleResend}
                  >
                    Resend Confirmation Code
                  </Button>
                </PanelLayoutPage.Footer>
              </FlexScrollbar>
            </PanelLayoutPage>
          )}
        />
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default EmailVerificationPage;
