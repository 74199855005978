import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import FlexScrollbar from '../../../components/FlexScrollbar';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import Divider from '../../../components/Divider';
import LinkButton from '../../../components/buttons/LinkButton';
import LogoWithTagline from './LogoWithTagline';
import Spacer from '../../../components/helpers/Spacer';
import TextField from '../../../components/fields/TextField';
import useRefHeight from '../../../utils/hooks/useRefHeight';
import { login, getAuthStatus } from '../../../reducers/auth';
import { fetchPerson, getPersonStatus } from '../../../reducers/person';

const LoginPage = () => {
  const { isFetching: isAuthFetching } = useSelector(getAuthStatus);
  const { isFetching: isPersonFetching } = useSelector(getPersonStatus);
  const [navHeight, navRef] = useRefHeight();
  const dispatch = useDispatch();
  const isFetching = isAuthFetching || isPersonFetching;

  const submit = ({ email, password }) => {
    toast.dismiss();
    return dispatch(login(email, password))
      .then(() => dispatch(fetchPerson))
      .catch((err) => {
        toast.error(err.error);
      });
  };

  return (
    <React.Fragment>
      <Helmet title="Login" />
      <PanelLayoutContainer>
        <Form
          onSubmit={submit}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Email is required.';
            }
            if (!values.password) {
              errors.password = 'Password is required.';
            }
            return errors;
          }}
          render={({ handleSubmit, pristine, invalid }) => (
            <PanelLayoutPage as="form" onSubmit={handleSubmit}>
              <PanelNavigation ref={navRef} />
              <FlexScrollbar
                refHeights={[navHeight]}
                desktopHeight={PanelLayoutPage.minHeight}
              >
                <PanelLayoutPage.Header>
                  <LogoWithTagline />
                  <Divider />
                </PanelLayoutPage.Header>

                <PanelLayoutPage.Body>
                  <TextField
                    name="email"
                    type="email"
                    placeholder="friendlyplant@tendship.com"
                  >
                    <strong>Email</strong>
                  </TextField>

                  <TextField
                    name="password"
                    type="password"
                    placeholder="********"
                    hint={
                      <div>
                        If you forgot your password, you can{' '}
                        <Link to="/forgot-password">reset your password</Link>
                      </div>
                    }
                  >
                    <strong>Password</strong>
                  </TextField>
                </PanelLayoutPage.Body>

                <PanelLayoutPage.Footer>
                  <Button
                    type="submit"
                    disabled={pristine || invalid || isFetching}
                    variant="primary"
                    size="block"
                  >
                    Login
                  </Button>
                  <Spacer />
                  <LinkButton
                    variant="secondary"
                    size="block"
                    to="/overview/appreciate-each-day"
                  >
                    Register
                  </LinkButton>
                </PanelLayoutPage.Footer>
              </FlexScrollbar>
            </PanelLayoutPage>
          )}
        />
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default LoginPage;
