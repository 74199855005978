import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TEAL_100, GREY_100, GREY_700, GREY_800 } from '../../../theme';
import getDateCountdown from '../../../utils/getDateCountdown';

const Box = styled(Link)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  min-width: 300px;
  width: 100%;
  height: 55px;

  padding: 5px;
  text-decoration: none;
  font-weight: 400;

  color: ${GREY_800};
  border-bottom: 1px solid ${GREY_100};
  &:hover {
    background: ${TEAL_100};
  }
`;

const InnerBox = styled.div`
  box-sizing: border-box;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 40px;
`;

const Details = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  margin-left: 20px;
  width: 100%;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 700;
  max-width: 35ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  color: ${GREY_700};
  font-style: italic;
`;

const SmallText = styled.span`
  font-size: 14px;
  text-align: center;
`;

const BigText = styled.span`
  font-size: 24px;
  text-align: center;
`;

const FriendSlat = ({ shortId, name, entryCount, reminderAt }) => {
  const { count, unit } = getDateCountdown(reminderAt && new Date(reminderAt));
  return (
    <Box to={`/planner/friend/${shortId}`}>
      <InnerBox>
        {reminderAt && <BigText>{count}</BigText>}
        {reminderAt && <SmallText>{unit}</SmallText>}
      </InnerBox>
      <Details>
        <Name>{name}</Name>
        <Description>
          Mentioned {entryCount} {entryCount === 1 ? 'time' : 'times'}
        </Description>
      </Details>
    </Box>
  );
};

export default FriendSlat;
