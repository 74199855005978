import React from 'react';
import { Helmet } from 'react-helmet-async';
import CautionIcon from '../../../components/svg/icons/CautionIcon';
import FlexScrollbar from '../../../components/FlexScrollbar';
import NoticeWithText from '../../../components/NoticeWithText';
import Button from '../../../components/buttons/Button';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const ErrorPage = () => {
  const [navHeight, navRef] = useRefHeight();
  return (
    <React.Fragment>
      <Helmet title="Error" />
      <PanelLayoutContainer>
        <PanelLayoutPage>
          <PanelNavigation ref={navRef} />
          <FlexScrollbar
            refHeights={[navHeight]}
            desktopHeight={PanelLayoutPage.minHeight}
          >
            <PanelLayoutPage.Header>
              <NoticeWithText
                image={<CautionIcon width={150} height={133} />}
                title="Something Went Wrong!"
              >
                An unexpected error has occured! Please refresh the page and try
                again.
              </NoticeWithText>
            </PanelLayoutPage.Header>
            <PanelLayoutPage.Body></PanelLayoutPage.Body>
            <PanelLayoutPage.Footer>
              <Button
                variant="primary"
                size="block"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh Page
              </Button>
            </PanelLayoutPage.Footer>
          </FlexScrollbar>
        </PanelLayoutPage>
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default ErrorPage;
