import { createSelector } from 'reselect';
import union from 'lodash.union';
import immutableSort from '../../utils/immutableSort';
import { getThreadIds, getVirtualThreadIds } from './selectors';

export function getVisibleThreadIds(threadIds, virtualIds, limit) {
  const activeThreadIds = immutableSort(threadIds, [], (a, b) => b - a);
  const ids = union(activeThreadIds.slice(0, limit), virtualIds);
  // Sort all threads from lowest (oldest) to highest (newest).
  ids.sort((a, b) => a - b);
  return ids;
}

export function getTimelineItems(ids) {
  return ids.reduce((accum, id, index) => {
    const nextId = ids[index + 1];
    const gapSize = nextId ? nextId - id - 1 : 0;

    accum.push({
      type: 'thread',
      id,
    });

    if (gapSize > 0 && gapSize <= 3) {
      accum.push({
        type: 'thread',
        id: id + 1,
      });
    }
    if (gapSize > 1 && gapSize <= 3) {
      accum.push({
        type: 'thread',
        id: id + 2,
      });
    }
    if (gapSize === 3) {
      accum.push({
        type: 'thread',
        id: id + 3,
      });
    }
    if (gapSize > 3) {
      accum.push({
        type: 'gap',
        id: nextId - 1,
        size: gapSize,
      });
    }
    return accum;
  }, []);
}

export const getTimeline = createSelector(
  getThreadIds,
  getVirtualThreadIds,
  (state) => state.thread.threads,
  (state) => state.thread.virtualThreads,
  (state) => state.thread.limit,
  (threadIds, virtualIds, threads, virtualThreads, limit) => {
    const ids = getVisibleThreadIds(threadIds, virtualIds, limit);
    const items = getTimelineItems(ids);
    return items.map((item) => {
      if (item.type === 'gap') {
        return item;
      }
      const thread = threads[item.id] || virtualThreads[item.id];
      return { ...item, ...thread };
    });
  }
);
