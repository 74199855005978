import React from 'react';
import { TEAL_900 } from '../../../theme';

const PlannerIcon = ({ width = 21, height = 24, color = TEAL_900 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8 16.8V1.2C20.8 0.45 20.3543 0 19.6114 0H4.45714C1.93143 0 0 1.95 0 4.5V19.5C0 22.05 1.93143 24 4.45714 24H19.6114C20.2057 24 20.8 23.55 20.8 22.8V22.05C20.8 21.75 20.6514 21.45 20.3543 21.15C20.2057 20.4 20.2057 18.3 20.3543 17.7C20.6514 17.55 20.8 17.25 20.8 16.8V16.8ZM11.1429 3C11.7305 3 12.305 3.17595 12.7937 3.50559C13.2823 3.83524 13.6632 4.30377 13.8881 4.85195C14.113 5.40013 14.1718 6.00333 14.0572 6.58527C13.9425 7.16721 13.6595 7.70176 13.244 8.12132C12.8284 8.54088 12.299 8.8266 11.7226 8.94236C11.1462 9.05811 10.5487 8.9987 10.0057 8.77164C9.46278 8.54458 8.99871 8.16006 8.6722 7.66671C8.3457 7.17336 8.17143 6.59334 8.17143 6C8.17143 5.20435 8.48449 4.44129 9.04174 3.87868C9.59899 3.31607 10.3548 3 11.1429 3V3ZM5.94286 13.2C5.94286 11.7089 7.33989 10.5 9.06286 10.5H9.295C9.88102 10.7475 10.5099 10.875 11.1452 10.875C11.7804 10.875 12.4093 10.7475 12.9954 10.5H13.2252C14.9481 10.5 16.3452 11.7089 16.3452 13.2V14.1C16.3452 14.5973 15.8809 15 15.3052 15H6.98286C6.40714 15 5.94286 14.5969 5.94286 14.1V13.2ZM17.68 21H4.45714C3.56571 21 2.97143 20.4 2.97143 19.5C2.97143 18.6 3.71429 18 4.45714 18H17.68V21Z"
      fill={color}
    />
  </svg>
);

export default PlannerIcon;
