import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Pip from './Pip';
import { TEAL_700, TEAL_800, GREY_200 } from '../../../theme';

const PaddingBox = styled.div`
  padding: 0 0 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PipRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const PipWrapper = styled.div`
  margin: 0 8px;
`;

const LinkPipWrapper = styled(Link)`
  margin: 0 8px;
  &:hover rect {
    fill: ${TEAL_800};
  }
`;

const Pips = ({ urls, activeUrl }) => (
  <PaddingBox>
    <PipRow>
      {urls.map((url) => {
        if (url === activeUrl) {
          return (
            <PipWrapper key={url}>
              <Pip color={TEAL_700} />
            </PipWrapper>
          );
        }
        return (
          <LinkPipWrapper key={url} to={url}>
            <Pip color={GREY_200} />
          </LinkPipWrapper>
        );
      })}
    </PipRow>
  </PaddingBox>
);

export default Pips;
