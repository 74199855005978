import styled from 'styled-components';
import {
  WHITE_000,
  GREY_200,
  GREY_300,
  GREY_400,
  GREY_800,
  BORDER_MD,
} from '../../../../theme';

export default styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 4px 6px;
  margin: 0;
  background: ${WHITE_000};
  border: 1px solid ${GREY_300};
  border-radius: ${BORDER_MD};
  color: ${GREY_800};
  font-size: 16px;
  line-height: 14px;
  height: 34px;
  &:disabled {
    background: ${GREY_200};
  }
  ::placeholder {
    color: ${GREY_400};
    font-style: italic;
    opacity: 1; /* Firefox */
  }
`;
