import React from 'react';
import md5 from 'md5';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import DropArea from '../../../components/fields/DropArea';
import FlexScrollbar from '../../../components/FlexScrollbar';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import Divider from '../../../components/Divider';
import Explainer from './Explainer';
import LinePlantNight from '../../../components/svg/illustrations/LinePlantNight';
import Spacer from '../../../components/helpers/Spacer';
import TextField from '../../../components/fields/TextField';
import { logout } from '../../../reducers/auth';
import { getPerson } from '../../../reducers/person';
import { setCryptSecret } from '../../../reducers/crypt';
import { isValidSecret, getKeyFromSecret } from '../../../utils/encryption';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const DecryptionPage = () => {
  const { crypt_id, crypt_key_md5 } = useSelector(getPerson);
  const [navHeight, navRef] = useRefHeight();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBack = () => {
    dispatch(logout()).then(() => history.push('/'));
  };

  const submit = ({ encryption_key }) => {
    toast.dismiss();
    return dispatch(setCryptSecret(encryption_key));
  };

  return (
    <React.Fragment>
      <Helmet title="Securing Your Journal" />
      <PanelLayoutContainer>
        <Form
          onSubmit={submit}
          validate={(values) => {
            const errors = {};

            if (!values.encryption_key) {
              errors.encryption_key = 'Encryption key is required.';
            }

            if (
              values.encryption_key &&
              !isValidSecret(values.encryption_key)
            ) {
              errors.encryption_key = 'Encryption key must be valid.';
            }

            const secret =
              values.encryption_key && getKeyFromSecret(values.encryption_key);
            if (secret && md5(secret) !== crypt_key_md5) {
              errors.encryption_key = 'Encryption key must be valid.';
            }

            return errors;
          }}
          render={({ handleSubmit, invalid }) => (
            <PanelLayoutPage as="form" onSubmit={handleSubmit}>
              <PanelNavigation ref={navRef} onBackButtonClick={handleBack} />
              <DropArea name="encryption_key">
                <FlexScrollbar
                  refHeights={[navHeight]}
                  desktopHeight={PanelLayoutPage.minHeight}
                >
                  <PanelLayoutPage.Header>
                    <Explainer
                      image={<LinePlantNight height={200} width={200} />}
                      title="Journal Decryption"
                    >
                      To finish logging in, please provide your encryption key.
                      This extra step ensures that only you can read your
                      journal.
                    </Explainer>
                  </PanelLayoutPage.Header>
                  <PanelLayoutPage.Body />

                  <PanelLayoutPage.Footer>
                    <Divider />
                    <TextField
                      name="encryption_key"
                      type="text"
                      placeholder="XXXXXX.Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y0Y"
                      hint={
                        <React.Fragment>
                          Your encryption key was created when you first
                          registered for tendship and was stored inside the{' '}
                          <strong>&apos;{crypt_id}.key.txt&apos;</strong> file.
                          Copy that value inside the file or drag the file into
                          this window.
                        </React.Fragment>
                      }
                    >
                      <strong>Encryption Key</strong>
                    </TextField>
                    <Spacer margin={25} />
                    <Button
                      type="submit"
                      disabled={invalid}
                      variant="primary"
                      size="block"
                    >
                      Decrypt Journal
                    </Button>
                  </PanelLayoutPage.Footer>
                </FlexScrollbar>
              </DropArea>
            </PanelLayoutPage>
          )}
        />
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default DecryptionPage;
