import React from 'react';

const LinePlantNoon = ({ width = 217, height = 200 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 217 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M75.2603 134.214C75.3151 134.718 71.6877 135.644 71.0301 135.644C68.0603 135.616 67.8466 135.6 64.9425 134.718C64.8712 134.718 63.9233 136.006 62.8219 136.438C59.7808 137.611 58.2082 137.112 54.8822 136.312C53.8775 135.88 52.913 135.359 52 134.756C49.1452 135.611 47.326 136.236 44.5534 135.852C40.4493 135.26 38.252 132.921 38.1479 132.734C34.8931 133.178 28 134.926 27.9945 135.584C27.9945 137.134 37.726 138.767 50.926 138.91C64.126 139.052 76.526 136.625 76.5425 135.074C76.5425 134.707 76.3233 134.455 75.2603 134.214Z"
      fill="#616E7C"
    />
    <path
      d="M112.416 153.879C110.871 153.266 102.91 152.701 98.4165 152.647C93.3284 152.525 88.2389 152.823 83.2 153.54C81.9946 153.693 87.7096 155.025 88.7836 155.112C96.137 155.715 106.866 155.222 107.485 155.184C108.767 155.096 113.633 154.367 112.416 153.879Z"
      fill="#616E7C"
    />
    <path
      opacity="0.15"
      d="M110.581 139.896C110.489 139.685 110.343 139.502 110.159 139.365C109.974 139.227 109.757 139.14 109.529 139.112C109.677 138.277 109.572 137.416 109.227 136.641C108.63 135.375 106.312 134.57 104.296 134.762C106.104 133.397 107.671 131.589 108.258 129.359C109.715 123.83 105.189 119.359 100.038 118.531C96.5425 117.984 94.9809 122.942 98.5425 123.956C101.748 124.866 101.88 127.562 99.0412 129.112C97.2001 130.12 94.8603 130.279 93.2165 131.638C92.2137 132.471 91.3809 133.885 91.7096 135.32C91.6548 135.397 90.1973 135.825 89.9343 137.2C89.2713 140.608 91.2274 143.731 94.3453 144.356C95.7239 144.575 97.1233 144.632 98.5151 144.526C97.611 146.077 97.7042 147.649 99.2932 148.685C100.504 149.474 102.647 148.488 103.403 146.948C107.436 146.197 112.723 144.312 110.581 139.896Z"
      fill="#7B8794"
    />
    <path
      d="M136.521 136.586C136.647 136.132 128.877 135.233 124.849 135.2C120.822 135.167 115.288 135.342 111.671 136.438C110.888 136.674 115.71 137.342 116.603 137.452C120.438 137.923 124.444 137.775 130.849 137.578C131.562 137.578 136.663 137.118 136.521 136.586Z"
      fill="#616E7C"
    />
    <path
      opacity="0.15"
      d="M135.441 122.077C135.085 121.504 134.604 121.02 134.035 120.658C133.466 120.297 132.823 120.068 132.153 119.989C132.29 119.677 132.397 119.352 132.471 119.019C132.611 118.42 132.625 117.799 132.513 117.195C132.4 116.59 132.164 116.016 131.819 115.507C132.658 115.001 133.275 114.196 133.545 113.255C133.818 112.239 133.702 111.158 133.22 110.223C132.737 109.289 131.923 108.568 130.937 108.203C129.096 107.507 128.592 107.162 126.658 106.904C125.165 106.828 123.669 106.935 122.203 107.222C123.478 107.011 124.709 106.591 125.847 105.978C127.66 105.09 127.567 101.096 125.332 100.186C121.419 98.5917 117.288 99.841 114.521 103.704C111.753 107.567 114.608 111.644 118.055 113.742C119.266 114.482 121.49 115.304 121.792 116.899C122.027 118.142 120.038 118.241 119.189 118.384C117.501 118.663 115.836 119.079 114.515 120.236C113.794 120.867 113.291 121.711 113.078 122.646C112.865 123.581 112.954 124.56 113.331 125.441C114.23 127.534 116.367 128.657 118.526 128.997C120.17 129.255 122.91 129.068 123.847 130.767C124.581 132.077 126.942 131.567 126.8 129.962C126.736 128.91 126.317 127.911 125.611 127.129L126.805 127.222C129.364 127.403 133.485 128.51 135.282 126.044C135.689 125.469 135.921 124.788 135.949 124.084C135.978 123.38 135.801 122.682 135.441 122.077Z"
      fill="#7B8794"
    />
    <path
      d="M181.03 126.269C171.299 124.937 162.948 125.145 153.173 126.329C152.077 126.46 151.71 127.808 152.921 128.066C163.973 130.389 174.323 129.6 182.784 128.329C183.496 128.219 183.923 126.663 181.03 126.269Z"
      fill="#616E7C"
    />
    <path
      d="M143.474 126.882C144.071 135.869 144.893 144.833 145.666 153.803C145.995 157.573 146.32 161.344 146.641 165.118C146.762 166.504 146.586 168.488 147.94 169.277C149.293 170.066 151.512 170.23 153.058 170.532C154.975 170.904 156.893 171.184 158.86 171.392C167.89 172.373 177.304 171.94 186.06 169.397C186.219 169.356 186.364 169.274 186.481 169.158C186.597 169.043 186.681 168.898 186.723 168.74C189.332 154.871 189.573 140.685 191.008 126.679C191.134 125.463 189.233 125.474 189.107 126.679C188.011 137.293 187.573 147.962 186.367 158.564C186.06 161.206 185.869 164.011 185.222 166.597C184.899 167.907 184.57 167.885 183.255 168.208C181.94 168.532 180.515 168.811 179.14 169.041C173.917 169.882 168.619 170.157 163.337 169.863C160.768 169.741 158.207 169.481 155.666 169.085C154.241 168.86 149.693 168.647 148.773 167.408C148.225 166.669 148.334 163.162 148.225 162.214C147.673 156.117 147.125 150.016 146.581 143.912C146.06 138.236 145.578 132.57 145.178 126.882C145.101 125.786 143.403 125.786 143.479 126.882H143.474Z"
      fill="#616E7C"
    />
    <path
      d="M190.088 125.704C183.041 122.855 174.296 122.455 166.789 122.416C159.282 122.378 151.403 123.079 144.29 125.786C144.107 125.842 143.946 125.955 143.832 126.109C143.718 126.263 143.656 126.449 143.656 126.641C143.656 126.833 143.718 127.019 143.832 127.173C143.946 127.327 144.107 127.44 144.29 127.496C150.937 129.781 158.394 130.279 165.364 130.455C173.616 130.663 182.274 130.208 190.12 127.441C191.183 127.063 190.723 125.353 189.649 125.731C181.934 128.471 173.479 128.893 165.364 128.685C158.531 128.51 151.277 128.033 144.762 125.786V127.496C151.742 124.838 159.37 124.148 166.789 124.181C174.647 124.219 182.055 125.682 189.77 126.855C189.917 126.879 190.068 126.848 190.193 126.768C190.319 126.688 190.41 126.564 190.45 126.42C190.489 126.276 190.474 126.123 190.407 125.99C190.34 125.856 190.227 125.753 190.088 125.699V125.704Z"
      fill="#616E7C"
    />
    <path
      d="M167.71 119.364C167.775 114.926 168.411 110.214 171.079 106.504C172.5 104.551 174.383 102.981 176.559 101.934C178.959 100.773 183.134 99.8082 184.866 102.57C188.044 107.627 176.553 108.751 173.83 109.693C171.107 110.636 168.46 112.159 166.778 114.559C166.033 115.655 167.786 116.641 168.526 115.584C172.296 110.208 179.994 111.485 184.992 108.126C188.449 105.797 187.731 101.403 184.044 99.7041C179.496 97.6219 173.868 100.86 170.893 104.225C167.359 108.197 165.792 114.301 166.647 119.501C166.756 120.137 167.742 119.962 167.742 119.353L167.71 119.364Z"
      fill="#616E7C"
    />
    <path
      d="M168.033 126.301C168.8 121.37 165.841 116.011 161.94 113.123C157.49 109.836 151.14 109.742 146.208 111.945C144.564 112.679 141.178 114.4 140.729 116.395C140.312 118.301 142.51 119.299 143.978 119.682C151.173 121.589 158.531 116.137 165.348 120.411C166.247 120.959 167.359 119.605 166.504 118.91C163.594 116.537 159.688 116.416 156.093 116.882C153.31 117.249 150.559 117.978 147.737 118.066C146.712 118.066 144.679 118.247 143.83 117.589C142.115 116.258 144.734 114.932 145.737 114.351C149.923 111.918 155.797 111.567 159.984 114.153C164.17 116.74 166.268 121.403 166.822 126.142C166.893 126.734 167.863 127.014 167.973 126.296L168.033 126.301Z"
      fill="#616E7C"
    />
    <path
      d="M168.11 118.97C169.304 112.608 166.975 106.312 162.63 101.627C158.285 96.9425 150.088 92.674 143.688 95.8904C138.104 98.6959 138.471 106.756 145.2 107.523C149.638 108.027 153.852 106.795 158.307 107.978C163.008 109.222 166.438 112.515 166.137 117.622C166.055 118.975 168.17 118.975 168.252 117.622C168.548 112.406 165.353 108.307 160.548 106.504C157.766 105.546 154.831 105.106 151.89 105.205C148.723 105.249 142.126 106.882 141.808 102.038C141.474 96.9425 148.493 96.4603 151.912 97.3808C156.502 98.7094 160.506 101.554 163.27 105.451C166.035 109.348 167.397 114.068 167.134 118.838C167.134 119.386 168.005 119.523 168.11 118.97Z"
      fill="#616E7C"
    />
    <path
      d="M166.986 107.945C167.501 103.063 167.468 98.3946 170.153 94.0713C171.386 92.0932 173.167 90.1151 175.31 89.0959C177.452 88.0768 181.605 87.8576 180.888 91.8357C180.652 93.1343 179.556 94.2521 178.608 95.0905C175.753 97.622 172.099 98.7288 169.529 101.633C167.545 103.885 165.781 106.614 165.408 109.649C165.266 110.833 167.129 110.822 167.271 109.649C167.611 106.849 169.671 104.066 171.605 102.137C173.671 100.077 176.444 99.0521 178.773 97.3261C181.605 95.2274 185.107 90.2357 180.992 87.4631C176.877 84.6904 171.973 88.5589 169.611 91.7809C166.203 96.4384 165.457 102.373 165.753 107.945C165.753 108.109 165.819 108.267 165.935 108.383C166.051 108.499 166.208 108.564 166.373 108.564C166.537 108.564 166.694 108.499 166.81 108.383C166.926 108.267 166.992 108.109 166.992 107.945H166.986Z"
      fill="#616E7C"
    />
    <path
      d="M166.488 105.677C166.559 102.701 166.219 99.7644 166.17 96.7945C166.126 94.0548 166.142 91.3151 165.183 88.7068C163.764 84.8712 159.704 81.2274 155.321 82.4603C151.216 83.611 151.14 87.9397 153.868 90.6027C155.907 92.5808 158.296 94.011 160.12 96.2411C162.219 98.8164 163.326 101.693 164.427 104.784C164.46 104.902 164.515 105.012 164.59 105.109C164.665 105.205 164.759 105.286 164.865 105.347C164.971 105.407 165.088 105.446 165.21 105.462C165.331 105.477 165.455 105.468 165.573 105.436C165.691 105.403 165.801 105.348 165.898 105.273C165.994 105.198 166.075 105.104 166.136 104.998C166.196 104.892 166.235 104.774 166.251 104.653C166.266 104.532 166.257 104.408 166.225 104.29C165.77 101.085 164.088 97.9781 162.137 95.4356C160.987 93.9392 159.669 92.5805 158.208 91.3863C156.789 90.2301 153.392 88.4 154.022 86.0493C155.03 82.2849 160.499 85.0137 161.901 86.5973C163.726 88.6356 164.274 91.326 164.488 93.9671C164.8 97.8685 165.068 101.786 165.386 105.693C165.386 105.838 165.444 105.978 165.547 106.081C165.649 106.183 165.789 106.241 165.934 106.241C166.079 106.241 166.219 106.183 166.322 106.081C166.424 105.978 166.482 105.838 166.482 105.693L166.488 105.677Z"
      fill="#616E7C"
    />
    <path
      d="M168.148 124.192C168.09 122.777 168.129 121.36 168.263 119.951C168.63 117.605 170.838 116.663 172.795 115.644C176.471 113.753 184.362 110.405 186.932 115.644C188.647 119.107 185.052 120.575 182.269 120.603C179.923 120.603 177.666 119.671 175.392 119.211C172.362 118.597 168.882 118.504 166.625 120.942C165.847 121.781 166.866 123.293 167.901 122.586C173.699 118.685 180.132 124.816 186.258 122.038C190.46 120.131 190.597 114.915 186.871 112.351C181.978 109.003 175.562 111.753 170.981 114.301C169.216 115.282 167.041 116.405 166.488 118.499C165.984 120.395 166.258 122.515 166.625 124.411C166.8 125.293 168.148 125.058 168.159 124.203L168.148 124.192Z"
      fill="#616E7C"
    />
    <path
      d="M86.1151 150.51C86.7781 145.469 87.4466 140.521 87.4302 135.419C87.4302 133.989 85.2001 133.984 85.2055 135.419C85.2055 140.521 84.537 145.469 83.874 150.51C83.6877 151.934 85.9288 151.923 86.1151 150.51Z"
      fill="#616E7C"
    />
    <path
      d="M17.567 135.753C17.6273 140.658 18.378 145.655 19.0136 150.515C19.443 154.582 20.1757 158.612 21.2054 162.57C23.03 168.948 27.1616 174.077 33.3205 176.773C40.6026 179.94 49.8246 180.236 57.6547 180.06C64.23 179.907 72.2136 179.205 77.3314 174.641C78.4273 173.671 76.8163 172.082 75.7314 173.041C70.7013 177.507 62.1917 177.786 55.8081 177.808C48.7396 177.836 40.5698 177.655 34.0163 174.773C19.1232 168.219 21.6437 148.762 19.0465 135.551C18.8821 134.712 17.5616 134.915 17.567 135.753Z"
      fill="#616E7C"
    />
    <path
      d="M26.8548 132.192C25.3972 132.192 16.2465 133.359 17.5397 136.055C18.2137 137.474 22.0055 138.044 23.2712 138.4C26.1572 139.207 29.0908 139.833 32.0548 140.274C46.1295 142.289 60.428 142.166 74.4657 139.907C78.2575 139.293 84.4329 138.723 87.1671 135.567C87.2618 135.467 87.3329 135.346 87.3754 135.215C87.4179 135.084 87.4308 134.945 87.4129 134.808C87.3951 134.671 87.3471 134.54 87.2723 134.424C87.1976 134.308 87.098 134.21 86.9808 134.137C84.4356 132.337 81.4525 131.256 78.3452 131.008C77.1835 130.915 77.2109 132.696 78.3452 132.811C81.1226 133.101 83.7792 134.099 86.0602 135.71L85.8794 134.279C83.315 137.255 76.915 137.726 73.3698 138.279C65.7238 139.42 57.9985 139.944 50.2685 139.847C42.6959 139.808 34.926 139.414 27.5287 137.786C26.0219 137.452 24.5205 137.069 23.0356 136.641C22.3671 136.449 19.1397 135.304 19.1068 135.501C19.0356 135.951 20.263 135.233 20.5698 135.123C21.2986 134.866 22.0383 134.652 22.7616 134.455C24.0789 134.053 25.4372 133.801 26.8109 133.704C26.9982 133.688 27.1727 133.602 27.2999 133.464C27.427 133.325 27.4975 133.144 27.4975 132.956C27.4975 132.768 27.427 132.587 27.2999 132.449C27.1727 132.31 26.9982 132.224 26.8109 132.208L26.8548 132.192Z"
      fill="#616E7C"
    />
    <path
      d="M47.9726 114.619C46.4055 108.455 41.7699 103.293 36.2904 100.225C36.1243 100.129 35.9359 100.078 35.7441 100.078C35.5523 100.077 35.3638 100.128 35.1974 100.223C35.031 100.318 34.8925 100.456 34.7959 100.621C34.6992 100.787 34.6478 100.975 34.6466 101.167C34.0274 110.482 35.1069 121.54 44.1261 126.816C45.4247 127.578 46.4713 125.677 45.2877 124.827C37.3534 119.14 36.3288 110.252 36.8165 101.167L35.1726 102.104C40.4313 104.908 44.5743 109.424 46.9151 114.904C46.9722 115.017 47.0665 115.107 47.1821 115.158C47.2977 115.209 47.4275 115.219 47.5494 115.185C47.6713 115.152 47.7779 115.077 47.851 114.974C47.9242 114.871 47.9594 114.745 47.9507 114.619H47.9726Z"
      fill="#616E7C"
    />
    <path
      d="M53.4246 134.416C51.9287 133.134 52.515 131.819 53.5123 130.296C54.2824 129.137 55.1204 128.024 56.0219 126.964C56.537 126.351 57.4794 124.827 58.3233 124.882C59.052 124.926 60.5479 126.619 61.1397 127.112C63.7698 129.304 65.6109 133.523 61.8356 135.721C59.0027 137.364 55.5506 136.016 53.1671 134.219L52.1315 135.994L52.1808 136.033C53.2767 136.8 54.2849 135.019 53.2164 134.257L53.1671 134.219C52.0712 133.447 51.0904 135.216 52.1315 135.994C55.3808 138.444 60.2191 139.682 63.6986 136.926C67.2931 134.077 66.4383 129.211 63.3643 126.312C62.1315 125.14 60.2356 123.096 58.4876 122.729C56.7397 122.362 55.5561 124.345 54.5643 125.512C52.1534 128.351 48.2301 132.636 51.9726 135.874C52.9917 136.729 54.4548 135.282 53.4246 134.416Z"
      fill="#616E7C"
    />
    <path
      d="M65.6657 129.863C68.1808 127.869 70.3999 125.841 73.2054 124.208C75.7299 122.847 78.1494 121.299 80.4438 119.578L78.652 119.107C79.7479 123.414 79.8246 129.107 75.4356 131.71C73.1123 133.101 69.0684 134.121 66.4821 133.085C65.0136 132.499 64.378 134.871 65.8301 135.452C69.2602 136.822 74.789 135.386 77.6054 133.145C81.8629 129.759 82.5369 123.326 80.7232 118.515C80.6685 118.337 80.5694 118.176 80.4353 118.048C80.3013 117.919 80.1366 117.826 79.9568 117.778C79.777 117.731 79.5881 117.729 79.4077 117.775C79.2274 117.82 79.0616 117.911 78.926 118.038C76.2903 120.318 73.0794 121.819 70.1862 123.732C68.0657 125.134 65.5232 126.767 64.7999 129.337C64.7883 129.442 64.8075 129.549 64.8553 129.644C64.903 129.738 64.9772 129.817 65.0689 129.871C65.1605 129.924 65.2657 129.95 65.3717 129.944C65.4776 129.939 65.5797 129.903 65.6657 129.841V129.863Z"
      fill="#616E7C"
    />
    <path
      d="M59.4959 119.551C60.5644 117.594 60.7507 115.781 62.4384 114.126C64.4712 112.137 67.0466 110.745 69.4302 109.222L68.1644 108.893C71.1343 113.726 69.7534 121.649 66.7562 126.197C65.9836 127.37 67.8521 128.46 68.6356 127.293C72.0329 122.126 73.5671 113.2 69.7589 107.956C69.6344 107.747 69.4326 107.594 69.197 107.532C68.9614 107.469 68.7107 107.502 68.4986 107.622C64.1425 110.619 58.5754 113.293 58.4932 119.277C58.5006 119.391 58.5436 119.5 58.6163 119.589C58.689 119.677 58.7876 119.741 58.8982 119.771C59.0089 119.8 59.1261 119.794 59.2333 119.754C59.3405 119.714 59.4324 119.641 59.4959 119.545V119.551Z"
      fill="#616E7C"
    />
    <path
      d="M71.6986 116.34C73.3863 113.052 75.348 109.836 78.274 107.469L76.7343 107.063C77.8302 112.186 77.7699 116.701 74.6904 121.129C74.1151 121.962 75.3863 122.91 76.0767 122.197C79.7863 118.362 80.4932 111.392 78.5096 106.575C78.4648 106.422 78.381 106.282 78.2664 106.17C78.1519 106.058 78.0103 105.977 77.8555 105.936C77.7007 105.895 77.5379 105.894 77.3828 105.934C77.2276 105.974 77.0854 106.053 76.9699 106.164C74.2302 109.101 71.8521 111.989 70.3945 115.781C70.1041 116.542 71.3425 117.058 71.7096 116.329L71.6986 116.34Z"
      fill="#616E7C"
    />
    <path
      d="M37.863 128.625C36.3898 126.547 34.233 125.053 31.7698 124.406C27.863 123.353 24.2739 124.488 20.3506 124.74C19.8027 124.778 19.1397 125.353 19.4027 125.989C22.4109 133.364 31.1287 137.671 38.2301 133.112C39.326 132.422 38.3068 130.69 37.2274 131.392C31.3096 135.227 23.8137 131.753 21.3041 125.479L20.3506 126.729C23.7534 126.532 27.0959 125.49 30.4986 126.181C33.0794 126.729 34.789 128.142 36.8931 129.605C37.4411 130 38.2794 129.205 37.863 128.636V128.625Z"
      fill="#616E7C"
    />
    <path
      d="M43.6164 106.427C42.7945 102.553 44.5315 99.6055 46.6137 96.5644L45.3479 96.8932C47.0465 97.8685 48.8164 98.685 50.2794 100.033C51.4372 101.195 52.3862 102.548 53.0849 104.033C53.5397 104.866 54.7287 104.269 54.5424 103.419C53.7698 99.348 49.5616 97.1233 46.3233 95.2987C46.1104 95.1804 45.8603 95.1486 45.6246 95.2098C45.389 95.271 45.1859 95.4206 45.0575 95.6274C43.7698 97.5507 42.4767 99.2494 41.9835 101.567C41.6328 103.211 41.4356 105.688 42.7178 106.975C42.7976 107.085 42.9152 107.161 43.0479 107.189C43.1807 107.217 43.3191 107.195 43.4363 107.127C43.5536 107.059 43.6414 106.949 43.6828 106.82C43.7241 106.691 43.7161 106.551 43.6602 106.427H43.6164Z"
      fill="#616E7C"
    />
    <path
      d="M36.5041 113.014C34.0328 109.321 29.2986 107.43 25.3972 105.655C25.2532 105.574 25.0908 105.532 24.9258 105.533C24.7608 105.534 24.5989 105.578 24.4559 105.66C24.3129 105.742 24.1937 105.86 24.1099 106.003C24.0261 106.145 23.9807 106.306 23.978 106.471C24.1205 110.647 24.0821 114.926 25.8301 118.811C27.3916 122.365 30.0902 125.298 33.5013 127.151C33.7061 127.249 33.9406 127.267 34.158 127.201C34.3754 127.135 34.5599 126.989 34.6746 126.793C34.7894 126.596 34.8259 126.364 34.777 126.142C34.7282 125.92 34.5974 125.724 34.4109 125.594C31.1859 123.701 28.6804 120.791 27.2876 117.321C25.9671 113.879 26.0164 110.104 25.8794 106.471L24.4547 107.288C28.241 108.992 32.5424 110.778 35.4465 113.836C35.9945 114.384 36.9479 113.666 36.5424 113.014H36.5041Z"
      fill="#616E7C"
    />
    <path
      d="M59.4521 105.485C60.6137 101.704 62.1151 98.3617 65.8247 96.4439L64.4987 95.8959C65.5946 98.4767 66.7617 101.134 66.8987 103.973C66.9973 106.093 66.5315 107.578 65.8028 109.523C65.3425 110.756 67.2603 111.227 67.748 110.071C69.8466 105.052 68.1863 100.011 66.148 95.211C66.0248 95.0074 65.8272 94.8598 65.5971 94.7994C65.3669 94.739 65.1223 94.7707 64.9151 94.8877C61.2987 96.8713 58.1041 101.041 58.1644 105.299C58.1673 105.456 58.2262 105.606 58.3305 105.724C58.4348 105.841 58.5776 105.917 58.7331 105.938C58.8886 105.959 59.0466 105.924 59.1784 105.839C59.3103 105.754 59.4073 105.624 59.4521 105.474V105.485Z"
      fill="#616E7C"
    />
    <path
      d="M48.4273 127.299C47.1287 121.584 47.5068 116.74 50.3342 111.534C52.5919 107.191 55.4287 103.175 58.7671 99.5946L57.0465 99.1453C58.151 104.183 58.6676 109.331 58.5862 114.488C58.5095 117.748 57.3424 120.559 56.1424 123.578C55.5945 124.948 57.8301 125.562 58.3342 124.186C59.589 120.953 60.7287 117.967 60.778 114.488C60.8168 109.139 60.2209 103.805 59.0027 98.5973C58.9528 98.4259 58.8595 98.2702 58.7318 98.1454C58.6041 98.0207 58.4462 97.9311 58.2737 97.8853C58.1011 97.8395 57.9196 97.8392 57.7468 97.8842C57.574 97.9293 57.4159 98.0183 57.2876 98.1425C50.0328 105.847 43.589 116.997 46.1808 127.907C46.526 129.348 48.7287 128.74 48.3725 127.299H48.4273Z"
      fill="#616E7C"
    />
    <path
      d="M52.1041 133.452C50.1534 127.452 44.3616 123.562 38.4767 122.049C38.2239 121.982 37.9547 122.016 37.7271 122.146C37.4996 122.275 37.3319 122.488 37.2602 122.74C36.3123 127.26 35.0027 132.603 39.6054 135.655C42.6739 137.682 48.4822 138.11 51.6 135.978C51.8146 135.844 51.9744 135.638 52.0508 135.397C52.1273 135.156 52.1152 134.896 52.0169 134.663C51.9185 134.43 51.7403 134.239 51.5142 134.126C51.2882 134.013 51.0291 133.983 50.7835 134.044C47.7972 134.553 44.9917 135.545 41.989 134.351C37.3643 132.515 37.863 127.189 39.1726 123.266L37.9506 123.956C43.589 125.512 47.7589 129.025 50.5534 134.099C50.658 134.272 50.8217 134.402 51.0143 134.465C51.207 134.528 51.4159 134.519 51.6028 134.441C51.7896 134.363 51.942 134.219 52.0321 134.038C52.1221 133.856 52.1438 133.648 52.0931 133.452H52.1041Z"
      fill="#616E7C"
    />
    <path
      d="M107.233 135.704C106.641 140.427 107.069 147.085 109.567 151.244C109.607 151.312 109.659 151.371 109.722 151.419C109.785 151.467 109.856 151.502 109.932 151.522C110.008 151.542 110.087 151.547 110.165 151.536C110.243 151.526 110.318 151.5 110.386 151.46C110.454 151.421 110.514 151.368 110.562 151.306C110.609 151.243 110.644 151.172 110.664 151.095C110.684 151.019 110.689 150.94 110.679 150.862C110.668 150.784 110.642 150.709 110.603 150.641C108.318 145.852 108.614 140.833 108.055 135.704C108.055 135.595 108.012 135.491 107.934 135.414C107.857 135.336 107.753 135.293 107.644 135.293C107.535 135.293 107.43 135.336 107.353 135.414C107.276 135.491 107.233 135.595 107.233 135.704Z"
      fill="#616E7C"
    />
    <path
      d="M140.493 136.126C140.225 143.573 139.299 153.222 131.781 157.052C130.921 157.49 131.682 158.794 132.542 158.351C140.093 154.515 142.307 143.83 141.507 136.126C141.494 136.001 141.435 135.885 141.341 135.8C141.248 135.716 141.126 135.669 141 135.669C140.874 135.669 140.752 135.716 140.659 135.8C140.565 135.885 140.506 136.001 140.493 136.126Z"
      fill="#616E7C"
    />
    <path
      d="M116.8 158.17C123.129 159.551 131.079 159.304 137.222 157.173C137.359 157.119 137.471 157.017 137.537 156.886C137.603 156.754 137.617 156.603 137.577 156.461C137.538 156.32 137.447 156.198 137.322 156.12C137.198 156.042 137.049 156.013 136.904 156.038C133.486 156.948 129.978 157.475 126.444 157.611C123.266 157.682 120.077 157.205 116.91 157.353C116.466 157.353 116.323 158.066 116.8 158.17Z"
      fill="#616E7C"
    />
    <path
      d="M117.074 160.652C122.286 161.238 127.543 161.304 132.767 160.849C135.288 160.63 137.781 159.6 137.633 156.668C137.606 156.049 136.586 155.825 136.482 156.515C135.874 160.504 130.57 159.77 127.469 159.885C124 160.005 120.537 159.846 117.058 159.819C116.51 159.819 116.51 160.597 117.058 160.652H117.074Z"
      fill="#616E7C"
    />
    <path
      d="M144.805 147.397C143.014 148.564 140.778 148.888 138.701 149.183C137.918 149.299 138.099 150.63 138.888 150.57C141.112 150.405 143.616 149.819 145.463 148.553C146.175 148.077 145.518 146.91 144.784 147.397H144.805Z"
      fill="#616E7C"
    />
    <path
      d="M141.644 140.822C142.099 139.923 143.222 138.63 144.351 139.299C145.079 139.732 145.753 138.532 145.008 138.203C143.156 137.326 141.255 138.614 140.663 140.433C140.651 140.552 140.677 140.671 140.739 140.774C140.801 140.876 140.895 140.955 141.006 140.999C141.117 141.043 141.24 141.05 141.355 141.018C141.47 140.986 141.571 140.917 141.644 140.822Z"
      fill="#616E7C"
    />
    <path
      d="M144.34 144.849C143.129 145.808 141.205 146.345 139.704 146.592C139.008 146.707 139.194 147.786 139.868 147.792C141.837 147.7 143.729 146.999 145.282 145.786C145.951 145.238 145.003 144.323 144.34 144.849Z"
      fill="#616E7C"
    />
    <path
      d="M141.342 143.507C141.967 142.855 143.096 141.715 144.082 141.907C144.882 142.06 145.222 140.811 144.416 140.679C142.838 140.405 141.496 141.605 140.548 142.734C140.104 143.282 140.827 144.027 141.326 143.507H141.342Z"
      fill="#616E7C"
    />
    <path
      d="M108.027 136.142C113.37 134.164 119.452 133.951 125.09 134.016C129.912 134.066 136.247 133.956 140.504 136.597L140.663 135.381C136.51 136.805 131.474 136.712 127.14 136.827C120.767 136.997 114.186 136.827 108 135.151C107.829 135.119 107.652 135.153 107.505 135.246C107.357 135.339 107.25 135.483 107.204 135.651C107.158 135.819 107.176 135.998 107.256 136.153C107.335 136.308 107.47 136.427 107.633 136.488C113.94 138.175 120.652 138.356 127.14 138.175C131.578 138.055 136.756 138.131 141.019 136.663C141.143 136.625 141.253 136.552 141.336 136.454C141.42 136.355 141.473 136.234 141.49 136.106C141.507 135.978 141.486 135.847 141.431 135.73C141.376 135.613 141.288 135.515 141.178 135.446C136.904 132.811 130.625 132.8 125.797 132.707C119.874 132.603 113.299 132.8 107.715 135.063C107.068 135.326 107.331 136.378 108.005 136.131L108.027 136.142Z"
      fill="#616E7C"
    />
    <path
      d="M77.6494 153.057C77.222 157.945 78.6302 163.715 80.4274 168.225C81.885 171.868 83.9672 176.099 87.8028 177.693C88.5809 178.016 89.074 176.751 88.3507 176.345C80.1699 171.66 80.4329 160.888 78.4877 152.942C78.3617 152.444 77.6822 152.597 77.6439 153.057H77.6494Z"
      fill="#616E7C"
    />
    <path
      d="M116.91 153.715C116.11 158.027 116.148 162.312 114.652 166.515C113.195 170.614 110.91 174.575 106.795 176.378C106.022 176.718 106.592 177.847 107.343 177.682C111.441 176.778 113.918 172.099 115.381 168.564C117.14 164.285 118.581 158.356 117.759 153.721C117.731 153.629 117.675 153.55 117.599 153.493C117.522 153.436 117.429 153.406 117.334 153.406C117.239 153.406 117.146 153.436 117.07 153.493C116.994 153.55 116.937 153.629 116.91 153.721V153.715Z"
      fill="#616E7C"
    />
    <path
      d="M82.3946 175.929C91.7097 180.044 103.025 180.099 112.477 176.356C113.381 175.995 113.025 174.553 112.06 174.855C107.238 176.329 102.575 177.507 97.485 177.479C92.3946 177.452 87.7097 176.148 82.8055 174.953C82.2576 174.822 81.8192 175.677 82.3946 175.929Z"
      fill="#616E7C"
    />
    <path
      d="M82.1698 175.403C81.6219 178.082 82.778 179.523 85.3369 180.055C90.0952 181.097 94.9601 181.571 99.8301 181.468C102.746 181.358 105.65 181.025 108.515 180.471C111.496 179.923 112.701 178.663 112.997 175.54C113.079 174.707 111.682 174.515 111.523 175.342C111.233 176.888 110.86 178.137 109.282 178.674C108.331 178.945 107.357 179.128 106.373 179.222C104.263 179.534 102.115 179.808 99.9835 179.923C96.822 180.032 93.6569 179.902 90.515 179.534C88.7999 179.359 86.8657 179.315 85.2109 178.816C83.1671 178.203 83.315 177.173 82.7287 175.397C82.641 175.151 82.2301 175.101 82.1808 175.397L82.1698 175.403Z"
      fill="#616E7C"
    />
    <path
      d="M78.2082 157.321C73.5671 156.055 68.8931 158.318 70.0164 163.682C70.9643 168.153 76.7725 169.901 80.8383 169.819C81.0447 169.819 81.2426 169.737 81.3885 169.591C81.5344 169.445 81.6164 169.247 81.6164 169.041C81.6164 168.835 81.5344 168.637 81.3885 168.491C81.2426 168.345 81.0447 168.263 80.8383 168.263C78.252 168.181 73.463 167.31 72.0712 164.849C71.7046 164.203 71.4733 163.489 71.3913 162.75C71.3094 162.012 71.3785 161.264 71.5945 160.553C72.6191 157.907 75.8082 158.236 78.0438 158.466C78.7068 158.532 78.8164 157.485 78.1972 157.321H78.2082Z"
      fill="#616E7C"
    />
    <path
      d="M79.0027 160.455C77.6493 159.808 75.989 159.112 74.4876 159.649C73.8333 159.883 73.2922 160.356 72.9737 160.974C72.6553 161.591 72.5834 162.307 72.7725 162.975C73.5835 165.995 77.6766 167.386 80.4767 167.069C80.6611 167.053 80.833 166.968 80.9581 166.832C81.0833 166.695 81.1528 166.517 81.1528 166.332C81.1528 166.146 81.0833 165.968 80.9581 165.831C80.833 165.695 80.6611 165.61 80.4767 165.595C78.7671 165.507 76.4986 165.353 75.178 164.099C74.241 163.211 73.4082 161.359 75.2054 160.871C76.2027 160.597 77.6438 161.101 78.5917 161.419C79.1397 161.616 79.5506 160.707 79.0027 160.449V160.455Z"
      fill="#616E7C"
    />
    <path
      d="M117.282 153.112C111.255 149.529 103.134 149.397 96.3507 149.447C92.6968 149.451 89.0477 149.711 85.4301 150.225C82.9589 150.586 80.2246 150.986 78.0438 152.29C77.9131 152.372 77.8085 152.489 77.7425 152.629C77.6765 152.768 77.6519 152.923 77.6715 153.076C77.6911 153.229 77.7541 153.373 77.8532 153.491C77.9522 153.609 78.083 153.696 78.2301 153.742C84.1698 156.027 90.8329 156.553 97.1561 156.663C103.731 156.784 110.915 156.553 117.266 154.586C117.366 154.559 117.459 154.511 117.541 154.448C117.623 154.384 117.691 154.304 117.743 154.214C117.794 154.124 117.826 154.024 117.839 153.921C117.852 153.818 117.844 153.714 117.816 153.614C117.789 153.514 117.742 153.42 117.678 153.338C117.614 153.256 117.534 153.188 117.444 153.137C117.354 153.086 117.254 153.053 117.151 153.04C117.048 153.028 116.944 153.035 116.844 153.063C110.381 154.491 103.774 155.164 97.1561 155.068C90.9534 154.964 84.4986 154.444 78.652 152.236L78.8438 153.682C80.8109 152.488 83.3315 152.121 85.5671 151.77C89.1377 151.241 92.7411 150.962 96.3507 150.937C102.964 150.855 110.74 150.937 116.773 154.005C116.83 154.039 116.893 154.061 116.959 154.07C117.024 154.079 117.091 154.075 117.155 154.058C117.219 154.041 117.28 154.012 117.332 153.972C117.385 153.932 117.43 153.882 117.463 153.825C117.496 153.767 117.518 153.704 117.527 153.639C117.536 153.573 117.532 153.506 117.516 153.442C117.499 153.378 117.47 153.318 117.429 153.265C117.389 153.212 117.339 153.168 117.282 153.134V153.112Z"
      fill="#616E7C"
    />
    <path
      d="M0.0930568 167.447C-0.0165832 170.667 0.137355 173.89 0.553331 177.085C0.651961 178 2.07662 178.033 2.01086 177.085C1.78621 173.863 1.80813 170.471 1.2547 167.288C1.13415 166.597 0.15881 166.816 0.0930568 167.447Z"
      fill="#616E7C"
    />
    <path
      d="M130.137 198.959C145.03 198.307 160.055 198.077 174.784 195.556C182.046 194.282 189.193 192.418 196.153 189.984C202.641 187.742 209.633 185.052 214.553 180.077C215.337 179.288 214.115 178.066 213.337 178.855C208.11 184.131 200.378 186.849 193.468 189.09C186.987 191.235 180.355 192.895 173.627 194.055C159.277 196.422 144.663 196.663 130.164 197.31C129.107 197.359 129.101 199.008 130.164 198.953L130.137 198.959Z"
      fill="#616E7C"
    />
    <path
      d="M74.9425 197.934C79.8411 198.219 84.7397 198.515 89.6384 198.773C90.6904 198.827 90.6849 197.189 89.6384 197.129C84.7397 196.882 79.8411 196.581 74.9425 196.334C73.9233 196.279 73.9233 197.863 74.9425 197.923V197.934Z"
      fill="#616E7C"
    />
    <path
      d="M0.821919 177.638C3.87945 181.474 8.4548 183.879 12.8767 185.858C18.4935 188.276 24.2969 190.234 30.2301 191.715C40.3397 194.367 50.8329 196.471 61.2767 197.233C62.2849 197.31 62.2849 195.731 61.2767 195.66C51.189 194.964 41.063 192.97 31.2822 190.482C25.3305 189.051 19.5035 187.143 13.8575 184.778C9.4411 182.86 4.89315 180.493 1.62192 176.877C1.13425 176.329 0.40548 177.118 0.838357 177.66L0.821919 177.638Z"
      fill="#616E7C"
    />
    <path
      d="M213.704 168.767C213.759 172.307 213.381 175.77 213.277 179.293C213.249 180.142 214.663 180.389 214.795 179.501C215.31 175.947 215.402 172.343 215.069 168.767C214.997 167.918 213.704 167.885 213.721 168.767H213.704Z"
      fill="#616E7C"
    />
    <path
      d="M88.3289 146.252C94.7343 145.704 101.123 145.414 107.551 145.359C107.719 145.359 107.881 145.292 108 145.173C108.119 145.054 108.186 144.892 108.186 144.723C108.186 144.555 108.119 144.393 108 144.274C107.881 144.155 107.719 144.088 107.551 144.088C101.123 144.148 94.7343 144.427 88.3289 144.997C87.5289 145.069 87.5234 146.323 88.3289 146.252Z"
      fill="#616E7C"
    />
    <path
      d="M15.2494 156.258C10.1645 158.033 -1.13412 161.189 0.213821 168.592C1.38642 175.003 9.92341 177.693 15.1727 179.6C35.1946 186.871 57.0631 188.915 78.1864 190.208C90.6851 190.97 103.211 191.189 115.732 191.151C127.929 191.112 140.225 191.216 152.384 190.186C162.893 189.293 173.329 187.676 183.617 185.348C191.507 183.562 199.507 181.468 206.893 178.142C209.151 177.129 211.743 175.951 213.43 174.082C215.595 171.666 215.381 167.447 214.159 164.652C212.937 161.858 210.515 160.323 208.006 158.811C203.667 156.207 198.837 154.528 193.819 153.879C192.723 153.732 192.723 155.485 193.819 155.633C198.339 156.232 202.696 157.722 206.636 160.016C208.789 161.266 211.222 162.63 212.384 164.948C215.858 171.808 210.307 174.86 205.069 177.09C189.534 183.715 171.841 186.647 155.145 188.307C144.652 189.348 134.093 189.403 123.556 189.512C112.532 189.649 101.507 189.627 90.4933 189.26C70.1207 188.581 49.3152 187.156 29.4577 182.329C21.4138 180.373 12.3618 178.214 5.41382 173.507C1.93437 171.151 -0.208096 166.795 3.4467 163.419C6.69601 160.427 11.5344 158.915 15.5946 157.479C16.3508 157.211 16.0275 156 15.2604 156.269L15.2494 156.258Z"
      fill="#616E7C"
    />
    <path
      d="M1.6602 150.8C6.2465 151.348 11.1122 150.8 15.7259 150.74C15.909 150.724 16.0795 150.64 16.2037 150.505C16.3279 150.369 16.3968 150.192 16.3968 150.008C16.3968 149.824 16.3279 149.647 16.2037 149.512C16.0795 149.377 15.909 149.293 15.7259 149.277C11.0629 149.359 6.14239 149.107 1.52321 149.781C0.898552 149.874 1.13417 150.74 1.6602 150.8Z"
      fill="#616E7C"
    />
    <path
      d="M193.989 150.444C200.668 151.129 207.836 150.773 214.537 150.499C214.697 150.499 214.85 150.435 214.963 150.322C215.076 150.209 215.14 150.056 215.14 149.896C215.14 149.736 215.076 149.583 214.963 149.47C214.85 149.357 214.697 149.293 214.537 149.293C207.786 149.238 200.586 148.789 193.89 149.699C193.43 149.759 193.6 150.405 193.989 150.444Z"
      fill="#616E7C"
    />
    <path
      d="M203.764 18.4547C203.523 40.2575 204.258 62.1041 204.597 83.9013C204.937 105.699 205.003 127.545 205.6 149.348C205.616 149.541 205.704 149.721 205.847 149.852C205.99 149.983 206.176 150.055 206.37 150.055C206.564 150.055 206.75 149.983 206.893 149.852C207.035 149.721 207.123 149.541 207.14 149.348C207.025 127.534 206.395 105.71 206.044 83.9013C205.693 62.0931 205.721 40.2356 204.767 18.4547C204.767 18.3225 204.715 18.1957 204.621 18.1022C204.528 18.0086 204.401 17.9561 204.268 17.9561C204.136 17.9561 204.009 18.0086 203.916 18.1022C203.822 18.1957 203.77 18.3225 203.77 18.4547H203.764Z"
      fill="#616E7C"
    />
    <path
      d="M6.10425 16.3342C6.1426 38.6027 6.70151 60.8822 7.20014 83.1452C7.43758 94.1041 7.67503 105.063 7.91247 116.022C8.14809 127.321 8.05493 138.669 8.63028 149.951C8.64675 150.148 8.73673 150.332 8.88237 150.466C9.02801 150.6 9.21868 150.674 9.41658 150.674C9.61447 150.674 9.80514 150.6 9.95078 150.466C10.0964 150.332 10.1864 150.148 10.2029 149.951C10.3563 138.658 9.7974 127.315 9.55082 116.022C9.31338 105.063 9.07229 94.1041 8.82754 83.1452C8.32343 60.8767 7.90151 38.5973 7.10151 16.3342C7.08869 16.2131 7.03149 16.101 6.94094 16.0196C6.8504 15.9381 6.7329 15.893 6.6111 15.893C6.4893 15.893 6.3718 15.9381 6.28125 16.0196C6.1907 16.101 6.13351 16.2131 6.12069 16.3342H6.10425Z"
      fill="#616E7C"
    />
    <path
      d="M203.014 112.816C199.616 117.112 196.027 121.227 192.493 125.419C192.214 125.748 192.679 126.23 192.975 125.896C196.647 121.814 200.099 117.523 203.441 113.162C203.627 112.915 203.205 112.586 203.014 112.827V112.816Z"
      fill="#616E7C"
    />
    <path
      d="M61.0303 25.5233C47.4248 41.8739 33.7262 58.1808 20.3563 74.7287C20.1262 75.0137 20.5207 75.4246 20.7618 75.1342C34.4933 58.8822 47.918 42.3507 61.4084 25.9013C61.6275 25.6383 61.2549 25.2548 61.0303 25.5233Z"
      fill="#616E7C"
    />
    <path
      d="M53.4246 20.137C42.2903 32.3617 31.4136 44.9754 20.8273 57.6767C20.5479 58.011 21.0136 58.4877 21.3095 58.1589C32.3561 45.8521 43.2712 33.2603 53.7643 20.4767C53.9616 20.2411 53.6383 19.9288 53.4246 20.137Z"
      fill="#616E7C"
    />
    <path
      d="M214.197 3.83559C208.652 13.9781 198.028 21.8082 186.049 20.8219C174.833 19.9013 164.258 11.9945 159.595 1.88491C159.288 1.22189 158.34 1.4849 158.148 2.07121C154.428 13.3698 143.469 21.3863 131.578 21.7315C118.932 22.1041 108.06 13.1013 103.178 1.92326C102.882 1.25477 101.781 1.46299 101.633 2.126C99.3426 12.1534 88.5755 18.0822 78.8988 18.6301C67.3043 19.3424 57.4522 11.0959 52.7508 1.04107C52.6619 0.897936 52.5336 0.783474 52.3812 0.711389C52.2289 0.639304 52.059 0.612648 51.8919 0.634618C51.7248 0.656588 51.5676 0.726252 51.4391 0.835265C51.3106 0.944279 51.2162 1.08802 51.1673 1.24929C48.6522 11.3096 40.4659 18.3561 30.3892 20.3178C18.9481 22.5479 8.47139 16.2246 2.41112 6.74518C2.36039 6.65883 2.29314 6.58332 2.21323 6.52296C2.13332 6.46259 2.0423 6.41856 1.94537 6.39338C1.84844 6.36819 1.7475 6.36234 1.64831 6.37617C1.54912 6.38999 1.45363 6.42322 1.36728 6.47395C1.28093 6.52468 1.20542 6.59192 1.14506 6.67183C1.08469 6.75174 1.04066 6.84276 1.01548 6.93969C0.990292 7.03662 0.984443 7.13756 0.998267 7.23675C1.01209 7.33594 1.04532 7.43143 1.09605 7.51778C7.05769 17.6329 18.3508 24.137 30.1974 22.0931C41.118 20.2082 50.1317 12.5644 52.8385 1.70956L51.2549 1.91778C56.307 12.7013 67.1454 21.3698 79.5508 20.2904C89.7481 19.4082 100.849 13.1671 103.26 2.56436L101.715 2.7671C106.926 14.6739 118.833 24.137 132.28 23.3589C144.674 22.6575 155.841 14.2959 159.677 2.48217L158.225 2.67395C163.156 13.2602 174.323 21.3863 186.049 22.2904C198.471 23.2548 209.578 14.9808 215.244 4.42737C215.611 3.74244 214.57 3.12874 214.197 3.81915V3.83559Z"
      fill="#616E7C"
    />
  </svg>
);

export default LinePlantNoon;
