import { LOGOUT } from '../auth';

export const READY = 'ready/READY';

const initialState = false;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case READY:
      return true;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function ready() {
  return { type: READY };
}

export const getIsReady = (state) => state.ready;
