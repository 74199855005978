import styled from 'styled-components';
import { TEAL_100 } from '../../../theme';

export default styled.div`
  text-decoration: none;
  font-weight: 400;
  padding: 5px 0;
  &:hover {
    background-color: ${TEAL_100};
  }
`;
