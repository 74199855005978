import { LOGOUT } from '../auth';
import fetchJSON from '../../utils/core/fetchJSON';

export const SINGLE_REQUEST = 'person/SINGLE_REQUEST';
export const SINGLE_SUCCESS = 'person/SINGLE_SUCCESS';
export const SINGLE_FAILURE = 'person/SINGLE_FAILURE';
export const UPDATE_REQUEST = 'person/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'person/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'person/UPDATE_FAILURE';

const initialState = {
  status: {
    ok: false,
    isFetching: false,
    error: null,
  },
  data: {
    id: null,
    email: null,
    name: null,
    crypt_id: null,
    crypt_key_md5: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SUCCESS:
    case SINGLE_SUCCESS: {
      const { id, email, name, crypt_id, crypt_key_md5 } = action.payload.data;
      return {
        status: {
          ok: true,
          isFetching: false,
          error: null,
        },
        data: {
          id,
          email,
          name,
          crypt_id,
          crypt_key_md5,
        },
      };
    }
    case SINGLE_REQUEST: {
      return {
        ...initialState,
        status: {
          ok: false,
          isFetching: true,
          error: null,
        },
      };
    }
    case SINGLE_FAILURE: {
      return {
        ...initialState,
        status: {
          ok: false,
          isFetching: false,
          error: action.error,
        },
      };
    }
    case UPDATE_REQUEST: {
      return {
        ...state,
        status: {
          ok: false,
          isFetching: true,
          error: null,
        },
      };
    }
    case UPDATE_FAILURE: {
      return {
        ...state,
        status: {
          ok: false,
          isFetching: false,
          error: action.error,
        },
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function fetchPerson() {
  return (dispatch) => {
    dispatch({ type: SINGLE_REQUEST });
    return dispatch(
      fetchJSON('/api/v1/person/me', {
        method: 'GET',
      })
    )
      .then(({ data }) =>
        dispatch({
          type: SINGLE_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: SINGLE_FAILURE, error: err.error });
        throw err;
      });
  };
}

export function setCryptId(crypt_id, crypt_key_md5) {
  return (dispatch) => {
    dispatch({ type: UPDATE_REQUEST });
    return dispatch(
      fetchJSON('/api/v1/person/me/crypt', {
        method: 'POST',
        body: { crypt_id, crypt_key_md5 },
      })
    )
      .then(({ data }) =>
        dispatch({
          type: SINGLE_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: UPDATE_FAILURE, error: err.error });
        throw err;
      });
  };
}

export function updateDetails(current_password, name, email) {
  return (dispatch) => {
    dispatch({ type: UPDATE_REQUEST });
    return dispatch(
      fetchJSON('/api/v1/person/me', {
        method: 'PATCH',
        body: { current_password, name, email },
      })
    )
      .then(({ data }) =>
        dispatch({
          type: UPDATE_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: UPDATE_FAILURE, error: err.error });
        throw err;
      });
  };
}

export const getPerson = (state) => state.person.data;
export const getPersonStatus = (state) => state.person.status;
export const getIsPersonLoaded = (state) => !!state.person.data.id;
export const getIsCryptGenerated = (state) => {
  const { crypt_id, crypt_key_md5 } = state.person.data;
  return crypt_id && crypt_key_md5;
};
