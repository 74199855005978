import React from 'react';
import { Helmet } from 'react-helmet-async';
import CautionIcon from '../../../components/svg/icons/CautionIcon';
import FlexScrollbar from '../../../components/FlexScrollbar';
import NoticeWithText from '../../../components/NoticeWithText';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const UnsupportedPage = () => {
  const [navHeight, navRef] = useRefHeight();
  return (
    <React.Fragment>
      <Helmet title="Unsupported Browser" />
      <PanelLayoutContainer>
        <PanelLayoutPage>
          <PanelNavigation ref={navRef} />
          <FlexScrollbar
            refHeights={[navHeight]}
            desktopHeight={PanelLayoutPage.minHeight}
          >
            <PanelLayoutPage.Header>
              <NoticeWithText
                image={<CautionIcon width={150} height={133} />}
                title="Unsupported Browser"
              >
                Your browser does not support secure client-side encryption.
                <br />
                <br /> Please use a modern browser such as{' '}
                <a href="https://www.google.com/chrome/">Google Chrome</a>.
              </NoticeWithText>
            </PanelLayoutPage.Header>
            <PanelLayoutPage.Body></PanelLayoutPage.Body>
            <PanelLayoutPage.Footer></PanelLayoutPage.Footer>
          </FlexScrollbar>
        </PanelLayoutPage>
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default UnsupportedPage;
