export default function formValidator(values) {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name is required.';
  }

  if (values.name && /[~!@#$%^&*+:<>?|\\|]/.test(values.name)) {
    errors.name = 'Name cannot include most special characters.';
  }

  if (!values.reminder_frequency) {
    errors.reminder_frequency =
      'Please select how often you want to be in touch.';
  }

  return errors;
}
