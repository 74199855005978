import React from 'react';
import { Field } from 'react-final-form';
import FieldTrim from './partials/FieldTrim';
import TextInput from './inputs/TextInput';

const TextField = ({ children, name, hint, ...props }) => (
  <Field name={name}>
    {({ input, meta }) => (
      <FieldTrim name={name} label={children} meta={meta} hint={hint}>
        <TextInput {...input} {...props} />
      </FieldTrim>
    )}
  </Field>
);

export default TextField;
