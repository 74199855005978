import Delta from 'quill-delta';
import getBlocksFromAnnoText from './getBlocksFromAnnoText';

export default function getDeltaFromAnnoText(text, friends) {
  if (!text) {
    return new Delta([]);
  }
  return getBlocksFromAnnoText(text, friends).map((block) => {
    // String block
    if (!block.id && !block.value) {
      return {
        insert: block,
      };
    }
    // Convert non-existent friends into regular text.
    if (!friends[block.id]) {
      return {
        insert: `@${block.value}`,
      };
    }
    // Insert the `mention` as per `quill-mention` format.
    return {
      insert: {
        mention: {
          denotationChar: '@',
          id: block.id,
          value: block.value,
        },
      },
    };
  });
}
