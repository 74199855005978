import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoginPage from './pages/auth/LoginPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import OverviewPage from './pages/registration/OverviewPage';
import RegistrationPage from './pages/registration/RegistrationPage';
import EmailVerificationPage from './pages/registration/EmailVerificationPage';
import LoadingPage from './pages/system/LoadingPage';
import KeyGenerationPage from './pages/registration/KeyGenerationPage';
import DecryptionPage from './pages/auth/DecryptionPage';
import JournalPage from './pages/journal/JournalPage';
import ThreadPage from './pages/journal/ThreadPage';
import PlannerPage from './pages/planner/PlannerPage';
import AddFriendPage from './pages/planner/AddFriendPage';
import EditFriendPage from './pages/planner/EditFriendPage';
import SearchPage from './pages/search/SearchPage';
import OptionsPage from './pages/options/OptionsPage';
import ChangeDetailsPage from './pages/options/ChangeDetailsPage';
import ChangePasswordPage from './pages/options/ChangePasswordPage';
import ErrorPage from './pages/system/ErrorPage';
import NotFoundPage from './pages/system/NotFoundPage';
import UnsupportedPage from './pages/system/UnsupportedPage';
import { getIsAuthenticated, getIsVerified } from './reducers/auth';
import { getIsPersonLoaded, getIsCryptGenerated } from './reducers/person';
import { getIsCryptLoaded } from './reducers/crypt';
import { getIsReady } from './reducers/ready';
import { getIsErrorRaised } from './reducers/error';
import { hasCryptoSupport } from './utils/encryption';
import StaleManager from './init/StaleManager';
import ToastManager from './init/ToastManager';

const Router = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isVerified = useSelector(getIsVerified);
  const isPersonLoaded = useSelector(getIsPersonLoaded);
  const isCryptGenerated = useSelector(getIsCryptGenerated);
  const isCryptLoaded = useSelector(getIsCryptLoaded);
  const isReady = useSelector(getIsReady);
  const isErrorRaised = useSelector(getIsErrorRaised);
  const isUnsupported = !hasCryptoSupport();

  // Router Sub-States
  const _isOk = !isErrorRaised && !isUnsupported;
  const _isVerified = _isOk && isAuthenticated && isVerified;
  const _isLoaded = _isVerified && isPersonLoaded;
  const _hasCrypt = _isLoaded && isCryptGenerated;
  const _isCrypted = _hasCrypt && isCryptLoaded;

  // Router States
  const isGuest = !isErrorRaised && !isAuthenticated;
  const isVerifying = isAuthenticated && !isVerified;
  const isLoading =
    (_isVerified && !isPersonLoaded) || (_isCrypted && !isReady);
  const isUncrypted = _isLoaded && !isCryptGenerated;
  const isCrypting = _hasCrypt && !isCryptLoaded;
  const isAuthorized = _isCrypted && isReady;

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Tendship"
        defaultTitle="Tendship"
        meta={[
          {
            name: 'description',
            content: 'Tendship stay in touch.',
          },
        ]}
      />
      <BrowserRouter>
        <StaleManager />
        <ToastManager />
        {isUnsupported && !isErrorRaised && (
          <Switch>
            <Route path="*">
              <UnsupportedPage />
            </Route>
          </Switch>
        )}
        {!isUnsupported && isErrorRaised && (
          <Switch>
            <Route path="*">
              <ErrorPage />
            </Route>
          </Switch>
        )}
        {isGuest && (
          <Switch>
            <Route exact path="/">
              <LoginPage />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPasswordPage />
            </Route>
            <Route exact path="/reset-password/:token">
              <ResetPasswordPage />
            </Route>
            <Route exact path="/overview/:step">
              <OverviewPage />
            </Route>
            <Route exact path="/register">
              <RegistrationPage />
            </Route>
            <Route exact path="/email-verification/:token">
              <EmailVerificationPage />
            </Route>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        )}
        {isVerifying && (
          <Switch>
            <Route exact path="/email-verification/:token">
              <EmailVerificationPage />
            </Route>
            <Route path="*">
              <EmailVerificationPage />
            </Route>
          </Switch>
        )}
        {isLoading && (
          <Switch>
            <Route exact path="/journal/thread/:threadId">
              <LoadingPage />
            </Route>
            <Route path="*">
              <LoadingPage />
            </Route>
          </Switch>
        )}
        {isUncrypted && (
          <Switch>
            <Route path="*">
              <KeyGenerationPage />
            </Route>
          </Switch>
        )}
        {isCrypting && (
          <Switch>
            <Route path="*">
              <DecryptionPage />
            </Route>
          </Switch>
        )}
        {isAuthorized && (
          <Switch>
            <Route exact path="/">
              <JournalPage />
            </Route>
            <Route exact path="/journal/thread/:threadId">
              <ThreadPage />
            </Route>
            <Route exact path="/planner">
              <PlannerPage />
            </Route>
            <Route exact path="/planner/add-friend">
              <AddFriendPage />
            </Route>
            <Route exact path="/planner/friend/:friendId">
              <EditFriendPage />
            </Route>
            <Route exact path="/search">
              <SearchPage />
            </Route>
            <Route exact path="/options">
              <OptionsPage />
            </Route>
            <Route exact path="/options/change-details">
              <ChangeDetailsPage />
            </Route>
            <Route exact path="/options/change-password">
              <ChangePasswordPage />
            </Route>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        )}
      </BrowserRouter>
    </React.Fragment>
  );
};

export default Router;
