import differenceInHours from 'date-fns/differenceInHours';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';

export default function getDateCountdown(date, today = new Date()) {
  if (!date) {
    return { count: null, unit: null };
  }

  const hours = differenceInHours(date, today);
  if (hours <= 24) {
    return { count: 1, unit: 'day' };
  }

  const days = Math.ceil(hours / 24);
  if (days < 10) {
    return { count: days, unit: 'days' };
  }

  // Humans usually think "2 weeks" in number of relative weeks, rather than
  // literal number of days.
  const weeks = differenceInCalendarWeeks(date, today, { weekStartsOn: 1 });
  if (weeks === 1) {
    return { count: 1, unit: 'week' };
  }
  if (days < 60) {
    return { count: weeks, unit: 'weeks' };
  }

  // We can be rougher with month calculations.
  const months = Math.ceil(days / 30);
  return { count: months, unit: 'months' };
}
