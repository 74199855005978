import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import OptionGroupField from '../../../components/fields/OptionGroupField';
import AutoSave from '../../../components/fields/AutoSave';
import { getSettings, updateSettings } from '../../../reducers/setting';

const EmailFrequencySetting = () => {
  const settings = useSelector(getSettings);
  const dispatch = useDispatch();

  const submit = ({ email_reminder_frequency }) => {
    toast.dismiss();
    return dispatch(updateSettings({ email_reminder_frequency })).catch(
      (err) => {
        toast.error(err.error);
      }
    );
  };
  return (
    <Form
      initialValues={{
        email_reminder_frequency: settings.email_reminder_frequency,
      }}
      onSubmit={submit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <OptionGroupField
            name="email_reminder_frequency"
            description="How often do you want to be sent reminder emails for friends that you haven’t mentioned in a while?"
            options={[
              {
                value: 'daily',
                label: 'Daily',
              },
              {
                value: 'weekly',
                label: 'Weekly',
              },
              {
                value: 'never',
                label: 'No Reminders',
              },
            ]}
          >
            <strong>
              Reminder Email Schedule <AutoSave onValueChange={handleSubmit} />
            </strong>
          </OptionGroupField>
        </form>
      )}
    />
  );
};

export default EmailFrequencySetting;
