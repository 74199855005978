export const READ_REQUEST = 'entry/READ_REQUEST';
export const READ_SUCCESS = 'entry/READ_SUCCESS';
export const READ_FAILURE = 'entry/READ_FAILURE';
export const CREATE_REQUEST = 'entry/CREATE_REQUEST';
export const CREATE_SUCCESS = 'entry/CREATE_SUCCESS';
export const CREATE_FAILURE = 'entry/CREATE_FAILURE';
export const UPDATE_REQUEST = 'entry/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'entry/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'entry/UPDATE_FAILURE';
export const DELETE_REQUEST = 'entry/DELETE_REQUEST';
export const DELETE_SUCCESS = 'entry/DELETE_SUCCESS';
export const DELETE_FAILURE = 'entry/DELETE_FAILURE';
