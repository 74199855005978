import isString from 'lodash.isstring';
import { unescapeCtrlChars } from './escapeCtrlChars';

function getControlIndexAndId(body, openIndex) {
  const localClosingIndex = body.slice(openIndex).indexOf('>');
  const closingIndex = openIndex + localClosingIndex;
  const [value, id] = body.slice(openIndex + 1, closingIndex).split('|');
  if (localClosingIndex === -1) {
    return {
      id: null,
      value: null,
    };
  }
  return {
    closingIndex,
    id,
    value: value.slice(1),
    type: '@',
  };
}

function splitByControlChars(body, friends) {
  const blocks = [''];
  let blockPointer = 0;
  for (let i = 0; i < body.length; i += 1) {
    const char = body[i];
    if (char === '>') {
      throw new Error('Invalid body. Control block was not opened.');
    } else if (char === '<') {
      const { closingIndex, id, value, type } = getControlIndexAndId(body, i);
      if (id === null) {
        throw new Error('Invalid body. Control block was not closed.');
      }
      const doesFriendExist = !!friends[id];

      // If the current block is empty, replace it with the control block
      blockPointer =
        blocks[blockPointer] !== '' ? blockPointer + 1 : blockPointer;

      // Create the data block and skip past the control characters
      blocks[blockPointer] = {
        id,
        value: doesFriendExist ? friends[id].name : value,
        type,
      };
      i = closingIndex;

      // Set up the next value block
      if (i + 1 < body.length) {
        blockPointer += 1;
        blocks[blockPointer] = '';
      }
    } else {
      blocks[blockPointer] += char;
    }
  }
  return blocks;
}

export default function getBlocksFromAnnoText(body, friends) {
  if (!body) {
    return [];
  }
  return splitByControlChars(body, friends).map((block) =>
    isString(block) ? unescapeCtrlChars(block) : block
  );
}
