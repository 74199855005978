import query from 'query-string';
import { NOT_AUTHENTICATED } from '../../errors';
import {
  getTokenHeader,
  notAuthenticated,
  refreshToken,
} from '../../reducers/auth';
import { raiseError } from '../../reducers/error';

function isFormData(body) {
  return body instanceof FormData;
}

function willSendJSON(options) {
  if (!options) {
    return false;
  }
  return (
    options.method &&
    options.method.toUpperCase() !== 'GET' &&
    !isFormData(options.body)
  );
}

function willSendData(options) {
  if (!options) {
    return false;
  }
  return (
    options.method &&
    options.method.toUpperCase() !== 'GET' &&
    isFormData(options.body)
  );
}

function getBody(options) {
  if (willSendJSON(options)) {
    return JSON.stringify(options.body);
  }
  if (willSendData(options)) {
    return options.body;
  }
  return undefined;
}

function applyParamsToUrl(url, params) {
  if (!params) {
    return url;
  }
  return `${url}?${query.stringify(params, { arrayFormat: 'index' })}`;
}

// Before each request we will check if we need to refresh our access token.
let refreshPromise = null;
function refresh() {
  return (dispatch) => {
    if (refreshPromise) {
      return refreshPromise;
    }

    refreshPromise = dispatch(refreshToken());

    // If the token needs to be refreshed, we will delay further requests until
    // the token has been refreshed.
    return refreshPromise.then(() => {
      refreshPromise = null;
    });
  };
}

// Make a fetch request to the API.
// Automatically handles JSON content-type and body.
// Dispatches actions to auth/fetchReducer.
export default function fetchJSON(url, options = {}) {
  const { headers } = options;
  const isSendingJSON = willSendJSON(options);
  const isSendingData = willSendData(options);
  const body = getBody(options);
  const params = isSendingJSON || isSendingData ? null : options.body;
  const urlWithParams = applyParamsToUrl(url, params);

  return (dispatch, getState) =>
    dispatch(refresh()).then(() => {
      const state = getState();
      const tokenHeader = getTokenHeader(state);

      return fetch(urlWithParams, {
        ...options,
        credentials: 'same-origin',
        headers: {
          ...(isSendingJSON ? { 'Content-Type': 'application/json' } : {}),
          ...(tokenHeader ? { Authorization: tokenHeader } : {}),
          ...headers,
        },
        body,
      })
        .catch((networkError) =>
          Promise.reject({
            ok: false,
            error: networkError.message,
          })
        )
        .then((res) => res.text())
        .then((res) => {
          let json = null;
          try {
            json = JSON.parse(res);
          } catch (err) {
            json = {
              ok: false,
              status: 500,
              error: 'INVALID_RESPONSE',
            };
          }

          if (json.ok) {
            return json;
          }

          if (json.error === NOT_AUTHENTICATED) {
            dispatch(notAuthenticated());
          }

          if (json.status === 500) {
            dispatch(raiseError());
          }

          return Promise.reject(json);
        });
    });
}
