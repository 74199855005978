import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import CaretDownIcon from './CaretDownIcon';
import CaretUpIcon from './CaretUpIcon';
import { THREAD_EXPAND_SIZE, expandTimeline } from '../../../reducers/thread';
import { GREY_100, GREY_600, TEAL_600 } from '../../../theme';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 10px 0;
  cursor: pointer;
  color: ${GREY_600};
  &:hover {
    color: ${TEAL_600};
  }
  &:hover path {
    fill: ${TEAL_600};
  }
`;

const Line = styled.div`
  border-top: 2px solid ${GREY_100};
  flex-grow: 2;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  vertical-align: center;
  text-align: center;

  margin-right: 5px;
  padding: 5px 0 6px 0;
`;

const ThreadGap = ({ threadId, size }) => {
  const dispatch = useDispatch();
  const expandCount = Math.min(size, THREAD_EXPAND_SIZE);
  const onClick = () => dispatch(expandTimeline(threadId));
  return (
    <Container onClick={onClick}>
      <Line />
      <TextContainer>
        <Icon>
          <CaretUpIcon />
        </Icon>
        <Text>
          Click here to show {expandCount} missed{' '}
          {expandCount === 1 ? 'day' : 'days'} ({size} total)
        </Text>
        <Icon>
          <CaretDownIcon />
        </Icon>
      </TextContainer>
      <Line />
    </Container>
  );
};

export default React.memo(ThreadGap);
