import React from 'react';
import styled from 'styled-components';
import BackButton from '../buttons/BackButton';
import H2 from '../H2';
import MediaWidths from './MediaWidths';
import { TEAL_200, TEAL_700, TEAL_900 } from '../../theme';

const HeaderH2 = styled(H2)`
  color: ${TEAL_900};
`;

const Background = styled(MediaWidths)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${TEAL_200};
  width: 100%;
  min-height: 45px;
  padding: 10px 15px;
`;

const TabSubNavigation = React.forwardRef(
  ({ children, backButtonTo, onBackButtonClick }, ref) => (
    <Background ref={ref}>
      <BackButton
        to={backButtonTo}
        onClick={onBackButtonClick}
        color={TEAL_900}
        activeColor={TEAL_700}
      />
      <HeaderH2>{children}</HeaderH2>
    </Background>
  )
);

export default TabSubNavigation;
