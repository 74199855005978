import omit from 'object.omit';
import { schema, normalize } from 'normalizr';
import { LOGOUT } from '../auth';
import fetchJSON from '../../utils/core/fetchJSON';
import { getCryptId, getCryptKeyArray } from '../crypt';
import { encryptText, decryptText } from '../../utils/encryption';
import { getNextThreadIds } from '../thread';
import { fetchFriends } from '../friend';
import {
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from './actions';
export * from './actions';
export * from './selectors';

const entitySchema = new schema.Entity('entry');

const initialState = {
  status: {
    ok: false,
    isFetching: false,
    error: null,
  },
  params: {
    threadIds: [],
  },
  entities: {
    entry: {},
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case READ_SUCCESS: {
      const { threadIds } = action.payload.params;
      const { entities } = normalize(action.payload.data, [entitySchema]);
      const prevEntries = state.entities.entry;

      // This allows us to pick up a deleted entry if we re-request a
      // thread, for example.
      const affectedEntryIds = Object.keys(prevEntries).filter((id) => {
        const entry = prevEntries[id];
        if (threadIds.includes(entry.thread_id)) {
          return true;
        }
        return false;
      });
      const entry = {
        ...omit(prevEntries, affectedEntryIds),
        ...entities.entry,
      };
      return {
        status: {
          ok: true,
          isFetching: false,
          error: null,
        },
        entities: {
          ...state.entities,
          entry,
        },
      };
    }
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS: {
      const entry = {
        ...state.entities.entry,
        [action.payload.data.id]: action.payload.data,
      };
      return {
        ...state,
        status: {
          ok: true,
          isFetching: false,
          error: null,
        },
        entities: {
          ...state.entities,
          entry,
        },
      };
    }
    case DELETE_SUCCESS: {
      const { id: deletedId } = action.payload.data;
      const entry = omit(state.entities.entry, deletedId);
      return {
        ...state,
        status: {
          ok: true,
          isFetching: false,
          error: null,
        },
        entities: {
          ...state.entities,
          entry,
        },
      };
    }
    case READ_REQUEST:
    case CREATE_REQUEST:
    case UPDATE_REQUEST:
    case DELETE_REQUEST: {
      return {
        ...state,
        status: {
          ok: false,
          isFetching: true,
          error: null,
        },
      };
    }
    case READ_FAILURE:
    case CREATE_FAILURE:
    case UPDATE_FAILURE:
    case DELETE_FAILURE: {
      return {
        ...state,
        status: {
          ok: false,
          isFetching: false,
          error: action.error,
        },
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

function getDecryptedEntry(entry, keyArray) {
  return omit(
    {
      ...entry,
      text: decryptText(entry.crypt_text, keyArray),
    },
    ['crypt_text']
  );
}

export function fetchEntries({ threadIds }) {
  return async (dispatch, getState) => {
    const state = getState();
    const cryptKeyArray = getCryptKeyArray(state);

    if (threadIds && !threadIds.length) {
      return Promise.resolve();
    }

    dispatch({
      type: READ_REQUEST,
      payload: { params: { threadIds: threadIds || [] } },
    });
    try {
      const { data } = await dispatch(
        fetchJSON('/api/v1/entry', {
          method: 'GET',
          body: {
            thread_ids: threadIds,
          },
        })
      );
      dispatch({
        type: READ_SUCCESS,
        payload: {
          data: data.map((entry) => getDecryptedEntry(entry, cryptKeyArray)),
          params: { threadIds: threadIds || [] },
        },
      });
      return data;
    } catch (err) {
      dispatch({ type: READ_FAILURE, error: err.error });
      throw err;
    }
  };
}

export function fetchEntriesForThreads() {
  return async (dispatch, getState) => {
    const state = getState();
    const threadIds = getNextThreadIds(state);
    return dispatch(fetchEntries({ threadIds }));
  };
}

export function createEntry(threadId, text, friendIds) {
  return async (dispatch, getState) => {
    const state = getState();
    const cryptId = getCryptId(state);
    const cryptKeyArray = getCryptKeyArray(state);
    const cryptText = encryptText(text, cryptKeyArray);

    dispatch({ type: CREATE_REQUEST });
    try {
      const { data } = await dispatch(
        fetchJSON('/api/v1/entry', {
          method: 'POST',
          body: {
            thread_id: threadId,
            crypt_id: cryptId,
            crypt_text: cryptText,
            friend_ids: friendIds,
          },
        })
      );
      await dispatch(fetchFriends());
      dispatch({
        type: CREATE_SUCCESS,
        payload: { data: getDecryptedEntry(data, cryptKeyArray) },
      });
      return data;
    } catch (err) {
      dispatch({ type: CREATE_FAILURE, error: err.error });
      throw err;
    }
  };
}

export function updateEntry(id, text, friendIds) {
  return async (dispatch, getState) => {
    const state = getState();
    const cryptKeyArray = getCryptKeyArray(state);
    const cryptText = encryptText(text, cryptKeyArray);

    dispatch({ type: UPDATE_REQUEST });
    try {
      const { data } = await dispatch(
        fetchJSON(`/api/v1/entry/${id}`, {
          method: 'PATCH',
          body: {
            crypt_text: cryptText,
            friend_ids: friendIds,
          },
        })
      );
      await dispatch(fetchFriends());
      dispatch({
        type: UPDATE_SUCCESS,
        payload: { data: getDecryptedEntry(data, cryptKeyArray) },
      });
      return data;
    } catch (err) {
      dispatch({ type: UPDATE_FAILURE, error: err.error });
      throw err;
    }
  };
}

export function deleteEntry(id) {
  return async (dispatch) => {
    dispatch({ type: DELETE_REQUEST });
    try {
      const { data } = await dispatch(
        fetchJSON(`/api/v1/entry/${id}`, {
          method: 'DELETE',
        })
      );
      await dispatch(fetchFriends());
      dispatch({
        type: DELETE_SUCCESS,
        payload: { data },
      });
      return data;
    } catch (err) {
      dispatch({ type: DELETE_FAILURE, error: err.error });
      throw err;
    }
  };
}
