import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import FlexScrollbar from '../../../components/FlexScrollbar';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import H1Divider from '../../../components/H1Divider';
import Spacer from '../../../components/helpers/Spacer';
import TextField from '../../../components/fields/TextField';
import fetchJSON from '../../../utils/core/fetchJSON';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const ForgotPasswordPage = () => {
  const [isFetchingSend, setSendState] = useState(false);
  const [navHeight, navRef] = useRefHeight();
  const dispatch = useDispatch();

  const handleSend = ({ email }) => {
    toast.dismiss();
    setSendState(true);
    return dispatch(
      fetchJSON('/api/v1/person/password-reset/send', {
        method: 'POST',
        body: { email },
      })
    )
      .then(() => {
        toast.success('The reset password email has been sent.', {
          autoClose: 4000,
        });
        setSendState(false);
      })
      .catch((err) => {
        toast.error(err.error);
        setSendState(false);
      });
  };

  return (
    <React.Fragment>
      <Helmet title="Forgot Password" />
      <PanelLayoutContainer>
        <Form
          onSubmit={handleSend}
          validate={(values) => {
            const errors = {};

            if (!values.email) {
              errors.email = 'Email is required.';
            }

            return errors;
          }}
          render={({ handleSubmit, invalid }) => (
            <PanelLayoutPage as="form" onSubmit={handleSubmit}>
              <PanelNavigation ref={navRef} backButtonTo="/" />
              <FlexScrollbar
                refHeights={[navHeight]}
                desktopHeight={PanelLayoutPage.minHeight}
              >
                <PanelLayoutPage.Header>
                  <Spacer margin={10} />
                  <H1Divider>Forgot Password</H1Divider>
                </PanelLayoutPage.Header>
                <PanelLayoutPage.Body>
                  <TextField
                    name="email"
                    type="email"
                    placeholder="friendlyplant@tendship.com"
                    hint="You will be sent a email to this address that has a link to reset your password."
                  >
                    <strong>Email</strong>
                  </TextField>
                </PanelLayoutPage.Body>

                <PanelLayoutPage.Footer>
                  <Spacer margin={25} />
                  <Button
                    type="submit"
                    disabled={isFetchingSend || invalid}
                    variant="primary"
                    size="block"
                  >
                    Request Password Reset
                  </Button>
                </PanelLayoutPage.Footer>
              </FlexScrollbar>
            </PanelLayoutPage>
          )}
        />
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default ForgotPasswordPage;
