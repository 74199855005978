import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import FlexScrollbar from '../../../components/FlexScrollbar';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import H1Divider from '../../../components/H1Divider';
import Spacer from '../../../components/helpers/Spacer';
import TextField from '../../../components/fields/TextField';
import fetchJSON from '../../../utils/core/fetchJSON';
import validatePassword from '../../../utils/validatePassword';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const ResetPasswordPage = () => {
  const [isFetchingReset, setResetState] = useState(false);
  const [navHeight, navRef] = useRefHeight();
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();

  const submit = ({ password, password_confirm }) => {
    toast.dismiss();
    setResetState(true);
    return dispatch(
      fetchJSON('/api/v1/person/password-reset/confirm', {
        method: 'POST',
        body: {
          token,
          password,
          password_confirm,
        },
      })
    )
      .then(() => {
        history.push('/');
        setResetState(false);
      })
      .catch((err) => {
        toast.error(err.error);
        setResetState(false);
      });
  };

  return (
    <React.Fragment>
      <Helmet title="Password Reset" />
      <PanelLayoutContainer>
        <Form
          onSubmit={submit}
          validate={(values) => {
            const errors = {};

            if (!values.password) {
              errors.password = ['Password is required.'];
            }
            const passwordErrors = validatePassword(values.password);
            if (passwordErrors.length) {
              errors.password = passwordErrors;
            }

            if (!values.password_confirm) {
              errors.password_confirm = [
                'Confirming the password is required.',
              ];
            } else if (values.password !== values.password_confirm) {
              errors.password_confirm = 'Must match the above password.';
            }

            return errors;
          }}
          render={({ handleSubmit, invalid }) => (
            <PanelLayoutPage as="form" onSubmit={handleSubmit}>
              <PanelNavigation ref={navRef} backButtonTo="/" />
              <FlexScrollbar
                refHeights={[navHeight]}
                desktopHeight={PanelLayoutPage.minHeight}
              >
                <PanelLayoutPage.Header>
                  <Spacer margin={10} />
                  <H1Divider>Password Reset</H1Divider>
                </PanelLayoutPage.Header>
                <PanelLayoutPage.Body>
                  <TextField
                    name="password"
                    type="password"
                    placeholder="********"
                    autoComplete="new-password"
                    hint={
                      'The password must be at least 8 characters long with at least one capital, number and special character.'
                    }
                  >
                    <strong>Password</strong>
                  </TextField>

                  <TextField
                    name="password_confirm"
                    type="password"
                    placeholder="********"
                    autoComplete="new-password"
                  >
                    <strong>Confirm Password</strong>
                  </TextField>
                </PanelLayoutPage.Body>

                <PanelLayoutPage.Footer>
                  <Spacer margin={25} />
                  <Button
                    type="submit"
                    disabled={isFetchingReset || invalid}
                    variant="primary"
                    size="block"
                  >
                    Reset Password
                  </Button>
                </PanelLayoutPage.Footer>
              </FlexScrollbar>
            </PanelLayoutPage>
          )}
        />
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
