import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import Divider from '../../../components/Divider';
import Explainer from './Explainer';
import FlexScrollbar from '../../../components/FlexScrollbar';
import LinkButton from '../../../components/buttons/LinkButton';
import NotFoundPage from '../../system/NotFoundPage';
import Pips from './Pips';
import PlantMorning from '../../../components/svg/illustrations/PlantMorning';
import PlantNoon from '../../../components/svg/illustrations/PlantNoon';
import PlantNight from '../../../components/svg/illustrations/PlantNight';
import Spacer from '../../../components/helpers/Spacer';
import SwipeNavigation from '../../../components/SwipeNavigation';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const STEP_0 = 'appreciate-each-day';
const STEP_1 = 'keep-in-touch';
const STEP_2 = 'be-yourself';
const ALL_STEPS = [STEP_0, STEP_1, STEP_2];

const STEPS = [
  {
    name: STEP_0,
    backButtonTo: '/',
    nextButtonTo: `/overview/${STEP_1}`,
    image: <PlantMorning />,
    title: 'Appreciate Each Day',
    text: `Tendship is a smarter journal.
      Easily jot down the day’s small surprises using an intuitive chat-like interface.
      If you fall behind, tendship makes it easy to backfill missed days.`,
  },
  {
    name: STEP_1,
    backButtonTo: `/overview/${STEP_0}`,
    nextButtonTo: `/overview/${STEP_2}`,
    image: <PlantNoon />,
    title: 'Keep In Touch',
    text: `Tendship is a relationship planner.
    See the people you care about more often.
    Tendship will remind you when you haven’t seen someone in a while.`,
  },
  {
    name: STEP_2,
    backButtonTo: `/overview/${STEP_1}`,
    nextButtonTo: '/register',
    image: <PlantNight />,
    title: 'Be Yourself',
    text: `Tendship is private and secure.
    What you write is for you and only you.
    Tendship is not a social network.
    Even better, all of your entries are encrypted.`,
  },
];

const OverviewPage = () => {
  const { step } = useParams();
  const [navHeight, navRef] = useRefHeight();

  if (!ALL_STEPS.includes(step)) {
    return <NotFoundPage />;
  }

  const index = STEPS.findIndex(({ name }) => name === step);
  const { name, backButtonTo, nextButtonTo, image, title, text } = STEPS[index];

  return (
    <SwipeNavigation
      leftTo={index === 0 ? undefined : backButtonTo}
      rightTo={index === STEPS.length - 1 ? undefined : nextButtonTo}
    >
      <Helmet title={title} />
      <PanelLayoutContainer>
        <PanelLayoutPage>
          <PanelNavigation ref={navRef} backButtonTo={backButtonTo} />
          <FlexScrollbar
            refHeights={[navHeight]}
            desktopHeight={PanelLayoutPage.minHeight}
          >
            <PanelLayoutPage.Header>
              <Explainer image={image} title={title}>
                {text}
              </Explainer>
            </PanelLayoutPage.Header>
            <PanelLayoutPage.Body />
            <PanelLayoutPage.Footer>
              <Pips urls={ALL_STEPS} activeUrl={name} />
              <Divider />
              <Spacer margin={25} />
              <LinkButton variant="primary" size="block" to={nextButtonTo}>
                Continue
              </LinkButton>
            </PanelLayoutPage.Footer>
          </FlexScrollbar>
        </PanelLayoutPage>
      </PanelLayoutContainer>
    </SwipeNavigation>
  );
};

export default OverviewPage;
