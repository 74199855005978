import React from 'react';

const PlantNight = ({ width = 200, height = 200 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M162.682 129.337C162.733 129.815 159.326 130.668 158.709 130.658C155.924 130.627 155.723 130.606 153.005 129.774C152.938 129.748 152.069 131.074 151.031 131.742C148.174 132.832 146.678 131.989 143.558 131.228C142.616 130.819 141.713 130.325 140.86 129.753C138.183 130.55 136.476 131.13 133.876 130.74C130.031 130.169 127.981 127.97 127.883 127.795C124.83 128.196 118.355 129.805 118.344 130.421C118.344 131.876 127.462 133.453 139.843 133.638C152.223 133.823 163.859 131.583 163.88 130.144C163.885 129.81 163.679 129.568 162.682 129.337Z"
      fill="#52606D"
    />
    <path
      d="M174.174 136.465C174.389 134.353 174.492 132.23 174.616 130.108C174.693 128.756 172.591 128.761 172.514 130.108C172.39 132.23 172.287 134.353 172.072 136.465C171.933 137.812 174.04 137.801 174.174 136.465Z"
      fill="#52606D"
    />
    <path
      d="M109.052 130.827C109.078 133.099 109.33 135.37 109.566 137.627C109.695 138.968 111.823 138.968 111.668 137.627C111.406 135.283 111.196 132.919 110.733 130.606C110.548 129.661 109.063 129.887 109.073 130.827H109.052Z"
      fill="#52606D"
    />
    <path
      d="M117.846 127.43C114.932 127.394 111.679 128.037 109.464 130.061C109.321 130.206 109.241 130.401 109.241 130.604C109.241 130.807 109.321 131.002 109.464 131.146C111.596 132.888 114.511 133.459 117.147 133.993C120.056 134.584 122.975 135.062 125.925 135.386C133.069 136.129 140.26 136.318 147.433 135.951C153.554 135.749 159.651 135.075 165.668 133.937C168.351 133.423 172.863 132.826 174.523 130.313C174.618 130.151 174.656 129.963 174.632 129.777C174.607 129.591 174.522 129.418 174.39 129.285C171.907 127.194 169.178 126.305 165.961 126.099C164.866 126.027 164.887 127.697 165.961 127.79C168.675 128.021 171.075 128.725 173.182 130.493L173.048 129.465C171.604 131.644 166.47 132.102 164.193 132.549C158.419 133.572 152.58 134.183 146.719 134.379C140.379 134.68 134.027 134.558 127.703 134.014C124.743 133.74 121.802 133.327 118.879 132.775C116.186 132.261 112.768 131.876 110.574 130.092V131.177C112.439 129.445 115.389 128.879 117.872 128.864C118.047 128.849 118.211 128.769 118.33 128.639C118.449 128.509 118.516 128.339 118.516 128.162C118.516 127.986 118.449 127.816 118.33 127.686C118.211 127.556 118.047 127.476 117.872 127.461L117.846 127.43Z"
      fill="#52606D"
    />
    <path
      d="M137.669 110.943C136.194 105.161 131.846 100.32 126.706 97.4418C126.551 97.352 126.375 97.3045 126.195 97.304C126.016 97.3035 125.839 97.35 125.683 97.4389C125.527 97.5278 125.397 97.6559 125.306 97.8106C125.215 97.9653 125.166 98.1411 125.164 98.3206C124.584 107.058 125.601 117.434 134.056 122.383C135.279 123.098 136.255 121.314 135.145 120.518C127.703 115.183 126.747 106.842 127.205 98.3206L125.663 99.1994C130.596 101.832 134.483 106.069 136.682 111.21C136.923 111.786 137.818 111.59 137.653 110.943H137.669Z"
      fill="#52606D"
    />
    <path
      d="M142.803 129.532C141.4 128.329 141.945 127.096 142.885 125.667C143.606 124.58 144.39 123.537 145.234 122.543C145.717 121.962 146.606 120.538 147.392 120.584C148.076 120.631 149.479 122.219 150.039 122.681C152.501 124.737 154.228 128.694 150.687 130.755C148.03 132.297 144.792 131.033 142.556 129.347L141.585 131.012L141.636 131.048C142.664 131.768 143.61 130.097 142.608 129.383L142.556 129.347C141.528 128.617 140.613 130.277 141.585 131.012C144.632 133.31 149.176 134.471 152.434 131.886C155.805 129.213 155.004 124.65 152.126 121.931C150.964 120.831 149.191 118.914 147.552 118.57C145.912 118.225 144.797 120.086 143.872 121.181C141.61 123.843 137.926 127.862 141.441 130.894C142.376 131.68 143.738 130.324 142.803 129.532Z"
      fill="#52606D"
    />
    <path
      d="M154.259 125.241C156.618 123.37 158.699 121.468 161.336 119.937C163.702 118.657 165.971 117.205 168.125 115.594L166.439 115.147C167.467 119.192 167.539 124.531 163.427 126.993C161.243 128.299 157.45 129.255 155.024 128.283C153.647 127.733 153.051 129.959 154.413 130.503C157.635 131.788 162.821 130.437 165.462 128.34C169.451 125.164 170.088 119.13 168.382 114.612C168.329 114.447 168.236 114.298 168.11 114.178C167.985 114.058 167.831 113.973 167.663 113.929C167.495 113.884 167.319 113.883 167.151 113.926C166.983 113.968 166.828 114.052 166.701 114.17C164.229 116.308 161.217 117.717 158.478 119.51C156.489 120.821 154.104 122.352 153.426 124.768C153.415 124.867 153.433 124.967 153.478 125.056C153.523 125.144 153.592 125.218 153.678 125.268C153.764 125.318 153.863 125.343 153.962 125.338C154.062 125.333 154.157 125.299 154.238 125.241H154.259Z"
      fill="#52606D"
    />
    <path
      d="M148.477 115.568C149.479 113.734 149.654 112.032 151.237 110.48C153.138 108.615 155.554 107.304 157.789 105.881L156.607 105.567C159.388 110.105 158.093 117.532 155.281 121.802C154.562 122.902 156.309 123.925 157.044 122.83C160.236 117.989 161.67 109.612 158.103 104.693C157.983 104.498 157.793 104.357 157.571 104.3C157.35 104.242 157.115 104.273 156.916 104.385C152.83 107.196 147.613 109.699 147.531 115.317C147.537 115.425 147.576 115.529 147.645 115.614C147.713 115.698 147.807 115.759 147.912 115.787C148.017 115.815 148.129 115.809 148.23 115.77C148.332 115.73 148.418 115.66 148.477 115.568V115.568Z"
      fill="#52606D"
    />
    <path
      d="M159.917 112.572C161.5 109.488 163.345 106.477 166.084 104.257L164.635 103.876C165.663 108.682 165.611 112.916 162.718 117.069C162.178 117.85 163.376 118.739 164.018 118.097C167.498 114.499 168.161 107.957 166.305 103.439C166.262 103.295 166.182 103.165 166.074 103.061C165.966 102.957 165.832 102.882 165.687 102.844C165.542 102.805 165.389 102.805 165.243 102.843C165.098 102.881 164.965 102.955 164.856 103.059C162.286 105.809 160.061 108.522 158.689 112.079C158.416 112.793 159.578 113.276 159.922 112.593L159.917 112.572Z"
      fill="#52606D"
    />
    <path
      d="M128.181 124.079C126.804 122.129 124.781 120.727 122.471 120.122C118.802 119.135 115.435 120.194 111.761 120.435C111.247 120.471 110.625 121.011 110.867 121.607C113.688 128.525 121.87 132.564 128.531 128.288C129.559 127.641 128.597 126.017 127.585 126.675C122.034 130.272 115.009 127.009 112.65 121.129L111.761 122.301C114.947 122.116 118.082 121.134 121.274 121.787C123.7 122.301 125.298 123.627 127.272 124.999C127.785 125.369 128.572 124.624 128.181 124.09V124.079Z"
      fill="#52606D"
    />
    <path
      d="M133.583 103.254C132.807 99.6208 134.441 96.861 136.389 94.0035L135.202 94.3118C136.8 95.2266 138.455 95.9924 139.827 97.2567C140.911 98.3484 141.801 99.6172 142.458 101.008C142.88 101.79 143.975 101.224 143.82 100.433C143.096 96.6143 139.149 94.5277 136.086 92.8111C135.885 92.702 135.65 92.6736 135.43 92.7319C135.209 92.7902 135.019 92.9307 134.898 93.1246C133.696 94.9286 132.478 96.5218 132.015 98.6957C131.691 100.238 131.501 102.555 132.704 103.768C132.775 103.88 132.887 103.96 133.015 103.992C133.144 104.023 133.28 104.004 133.395 103.938C133.51 103.871 133.594 103.763 133.631 103.636C133.668 103.508 133.654 103.372 133.593 103.254H133.583Z"
      fill="#52606D"
    />
    <path
      d="M126.907 109.437C124.589 105.973 120.148 104.2 116.494 102.535C116.359 102.46 116.206 102.421 116.051 102.422C115.896 102.423 115.744 102.464 115.61 102.541C115.476 102.618 115.363 102.728 115.284 102.861C115.205 102.994 115.161 103.146 115.158 103.301C115.292 107.217 115.256 111.231 116.895 114.875C118.36 118.206 120.891 120.956 124.09 122.692C124.282 122.784 124.502 122.801 124.706 122.739C124.91 122.677 125.083 122.54 125.191 122.356C125.298 122.172 125.333 121.954 125.287 121.745C125.241 121.537 125.118 121.354 124.943 121.232C121.917 119.46 119.566 116.732 118.262 113.477C117.018 110.249 117.07 106.703 116.936 103.301L115.605 104.066C119.156 105.665 123.186 107.34 125.91 110.208C126.424 110.722 127.323 110.044 126.912 109.437H126.907Z"
      fill="#52606D"
    />
    <path
      d="M148.405 102.37C149.494 98.8294 150.908 95.6892 154.387 93.8904L153.143 93.3765C154.171 95.7971 155.266 98.2898 155.389 100.952C155.487 102.941 155.045 104.329 154.361 106.153C153.93 107.315 155.733 107.757 156.191 106.667C158.154 101.964 156.597 97.2362 154.69 92.7341C154.574 92.5427 154.387 92.4042 154.171 92.3476C153.954 92.291 153.724 92.3209 153.529 92.4308C150.137 94.2913 147.146 98.2024 147.197 102.196C147.201 102.342 147.257 102.483 147.355 102.592C147.453 102.701 147.587 102.772 147.733 102.791C147.878 102.81 148.026 102.777 148.149 102.697C148.272 102.617 148.363 102.496 148.405 102.355V102.37Z"
      fill="#52606D"
    />
    <path
      d="M138.085 122.851C137.473 120.173 136.995 117.711 137.422 114.926C137.844 112.52 138.664 110.201 139.848 108.065C141.903 104.092 144.221 100.068 147.346 96.825L145.737 96.3984C146.765 101.024 148.06 105.881 147.68 110.64C147.428 113.826 146.498 116.447 145.316 119.377C144.802 120.661 146.894 121.237 147.397 119.952C148.626 116.833 149.53 114.016 149.762 110.635C150.09 105.701 148.734 100.644 147.593 95.8845C147.547 95.7237 147.46 95.5776 147.341 95.4604C147.221 95.3433 147.074 95.2591 146.912 95.2161C146.75 95.1731 146.58 95.1728 146.419 95.2152C146.257 95.2575 146.109 95.3412 145.989 95.4579C142.479 99.2097 139.94 103.887 137.699 108.476C135.477 113.128 134.88 118.39 136.003 123.421C136.327 124.773 138.393 124.203 138.059 122.846L138.085 122.851Z"
      fill="#52606D"
    />
    <path
      d="M141.539 128.607C139.709 122.979 134.282 119.33 128.762 117.907C128.524 117.845 128.272 117.879 128.059 118.001C127.845 118.123 127.688 118.323 127.621 118.56C126.727 122.794 125.498 127.81 129.821 130.673C132.699 132.57 138.147 132.976 141.066 130.976C141.239 130.836 141.361 130.644 141.415 130.428C141.469 130.212 141.452 129.984 141.366 129.779C141.279 129.574 141.129 129.403 140.937 129.29C140.744 129.178 140.521 129.131 140.3 129.157C137.499 129.635 134.868 130.565 132.051 129.45C127.719 127.728 128.186 122.733 129.41 119.053L128.269 119.7C133.557 121.155 137.468 124.454 140.089 129.214C140.187 129.376 140.341 129.498 140.522 129.557C140.702 129.616 140.898 129.608 141.073 129.535C141.249 129.461 141.392 129.327 141.476 129.156C141.561 128.986 141.581 128.791 141.533 128.607H141.539Z"
      fill="#52606D"
    />
    <path
      d="M174.138 133.325C176.99 134.219 171.44 137.437 171.08 137.637C169.634 138.457 168.135 139.18 166.593 139.801C163.608 141.017 160.453 141.764 157.239 142.016C153.267 142.293 149.253 142.047 145.321 142.797C142.751 143.311 139.997 144.339 138.609 146.729C136.862 149.751 137.663 153.98 139.02 156.971C139.344 157.686 140.362 157.156 140.223 156.457C139.38 152.315 137.905 147.34 142.757 145.151C146.472 143.491 151.021 143.743 154.999 143.609C160.617 143.499 166.123 142.019 171.039 139.297C172.688 138.372 175.602 137.087 176.132 135.026C176.22 134.729 176.247 134.416 176.209 134.108C176.172 133.8 176.072 133.503 175.914 133.236C175.757 132.968 175.547 132.736 175.296 132.553C175.045 132.37 174.759 132.241 174.456 132.174C174.302 132.132 174.138 132.152 173.999 132.231C173.86 132.31 173.759 132.441 173.716 132.595C173.674 132.749 173.695 132.914 173.774 133.053C173.853 133.191 173.984 133.293 174.138 133.335V133.325Z"
      fill="#52606D"
    />
    <path
      d="M174.533 131.223C178.223 130.791 180.212 133.926 179.621 137.282C179.185 139.754 177.525 141.692 176.24 143.758C175.823 144.431 176.836 145.254 177.365 144.621C179.647 141.882 181.79 138.243 180.988 134.533C180.3 131.341 177.55 129.604 174.379 130.097C173.752 130.195 173.891 131.295 174.533 131.223V131.223Z"
      fill="#52606D"
    />
    <path
      d="M139.868 160.368C138.326 164.994 135.489 169.105 133.922 173.731C132.837 176.933 133.47 179.821 136.754 181.27C140.742 183.028 145.223 181.481 149.088 180.196C154.022 178.552 158.164 178.418 163.232 179.312C171.141 180.715 180.12 179.98 185.136 172.847C186.966 170.256 187.78 167.082 187.423 163.93C187.12 160.482 185.778 157.824 184.463 154.715C182.833 150.861 181.785 147.057 182.201 142.895C182.453 140.376 183.414 136.326 181.328 134.317C180.475 133.495 179.174 134.795 180.033 135.612C181.61 137.128 180.546 141.265 180.361 143.198C180.127 145.589 180.361 148.004 181.05 150.306C182.705 156.18 187.125 161.864 185.48 168.268C183.425 176.213 174.287 178.896 167.071 178.233C163.71 177.92 160.39 176.922 157.018 176.994C153.339 177.077 150.024 178.341 146.565 179.456C144.072 180.258 141.287 180.998 138.66 180.397C136.964 180.006 135.803 179.323 135.14 177.693C134.477 176.064 134.924 174.826 135.505 173.258C137.088 168.956 139.102 164.932 140.454 160.528C140.572 160.147 139.986 160.014 139.863 160.363L139.868 160.368Z"
      fill="#52606D"
    />
    <path
      d="M153.087 158.467C158.776 156.103 164.907 154.556 169.723 150.516L168.52 150.002C168.005 153.983 166.265 157.704 163.54 160.651C162.975 161.294 163.869 162.193 164.486 161.597C167.577 158.475 169.505 154.388 169.949 150.018C170.026 149.355 169.158 149.165 168.746 149.504C164.121 153.482 158.072 154.849 152.742 157.588C152.275 157.83 152.609 158.662 153.107 158.451L153.087 158.467Z"
      fill="#52606D"
    />
    <path
      d="M144.566 143.141C139.061 141.728 133.197 141.954 127.565 141.769C120.966 141.646 114.465 140.157 108.472 137.395C107.588 136.995 106.807 138.238 107.696 138.727C119.069 144.95 132.031 142.895 144.427 144.138C145.002 144.195 145.105 143.28 144.566 143.141V143.141Z"
      fill="#52606D"
    />
    <path
      d="M109.124 131.896C106.955 132.362 105.017 133.576 103.651 135.324C102.073 137.534 102.551 141.091 102.402 143.65C101.914 151.94 98.7737 159.726 95.9727 167.445C95.5667 168.566 97.3706 169.064 97.7612 167.959C100.028 161.422 102.505 154.869 103.553 148.003C104.021 144.961 103.892 141.903 104.268 138.86C104.504 136.948 105.028 135.946 106.601 134.815C107.547 134.137 108.595 133.623 109.597 133.037C109.72 132.959 109.811 132.84 109.855 132.701C109.898 132.561 109.891 132.412 109.834 132.277C109.777 132.143 109.675 132.033 109.545 131.967C109.415 131.901 109.266 131.883 109.124 131.917V131.896Z"
      fill="#52606D"
    />
    <path
      d="M96.3941 168.55C99.7501 172.821 106.02 174.82 111.134 175.853C114.061 176.38 117.026 176.663 119.999 176.701C124.262 176.866 128.513 177.257 132.735 177.873C132.973 177.941 133.228 177.912 133.444 177.792C133.66 177.672 133.82 177.471 133.888 177.233C133.957 176.996 133.928 176.74 133.808 176.524C133.688 176.308 133.486 176.148 133.249 176.08C126.953 173.972 119.922 175.257 113.431 174.445C107.557 173.705 101.693 171.67 97.1033 167.862C97.0069 167.814 96.898 167.798 96.7919 167.815C96.6858 167.833 96.5878 167.883 96.5117 167.959C96.4357 168.035 96.3854 168.133 96.368 168.239C96.3506 168.345 96.3669 168.454 96.4146 168.55H96.3941Z"
      fill="#52606D"
    />
    <path
      d="M135.32 157.927C130.478 155.343 125.13 153.849 119.65 153.549C118.956 153.549 118.992 154.535 119.65 154.612C124.923 155.299 130.081 156.682 134.991 158.724C135.438 158.904 135.736 158.169 135.32 157.943V157.927Z"
      fill="#52606D"
    />
    <path
      d="M82.9802 3.92022C68.9959 44.506 50.7151 83.2776 24.7714 117.665C17.4529 127.353 9.49716 136.501 1.26899 145.418C0.523781 146.225 1.7007 147.417 2.48189 146.631C32.6861 116.308 55.1196 78.0303 71.4731 38.7036C76.1756 27.397 80.4516 15.9104 84.0338 4.20289C84.2394 3.52449 83.2063 3.25211 82.9802 3.91509V3.92022Z"
      fill="#52606D"
    />
    <path
      d="M29.474 2.0392C27.9939 19.3076 26.2362 36.5297 23.3068 53.6181C20.4757 69.9821 16.81 86.1906 12.3239 102.18C9.66167 111.724 6.49066 121.098 3.70511 130.601C3.45328 131.454 4.73298 131.778 5.02593 130.966C10.9362 114.7 15.3664 97.6679 19.2466 80.821C22.944 64.729 25.7713 48.4495 27.7164 32.0533C28.9036 22.0828 29.8132 12.0713 30.2861 2.0392C30.2861 1.52526 29.51 1.52526 29.4638 2.0392H29.474Z"
      fill="#52606D"
    />
    <path
      d="M8.4488 5.89372C8.4488 20.2532 9.50752 34.5869 9.28652 48.9618C9.09123 61.6356 8.29976 74.2836 7.12284 86.906C6.31595 95.571 5.15445 104.21 4.45549 112.88C4.40924 113.441 5.36002 113.579 5.44739 113.014C9.44584 86.3972 10.9363 59.1224 10.4326 32.2228C10.2733 23.4859 10.2476 14.6101 9.43556 5.89372C9.40443 5.78697 9.33951 5.69319 9.25055 5.62647C9.16159 5.55975 9.05338 5.52368 8.94218 5.52368C8.83098 5.52368 8.72278 5.55975 8.63382 5.62647C8.54486 5.69319 8.47994 5.78697 8.4488 5.89372V5.89372Z"
      fill="#52606D"
    />
    <path
      d="M50.8641 11.0691C48.0633 29.6173 43.4518 47.8464 37.0957 65.4953C30.6252 83.4009 22.1915 100.397 12.5345 116.792C12.4611 116.935 12.4454 117.102 12.4908 117.257C12.5361 117.412 12.639 117.543 12.7783 117.625C12.9177 117.706 13.0829 117.731 13.2402 117.695C13.3974 117.659 13.5348 117.563 13.6241 117.429C33.9041 85.6263 46.7269 48.6381 51.9383 11.3672C52.0359 10.6631 50.9721 10.3393 50.8641 11.0691V11.0691Z"
      fill="#52606D"
    />
    <path
      d="M67.5929 151.673C77.7826 151.673 87.9689 151.71 98.1518 151.786C99.1796 151.786 99.1796 150.244 98.1518 150.203C87.986 149.771 77.7689 150.085 67.5929 150.105C67.396 150.121 67.2123 150.211 67.0784 150.356C66.9445 150.501 66.8701 150.691 66.8701 150.889C66.8701 151.086 66.9445 151.277 67.0784 151.422C67.2123 151.567 67.396 151.657 67.5929 151.673V151.673Z"
      fill="#52606D"
    />
    <path
      d="M1.10461 151.57C10.448 152.233 19.9456 151.673 29.3045 151.632C29.4908 151.616 29.6644 151.531 29.791 151.393C29.9176 151.256 29.9878 151.076 29.9878 150.889C29.9878 150.702 29.9176 150.522 29.791 150.384C29.6644 150.247 29.4908 150.162 29.3045 150.146C19.9353 150.264 10.4532 150.09 1.10461 150.768C0.998291 150.768 0.896327 150.81 0.821149 150.886C0.745971 150.961 0.703735 151.063 0.703735 151.169C0.703735 151.275 0.745971 151.377 0.821149 151.452C0.896327 151.528 0.998291 151.57 1.10461 151.57V151.57Z"
      fill="#52606D"
    />
    <path
      d="M183.825 141.8C187.757 142.021 191.766 141.728 195.708 141.712C195.887 141.697 196.055 141.615 196.177 141.482C196.299 141.35 196.366 141.176 196.366 140.996C196.366 140.815 196.299 140.641 196.177 140.509C196.055 140.376 195.887 140.294 195.708 140.279C191.735 140.279 187.618 140.119 183.676 140.71C183.029 140.808 183.234 141.764 183.825 141.8V141.8Z"
      fill="#52606D"
    />
    <path
      d="M185.367 151.118C189.546 151.549 194.618 151.333 198.812 150.994C198.992 150.979 199.159 150.897 199.281 150.764C199.403 150.631 199.471 150.458 199.471 150.277C199.471 150.097 199.403 149.923 199.281 149.79C199.159 149.658 198.992 149.576 198.812 149.56C194.326 149.302 189.827 149.448 185.367 149.997C185.219 149.997 185.076 150.056 184.971 150.161C184.866 150.266 184.807 150.409 184.807 150.557C184.807 150.706 184.866 150.848 184.971 150.954C185.076 151.059 185.219 151.118 185.367 151.118V151.118Z"
      fill="#52606D"
    />
    <path
      d="M155.446 2.58913C155.538 18.0073 155.883 33.4512 156.191 48.8796C156.499 64.3081 156.242 79.7777 156.941 95.1702C156.959 95.4003 157.062 95.6154 157.231 95.7723C157.4 95.9292 157.623 96.0165 157.854 96.0165C158.084 96.0165 158.307 95.9292 158.476 95.7723C158.645 95.6154 158.748 95.4003 158.766 95.1702C159.079 79.7777 158.252 64.2721 157.861 48.8796C157.471 33.4872 157.183 18.0433 156.833 2.58913C156.833 2.40648 156.761 2.23131 156.632 2.10216C156.502 1.97301 156.327 1.90045 156.145 1.90045C155.962 1.90045 155.787 1.97301 155.658 2.10216C155.529 2.23131 155.456 2.40648 155.456 2.58913H155.446Z"
      fill="#52606D"
    />
    <path
      d="M163.694 2.8615C162.959 16.1571 163.571 29.6994 163.967 43.0104C164.306 54.2811 164.943 65.5364 165.586 76.7968C166.069 85.1398 166.54 93.4844 166.999 101.831C167.061 102.956 168.818 102.961 168.757 101.831C167.395 77.2028 165.673 52.5903 165.211 27.9212C165.061 19.5851 165.329 11.1873 164.897 2.8615C164.897 2.70202 164.834 2.54908 164.721 2.43631C164.608 2.32355 164.455 2.26019 164.296 2.26019C164.136 2.26019 163.983 2.32355 163.871 2.43631C163.758 2.54908 163.694 2.70202 163.694 2.8615V2.8615Z"
      fill="#52606D"
    />
    <path
      d="M99.5395 139.991C90.1395 139.652 80.719 139.78 71.3088 139.718C57.1395 139.631 42.9702 139.508 28.7957 139.451C25.121 139.451 21.3847 139.251 17.7152 139.492L18.6043 140.381C18.5529 137.298 18.1829 134.271 18.1315 131.202C18.1146 130.983 18.0155 130.778 17.854 130.628C17.6925 130.479 17.4805 130.396 17.2603 130.396C17.0402 130.396 16.8282 130.479 16.6667 130.628C16.5052 130.778 16.4061 130.983 16.3892 131.202C16.2659 134.286 16.7593 137.313 16.8209 140.381C16.8236 140.618 16.9187 140.844 17.0858 141.011C17.2529 141.178 17.4788 141.273 17.7152 141.276C27.1254 140.618 36.7721 141.209 46.1977 141.245C60.3465 141.291 74.4952 141.384 88.644 141.399C92.2415 141.399 96.1166 141.702 99.6834 141.08C100.321 140.972 100.141 140.017 99.5395 139.991V139.991Z"
      fill="#52606D"
    />
    <path
      d="M51.3267 186.225C62.2325 177.4 74.5105 170.015 86.4647 162.712C86.5722 162.647 86.6611 162.555 86.7227 162.446C86.7844 162.337 86.8168 162.213 86.8168 162.088C86.8168 161.962 86.7844 161.839 86.7227 161.729C86.6611 161.62 86.5722 161.528 86.4647 161.463C76.8204 156.88 66.6484 153.502 56.1783 151.405C53.6531 150.888 51.1194 150.436 48.5771 150.049C47.4842 149.884 46.386 149.732 45.2828 149.591C44.1881 149.447 42.4201 148.908 41.3409 149.134C40.6203 149.378 39.9384 149.724 39.316 150.162L11.9692 165.297L3.05753 170.236C2.93384 170.313 2.83498 170.425 2.77272 170.557C2.71047 170.688 2.68745 170.835 2.7064 170.98C2.72536 171.125 2.7855 171.261 2.87964 171.372C2.97379 171.484 3.09798 171.566 3.23741 171.608C18.9845 176.434 34.6648 181.373 50.2474 186.708C51.1571 187.021 51.522 185.608 50.6432 185.279C35.225 179.533 19.3648 174.97 3.63314 170.159L3.81302 171.531L24.7766 159.906L37.8666 152.659C38.9202 152.079 40.1485 151.092 41.31 150.748C41.8805 150.583 41.8548 150.65 42.6309 150.778C45.5038 151.246 48.4127 151.503 51.2856 152.002C63.2013 153.986 74.7877 157.598 85.7195 162.738V161.489C73.7139 168.874 61.2612 176.296 50.4941 185.433C49.9237 185.922 50.7408 186.708 51.3113 186.251L51.3267 186.225Z"
      fill="#52606D"
    />
    <path
      d="M7.72927 177.575C5.96132 178.829 4.09572 179.929 2.32776 181.173C2.22296 181.239 2.13929 181.333 2.08668 181.446C2.03408 181.558 2.01474 181.683 2.03097 181.805C2.0472 181.928 2.09832 182.044 2.17825 182.138C2.25819 182.233 2.36358 182.303 2.48195 182.339C18.08 188.137 33.7243 194.026 49.6462 198.873C50.5044 199.135 50.8693 197.794 50.0162 197.526C34.1201 192.582 18.4449 186.857 2.82115 181.106L2.97533 182.278C4.733 181.013 6.5729 179.903 8.36141 178.644C9.01412 178.176 8.36142 177.102 7.72927 177.56V177.575Z"
      fill="#52606D"
    />
    <path
      d="M8.77255 173.479C9.02952 175.715 9.33274 178.834 8.01192 180.818C7.97061 180.891 7.94636 180.973 7.94097 181.057C7.93558 181.141 7.94919 181.225 7.9808 181.303C8.0124 181.381 8.06118 181.451 8.12351 181.508C8.18585 181.564 8.26013 181.606 8.34084 181.63C15.464 183.994 22.5821 186.367 29.695 188.748C33.1384 189.906 36.5801 191.068 40.0201 192.233C42.5898 193.101 45.5552 194.7 48.2431 194.931C48.3757 194.929 48.504 194.884 48.6094 194.803C48.7148 194.723 48.7918 194.611 48.829 194.484C49.9751 191.4 50.2783 188.856 49.2401 185.69C49.202 185.554 49.1112 185.438 48.9879 185.369C48.8645 185.3 48.7186 185.282 48.5823 185.32C48.446 185.358 48.3304 185.449 48.261 185.573C48.1916 185.696 48.1741 185.842 48.2123 185.978C48.8226 188.108 48.8351 190.364 48.2482 192.5C47.9656 193.739 48.3613 193.656 47.2563 193.42C46.3835 193.177 45.5255 192.883 44.6866 192.541C40.7053 191.171 36.7189 189.827 32.7273 188.512C24.6995 185.839 16.6649 183.191 8.6235 180.566L8.95243 181.373C10.376 179.215 10.1088 175.72 9.65652 173.361C9.56401 172.847 8.7006 172.965 8.76227 173.479H8.77255Z"
      fill="#52606D"
    />
    <path
      d="M11.8304 178.356C23.0035 181.841 34.0686 185.839 45.3238 189.036C45.4521 189.052 45.5819 189.022 45.6896 188.95C45.7973 188.878 45.8757 188.771 45.9106 188.646C45.9454 188.522 45.9344 188.389 45.8796 188.272C45.8247 188.155 45.7296 188.061 45.6117 188.008C34.6339 184.061 23.3015 180.664 12.0411 177.596C11.9902 177.582 11.937 177.578 11.8846 177.585C11.8322 177.592 11.7817 177.609 11.7359 177.635C11.6434 177.688 11.5757 177.775 11.5477 177.878C11.5198 177.981 11.5339 178.091 11.5869 178.184C11.6399 178.276 11.7275 178.344 11.8304 178.372V178.356Z"
      fill="#52606D"
    />
    <path
      d="M85.8224 162.486C86.6909 164.1 86.7526 166.083 86.6961 167.898C86.6498 169.409 86.619 169.984 85.4369 170.884C83.597 172.282 81.7057 173.613 79.8453 174.995C75.3911 178.271 70.937 181.546 66.4828 184.822C60.8843 188.933 55.2961 193.072 49.7181 197.238L50.8437 198.102C52.3135 194.129 53.0999 190.059 51.6249 185.968C51.3216 185.125 49.9905 185.454 50.2578 186.348C51.445 190.3 50.7717 193.898 49.3687 197.696C49.331 197.842 49.3372 197.997 49.3865 198.139C49.4359 198.282 49.5261 198.408 49.646 198.5C49.766 198.592 49.9103 198.646 50.0612 198.657C50.212 198.668 50.3625 198.634 50.4942 198.559C57.0589 193.708 63.6168 188.851 70.1678 183.989C74.8721 180.505 79.5797 177.025 84.2908 173.551C85.6168 172.575 87.6314 171.67 87.9809 169.953C88.4949 167.512 88.1351 164.059 86.8657 161.869C86.7757 161.745 86.6427 161.659 86.4926 161.627C86.3425 161.596 86.186 161.621 86.0537 161.699C85.9215 161.777 85.8229 161.901 85.7773 162.047C85.7316 162.194 85.7422 162.352 85.807 162.491L85.8224 162.486Z"
      fill="#52606D"
    />
    <path
      d="M91.7635 188.147C83.9928 189.457 76.4687 191.575 68.775 193.235L69.1862 194.217L69.2324 194.191C69.3558 194.121 69.4462 194.004 69.4838 193.867C69.5214 193.731 69.5031 193.584 69.4329 193.461C69.3627 193.338 69.2464 193.247 69.1095 193.21C68.9726 193.172 68.8264 193.19 68.7031 193.261C66.488 194.412 64.3089 195.62 62.1298 196.833C62.0277 196.895 61.9487 196.989 61.9045 197.101C61.8602 197.212 61.8532 197.335 61.8844 197.45C61.9156 197.566 61.9834 197.668 62.0777 197.742C62.1719 197.816 62.2876 197.858 62.4073 197.86C65.4087 197.819 68.4256 198.277 71.3653 197.665C74.0069 197.115 76.6486 196.576 79.2902 196.01C83.854 195.029 88.4897 194.201 93.0073 193.029C93.6548 192.86 93.367 191.955 92.74 192.048C88.2739 192.731 83.8643 193.847 79.4547 194.813C77.2413 195.296 75.0245 195.767 72.8043 196.226C69.2787 196.961 65.9895 196.709 62.4073 196.766L62.6849 197.794C64.8588 196.576 67.0379 195.363 69.2479 194.196L68.7031 193.261L68.6568 193.286C68.5508 193.356 68.4726 193.461 68.4358 193.583C68.399 193.705 68.4059 193.835 68.4553 193.953C68.5047 194.07 68.5935 194.166 68.7064 194.224C68.8192 194.283 68.9489 194.3 69.0731 194.273C76.7565 192.598 84.2703 190.419 92.0154 189.005C92.5858 188.902 92.3391 188.049 91.7789 188.147H91.7635Z"
      fill="#52606D"
    />
    <path
      d="M114.012 183.105C106.652 184.313 99.3492 185.983 92.0924 187.705C91.9646 187.744 91.8506 187.818 91.7642 187.92C91.6778 188.022 91.6226 188.146 91.6053 188.279C91.5879 188.411 91.6091 188.546 91.6663 188.666C91.7236 188.787 91.8144 188.889 91.9279 188.959C93.1066 189.836 93.2607 190.908 92.3905 192.176C92.2881 192.284 92.2237 192.423 92.2068 192.571C92.1899 192.719 92.2215 192.869 92.2968 192.997C92.3721 193.126 92.487 193.226 92.6244 193.284C92.7617 193.342 92.9141 193.353 93.0586 193.317C100.475 191.832 108.03 190.537 115.322 188.517C115.407 188.493 115.486 188.453 115.556 188.399C115.625 188.345 115.683 188.277 115.726 188.201C115.769 188.124 115.797 188.04 115.807 187.953C115.818 187.865 115.811 187.777 115.788 187.692C115.764 187.607 115.724 187.528 115.67 187.459C115.615 187.39 115.548 187.332 115.471 187.289C115.395 187.245 115.31 187.218 115.223 187.207C115.136 187.197 115.047 187.203 114.963 187.227C107.536 188.769 100.141 190.506 92.6937 191.996L93.3567 193.142C93.7138 192.764 93.9817 192.311 94.1406 191.816C94.2995 191.321 94.3454 190.796 94.2749 190.281C94.2043 189.766 94.0193 189.273 93.7332 188.839C93.4471 188.404 93.0673 188.04 92.6217 187.772L92.4573 189.026C99.7141 187.304 106.991 185.567 114.346 184.318C115.143 184.184 114.803 182.977 114.012 183.105V183.105Z"
      fill="#52606D"
    />
    <path
      d="M114.063 184.056C115.091 184.95 115.091 186.451 114.644 187.617C114.346 188.394 115.569 188.872 115.97 188.173C116.9 186.554 116.422 184.138 114.726 183.198C114.212 182.91 113.575 183.64 114.063 184.056V184.056Z"
      fill="#52606D"
    />
    <path
      d="M68.816 194.042C69.1541 194.507 69.3714 195.049 69.4482 195.62C69.4231 196.182 69.2978 196.736 69.0781 197.254C68.9497 197.768 69.7103 198.133 69.9621 197.629C70.2724 196.979 70.4125 196.261 70.3694 195.541C70.3262 194.822 70.1012 194.126 69.7154 193.518C69.3711 192.942 68.4511 193.476 68.816 194.032V194.042Z"
      fill="#52606D"
    />
  </svg>
);

export default PlantNight;
