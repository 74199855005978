import styled from 'styled-components';
import { LG_SCREEN } from '../../theme';

export default styled.div`
  min-width: 320px;
  width: 100%;

  /* Desktop */
  @media (min-width: ${LG_SCREEN}px) {
    width: ${LG_SCREEN}px;
  }
`;
