import React from 'react';
import { GREY_200 } from '../../../theme';

const CaretUpIcon = ({ width = 10, height = 6, color = GREY_200 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.10466 5.94091L1.31581 5.94091C0.776979 5.94091 0.507563 5.30349 0.888983 4.92994L4.7819 1.11435C5.01801 0.883098 5.40246 0.883098 5.63858 1.11435L9.53149 4.92994C9.91291 5.3035 9.64349 5.94091 9.10466 5.94091Z"
      fill={color}
    />
  </svg>
);

export default CaretUpIcon;
