import isString from 'lodash.isstring';
import { escapeCtrlChars } from './escapeCtrlChars';

export default function getAnnoTextFromDelta(delta) {
  return delta.reduce(
    (sum, op) => {
      if (!op.insert) {
        throw new TypeError('only `insert` operations can be transformed!');
      }

      // Mention of some kind
      if (op.insert && op.insert.mention) {
        const { id, value } = op.insert.mention;
        return {
          text: `${sum.text}<@${value}|${id}>`,
          friendIds: sum.friendIds.concat(op.insert.mention.id),
        };
      }
      if (isString(op.insert)) {
        return {
          text: `${sum.text}${escapeCtrlChars(op.insert)}`,
          friendIds: sum.friendIds,
        };
      }
      return sum;
    },
    {
      text: '',
      friendIds: [],
    }
  );
}
