import styled from 'styled-components';
import {
  WHITE_000,
  GREY_200,
  TEAL_200,
  BORDER_MD,
  LG_SCREEN,
} from '../../theme';

const DialogCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;

  font-size: 16px;

  border-radius: ${BORDER_MD};
  background-color: ${WHITE_000};

  min-width: 300px;
  width: 100%;

  /* Desktop */
  @media (min-width: ${LG_SCREEN}px) {
    width: 400px;
  }
`;

DialogCard.Header = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: ${TEAL_200};
  padding: 10px 15px;
  border-radius: ${BORDER_MD} ${BORDER_MD} 0 0;
`;

DialogCard.Body = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%
  width: 100%;
  padding: 10px 15px;
`;

DialogCard.Footer = styled.div`
  box-sizing: border-box;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 15px;
  border-top: 1px solid ${GREY_200};
  border-radius: 0 0 ${BORDER_MD} ${BORDER_MD};
`;

export default DialogCard;
