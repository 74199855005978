const CONTROL_CHARS = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '|': '&#124;',
};

const REVERSE_CONTROL_CHARS = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&#124;': '|',
};

export function escapeCtrlChars(text) {
  return text
    .replace(/&/g, CONTROL_CHARS['&'])
    .replace(/</g, CONTROL_CHARS['<'])
    .replace(/>/g, CONTROL_CHARS['>'])
    .replace(/\|/g, CONTROL_CHARS['|']);
}

export function unescapeCtrlChars(text) {
  return text
    .replace(/&#124;/g, REVERSE_CONTROL_CHARS['&#124;'])
    .replace(/&lt;/g, REVERSE_CONTROL_CHARS['&lt;'])
    .replace(/&gt;/g, REVERSE_CONTROL_CHARS['&gt;'])
    .replace(/&amp;/g, REVERSE_CONTROL_CHARS['&amp;']);
}
