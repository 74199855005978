import React from 'react';

const LinePlantCoffee = ({ width = 102, height = 88 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 102 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.2603 40.2137C59.3151 40.7178 55.6877 41.6439 55.0301 41.6439C52.0603 41.6165 51.8466 41.6 48.9425 40.7178C48.8712 40.7178 47.9233 42.0055 46.8219 42.4384C43.7808 43.611 42.2082 43.1124 38.8822 42.3124C37.8775 41.8796 36.913 41.3588 36 40.7562C33.1452 41.611 31.326 42.2356 28.5534 41.8521C24.4493 41.2603 22.252 38.9206 22.1479 38.7343C18.8931 39.1781 12 40.9261 11.9945 41.5836C11.9945 43.1343 21.726 44.7672 34.926 44.9097C48.126 45.0521 60.526 42.6247 60.5425 41.074C60.5425 40.7069 60.3233 40.4548 59.2603 40.2137Z"
      fill="#616E7C"
    />
    <path
      d="M96.4164 59.8794C94.8712 59.2657 86.9096 58.7013 82.4164 58.6465C77.3283 58.5247 72.2389 58.8234 67.2 59.5397C65.9945 59.6931 71.7096 61.0246 72.7835 61.1123C80.137 61.715 90.8657 61.2219 91.4849 61.1835C92.7671 61.0958 97.6329 60.3671 96.4164 59.8794Z"
      fill="#616E7C"
    />
    <path
      opacity="0.15"
      d="M94.5809 45.8958C94.4885 45.6852 94.343 45.5022 94.1585 45.3648C93.9741 45.2275 93.7571 45.1404 93.5288 45.1123C93.6768 44.2769 93.5719 43.4163 93.2275 42.641C92.6302 41.3753 90.3124 40.5698 88.2959 40.7616C90.1042 39.3972 91.6713 37.589 92.2576 35.3588C93.7151 29.8301 89.1891 25.3589 84.0384 24.5315C80.5425 23.9835 78.9809 28.9424 82.5425 29.9561C85.748 30.8657 85.8795 33.5616 83.0411 35.1123C81.2001 36.1205 78.8603 36.2794 77.2165 37.6383C76.2137 38.4712 75.3809 39.8849 75.7096 41.3205C75.6548 41.3972 74.1973 41.8246 73.9343 43.1999C73.2713 46.6082 75.2274 49.7314 78.3453 50.3561C79.7239 50.5748 81.1233 50.6318 82.5151 50.526C81.611 52.0766 81.7042 53.6492 83.2932 54.6849C84.5042 55.4739 86.6466 54.4876 87.4028 52.9479C91.4357 52.1972 96.7233 50.3123 94.5809 45.8958Z"
      fill="#7B8794"
    />
    <path
      d="M70.1151 56.5096C70.7781 51.4686 71.4466 46.5206 71.4301 41.4193C71.4301 39.9891 69.2 39.9836 69.2055 41.4193C69.2055 46.5206 68.537 51.4686 67.874 56.5096C67.6877 57.9343 69.9288 57.9233 70.1151 56.5096Z"
      fill="#616E7C"
    />
    <path
      d="M1.56703 41.7534C1.62731 46.6575 2.37799 51.6548 3.01361 56.515C3.44302 60.5823 4.17565 64.6117 5.20539 68.5698C7.03005 74.9479 11.1616 80.0767 17.3205 82.7725C24.6026 85.9397 33.8246 86.2356 41.6547 86.0602C48.23 85.9068 56.2136 85.2054 61.3314 80.6411C62.4273 79.6712 60.8163 78.0821 59.7314 79.041C54.7013 83.5068 46.1917 83.7863 39.8081 83.8082C32.7396 83.8356 24.5698 83.6547 18.0163 80.7726C3.1232 74.2191 5.64375 54.7616 3.04649 41.5507C2.8821 40.7123 1.56155 40.915 1.56703 41.7534Z"
      fill="#616E7C"
    />
    <path
      d="M10.8548 38.1918C9.39723 38.1918 0.246548 39.3589 1.5397 42.0548C2.21367 43.474 6.00545 44.0438 7.2712 44.4C10.1572 45.2068 13.0908 45.8327 16.0548 46.274C30.1295 48.2893 44.428 48.1655 58.4657 45.9069C62.2575 45.2932 68.4329 44.7233 71.1671 41.5671C71.2618 41.4667 71.3329 41.3465 71.3754 41.2152C71.4179 41.0839 71.4308 40.9448 71.4129 40.8079C71.3951 40.6711 71.3471 40.5399 71.2723 40.4238C71.1976 40.3078 71.098 40.2098 70.9808 40.137C68.4356 38.3371 65.4525 37.2563 62.3452 37.0082C61.1835 36.9151 61.2109 38.6959 62.3452 38.811C65.1226 39.1006 67.7792 40.0987 70.0602 41.7096L69.8794 40.2795C67.315 43.2548 60.915 43.726 57.3698 44.2795C49.7238 45.4201 41.9985 45.9442 34.2685 45.8466C26.6959 45.8082 18.926 45.4137 11.5287 43.7863C10.0219 43.4521 8.52052 43.0685 7.03559 42.6411C6.36709 42.4493 3.1397 41.3041 3.10682 41.5014C3.03559 41.9507 4.26299 41.2329 4.56983 41.1233C5.2986 40.8658 6.03833 40.6521 6.76162 40.4548C8.07887 40.0531 9.43723 39.8013 10.8109 39.7041C10.9982 39.6881 11.1727 39.6023 11.2999 39.4638C11.427 39.3253 11.4975 39.1442 11.4975 38.9562C11.4975 38.7682 11.427 38.587 11.2999 38.4485C11.1727 38.31 10.9982 38.2243 10.8109 38.2082L10.8548 38.1918Z"
      fill="#616E7C"
    />
    <path
      d="M31.9726 20.6191C30.4055 14.4547 25.7699 9.29309 20.2904 6.2246C20.1243 6.12869 19.9359 6.07805 19.7441 6.07776C19.5523 6.07747 19.3638 6.12754 19.1974 6.22294C19.031 6.31834 18.8925 6.45575 18.7959 6.62142C18.6992 6.7871 18.6478 6.97525 18.6466 7.16706C18.0274 16.4821 19.1069 27.5397 28.1261 32.8164C29.4247 33.578 30.4713 31.6766 29.2877 30.8273C21.3534 25.1397 20.3288 16.252 20.8165 7.16706L19.1726 8.10404C24.4313 10.9076 28.5743 15.4237 30.9151 20.9041C30.9722 21.0169 31.0665 21.1065 31.1821 21.1577C31.2977 21.2089 31.4275 21.2186 31.5494 21.1851C31.6713 21.1515 31.7779 21.0768 31.851 20.9737C31.9242 20.8705 31.9594 20.7453 31.9507 20.6191H31.9726Z"
      fill="#616E7C"
    />
    <path
      d="M37.4246 40.4164C35.9287 39.1342 36.515 37.8191 37.5123 36.2958C38.2824 35.1369 39.1204 34.0244 40.0219 32.9643C40.537 32.3506 41.4794 30.8274 42.3233 30.8821C43.052 30.926 44.5479 32.6191 45.1397 33.1123C47.7698 35.3041 49.6109 39.5232 45.8356 41.7205C43.0027 43.3643 39.5506 42.0164 37.1671 40.2191L36.1315 41.9945L36.1808 42.0328C37.2767 42.8 38.2849 41.0191 37.2164 40.2575L37.1671 40.2191C36.0712 39.4465 35.0904 41.2164 36.1315 41.9945C39.3808 44.4438 44.2191 45.6822 47.6986 42.926C51.2931 40.0767 50.4383 35.2109 47.3643 32.3123C46.1315 31.1397 44.2356 29.0958 42.4876 28.7287C40.7397 28.3616 39.5561 30.3452 38.5643 31.5123C36.1534 34.3506 32.2301 38.6356 35.9726 41.8739C36.9917 42.7287 38.4548 41.2821 37.4246 40.4164Z"
      fill="#616E7C"
    />
    <path
      d="M49.6658 35.863C52.1808 33.8685 54.4 31.8411 57.2055 30.2082C59.73 28.8469 62.1494 27.2992 64.4438 25.5781L62.652 25.1069C63.7479 29.4137 63.8247 35.1069 59.4356 37.7096C57.1123 39.1014 53.0685 40.1206 50.4822 39.0849C49.0137 38.4986 48.3781 40.8713 49.8301 41.4521C53.2603 42.8219 58.789 41.3863 61.6055 39.1452C65.863 35.7589 66.537 29.326 64.7233 24.5151C64.6686 24.3373 64.5695 24.1764 64.4354 24.0475C64.3013 23.9186 64.1366 23.826 63.9569 23.7783C63.7771 23.7306 63.5881 23.7294 63.4078 23.7749C63.2274 23.8204 63.0617 23.9111 62.926 24.0384C60.2904 26.3178 57.0794 27.8192 54.1863 29.7315C52.0658 31.1343 49.5233 32.7671 48.8 35.337C48.7884 35.4424 48.8076 35.549 48.8553 35.6437C48.9031 35.7384 48.9773 35.8173 49.0689 35.8706C49.1606 35.924 49.2658 35.9496 49.3717 35.9444C49.4777 35.9392 49.5798 35.9033 49.6658 35.8411V35.863Z"
      fill="#616E7C"
    />
    <path
      d="M43.4958 25.5506C44.5643 23.5945 44.7506 21.7808 46.4383 20.126C48.4712 18.1369 51.0465 16.7452 53.4301 15.2219L52.1643 14.8931C55.1342 19.726 53.7534 27.6493 50.7561 32.1972C49.9835 33.3698 51.852 34.4602 52.6356 33.2931C56.0328 28.126 57.5671 19.1999 53.7589 13.9561C53.6343 13.7466 53.4326 13.5942 53.1969 13.5317C52.9613 13.4693 52.7106 13.5016 52.4986 13.6219C48.1424 16.6191 42.5753 19.2931 42.4931 25.2767C42.5005 25.391 42.5436 25.5001 42.6162 25.5887C42.6889 25.6773 42.7875 25.7409 42.8982 25.7705C43.0089 25.8002 43.126 25.7944 43.2333 25.7539C43.3405 25.7135 43.4323 25.6405 43.4958 25.5452V25.5506Z"
      fill="#616E7C"
    />
    <path
      d="M55.6986 22.3398C57.3863 19.0521 59.348 15.8356 62.274 13.4685L60.7343 13.063C61.8302 18.1863 61.7699 22.7014 58.6904 27.1288C58.1151 27.9617 59.3863 28.9096 60.0767 28.1973C63.7863 24.3617 64.4932 17.3918 62.5096 12.5754C62.4648 12.4215 62.381 12.2819 62.2664 12.1699C62.1519 12.0579 62.0103 11.9774 61.8555 11.936C61.7007 11.8947 61.5379 11.894 61.3828 11.934C61.2276 11.974 61.0854 12.0534 60.9699 12.1644C58.2302 15.1014 55.8521 17.9891 54.3945 21.7809C54.1041 22.5425 55.3425 23.0576 55.7096 22.3288L55.6986 22.3398Z"
      fill="#616E7C"
    />
    <path
      d="M21.863 34.6247C20.3898 32.547 18.233 31.0535 15.7698 30.4055C11.863 29.3534 8.27393 30.4877 4.35064 30.7397C3.8027 30.7781 3.13968 31.3534 3.4027 31.9891C6.41092 39.3644 15.1287 43.6712 22.2301 39.1123C23.326 38.4219 22.3068 36.6904 21.2274 37.3918C15.3096 41.2274 7.81365 37.7534 5.30406 31.4795L4.35064 32.7288C7.75338 32.5315 11.0959 31.4904 14.4986 32.1808C17.0794 32.7288 18.789 34.1425 20.8931 35.6055C21.4411 36 22.2794 35.2055 21.863 34.6356V34.6247Z"
      fill="#616E7C"
    />
    <path
      d="M27.6164 12.4275C26.7945 8.55349 28.5315 5.60553 30.6137 2.56442L29.3479 2.89318C31.0465 3.86852 32.8164 4.68497 34.2794 6.03292C35.4372 7.1954 36.3862 8.54848 37.0849 10.0329C37.5397 10.8658 38.7287 10.2685 38.5424 9.41923C37.7698 5.34798 33.5616 3.12332 30.3233 1.29866C30.1104 1.18043 29.8603 1.14862 29.6246 1.20983C29.389 1.27104 29.1859 1.42057 29.0575 1.62743C27.7698 3.55072 26.4767 5.24935 25.9835 7.56717C25.6328 9.21101 25.4356 11.6877 26.7178 12.9754C26.7976 13.0851 26.9152 13.1613 27.0479 13.1893C27.1807 13.2173 27.3191 13.195 27.4363 13.1268C27.5536 13.0587 27.6414 12.9494 27.6828 12.8202C27.7241 12.691 27.7161 12.5511 27.6602 12.4275H27.6164Z"
      fill="#616E7C"
    />
    <path
      d="M20.5041 19.0137C18.0328 15.3205 13.2986 13.4301 9.3972 11.6548C9.25325 11.5742 9.09083 11.5323 8.92583 11.5332C8.76084 11.5342 8.59891 11.5779 8.45589 11.6602C8.31287 11.7425 8.19365 11.8605 8.10989 12.0026C8.02613 12.1448 7.98069 12.3062 7.97803 12.4712C8.12049 16.6466 8.08214 20.926 9.83008 24.8109C11.3916 28.3646 14.0902 31.2984 17.5013 33.1507C17.7061 33.2494 17.9406 33.2674 18.158 33.201C18.3754 33.1347 18.5599 32.9888 18.6746 32.7926C18.7894 32.5963 18.8259 32.364 18.777 32.142C18.7282 31.9199 18.5974 31.7245 18.4109 31.5945C15.1859 29.7013 12.6804 26.7911 11.2876 23.3205C9.96707 19.8794 10.0164 16.1041 9.8794 12.4712L8.45474 13.2877C12.241 14.9918 16.5424 16.7781 19.4465 19.8356C19.9945 20.3835 20.9479 19.6657 20.5424 19.0137H20.5041Z"
      fill="#616E7C"
    />
    <path
      d="M43.4521 11.4849C44.6137 7.70413 46.1151 4.36167 49.8247 2.44386L48.4987 1.89591C49.5946 4.47673 50.7617 7.13426 50.8987 9.97261C50.9973 12.0932 50.5315 13.5781 49.8028 15.5233C49.3425 16.7562 51.2603 17.2274 51.748 16.0712C53.8466 11.0521 52.1863 6.01097 50.148 1.21098C50.0248 1.00738 49.8272 0.859752 49.5971 0.799399C49.3669 0.739046 49.1223 0.770712 48.9151 0.887691C45.2987 2.87125 42.1041 7.04112 42.1644 11.2986C42.1673 11.4556 42.2262 11.6063 42.3305 11.7235C42.4348 11.8408 42.5776 11.9169 42.7331 11.938C42.8886 11.9592 43.0466 11.9241 43.1784 11.839C43.3103 11.7539 43.4073 11.6244 43.4521 11.474V11.4849Z"
      fill="#616E7C"
    />
    <path
      d="M32.4273 33.2987C31.1287 27.5836 31.5068 22.7398 34.3342 17.5343C36.5919 13.1911 39.4287 9.17452 42.7671 5.59458L41.0465 5.14526C42.151 10.1827 42.6676 15.3312 42.5862 20.4877C42.5095 23.748 41.3424 26.5589 40.1424 29.5781C39.5945 30.948 41.8301 31.5617 42.3342 30.1864C43.589 26.9535 44.7287 23.9672 44.778 20.4877C44.8168 15.1393 44.2209 9.80525 43.0027 4.59731C42.9528 4.42585 42.8595 4.27019 42.7318 4.14543C42.6041 4.02068 42.4462 3.93107 42.2737 3.88531C42.1011 3.83954 41.9196 3.83917 41.7468 3.88424C41.574 3.92931 41.4159 4.01827 41.2876 4.14251C34.0328 11.8466 27.589 22.9973 30.1808 33.9069C30.526 35.348 32.7287 34.7398 32.3725 33.2987H32.4273Z"
      fill="#616E7C"
    />
    <path
      d="M36.1041 39.4521C34.1534 33.4521 28.3616 29.5617 22.4767 28.0493C22.2239 27.9818 21.9547 28.0164 21.7271 28.1455C21.4996 28.2747 21.3319 28.4881 21.2602 28.7397C20.3123 33.2603 19.0027 38.6028 23.6054 41.6548C26.6739 43.6822 32.4822 44.1096 35.6 41.9781C35.8146 41.8443 35.9744 41.6383 36.0508 41.3972C36.1273 41.1561 36.1152 40.8957 36.0169 40.6627C35.9185 40.4297 35.7403 40.2394 35.5142 40.126C35.2882 40.0125 35.0291 39.9834 34.7835 40.0439C31.7972 40.5535 28.9917 41.5452 25.989 40.3507C21.3643 38.5151 21.863 33.1891 23.1726 29.2658L21.9506 29.9562C27.589 31.5123 31.7589 35.0247 34.5534 40.0987C34.658 40.2722 34.8217 40.4024 35.0143 40.4652C35.207 40.5281 35.4159 40.5195 35.6028 40.441C35.7896 40.3625 35.942 40.2194 36.0321 40.0378C36.1221 39.8563 36.1438 39.6483 36.0931 39.4521H36.1041Z"
      fill="#616E7C"
    />
    <path
      d="M61.6493 59.0575C61.2219 63.9452 62.6301 69.715 64.4274 74.2246C65.8849 77.8685 67.9671 82.0986 71.8027 83.6932C72.5808 84.0164 73.074 82.7507 72.3507 82.3452C64.1698 77.6603 64.4329 66.8876 62.4877 58.9424C62.3616 58.4438 61.6822 58.5972 61.6438 59.0575H61.6493Z"
      fill="#616E7C"
    />
    <path
      d="M100.91 59.7151C100.11 64.0274 100.148 68.3123 98.6521 72.5151C97.1946 76.6137 94.9096 80.5753 90.7946 82.3781C90.022 82.7178 90.5918 83.8466 91.3425 83.6822C95.4411 82.7781 97.9179 78.0986 99.3809 74.5644C101.14 70.2849 102.581 64.3562 101.759 59.7205C101.731 59.6295 101.675 59.5497 101.599 59.493C101.522 59.4364 101.429 59.4058 101.334 59.4058C101.239 59.4058 101.146 59.4364 101.07 59.493C100.994 59.5497 100.937 59.6295 100.91 59.7205V59.7151Z"
      fill="#616E7C"
    />
    <path
      d="M66.3945 81.9288C75.7096 86.0438 87.0247 86.0986 96.4767 82.3562C97.3808 81.9945 97.0247 80.5534 96.0603 80.8548C91.2384 82.3288 86.5753 83.5068 81.4849 83.4794C76.3945 83.4521 71.7096 82.148 66.8055 80.9534C66.2575 80.8219 65.8192 81.6767 66.3945 81.9288V81.9288Z"
      fill="#616E7C"
    />
    <path
      d="M66.1698 81.4027C65.6219 84.0822 66.778 85.5233 69.3369 86.0548C74.0952 87.0966 78.9601 87.5712 83.8301 87.4685C86.7462 87.3581 89.6497 87.0247 92.515 86.4712C95.4958 85.9233 96.7013 84.663 96.9972 81.5397C97.0794 80.7068 95.6821 80.515 95.5232 81.3424C95.2328 82.8877 94.8602 84.137 93.2821 84.674C92.3309 84.9445 91.357 85.1279 90.3725 85.2219C88.2629 85.5342 86.115 85.8082 83.9835 85.9233C80.822 86.0321 77.6569 85.902 74.515 85.5342C72.7999 85.3589 70.8657 85.3151 69.2109 84.8164C67.1671 84.2027 67.315 83.1726 66.7287 81.3972C66.641 81.1507 66.2301 81.1014 66.1808 81.3972L66.1698 81.4027Z"
      fill="#616E7C"
    />
    <path
      d="M62.2082 63.3206C57.5671 62.0548 52.8931 64.3178 54.0164 69.6822C54.9643 74.1534 60.7725 75.9014 64.8383 75.8192C65.0447 75.8192 65.2426 75.7372 65.3885 75.5913C65.5344 75.4454 65.6164 75.2475 65.6164 75.0411C65.6164 74.8347 65.5344 74.6368 65.3885 74.4909C65.2426 74.345 65.0447 74.263 64.8383 74.263C62.252 74.1808 57.463 73.3096 56.0712 70.8493C55.7046 70.203 55.4733 69.4889 55.3913 68.7504C55.3094 68.0119 55.3785 67.2644 55.5945 66.5534C56.6191 63.9069 59.8082 64.2356 62.0438 64.4658C62.7068 64.5315 62.8164 63.4849 62.1972 63.3206H62.2082Z"
      fill="#616E7C"
    />
    <path
      d="M63.0027 66.4548C61.6493 65.8082 59.989 65.1123 58.4876 65.6493C57.8333 65.8829 57.2922 66.3564 56.9737 66.9739C56.6553 67.5914 56.5834 68.3068 56.7725 68.9754C57.5835 71.9945 61.6766 73.3863 64.4767 73.0685C64.6611 73.0526 64.833 72.968 64.9581 72.8316C65.0833 72.6951 65.1528 72.5167 65.1528 72.3315C65.1528 72.1463 65.0833 71.9679 64.9581 71.8314C64.833 71.695 64.6611 71.6105 64.4767 71.5945C62.7671 71.5069 60.4986 71.3534 59.178 70.0986C58.241 69.211 57.4082 67.3589 59.2054 66.8712C60.2027 66.5973 61.6438 67.1014 62.5917 67.4192C63.1397 67.6164 63.5506 66.7069 63.0027 66.4493V66.4548Z"
      fill="#616E7C"
    />
    <path
      d="M101.282 59.1123C95.2548 55.5288 87.1342 55.3973 80.3507 55.4466C76.6968 55.451 73.0477 55.711 69.4301 56.2247C66.9589 56.5863 64.2246 56.9863 62.0438 58.2904C61.9131 58.372 61.8085 58.4894 61.7425 58.6287C61.6765 58.768 61.6519 58.9233 61.6715 59.0762C61.6911 59.229 61.7541 59.3731 61.8532 59.4912C61.9522 59.6093 62.083 59.6965 62.2301 59.7425C68.1698 62.0274 74.8329 62.5534 81.1561 62.663C87.7315 62.7836 94.9151 62.5534 101.266 60.5863C101.366 60.5586 101.459 60.5115 101.541 60.4476C101.623 60.3837 101.691 60.3044 101.743 60.2141C101.794 60.1237 101.826 60.0242 101.839 59.9212C101.852 59.8182 101.844 59.7137 101.816 59.6137C101.789 59.5137 101.742 59.4201 101.678 59.3383C101.614 59.2565 101.534 59.1881 101.444 59.1369C101.354 59.0858 101.254 59.0529 101.151 59.0403C101.048 59.0276 100.944 59.0353 100.844 59.063C94.381 60.4915 87.7742 61.1645 81.1561 61.0685C74.9534 60.9644 68.4986 60.4438 62.652 58.2356L62.8438 59.6822C64.8109 58.4877 67.3315 58.1206 69.5671 57.7699C73.1377 57.2406 76.7411 56.9622 80.3507 56.937C86.9644 56.8548 94.7397 56.937 100.773 60.0055C100.83 60.0389 100.893 60.0608 100.959 60.0698C101.024 60.0788 101.091 60.0748 101.155 60.0581C101.219 60.0413 101.28 60.012 101.332 59.972C101.385 59.9319 101.43 59.8819 101.463 59.8247C101.496 59.7675 101.518 59.7042 101.527 59.6386C101.536 59.5729 101.532 59.5061 101.516 59.442C101.499 59.3779 101.47 59.3177 101.429 59.2649C101.389 59.2121 101.339 59.1677 101.282 59.1342V59.1123Z"
      fill="#616E7C"
    />
  </svg>
);

export default LinePlantCoffee;
