import React from 'react';
import styled from 'styled-components';
import CenterRow from '../../../components/helpers/CenterRow';
import { GREY_500 } from '../../../theme';

const PaddingBox = styled.div`
  padding: 25px 0 0 0;
  margin: 0 auto;
`;

const Text = styled.div`
  font-size: 16px;
  text-align: center;
  margin: 0 auto;
  color: ${GREY_500};
  font-style: italic;
  margin-top: 10px;
`;

const EmptySlat = ({ image, children }) => (
  <PaddingBox>
    <CenterRow>{image}</CenterRow>
    <Text>{children}</Text>
  </PaddingBox>
);

export default EmptySlat;
