import styled from 'styled-components';
import { GREY_800 } from '../../../theme';

export default styled.label`
  cursor: text;
  display: block;
  font-size: 14px;
  font-weight: 700px;
  line-height: 14px;
  margin-bottom: 8px;
  color: ${GREY_800};
  padding: 0;
`;
