import React from 'react';
import styled from 'styled-components';
import { GREY_700, GREY_800 } from '../../theme';
import CenterColumn from '../helpers/CenterColumn';
import CenterFill from '../helpers/CenterFill';

const Background = styled(CenterFill)`
  box-sizing: border-box;
  background: ${GREY_700};
`;

const NavigationBackground = styled(CenterColumn)`
  background: ${GREY_800};
  width: 100%;
  height: 100%;
`;

const TabLayoutContainer = ({ navigation, children }) => (
  <Background>
    <NavigationBackground>{navigation}</NavigationBackground>
    <CenterColumn grow={1}>{children}</CenterColumn>
  </Background>
);

export default TabLayoutContainer;
