import React, { useState, useCallback, useEffect } from 'react';
import { FormSpy } from 'react-final-form';
import diff from 'object-diff';
import debounce from 'lodash.debounce';

const AutoSave = ({ values, onValueChange = () => {} }) => {
  const [prevValues, setPrevValues] = useState(null);

  const debouncedOnChange = useCallback(debounce(onValueChange, 500), [
    onValueChange,
  ]);

  useEffect(
    () => {
      if (!prevValues) {
        setPrevValues(values);
      } else if (prevValues !== values) {
        const diffValues = diff(prevValues, values);
        if (Object.keys(diffValues).length) {
          setPrevValues(values);
          debouncedOnChange(diffValues);
        }
      }
    },
    // eslint-disable-next-line
    [values, debouncedOnChange]
  );

  return null;
};

export default ({ ...props }) => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
);
