import omit from 'object.omit';
import { schema, normalize } from 'normalizr';
import { LOGOUT } from '../auth';
import fetchJSON from '../../utils/core/fetchJSON';

export const READ_REQUEST = 'friend/READ_REQUEST';
export const READ_SUCCESS = 'friend/READ_SUCCESS';
export const READ_FAILURE = 'friend/READ_FAILURE';
export const CREATE_REQUEST = 'friend/CREATE_REQUEST';
export const CREATE_SUCCESS = 'friend/CREATE_SUCCESS';
export const CREATE_FAILURE = 'friend/CREATE_FAILURE';
export const UPDATE_REQUEST = 'friend/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'friend/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'friend/UPDATE_FAILURE';
export const DELETE_REQUEST = 'friend/DELETE_REQUEST';
export const DELETE_SUCCESS = 'friend/DELETE_SUCCESS';
export const DELETE_FAILURE = 'friend/DELETE_FAILURE';
export * from './selectors';

const entitySchema = new schema.Entity('friend');

const initialState = {
  status: {
    ok: false,
    isFetching: false,
    error: null,
  },
  entities: {
    friend: {},
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case READ_SUCCESS: {
      const { entities } = normalize(action.payload.data, [entitySchema]);
      return {
        status: {
          ok: true,
          isFetching: false,
          error: null,
        },
        entities,
      };
    }
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS: {
      const friend = {
        ...state.entities.friend,
        [action.payload.data.id]: action.payload.data,
      };

      return {
        ...state,
        status: {
          ok: true,
          isFetching: false,
          error: null,
        },
        entities: {
          ...state.entities,
          friend,
        },
      };
    }
    case DELETE_SUCCESS: {
      const { id } = action.payload.data;
      const friend = omit(state.entities.friend, id);

      return {
        ...state,
        status: {
          ok: true,
          isFetching: false,
          error: null,
        },
        entities: {
          ...state.entities,
          friend,
        },
      };
    }
    case READ_REQUEST:
    case CREATE_REQUEST:
    case UPDATE_REQUEST:
    case DELETE_REQUEST: {
      return {
        ...state,
        status: {
          ok: false,
          isFetching: true,
          error: null,
        },
      };
    }
    case READ_FAILURE:
    case CREATE_FAILURE:
    case UPDATE_FAILURE:
    case DELETE_FAILURE: {
      return {
        ...state,
        status: {
          ok: false,
          isFetching: false,
          error: action.error,
        },
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function fetchFriends() {
  return (dispatch) => {
    dispatch({ type: READ_REQUEST });
    return dispatch(
      fetchJSON('/api/v1/friend', {
        method: 'GET',
      })
    )
      .then(({ data }) =>
        dispatch({
          type: READ_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: READ_FAILURE, error: err.error });
        throw err;
      });
  };
}

export function createFriend(name, reminder_frequency) {
  return (dispatch) => {
    dispatch({ type: CREATE_REQUEST });
    return dispatch(
      fetchJSON('/api/v1/friend', {
        method: 'POST',
        body: { name, reminder_frequency },
      })
    )
      .then(({ data }) =>
        dispatch({
          type: CREATE_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: CREATE_FAILURE, error: err.error });
        throw err;
      });
  };
}

export function updateFriend(id, name, reminder_frequency) {
  return (dispatch) => {
    dispatch({ type: UPDATE_REQUEST });
    return dispatch(
      fetchJSON(`/api/v1/friend/${id}`, {
        method: 'PATCH',
        body: { name, reminder_frequency },
      })
    )
      .then(({ data }) =>
        dispatch({
          type: UPDATE_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: UPDATE_FAILURE, error: err.error });
        throw err;
      });
  };
}

export function deleteFriend(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_REQUEST });
    return dispatch(
      fetchJSON(`/api/v1/friend/${id}`, {
        method: 'DELETE',
      })
    )
      .then(({ data }) =>
        dispatch({
          type: DELETE_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: DELETE_FAILURE, error: err.error });
        throw err;
      });
  };
}
