import React from 'react';
import styled from 'styled-components';
import H1 from '../H1';
import CenterRow from '../helpers/CenterRow';

const PaddingBox = styled.div`
  padding: 75px 0;
  margin: 0 auto;
`;

const Title = styled(H1)`
  margin: 20px 0 0 0;
`;

const Text = styled.div`
  font-size: 16px;
  text-align: center;
  width: 300px;
  margin: 15px auto 0 auto;
`;

const NoticeWithText = ({ image, title, children }) => (
  <PaddingBox>
    <CenterRow>{image}</CenterRow>
    <Title>{title}</Title>
    <Text>{children}</Text>
  </PaddingBox>
);

export default NoticeWithText;
