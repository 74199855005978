import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Scrollbars from 'react-scrollbars-custom';
import useBodyStyle from '../../utils/hooks/useBodyStyle';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import { LG_SCREEN } from '../../theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexScrollbar = ({
  children,
  refHeights = [],
  onReady = () => {},
  onScrollStop = () => {},
  reverse,
  desktopHeight,
}) => {
  useBodyStyle('overflowY', 'hidden');
  const isDesktopWidth = useMediaQuery(
    desktopHeight && `min-width: ${LG_SCREEN}px`
  );
  const [scrollbar, setScrollbar] = useState(null);
  const internalRef = useCallback(
    (node) => {
      if (node !== null && reverse) {
        node.scrollToBottom();
        setTimeout(() => node.scrollToBottom(), 0);
      }
      if (node !== null) {
        setScrollbar(node);
        onReady(node);
      }
    },
    // eslint-disable-next-line
    []
  );
  const offset = refHeights.reduce((s, v) => s + v, 0);
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  const height = `calc((var(--vh, 1vh) * 100) - ${offset}px)`;
  const maxHeight = isDesktopWidth ? `${desktopHeight - offset}px` : 'none';

  return (
    <Scrollbars
      ref={internalRef}
      style={{
        width: '100%',
        height,
        maxHeight,
      }}
      noScrollX
      contentProps={{
        renderer: ({ elementRef, ...restProps }) => (
          <Container {...restProps} ref={elementRef} className="Content" />
        ),
      }}
      onScrollStop={() => {
        onScrollStop(scrollbar);
      }}
    >
      {children}
    </Scrollbars>
  );
};

export default FlexScrollbar;
