import {
  NOT_AUTHENTICATED,
  LOGIN_SUCCESS,
  LOGIN_RESTORE,
  LOGIN_FAILURE,
  REFRESH_SUCCESS,
  REFRESH_FAILURE,
  LOGOUT,
} from '../reducers/auth';
import { CRYPT_SET } from '../reducers/crypt';
import { SET_DRAFT_TEXT } from '../reducers/editor';
import { saveState, resetState } from './localStorage';

export default function localStorageMiddleware() {
  return (store) => (next) => (action) => {
    switch (action.type) {
      case LOGIN_RESTORE:
      case LOGIN_SUCCESS:
      case REFRESH_SUCCESS:
      case SET_DRAFT_TEXT:
      case CRYPT_SET: {
        const done = next(action);
        const state = store.getState();
        saveState({
          auth: state.auth,
          crypt: state.crypt,
          editor: state.editor,
        });
        return done;
      }
      case NOT_AUTHENTICATED:
      case LOGIN_FAILURE:
      case REFRESH_FAILURE:
      case LOGOUT:
        resetState();
        return next(action);
      default:
        return next(action);
    }
  };
}
