import styled from 'styled-components';
import { WHITE_000 } from '../../theme';
import MediaWidths from './MediaWidths';

const TabLayoutPage = styled(MediaWidths)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  background-color: ${({ bgColor }) => bgColor || WHITE_000};
`;

TabLayoutPage.Header = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${({ padding: p = 0 }) => `${p}px ${p}px 0 ${p}px` || '0'};
`;

TabLayoutPage.Body = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${({ flexEnd }) => (flexEnd ? 'justify-content: flex-end;' : '')}
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: ${({ padding: p = 0 }) => `0 ${p}px` || '0'};
`;

TabLayoutPage.Footer = styled.div`
  box-sizing: border-box;
  align-self: flex-end;
  width: 100%;
  padding: ${({ padding: p = 0 }) => `0 ${p}px ${p}px ${p}px` || '0'};
`;

export default TabLayoutPage;
