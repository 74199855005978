import { useState, useCallback } from 'react';

export default function useCallbackState(deps) {
  const [state, setState] = useState(null);

  const callback = useCallback(
    (value) => {
      setState(value);
    },
    // eslint-disable-next-line
    deps
  );

  return [state, callback];
}
