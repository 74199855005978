import React from 'react';
import styled from 'styled-components';
import {
  GREY_000,
  GREY_200,
  GREY_900,
  RED_400,
  RED_200,
  RED_600,
  TEAL_500,
} from '../../theme';
import TrashIcon from '../svg/icons/TrashIcon';

const Background = styled.div`
  box-sizing: border-box;
  display: flex;

  background-color: ${GREY_900};
  height: 38px;
`;

const Bar = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  background-color: ${GREY_900};
  margin: 10px 20px 10px 20px;
`;

const DeleteButton = styled.button`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  color: ${RED_400};
  text-align: center;
  vertical-align: middle;

  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:disabled {
    cursor: auto;
    font-weight: 400;
    color: ${RED_600};
  }

  &:disabled path {
    fill: ${RED_600};
  }

  &:hover:enabled {
    color: ${RED_200};
  }

  &:hover path {
    fill: ${RED_200};
  }
`;

const CancelButton = styled.button`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  color: ${GREY_000};
  text-align: center;
  vertical-align: middle;

  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:disabled {
    cursor: auto;
    font-weight: 400;
    color: ${GREY_200};
  }

  &:hover:enabled {
    color: ${TEAL_500};
  }
`;

const Icon = styled.div`
  padding: 2px 6px 0 0;
`;

const EditEntryBar = React.forwardRef(
  (
    { disabled = false, onDelete = () => null, onDiscard = () => null },
    ref
  ) => (
    <Background ref={ref}>
      <Bar>
        <DeleteButton disabled={disabled} onClick={onDelete}>
          <Icon>
            <TrashIcon />{' '}
          </Icon>
          Delete Entry
        </DeleteButton>
        <CancelButton disabled={disabled} onClick={onDiscard}>
          Discard Changes
        </CancelButton>
      </Bar>
    </Background>
  )
);

export default EditEntryBar;
