import uuid from 'uuid/v4';
import aes from 'aes-js';
import isString from 'lodash.isstring';

function chunkText(str, size) {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);
  let i = 0;
  let o = 0;

  // eslint-disable-next-line no-plusplus
  for (; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
}

function getFlatUUID() {
  return uuid()
    .replace(/-/g, '')
    .toUpperCase();
}

function uuidToArray(val) {
  return new Uint8Array(chunkText(val, 2).map((chunk) => parseInt(chunk, 16)));
}

const hasCrypto = typeof crypto !== 'undefined' && crypto.getRandomValues;
const hasEncoding = typeof TextDecoder !== 'undefined';

export function createSecret() {
  const id = getFlatUUID().slice(0, 6);
  const secret = getFlatUUID();
  return `${id}.${secret}`;
}

export function hasCryptoSupport() {
  return hasCrypto && hasEncoding;
}

export function isValidSecret(secret) {
  return (
    secret && isString(secret) && /^[0-9A-F]{6}\.[0-9A-F]{32}$/.test(secret)
  );
}

export function getIdFromSecret(secret) {
  if (!secret && !isValidSecret(secret)) {
    return undefined;
  }
  return secret.split('.')[0];
}

export function getKeyFromSecret(secret) {
  if (!secret && !isValidSecret(secret)) {
    return undefined;
  }
  return secret.split('.')[1];
}

export function getKeyArrayFromSecret(secret) {
  if (!secret && !isValidSecret(secret)) {
    return undefined;
  }
  const key = secret.split('.')[1];
  return uuidToArray(key);
}

export function encryptText(text, keyArray) {
  const iv = getFlatUUID();
  const counter = new aes.Counter(uuidToArray(iv));
  const textBytes = new TextEncoder('utf-8').encode(text);
  // eslint-disable-next-line new-cap
  const aesCTR = new aes.ModeOfOperation.ctr(keyArray, counter);
  const encryptedBytes = aesCTR.encrypt(textBytes);
  const encryptedText = aes.utils.hex.fromBytes(encryptedBytes);
  return `${iv}${encryptedText}`;
}

export function decryptText(cryptText, keyArray) {
  const iv = cryptText.slice(0, 32);
  const encryptedText = cryptText.slice(32);

  const counter = new aes.Counter(uuidToArray(iv));
  const textBytes = aes.utils.hex.toBytes(encryptedText);
  // eslint-disable-next-line new-cap
  const aesCTR = new aes.ModeOfOperation.ctr(keyArray, counter);
  const decryptedBytes = aesCTR.decrypt(textBytes);
  return new TextDecoder('utf-8').decode(decryptedBytes);
}
