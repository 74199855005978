import React from 'react';
import styled from 'styled-components';
import { Field, useForm } from 'react-final-form';
import Description from './partials/Description';
import Label from './partials/Label';
import SelectButton from './partials/SelectButton';
import Spacer from '../helpers/Spacer';

const FieldSet = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0 0 0 0;
`;

const HiddenField = styled(Field)`
  display: none;
`;

const SelectButtonGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px auto;
  max-width: 300px;
`;

const WideSelectButton = styled(SelectButton)`
  width: 300px;
`;

const OptionGroupField = ({ children, name, description, options }) => {
  const { getFieldState } = useForm();
  const fieldState = getFieldState(name);
  const currentValue = fieldState && fieldState.value;
  const hasOddOptions = options.length % 2 === 1;
  return (
    <FieldSet>
      <Label as="legend" htmlFor={name}>
        {children}
      </Label>
      <Description>{description}</Description>

      <SelectButtonGrid>
        {options.map(({ label, value }, index) => {
          const id = `${name}-${value};`;
          let Button = SelectButton;
          if (hasOddOptions && index === options.length - 1) {
            Button = WideSelectButton;
          }
          return (
            <Spacer key={id} margin={15}>
              <HiddenField
                component="input"
                type="radio"
                id={id}
                name={name}
                value={value}
              />
              <Button htmlFor={id} isSelected={currentValue === value}>
                {label}
              </Button>
            </Spacer>
          );
        })}
      </SelectButtonGrid>
    </FieldSet>
  );
};

export default OptionGroupField;
