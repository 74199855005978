import styled from 'styled-components';
import {
  TEAL_000,
  GREY_100,
  GREY_700,
  TEAL_700,
  TEAL_800,
  BORDER_MD,
} from '../../../theme';

export default styled.label`
  display: block;
  box-sizing: border-box;

  background: ${({ isSelected }) => (isSelected ? TEAL_700 : GREY_100)};
  color: ${({ isSelected }) => (isSelected ? TEAL_000 : GREY_700)};
  letter-spacing: 0.5px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;

  border-radius: ${BORDER_MD};
  ${({ isSelected }) => (isSelected ? '' : 'cursor: pointer;')}

  padding: 12px 0;
  width: 140px;

  &:hover {
    background: ${({ isSelected }) => (isSelected ? TEAL_800 : TEAL_700)};
    color: ${TEAL_000};
  }
`;
