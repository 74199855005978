import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackIcon from '../svg/navigation/BackIcon';
import { TEAL_600, TEAL_700, TEAL_800, TEAL_900 } from '../../theme';

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ color }) => color};

  &:hover {
    color: ${({ activeColor }) => activeColor};
  }
  &:hover path {
    fill: ${({ activeColor, arrowActiveColor }) =>
      arrowActiveColor || activeColor};
  }
`;

const LinkWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;

const ClickWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Icon = styled.div`
  padding: 2px 6px 0 0;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: right;
`;

const BackButton = ({
  color = TEAL_900,
  activeColor = TEAL_700,
  arrowColor = TEAL_800,
  arrowActiveColor = TEAL_600,
  href,
  to,
  onClick,
}) => {
  const button = (
    <Label
      color={color}
      activeColor={activeColor}
      arrowActiveColor={arrowActiveColor}
    >
      <Icon>
        <BackIcon width={8} height={14} color={arrowColor || color} />
      </Icon>
      <Text>Back</Text>
    </Label>
  );
  const isUrl = !!href || !!to;
  return (
    <React.Fragment>
      {isUrl && (
        <LinkWrapper as={href ? 'a' : undefined} href={href} to={to}>
          {button}
        </LinkWrapper>
      )}
      {onClick && <ClickWrapper onClick={onClick}>{button}</ClickWrapper>}
    </React.Fragment>
  );
};

export default BackButton;
