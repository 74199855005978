import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getBlocksFromAnnoText from '../../../utils/annoText/getBlocksFromAnnoText';
import { getFriends } from '../../../reducers/friend';
import { TEAL_900 } from '../../../theme';

const MentionLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${TEAL_900};
  }
`;

const TextBlocks = ({ text, isWithinLink }) => {
  const friends = useSelector(getFriends);
  if (!text) {
    return null;
  }
  return getBlocksFromAnnoText(text, friends).map((block, index) => {
    if (!block.id && !block.name) {
      return block;
    }
    const { id, value } = block;
    const key = `${id}+${index}`;

    // Insert mention
    if (friends[id] && isWithinLink) {
      return <strong key={key}>@{friends[id].name}</strong>;
    }
    if (friends[id] && isWithinLink) {
      return (
        <MentionLink key={key} to={`/planner/friend/${friends[id].short_id}`}>
          <strong>@{friends[id].name}</strong>
        </MentionLink>
      );
    }
    return <strong key={key}>@{value}</strong>;
  });
};

export default React.memo(TextBlocks);
