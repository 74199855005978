import React from 'react';
import { TEAL_800 } from '../../../theme';

const Wordmark = ({ width = 330, height = 73, color = TEAL_800 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 330 73"
  >
    <path
      d="M18.5 18.84C18.4672 19.3358 18.2554 19.8027 17.9041 20.1541C17.5527 20.5054 17.0858 20.7172 16.59 20.75H11.59V49.2C11.6329 49.9472 11.9491 50.6525 12.4783 51.1817C13.0075 51.7109 13.7128 52.0271 14.46 52.07C14.9739 52.1188 15.4547 52.3453 15.8197 52.7103C16.1848 53.0753 16.4112 53.5561 16.46 54.07C16.4065 54.5735 16.1762 55.0418 15.81 55.3915C15.4438 55.7412 14.9654 55.9497 14.46 55.98C13.5586 56.0087 12.6608 55.8535 11.8214 55.5239C10.9819 55.1942 10.2184 54.697 9.57744 54.0626C8.93646 53.4282 8.43144 52.6698 8.09319 51.8338C7.75493 50.9978 7.59053 50.1017 7.61 49.2V20.75H2.72C0.809997 20.75 0.809998 19.75 0.809998 18.84C0.809998 17.93 0.809997 16.93 2.72 16.93H7.58V2.42001C7.6149 1.92497 7.82734 1.45918 8.17825 1.10827C8.52917 0.757355 8.99496 0.544916 9.49 0.51001C10.0071 0.526055 10.5011 0.728021 10.8813 1.07886C11.2615 1.42969 11.5025 1.90586 11.56 2.42001V16.93H16.56C17.0611 16.9558 17.5353 17.1645 17.8928 17.5165C18.2504 17.8685 18.4664 18.3394 18.5 18.84Z"
      fill={color}
    />
    <path
      d="M30.69 36.53C30.69 25.69 39.14 16.93 49.69 16.93C59.69 16.93 67.69 24.93 68.5 35.1V35.42C68.4924 35.6621 68.4363 35.9003 68.335 36.1204C68.2337 36.3404 68.0892 36.5379 67.9102 36.7011C67.7311 36.8643 67.5211 36.9899 67.2926 37.0704C67.0641 37.151 66.8218 37.1848 66.58 37.17H34.58C34.6703 41.144 36.2987 44.9281 39.1225 47.7258C41.9463 50.5235 45.7453 52.1166 49.72 52.17C52.2518 52.1742 54.7416 51.5235 56.9477 50.2812C59.1537 49.0389 61.001 47.2471 62.31 45.08C62.4346 44.8696 62.5996 44.686 62.7955 44.5398C62.9914 44.3936 63.2144 44.2877 63.4515 44.2282C63.6886 44.1687 63.9352 44.1568 64.177 44.1931C64.4187 44.2295 64.6509 44.3134 64.86 44.44C65.2979 44.72 65.6096 45.1595 65.7291 45.6653C65.8485 46.1712 65.7664 46.7037 65.5 47.15C63.8336 49.8536 61.5034 52.086 58.7309 53.635C55.9585 55.184 52.8359 55.9981 49.66 56C44.5632 55.9315 39.7022 53.8422 36.1451 50.1914C32.588 46.5405 30.6259 41.6268 30.69 36.53ZM34.75 33.43H64.32C63.7581 29.9297 61.9803 26.7396 59.299 24.4204C56.6177 22.1013 53.2047 20.8016 49.66 20.75C46.082 20.7786 42.6281 22.0644 39.9024 24.3824C37.1767 26.7005 35.3529 29.9031 34.75 33.43Z"
      fill={color}
    />
    <path
      d="M109.9 32.87C109.9 29.6874 108.636 26.6351 106.385 24.3847C104.135 22.1343 101.083 20.87 97.9 20.87C94.7182 20.9057 91.6752 22.178 89.415 24.4177C87.1548 26.6574 85.8547 29.6887 85.79 32.87V54.38C85.7404 54.7046 85.6019 55.0092 85.39 55.26C85.2304 55.4895 85.016 55.6755 84.7663 55.8013C84.5166 55.927 84.2394 55.9884 83.96 55.98H83.48C83.2109 55.9066 82.9592 55.7804 82.7394 55.6087C82.5196 55.437 82.3362 55.2233 82.2 54.98C82.1728 54.9467 82.1526 54.9083 82.1406 54.8671C82.1286 54.8259 82.1249 54.7826 82.13 54.74C82.1215 54.6793 82.0934 54.6231 82.05 54.58V18.92C82.05 18.424 82.247 17.9484 82.5977 17.5977C82.9484 17.247 83.424 17.05 83.92 17.05C84.4159 17.05 84.8916 17.247 85.2423 17.5977C85.593 17.9484 85.79 18.424 85.79 18.92V22.66C87.8812 20.1636 90.6906 18.372 93.8361 17.5289C96.9816 16.6857 100.31 16.8318 103.37 17.9474C106.429 19.0631 109.071 21.094 110.935 23.764C112.8 26.434 113.796 29.6135 113.79 32.87V54.07C113.755 54.554 113.538 55.0067 113.183 55.3373C112.827 55.6678 112.36 55.8515 111.875 55.8515C111.39 55.8515 110.922 55.6678 110.567 55.3373C110.212 55.0067 109.995 54.554 109.96 54.07L109.9 32.87Z"
      fill={color}
    />
    <path
      d="M160.5 48.41C158.77 50.7614 156.511 52.6722 153.905 53.9874C151.298 55.3026 148.419 55.9853 145.5 55.98C134.98 55.98 126.61 47.21 126.61 36.53C126.61 25.69 134.98 16.93 145.5 16.93C148.425 16.9371 151.308 17.6335 153.914 18.9626C156.52 20.2917 158.776 22.2162 160.5 24.58V2.1C160.523 1.58532 160.744 1.09944 161.117 0.743619C161.489 0.387796 161.985 0.189482 162.5 0.190003C163.004 0.197741 163.485 0.401456 163.842 0.757987C164.199 1.11452 164.402 1.59585 164.41 2.1V54.1C164.4 54.6041 164.193 55.0844 163.835 55.4391C163.477 55.7937 162.994 55.9949 162.49 56C161.975 56.0005 161.479 55.8022 161.107 55.4464C160.734 55.0906 160.513 54.6047 160.49 54.09L160.5 48.41ZM160.5 37.17V35.9C160.18 27.53 153.57 20.83 145.5 20.83C137.29 20.83 130.5 27.83 130.5 36.53C130.5 45.23 137.28 52.07 145.5 52.07C149.44 52.0039 153.202 50.416 155.998 47.6386C158.794 44.8613 160.408 41.1099 160.5 37.17Z"
      fill={color}
    />
    <path
      d="M178.58 47.85C178.739 47.6524 178.937 47.4889 179.161 47.3694C179.385 47.2499 179.631 47.1768 179.884 47.1544C180.137 47.132 180.392 47.1608 180.633 47.239C180.875 47.3173 181.098 47.4435 181.29 47.61C184 49.61 188.29 52.15 193.29 52.15C195.963 52.2185 198.58 51.3756 200.71 49.76C201.568 49.2759 202.292 48.5852 202.816 47.7505C203.34 46.9158 203.647 45.9635 203.71 44.98C203.63 43.15 202.91 41.87 200.92 40.2C199.24 38.92 196.45 37.97 192.63 37.33L192.47 37.25C188.96 36.61 185.85 35.82 183.62 34.25C182.362 33.5589 181.309 32.5474 180.568 31.3181C179.827 30.0887 179.424 28.6852 179.4 27.25C179.454 25.7331 179.864 24.2502 180.597 22.9212C181.33 21.5922 182.366 20.4545 183.62 19.6C186.471 17.7772 189.808 16.8604 193.19 16.97C198.19 16.97 201.79 19.28 204.82 21.19C205.245 21.4824 205.54 21.9275 205.644 22.4325C205.749 22.9374 205.654 23.4632 205.38 23.9C205.104 24.3212 204.673 24.6163 204.18 24.7212C203.688 24.8261 203.174 24.7322 202.75 24.46C199.88 22.55 197.01 20.55 193.19 20.55C190.536 20.4362 187.922 21.222 185.77 22.78C185.049 23.2919 184.453 23.9595 184.025 24.7333C183.598 25.5072 183.35 26.3673 183.3 27.25C183.3 28.84 184.02 29.96 185.53 31.15C187.969 32.6214 190.699 33.546 193.53 33.86H193.69C196.975 34.3287 200.147 35.3928 203.05 37C204.378 37.8741 205.472 39.0604 206.235 40.455C206.999 41.8496 207.409 43.4101 207.43 45C207.43 48.27 205.6 50.9 202.97 52.89C200.154 54.9619 196.736 56.0543 193.24 56C187.945 55.8028 182.869 53.8384 178.82 50.42C178.631 50.2698 178.475 50.0831 178.36 49.8711C178.246 49.6591 178.175 49.4261 178.153 49.1861C178.13 48.9461 178.157 48.704 178.23 48.4744C178.303 48.2448 178.422 48.0324 178.58 47.85Z"
      fill={color}
    />
    <path
      d="M249 32.31C249 25.45 243.42 20.83 236.56 20.83C229.7 20.83 224.21 25.45 224.21 32.31V54.31C224.21 54.46 224.13 54.54 224.13 54.62C224.13 54.7 224.05 54.86 223.97 54.94C223.86 55.1854 223.695 55.4028 223.489 55.5759C223.283 55.749 223.041 55.8735 222.78 55.94C222.737 55.9834 222.681 56.0115 222.62 56.02H222.22C221.939 56.0178 221.66 55.9602 221.401 55.8504C221.142 55.7405 220.907 55.5807 220.71 55.38C220.572 55.1838 220.464 54.9681 220.39 54.74C220.324 54.5368 220.297 54.3231 220.31 54.11V2.10997C220.411 1.66811 220.659 1.27354 221.013 0.990923C221.367 0.708302 221.807 0.554382 222.26 0.554382C222.713 0.554382 223.153 0.708302 223.507 0.990923C223.862 1.27354 224.109 1.66811 224.21 2.10997V22.66C225.727 20.8633 227.619 19.4197 229.752 18.43C231.885 17.4403 234.208 16.9283 236.56 16.93C245.56 16.93 252.9 23.3 252.9 32.31V54.07C252.799 54.5118 252.552 54.9064 252.197 55.189C251.843 55.4716 251.403 55.6256 250.95 55.6256C250.497 55.6256 250.057 55.4716 249.703 55.189C249.348 54.9064 249.101 54.5118 249 54.07V32.31Z"
      fill={color}
    />
    <path
      d="M269.26 6.08997C269.356 5.6431 269.603 5.24275 269.959 4.9556C270.314 4.66846 270.758 4.51184 271.215 4.51184C271.672 4.51184 272.115 4.66846 272.471 4.9556C272.827 5.24275 273.074 5.6431 273.17 6.08997V7.91997C273.233 8.21171 273.23 8.51385 273.161 8.80422C273.092 9.09459 272.959 9.36584 272.771 9.59808C272.584 9.83031 272.347 10.0176 272.077 10.1463C271.808 10.275 271.513 10.3418 271.215 10.3418C270.917 10.3418 270.622 10.275 270.353 10.1463C270.083 10.0176 269.846 9.83031 269.659 9.59808C269.471 9.36584 269.338 9.09459 269.269 8.80422C269.2 8.51385 269.197 8.21171 269.26 7.91997V6.08997ZM269.26 18.84C269.356 18.3931 269.603 17.9928 269.959 17.7056C270.314 17.4185 270.758 17.2618 271.215 17.2618C271.672 17.2618 272.115 17.4185 272.471 17.7056C272.827 17.9928 273.074 18.3931 273.17 18.84V54.07C273.074 54.5168 272.827 54.9172 272.471 55.2043C272.115 55.4915 271.672 55.6481 271.215 55.6481C270.758 55.6481 270.314 55.4915 269.959 55.2043C269.603 54.9172 269.356 54.5168 269.26 54.07V18.84Z"
      fill={color}
    />
    <path
      d="M291.58 37.09V18.84C291.585 18.335 291.788 17.8522 292.145 17.4952C292.502 17.1381 292.985 16.9352 293.49 16.93C294.005 16.9295 294.501 17.1278 294.873 17.4836C295.246 17.8394 295.467 18.3253 295.49 18.84V24.5C297.197 22.1653 299.428 20.2639 302.004 18.9491C304.579 17.6343 307.428 16.9427 310.32 16.93C312.832 16.9561 315.315 17.4781 317.625 18.4662C319.935 19.4543 322.028 20.8889 323.782 22.6878C325.536 24.4866 326.918 26.6142 327.848 28.9485C328.777 31.2827 329.237 33.7777 329.2 36.29C329.2 46.89 320.83 55.74 310.32 55.74C307.419 55.7277 304.563 55.028 301.985 53.6982C299.407 52.3684 297.181 50.4465 295.49 48.09V70.4C295.469 70.9164 295.25 71.4048 294.877 71.7628C294.504 72.1207 294.007 72.3204 293.49 72.32C292.984 72.3122 292.501 72.1071 292.145 71.7484C291.788 71.3897 291.585 70.9059 291.58 70.4V37.09ZM295.48 36.29C295.48 44.9 302.25 51.83 310.31 51.91C314.366 51.8129 318.218 50.1164 321.028 47.1904C323.838 44.2644 325.377 40.3462 325.31 36.29C325.347 34.2882 324.988 32.2987 324.253 30.4363C323.518 28.574 322.422 26.8754 321.027 25.4384C319.633 24.0014 317.968 22.8544 316.129 22.0635C314.29 21.2726 312.312 20.8534 310.31 20.83C302.42 20.83 295.8 27.37 295.48 35.65V36.29Z"
      fill={color}
    />
  </svg>
);

export default Wordmark;
