import startOfDay from 'date-fns/startOfDay';

const ONE_DAY = 1000 * 60 * 60 * 24;

// We get the thread_id by taking a date (1579421489884) and
// setting to the start of the day (0h 0m 0s). We then divide by
// 1,440,000 (one day in milliseconds).
export function getThreadId(date) {
  if (date === undefined || date === null) {
    return undefined;
  }
  const threadId = Math.ceil(startOfDay(date).getTime() / ONE_DAY);

  // Deals with the case where `threadId=-0`.
  return threadId === 0 ? 0 : threadId;
}

// Returns the (0h 0m 0s) date for the given ThreadId.
export function getDateFromThreadId(threadId) {
  if (threadId === undefined || threadId === null) {
    return undefined;
  }
  return startOfDay(threadId * ONE_DAY);
}
