import React, { useState, useEffect, useCallback } from 'react';
import Quill from 'quill';
import { useSelector } from 'react-redux';
import getDeltaFromAnnoText from '../../utils/annoText/getDeltaFromAnnoText';
import getAnnoTextFromDelta from '../../utils/annoText/getAnnoTextFromDelta';
import { getFriends, getFriendList } from '../../reducers/friend';

function isMentionChange(value, source) {
  return (
    source === 'api' &&
    value.ops &&
    value.ops.some((op) => op.insert && op.insert.mention)
  );
}

const QuillEntryEditor = ({
  editorRef,
  text,
  placeholder,
  onChange = () => {},
  onFocus = () => {},
  onEscape = () => {},
}) => {
  const [editor, setEditor] = useState(null);
  const [changeHandler, setChangeHandler] = useState(null);
  const [selectionHandler, setSelectionHandler] = useState(null);
  const [editorText, setEditorText] = useState(text);
  const friends = useSelector(getFriends);
  const friendList = useSelector(getFriendList);

  // Sync editor with prop changes.
  useEffect(
    () => {
      if (editorText !== text) {
        editor.setContents(getDeltaFromAnnoText(text, friends));
        setEditorText(text);
        if (editor.getSelection()) {
          editor.setSelection(editor.getLength());
        }
      }
    },
    // Including `editorText` will cause loops.
    // eslint-disable-next-line
    [editor, text, friends]
  );
  useEffect(
    () => {
      if (editor) {
        if (changeHandler) {
          editor.off('text-change', changeHandler.func);
        }
        const textChangeHandler = (value, prevValue, source) => {
          if (source === 'user' || isMentionChange(value, source)) {
            const delta = editor.getContents();
            const { text: annoText, friendIds } = getAnnoTextFromDelta(delta);
            setEditorText(annoText);
            onChange({
              text: annoText,
              friendIds,
            });
          }
        };
        editor.on('text-change', textChangeHandler);
        setChangeHandler({ func: textChangeHandler });
      }
    },
    // eslint-disable-next-line
    [editor, onChange]
  );

  useEffect(
    () => {
      if (editor) {
        if (selectionHandler) {
          editor.off('selection-change', selectionHandler.func);
        }
        const textChangeHandler = (range, oldRange) => {
          if (!oldRange && range) {
            onFocus(true);
          }
          onFocus(false);
        };
        editor.on('selection-change', textChangeHandler);
        setSelectionHandler({ func: textChangeHandler });
      }
    },
    // eslint-disable-next-line
    [editor, onChange]
  );

  const quillRef = useCallback(
    (node) => {
      // Will attempt to redraw when the component is destroyed which would
      // crash the app if `!node` was not there.
      if (!node || editor) {
        return;
      }

      const quill = new Quill(node, {
        theme: null,
        formats: ['mention'],
        modules: {
          mention: {
            source: (searchTerm, renderList) => {
              const mentionList = friendList.map((friend) => ({
                id: friend.id,
                value: friend.name,
              }));
              if (searchTerm.length === 0) {
                renderList(mentionList, searchTerm);
              } else {
                const term = searchTerm.toLowerCase();
                const matches = mentionList.filter(({ value }) =>
                  value.toLowerCase().includes(term)
                );
                renderList(matches, searchTerm);
              }
            },
          },
        },
        placeholder,
      });
      quill.keyboard.addBinding(
        {
          key: 'escape',
        },
        () => {
          quill.blur();
          onEscape();
        }
      );
      setEditor(quill);
      if (editorRef) {
        editorRef(quill);
      }
    },
    // eslint-disable-next-line
    []
  );

  return <div ref={quillRef} />;
};

export default React.memo(QuillEntryEditor);
