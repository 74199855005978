import omit from 'object.omit';
import { LOGOUT } from '../auth';
import reduceToObj from '../../utils/reduceToObj';
import fetchJSON from '../../utils/core/fetchJSON';
import { getThreadId } from '../../utils/getThreadId';
import {
  READ_SUCCESS as ENTRY_READ,
  CREATE_SUCCESS as ENTRY_CREATE,
  DELETE_SUCCESS as ENTRY_DELETE,
} from '../entry/actions';

export const THREAD_PAGE_SIZE = 10;
export const THREAD_EXPAND_SIZE = 5;
export const READ_REQUEST = 'thread/READ_REQUEST';
export const READ_SUCCESS = 'thread/READ_SUCCESS';
export const READ_FAILURE = 'thread/READ_FAILURE';
export const INIT_VIRTUAL = 'thread/INIT_VIRTUAL';
export const EXPAND_TIMELINE = 'thread/EXPAND_TIMELINE';
export const EXTEND_TIMELINE = 'thread/EXTEND_TIMELINE';
export * from './getTimeline';
export * from './selectors';

const initialState = {
  status: {
    ok: false,
    isFetching: false,
    error: null,
  },
  virtualThreads: {},
  threads: {},
  limit: THREAD_PAGE_SIZE,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ENTRY_READ: {
      const entries = action.payload.data;
      const threadIds = entries.reduce(
        (set, { thread_id }) => set.add(thread_id),
        new Set()
      );
      const threads = reduceToObj(Array.from(threadIds), (id) => ({
        [id]: { ...state.threads[id], is_loaded: true },
      }));
      return {
        ...state,
        threads: {
          ...state.threads,
          ...threads,
        },
      };
    }
    case ENTRY_CREATE: {
      const threadId = action.payload.data.thread_id;
      const thread = state.threads[threadId];
      const nextThread = {
        id: threadId,
        entry_count: thread ? thread.entry_count + 1 : 1,
        is_loaded: true,
      };

      return {
        ...state,
        virtualThreads: omit(state.virtualThreads, [`${threadId}`]),
        threads: {
          ...state.threads,
          [threadId]: nextThread,
        },
      };
    }
    case ENTRY_DELETE: {
      const threadId = action.payload.data.thread_id;
      const thread = state.threads[threadId];
      const nextThread = {
        ...thread,
        entry_count: thread.entry_count - 1,
      };

      if (nextThread.entry_count <= 0) {
        return {
          ...state,
          virtualThreads: {
            ...state.virtualThreads,
            [threadId]: nextThread,
          },
          threads: omit(state.threads, [`${threadId}`]),
        };
      }

      return {
        ...state,
        threads: {
          ...state.threads,
          [threadId]: nextThread,
        },
      };
    }
    case INIT_VIRTUAL: {
      const threadId = getThreadId(new Date());
      // Generate virtual ids for the last seven days.
      const virtualIds = [0, 1, 2, 3, 4, 5, 6].map((i) => threadId - i);
      const nextSevenDays = reduceToObj(virtualIds, (id) => ({
        [id]: {
          id,
          entry_count: 0,
          is_loaded: true,
        },
      }));
      return {
        ...state,
        virtualThreads: {
          ...state.virtualThreads,
          ...nextSevenDays,
        },
      };
    }
    case EXPAND_TIMELINE: {
      // Either we use payload provided thread_id or today's.
      const threadId = action.payload.id;

      // Generate virtual ids for the 5 days
      // Do not create virtual ids for threads that already exist.
      const virtualIds = [0, 1, 2, 3, 4]
        .map((i) => threadId - i)
        .filter((virtualId) => !state.threads[virtualId]);

      const nextFiveDays = reduceToObj(virtualIds, (id) => ({
        [id]: {
          id,
          entry_count: 0,
          is_loaded: true,
        },
      }));
      return {
        ...state,
        virtualThreads: {
          ...state.virtualThreads,
          ...nextFiveDays,
        },
      };
    }
    case EXTEND_TIMELINE: {
      return {
        ...state,
        limit: state.limit + THREAD_PAGE_SIZE,
      };
    }
    case READ_SUCCESS: {
      const { data } = action.payload;
      const threads = reduceToObj(data, (thread) => ({
        [thread.id]: {
          ...thread,
          is_loaded: false,
        },
      }));

      // We cannot have virtual threads existing for real threads.
      const ids = data.map(({ id }) => `${id}`);
      const virtualThreads = omit(state.virtualThreads, ids);

      return {
        ...state,
        status: {
          ok: true,
          isFetching: false,
          error: null,
        },
        virtualThreads,
        threads: {
          ...state.threads,
          ...threads,
        },
      };
    }
    case READ_REQUEST: {
      return {
        ...state,
        status: {
          ok: false,
          isFetching: true,
          error: null,
        },
      };
    }
    case READ_FAILURE: {
      return {
        ...state,
        status: {
          ok: false,
          isFetching: false,
          error: action.error,
        },
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function fetchThreads() {
  return (dispatch) => {
    dispatch({ type: READ_REQUEST });
    return dispatch(
      fetchJSON('/api/v1/thread', {
        method: 'GET',
      })
    )
      .then(({ data }) =>
        dispatch({
          type: READ_SUCCESS,
          payload: { data },
        })
      )
      .catch((err) => {
        dispatch({ type: READ_FAILURE, error: err.error });
        throw err;
      });
  };
}

export function initVirtualThreads() {
  return { type: INIT_VIRTUAL };
}

// Used when we want to create virtual threads between gaps.
export function expandTimeline(threadId) {
  return { type: EXPAND_TIMELINE, payload: { id: threadId } };
}

// Used when we want to load more entries.
export function extendTimeline() {
  return { type: EXTEND_TIMELINE };
}
