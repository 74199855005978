import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { Helmet } from 'react-helmet-async';
import Button from '../../../components/buttons/Button';
import Divider from '../../../components/Divider';
import FlexScrollbar from '../../../components/FlexScrollbar';
import Spacer from '../../../components/helpers/Spacer';
import TextField from '../../../components/fields/TextField';
import TabLayoutContainer from '../../../components/tabLayout/TabLayoutContainer';
import TabLayoutPage from '../../../components/tabLayout/TabLayoutPage';
import TabNavigation from '../../../components/tabLayout/TabNavigation';
import TabSubNavigation from '../../../components/tabLayout/TabSubNavigation';
import useRefHeight from '../../../utils/hooks/useRefHeight';
import {
  getPerson,
  getPersonStatus,
  updateDetails,
} from '../../../reducers/person';

const ChangeDetailsPage = () => {
  const { isFetching } = useSelector(getPersonStatus);
  const person = useSelector(getPerson);
  const [navHeight, navRef] = useRefHeight();
  const [subNavHeight, subNavRef] = useRefHeight();
  const dispatch = useDispatch();
  const history = useHistory();

  const submit = ({ current_password, name, email }) => {
    toast.dismiss();
    return dispatch(updateDetails(current_password, name, email))
      .then(() => {
        history.push('/options');
        toast.success('Account details successfully updated.', {
          autoClose: 4000,
        });
      })
      .catch((err) => {
        toast.error(err.error);
      });
  };

  return (
    <React.Fragment>
      <Helmet title="Change Details" />
      <TabLayoutContainer
        navigation={<TabNavigation ref={navRef} active="options" hasSubNav />}
      >
        <TabSubNavigation ref={subNavRef} backButtonTo="/options">
          Change Details
        </TabSubNavigation>
        <Form
          initialValues={{
            name: person.name,
            email: person.email,
          }}
          onSubmit={submit}
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = 'Name is required.';
            }

            if (!values.email) {
              errors.email = 'Email is required.';
            }

            if (!values.current_password) {
              errors.current_password = 'Current password is required.';
            }

            return errors;
          }}
          render={({ handleSubmit, invalid }) => (
            <TabLayoutPage as="form" onSubmit={handleSubmit}>
              <FlexScrollbar refHeights={[navHeight, subNavHeight]}>
                <TabLayoutPage.Header padding={15} />
                <TabLayoutPage.Body padding={15}>
                  <TextField
                    name="name"
                    type="text"
                    placeholder="Friendly Plant"
                    autoComplete="new-password"
                  >
                    <strong>Name</strong>
                  </TextField>

                  <TextField
                    name="email"
                    type="email"
                    placeholder="friendlyplant@tendship.com"
                    autoComplete="new-password"
                  >
                    <strong>Email</strong>
                  </TextField>
                </TabLayoutPage.Body>
                <TabLayoutPage.Footer padding={15}>
                  <Divider />
                  <TextField
                    name="current_password"
                    type="password"
                    placeholder="********"
                    hint="Your current password is required to make this change."
                  >
                    <strong>Current Password</strong>
                  </TextField>
                  <Spacer />
                  <Button
                    type="submit"
                    variant="primary"
                    size="block"
                    disabled={isFetching || invalid}
                  >
                    Save Changes
                  </Button>
                </TabLayoutPage.Footer>
              </FlexScrollbar>
            </TabLayoutPage>
          )}
        />
      </TabLayoutContainer>
    </React.Fragment>
  );
};

export default ChangeDetailsPage;
