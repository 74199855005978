import { useEffect } from 'react';
import { toast, Slide } from 'react-toastify';
import { useHistory } from 'react-router-dom';

toast.configure({
  transition: Slide,
  position: 'top-center',
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
});

const ToastManager = () => {
  const history = useHistory();

  // Everytime we transition to another page, we want to clear all toasts.
  useEffect(
    () =>
      history.listen(() => {
        toast.dismiss();
      }),
    // eslint-disable-next-line
    [history.location.key]
  );
  return null;
};

export default ToastManager;
