import React from 'react';
import { Helmet } from 'react-helmet-async';
import NoticeWithText from '../../../components/NoticeWithText';
import WipIcon from '../../../components/svg/icons/WipIcon';
import SwipeNavigation from '../../../components/SwipeNavigation';
import TabLayoutContainer from '../../../components/tabLayout/TabLayoutContainer';
import TabNavigation from '../../../components/tabLayout/TabNavigation';
import TabLayoutPage from '../../../components/tabLayout/TabLayoutPage';

const SearchPage = () => (
  <SwipeNavigation leftTo="/planner" rightTo="/options">
    <Helmet title="Search" />
    <TabLayoutContainer navigation={<TabNavigation active="search" />}>
      <TabLayoutPage>
        <TabLayoutPage.Header padding={15} />
        <TabLayoutPage.Body padding={15}>
          <NoticeWithText image={<WipIcon />} title="Coming Soon">
            Don’t worry. By the time you need this feature, it’ll be
            implemented.
          </NoticeWithText>
        </TabLayoutPage.Body>
        <TabLayoutPage.Footer padding={15} />
      </TabLayoutPage>
    </TabLayoutContainer>
  </SwipeNavigation>
);

export default SearchPage;
