import React from 'react';
import { GREY_200, GREY_900 } from '../../../theme';

const SignPostIcon = ({
  width = 30,
  height = 30,
  primaryColor = GREY_200,
  secondaryColor = GREY_900,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.625 145.312C65.625 146.556 66.1189 147.748 66.9979 148.627C67.877 149.506 69.0693 150 70.3125 150H79.6875C80.2597 149.999 80.8268 149.893 81.3604 149.687L81.5127 149.631L81.542 149.616C82.0884 149.382 82.5854 149.046 83.0068 148.626C83.4423 148.191 83.7874 147.675 84.0222 147.106C84.257 146.537 84.3769 145.928 84.375 145.312V112.5H65.625V145.312ZM79.6875 0H70.3125C69.0693 0 67.877 0.49386 66.9979 1.37294C66.1189 2.25201 65.625 3.4443 65.625 4.6875V9.375H84.375V4.6875C84.375 3.4443 83.8812 2.25201 83.0021 1.37294C82.123 0.49386 80.9307 0 79.6875 0V0ZM65.625 65.625H84.375V56.25H65.625V65.625Z"
      fill={secondaryColor}
    />
    <path
      d="M133.594 65.625H20.6923C19.4611 65.6248 18.2419 65.8671 17.1043 66.3381C15.9667 66.8091 14.9331 67.4996 14.0624 68.3701L1.37393 81.0615C0.938538 81.4968 0.593163 82.0136 0.357527 82.5824C0.121892 83.1512 0.000610352 83.7608 0.000610352 84.3765C0.000610352 84.9921 0.121892 85.6018 0.357527 86.1705C0.593163 86.7393 0.938538 87.2561 1.37393 87.6914L14.0624 100.38C14.9331 101.25 15.9667 101.941 17.1043 102.412C18.2419 102.883 19.4611 103.125 20.6923 103.125H133.594C135.458 103.125 137.247 102.384 138.566 101.066C139.884 99.747 140.625 97.9586 140.625 96.0938V72.6562C140.625 70.7914 139.884 69.003 138.566 67.6844C137.247 66.3658 135.458 65.625 133.594 65.625ZM148.626 24.8115L135.937 12.1201C135.067 11.2496 134.033 10.5591 132.896 10.0881C131.758 9.61713 130.539 9.3748 129.308 9.375H16.4062C14.5413 9.375 12.7529 10.1158 11.4343 11.4344C10.1157 12.753 9.3749 14.5414 9.3749 16.4062V39.8438C9.3749 41.7086 10.1157 43.497 11.4343 44.8156C12.7529 46.1342 14.5413 46.875 16.4062 46.875H129.308C130.539 46.8752 131.758 46.6329 132.896 46.1619C134.033 45.6909 135.067 45.0004 135.937 44.1299L148.626 31.4414C149.061 31.0061 149.407 30.4893 149.642 29.9205C149.878 29.3518 149.999 28.7421 149.999 28.1265C149.999 27.5108 149.878 26.9012 149.642 26.3324C149.407 25.7636 149.061 25.2468 148.626 24.8115Z"
      fill={primaryColor}
    />
  </svg>
);

export default SignPostIcon;
