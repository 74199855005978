import React from 'react';
import { Helmet } from 'react-helmet-async';
import FlexScrollbar from '../../../components/FlexScrollbar';
import PanelLayoutContainer from '../../../components/panelLayout/PanelLayoutContainer';
import PanelNavigation from '../../../components/panelLayout/PanelNavigation';
import PanelLayoutPage from '../../../components/panelLayout/PanelLayoutPage';
import NoticeWithText from '../../../components/NoticeWithText';
import SignPostIcon from '../../../components/svg/icons/SignPostIcon';
import useRefHeight from '../../../utils/hooks/useRefHeight';

const NotFoundPage = () => {
  const [navHeight, navRef] = useRefHeight();
  return (
    <React.Fragment>
      <Helmet title="Not Found" />
      <PanelLayoutContainer>
        <PanelLayoutPage>
          <PanelNavigation ref={navRef} backButtonTo="/" />
          <FlexScrollbar
            refHeights={[navHeight]}
            desktopHeight={PanelLayoutPage.minHeight}
          >
            <PanelLayoutPage.Header>
              <NoticeWithText
                image={<SignPostIcon width={150} height={150} />}
                title="Page Not Found"
              >
                This page does not exist or you don’t have access to it.
              </NoticeWithText>
            </PanelLayoutPage.Header>
            <PanelLayoutPage.Body></PanelLayoutPage.Body>
            <PanelLayoutPage.Footer></PanelLayoutPage.Footer>
          </FlexScrollbar>
        </PanelLayoutPage>
      </PanelLayoutContainer>
    </React.Fragment>
  );
};

export default NotFoundPage;
