import React from 'react';
import { useForm } from 'react-final-form';
import Dropzone from 'react-dropzone';

const DropArea = ({ children, name }) => {
  const { getFieldState } = useForm();
  const fieldState = getFieldState(name);

  const handleFileDrop = (files) => {
    const reader = new FileReader();
    reader.onload = () => {
      fieldState.change(reader.result);
    };
    reader.readAsText(files[0]);
  };

  return (
    <Dropzone multiple={false} disableClick onDrop={handleFileDrop} style={{}}>
      {children}
    </Dropzone>
  );
};

export default DropArea;
